import { Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { QueryKeys, URLQuery } from "../../../../../common/utils/queryUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { CalcType } from "../../../enums";
import { CALC_ROUTE_PATHS } from "../../../paths";
import {
  getCalcRecordRequestResponseActions,
  getCalcRecordStackTracesLogsActions,
  getCalcRecordWebservicesLogsActions
} from "../../ducks";
import { CalcRecordFilterPageResult, CalcRecordList } from "../../types";
import CalcTypeTag from "../CalcTypeTag";
import OperationTypeTag from "../OperationTypeTag";

interface Props {
  calcRecordsPage: CalcRecordFilterPageResult;
  onGetRequestResponseLogs: typeof getCalcRecordRequestResponseActions.request;
  onGetWebserviceLogs: typeof getCalcRecordWebservicesLogsActions.request;
  onGetStackTraceLogs: typeof getCalcRecordStackTracesLogsActions.request;
  onPageChange: (pageNumber: number) => void;
}

const CalcRecordsListTableView = (props: Props) => {
  const columns: ColumnsType<CalcRecordList> = [
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "account",
      title: t("calc.records.attrs.userId"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.user.name}</Ellipsis>
    },
    {
      key: "operationType",
      title: t("calc.records.enums.operationType._label"),
      width: 140,
      render: (_, record) => <OperationTypeTag type={record.operationType} style={{ marginRight: 0 }} />
    },
    {
      key: "calcType",
      title: t("calc.enums.calcType._label"),
      width: 130,
      render: (_, record) => <CalcTypeTag type={record.calcType} style={{ marginRight: 0 }} />
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 370,
      render: (_, record) => {
        const queryParams = new URLSearchParams({ [QueryKeys.RECORD_ID]: record.id });
        let calcPath = "";

        switch (record.calcType) {
          case CalcType.REALTY:
            calcPath = CALC_ROUTE_PATHS.realty.to;
            break;
          case CalcType.TRAVEL:
            calcPath = CALC_ROUTE_PATHS.travel.to;
            break;
          case CalcType.MTPL:
          case CalcType.CRASH:
          case CalcType.MTPL_CRASH:
          case CalcType.GAP:
          case CalcType.PAS:
            calcPath = CALC_ROUTE_PATHS.vehicle.to;
            break;
        }

        return (
          <>
            <ActionTextIcon
              icon="laptop"
              color="blue"
              text={t("calc.records.actions.showLogs")}
              onClick={() => props.onGetRequestResponseLogs({ id: record.id })}
            />
            <Divider type="vertical" />

            <ActionTextIcon
              icon="cloud"
              color="blue"
              text={t("calc.records.actions.showWebserviceLogs")}
              disabled={!record.hasWebservicesLogs}
              onClick={() => props.onGetWebserviceLogs({ id: record.id })}
            />
            <Divider type="vertical" />

            <ActionTextIcon
              icon="exclamation"
              color="orange"
              text={t("calc.records.actions.showStackTraceLogs")}
              disabled={!record.hasStackTracesLogs}
              onClick={() => props.onGetStackTraceLogs({ id: record.id })}
            />
            <Divider type="vertical" />

            <ActionTextIcon
              path={`${calcPath}${URLQuery.toString(queryParams)}`}
              target="_blank"
              icon="select"
              color="green"
              text={t("calc.records.actions.showInCalcForm")}
            />
          </>
        );
      }
    }
  ];

  return (
    <Table<CalcRecordList>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.MEDIUM }}
      dataSource={props.calcRecordsPage.pageData}
      pagination={{
        ...paginationTableProps,
        current: props.calcRecordsPage.pageIndex + 1,
        pageSize: props.calcRecordsPage.pageSize,
        total: props.calcRecordsPage.totalElementsCount,
        onChange: props.onPageChange
      }}
    />
  );
};

export default CalcRecordsListTableView;
