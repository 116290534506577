import { AutoComplete, Form } from "antd";
import { AutoCompleteProps } from "antd/lib/auto-complete";
import { FormItemProps } from "antd/lib/form";
import { CommercialRegisterSections } from "../../../../common/constants";
import { selectFilterFunction } from "../../../../common/utils/formUtils";

interface Props {
  formItemProps: FormItemProps;
  autocompleteProps?: AutoCompleteProps<string>;
}

const CommercialRegisterSectionAutoComplete = ({ formItemProps, autocompleteProps }: Props) => (
  <Form.Item {...formItemProps}>
    <AutoComplete
      {...autocompleteProps}
      dropdownMatchSelectWidth={false}
      options={CommercialRegisterSections.map(section => ({ value: section, label: section }))}
      filterOption={selectFilterFunction}
    />
  </Form.Item>
);

export default CommercialRegisterSectionAutoComplete;
