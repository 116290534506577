import { Button, Card, Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import moment, { Moment } from "moment/moment";
import t from "../../../../../app/i18n";
import BooleanRadioFormItem from "../../../../../common/components/form/components/BooleanRadioFormItem";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { datePickerStandardProps, selectStandardProps } from "../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import CityAutoComplete from "../../../../enumerations/components/form/CityAutoComplete";
import { ProductFinancialSector } from "../../../../product/enums";
import { FinancialMediationGeneralAttachmentType } from "../../../enums";
import { FINANCIAL_MEDIATION_SECTOR_TO_ATTACHMENT_MAP } from "../../../utils";

interface Props {
  formDisabled?: boolean;
  sector: ProductFinancialSector;
  showSecondaryClient: boolean;
  validateAllFields: boolean;
  onFormSubmit: () => void;
}

const signDateRule: Rule = {
  validator: (_, value) =>
    moment.isMoment(value) && (value as Moment).isBefore(moment(), "day")
      ? Promise.reject(t("financialMediation.validations.signDateRule"))
      : Promise.resolve(),
  warningOnly: true
};

const FinancialMediationFormOtherDataSection = ({
  formDisabled,
  sector,
  showSecondaryClient,
  validateAllFields,
  onFormSubmit
}: Props) => {
  const colSpan = 4;
  const consentFirstColSpan = 9;
  const consentOtherColSpan = 5;

  const clientDataTPrefix = "financialMediation.attrs.mediationData.policyHolderData";

  return (
    <Card type="inner" className="card-box" title={t("financialMediation.sections.otherDataCard")}>
      <Divider orientation="left" className="divider-subheader">
        {t("financialMediation.sections.attachments")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item
            name={["mediationData", "generalAttachments"]}
            label={<span className="form-item-additional">{t("financialMediation.helpers.generalAttachments")}</span>}
            rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
          >
            <Select
              {...selectStandardProps}
              mode="multiple"
              maxTagCount="responsive"
              options={Object.keys(FinancialMediationGeneralAttachmentType).map(attachment => ({
                label: t("financialMediation.enums.generalAttachmentType." + attachment),
                value: attachment
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["mediationData", "otherGeneralAttachments"]}
            label={t("financialMediation.attrs.mediationData.otherGeneralAttachments")}
            rules={[validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan * 2}>
          <Form.Item
            name={["mediationData", "sectorAttachments"]}
            label={<span className="form-item-additional">{t("financialMediation.helpers.sectorAttachments")}</span>}
            rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
          >
            <Select
              {...selectStandardProps}
              mode="multiple"
              maxTagCount="responsive"
              options={FINANCIAL_MEDIATION_SECTOR_TO_ATTACHMENT_MAP.get(sector)?.map(attachment => ({
                label: t("financialMediation.enums.sectorAttachmentType." + attachment),
                value: attachment
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["mediationData", "otherSectorAttachments"]}
            label={t("financialMediation.attrs.mediationData.otherSectorAttachments")}
            rules={[validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" className="divider-subheader">
        {t("financialMediation.sections.clientsConsents")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col offset={consentFirstColSpan} span={consentOtherColSpan} className="center-align">
          <b>{t("financialMediation.attrs.policyHolder")}</b>
        </Col>

        <Col span={consentOtherColSpan} className="center-align">
          {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={consentFirstColSpan} className="form-item-additional">
          {t(`${clientDataTPrefix}.understandProvidedInformation`)}
        </Col>

        <Col span={consentOtherColSpan}>
          <BooleanRadioFormItem
            formItemProps={{
              name: ["mediationData", "policyHolderData", "understandProvidedInformation"],
              rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
              className: "margin-bottom-small"
            }}
            useAgreementLabels
          />
        </Col>

        <Col span={consentOtherColSpan}>
          {showSecondaryClient && (
            <BooleanRadioFormItem
              formItemProps={{
                name: ["mediationData", "secondaryClientData", "understandProvidedInformation"],
                rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                className: "margin-bottom-small"
              }}
              useAgreementLabels
            />
          )}
        </Col>
      </Row>

      {(sector === ProductFinancialSector.NON_LIFE_INSURANCES || sector === ProductFinancialSector.LIFE_INSURANCES) && (
        <Row gutter={rowGutter}>
          <Col span={consentFirstColSpan} className="form-item-additional">
            {t(`${clientDataTPrefix}.confirmSummaryInformation`)}
          </Col>

          <Col span={consentOtherColSpan}>
            <BooleanRadioFormItem
              formItemProps={{
                name: ["mediationData", "policyHolderData", "confirmSummaryInformation"],
                rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                className: "margin-bottom-small"
              }}
              useAgreementLabels
            />
          </Col>

          <Col span={consentOtherColSpan}>
            {showSecondaryClient && (
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "secondaryClientData", "confirmSummaryInformation"],
                  rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                  className: "margin-bottom-small"
                }}
                useAgreementLabels
              />
            )}
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        <Col span={consentFirstColSpan} className="form-item-additional">
          <LabelWithPopover
            label={t(`${clientDataTPrefix}.financialRewardConsent`)}
            popoverTitle={t("financialMediation.helpers.financialRewardConsentDesc.title")}
            popoverContent={
              <div style={{ maxWidth: "550px", maxHeight: "350px", overflowY: "scroll" }}>
                {[1, 2, 3].map(value => (
                  <p key={value}>{t(`financialMediation.helpers.financialRewardConsentDesc.p${value}`)}</p>
                ))}
              </div>
            }
          />
        </Col>

        <Col span={consentOtherColSpan}>
          <BooleanRadioFormItem
            formItemProps={{
              name: ["mediationData", "policyHolderData", "financialRewardConsent"],
              rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
              className: "margin-bottom-small"
            }}
            useAgreementLabels
          />
        </Col>

        <Col span={consentOtherColSpan}>
          {showSecondaryClient && (
            <BooleanRadioFormItem
              formItemProps={{
                name: ["mediationData", "secondaryClientData", "financialRewardConsent"],
                rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                className: "margin-bottom-small"
              }}
              useAgreementLabels
            />
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={consentFirstColSpan} className="form-item-additional">
          <LabelWithPopover
            label={t(`${clientDataTPrefix}.providedInformationConsent`)}
            popoverTitle={t("financialMediation.helpers.providedInformationConsentDesc.title")}
            popoverContent={
              <div style={{ maxWidth: "550px", maxHeight: "450px", overflowY: "scroll" }}>
                {[1, 2, 3, 4, 5].map(value => (
                  <p key={value}>{t(`financialMediation.helpers.providedInformationConsentDesc.p${value}`)}</p>
                ))}
              </div>
            }
          />
        </Col>

        <Col span={consentOtherColSpan}>
          <BooleanRadioFormItem
            formItemProps={{
              name: ["mediationData", "policyHolderData", "providedInformationConsent"],
              rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
              className: "margin-bottom-small"
            }}
            useAgreementLabels
          />
        </Col>

        <Col span={consentOtherColSpan}>
          {showSecondaryClient && (
            <BooleanRadioFormItem
              formItemProps={{
                name: ["mediationData", "secondaryClientData", "providedInformationConsent"],
                rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                className: "margin-bottom-small"
              }}
              useAgreementLabels
            />
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={consentFirstColSpan} className="form-item-additional">
          <LabelWithPopover
            label={t(`${clientDataTPrefix}.electronicCommunicationConsent`)}
            popoverTitle={t("financialMediation.helpers.electronicCommunicationConsentDesc.title")}
            popoverContent={
              <div style={{ maxWidth: "400px" }}>
                {t("financialMediation.helpers.electronicCommunicationConsentDesc.p1")}
              </div>
            }
          />
        </Col>

        <Col span={consentOtherColSpan}>
          <BooleanRadioFormItem
            formItemProps={{
              name: ["mediationData", "policyHolderData", "electronicCommunicationConsent"],
              rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
              className: "margin-bottom-small"
            }}
            trueLabel={t("common.agree")}
            falseLabel={
              <LabelWithTooltip
                label={t("common.disagree")}
                tooltip={t("financialMediation.helpers.electronicCommunicationDisagree")}
              />
            }
          />
        </Col>

        <Col span={consentOtherColSpan}>
          {showSecondaryClient && (
            <BooleanRadioFormItem
              formItemProps={{
                name: ["mediationData", "secondaryClientData", "electronicCommunicationConsent"],
                rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                className: "margin-bottom-small"
              }}
              trueLabel={t("common.agree")}
              falseLabel={
                <LabelWithTooltip
                  label={t("common.disagree")}
                  tooltip={t("financialMediation.helpers.electronicCommunicationDisagree")}
                />
              }
            />
          )}
        </Col>
      </Row>

      <Divider orientation="left" className="divider-subheader">
        {t("financialMediation.sections.otherData")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["mediationData", "signDate"]}
            label={<span className="form-item-additional">{t("financialMediation.attrs.mediationData.signDate")}</span>}
            rules={[validations.conditionalRule(validateAllFields, validations.notNull), signDateRule]}
          >
            <DatePicker {...datePickerStandardProps} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <CityAutoComplete
            formItemProps={{
              name: ["mediationData", "signCity"],
              label: (
                <span className="form-item-additional">{t("financialMediation.attrs.mediationData.signCity")}</span>
              ),
              rules: [
                validations.conditionalRule(validateAllFields, validations.notBlank),
                validations.size(1, 64),
                validations.pattern(regexPatterns.wordRegex)
              ]
            }}
          />
        </Col>
      </Row>

      {!formDisabled && (
        <div className="margin-top-medium">
          <Button type="primary" icon={<AntIcon type="save" />} onClick={onFormSubmit}>
            {t("common.save")}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default FinancialMediationFormOtherDataSection;
