import { Loader } from "@googlemaps/js-api-loader";
import { AutoComplete, Input, Spin } from "antd";
import debounce from "lodash/debounce";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import t, { DEFAULT_LOCALE } from "../../../app/i18n";
import AntIcon from "../../components/icons/AntIcon";
import { BratislavaCityDistricts, KosiceCityDistricts } from "../../constants";
import { getCountryByTwoLetterCode, getGoogleMapsApiKey, removeStringWhiteSpaces } from "../../utils/utils";
import { Address } from "../types";

const MIN_SEARCH_VALUE_LENGTH = 4;

let placesService: typeof google.maps.places;
let autocompleteService: google.maps.places.AutocompleteService;

const googleMapsApiKey = getGoogleMapsApiKey();

if (googleMapsApiKey) {
  new Loader({
    apiKey: googleMapsApiKey,
    libraries: ["places"],
    language: DEFAULT_LOCALE,
    region: DEFAULT_LOCALE
  })
    .load()
    .then(value => {
      placesService = value.maps.places;
      autocompleteService = new placesService.AutocompleteService();
    })
    .catch(error => {
      console.error("Error occurred while loading Google Maps Places API.", error);
    });
}

interface Props {
  hide?: boolean;
  onAddressFind: (address: Address & { cityDistrict: string }) => void;
}

const AddressAutocomplete = ({ hide, onAddressFind }: Props) => {
  const divContainerRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<string>();
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();

  useEffect(() => {
    if (placesServiceInitialized()) {
      setSessionToken(new placesService.AutocompleteSessionToken());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const placesServiceInitialized = (): boolean => !!placesService;

  const handleSearchDebounced = useMemo(
    () =>
      debounce((value: string): void => {
        if (value?.length >= MIN_SEARCH_VALUE_LENGTH) {
          setInProgress(true);
          autocompleteService
            .getPlacePredictions(
              {
                input: value,
                sessionToken,
                types: ["address"],
                language: DEFAULT_LOCALE,
                region: DEFAULT_LOCALE
              },
              (predictions, status) => {
                if (predictions !== null && status === google.maps.places.PlacesServiceStatus.OK) {
                  setOptions(predictions.map<DefaultOptionType>(p => ({ label: p.description, value: p.place_id })));
                } else {
                  setOptions([]);
                  if (status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    console.error("Error occurred while executing place predictions request.", status, predictions);
                  }
                }
              }
            )
            ?.finally(() => {
              setInProgress(false);
            });
        }
      }, 500),
    [sessionToken]
  );

  const handleSearch = useCallback(
    (value: string): void => {
      handleSearchDebounced(value);
    },
    [handleSearchDebounced]
  );

  const handleSelect = (value: string): void => {
    if (!divContainerRef?.current) {
      return;
    }

    new placesService.PlacesService(divContainerRef?.current).getDetails(
      {
        placeId: value,
        sessionToken,
        fields: ["address_components"],
        language: DEFAULT_LOCALE,
        region: DEFAULT_LOCALE
      },
      (result, status) => {
        if (result !== null && status === google.maps.places.PlacesServiceStatus.OK) {
          const address: Partial<Address & { cityDistrict: string }> = {
            street: undefined,
            descriptiveNumber: undefined,
            orientationNumber: undefined,
            city: undefined,
            cityDistrict: undefined,
            zipCode: undefined,
            country: undefined
          };

          result.address_components?.forEach(component => {
            switch (component.types[0]) {
              case "route":
                address.street = component.long_name;
                break;
              case "premise":
                address.descriptiveNumber = component.long_name;
                break;
              case "street_number":
                address.orientationNumber = component.short_name;
                break;
              case "sublocality_level_1":
                address.cityDistrict = component.long_name;
                break;
              case "locality":
                address.city = component.long_name;
                break;
              case "postal_code":
                address.zipCode = removeStringWhiteSpaces(component.long_name);
                break;
              case "country":
                address.country = getCountryByTwoLetterCode(component.short_name);
                break;
            }
          });

          if (address.cityDistrict) {
            if (!address.city) {
              address.city = address.cityDistrict;
              address.cityDistrict = undefined;
            } else if (
              !(
                (address.city === "Bratislava" && BratislavaCityDistricts.includes(address.cityDistrict)) ||
                (address.city === "Košice" && KosiceCityDistricts.includes(address.cityDistrict))
              )
            ) {
              address.cityDistrict = undefined;
            }
          }

          onAddressFind(address as Address & { cityDistrict: string });
        } else {
          console.error("Error occurred while executing place details request.", status, result);
        }
      }
    );

    handleClear();
  };

  const handleClear = (): void => {
    setValue(undefined);
    setOptions([]);
    setInProgress(false);
    setSessionToken(new placesService.AutocompleteSessionToken());
  };

  return placesServiceInitialized() && !hide ? (
    <>
      <Spin spinning={inProgress} style={{ marginTop: "-3px" }}>
        <AutoComplete
          style={{ width: "230px" }}
          dropdownMatchSelectWidth={false}
          value={value}
          options={options}
          onChange={setValue}
          onSearch={handleSearch}
          onSelect={handleSelect}
          onBlur={handleClear}
          onClear={handleClear}
          notFoundContent={
            (value || "").length >= MIN_SEARCH_VALUE_LENGTH && (
              <div className="sub-header-info center-align">{t("address.search.noResult")}</div>
            )
          }
        >
          <Input
            size="small"
            bordered={false}
            className="input-bottom-border"
            allowClear
            placeholder={t("address.search.placeholder")}
            addonBefore={<AntIcon type="search" />}
          />
        </AutoComplete>
      </Spin>
      <div ref={divContainerRef} />
    </>
  ) : null;
};

export default AddressAutocomplete;
