import { Modal } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../../app/i18n";
import ContentWrapper from "../../../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, RootState } from "../../../../../common/types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { QueryKeys } from "../../../../../common/utils/queryUtils";
import { ClientFormType } from "../../../../client/enums";
import { Client } from "../../../../client/types";
import { VehicleInsurance } from "../../../../contract/types";
import { createCalcDraftActions, getCalcDraftActions, updateCalcDraftActions } from "../../../drafts/ducks";
import { CreateUpdateCalcDraft } from "../../../drafts/types";
import { CALC_ROUTE_PATHS } from "../../../paths";
import { getCalcRecordDataActions } from "../../../records/ducks";
import CalcLoadingModal from "../../components/CalcLoadingModal";
import { downloadCardReaderActions, loadContractToCalculatorActions } from "../../ducks";
import { OfferType } from "../../enums";
import { OperationStage } from "../../realty/enums";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import VehicleCalcWrapper from "../components/calc/VehicleCalcWrapper";
import VehicleGenWrapper from "../components/gen/VehicleGenWrapper";
import {
  calculateVehicleActions,
  deleteStateVehicleCalcResultsAction,
  deleteStateVehicleDraftAction,
  deleteStateVehicleGenResultAction,
  deleteStateVehicleInitialCalcGenDataAction,
  generateVehicleActions,
  generateVehicleOfferActions,
  selectVehicleCalcResults,
  selectVehicleDraft,
  selectVehicleGenResult,
  selectVehicleInitialCalcData,
  selectVehicleInitialGenData
} from "../ducks";
import { VehicleOwnerRelation, VehiclePolicyHolderRelation } from "../enums";
import {
  VehicleCalc,
  VehicleCalcDraft,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleFormClients,
  VehicleGen,
  VehicleGenForm,
  VehicleOfferType
} from "../types";
import {
  createVehicleCalcObjectFromCalcData,
  createVehicleCalcObjectFromGenData,
  createVehicleFormClientsObjectFromCalcData,
  createVehicleFormClientsObjectFromGenData,
  createVehicleGenFormDataObject
} from "../utils";

interface StateProps {
  initialCalcData?: VehicleCalc;
  initialGenData?: VehicleGen;
  draft?: VehicleCalcDraft;
  calcResults: VehicleCalcResults;
  genResult?: GenResponse;
}

interface ActionsMap {
  calculateVehicle: typeof calculateVehicleActions.request;
  generateVehicle: typeof generateVehicleActions.request;
  generateVehicleOffer: typeof generateVehicleOfferActions.request;
  downloadCardReader: typeof downloadCardReaderActions.request;
  createCalcDraft: typeof createCalcDraftActions.request;
  updateCalcDraft: typeof updateCalcDraftActions.request;
  deleteStateVehicleCalcResults: typeof deleteStateVehicleCalcResultsAction;
  deleteStateVehicleGenResult: typeof deleteStateVehicleGenResultAction;
  deleteStateVehicleDraft: typeof deleteStateVehicleDraftAction;
  deleteStateVehicleInitialCalcGenData: typeof deleteStateVehicleInitialCalcGenDataAction;
  getCalcRecordData: typeof getCalcRecordDataActions.request;
  getCalcDraft: typeof getCalcDraftActions.request;
  loadContractToCalculator: typeof loadContractToCalculatorActions.request;
}

const VehicleCalcContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get(QueryKeys.RECORD_ID);
  const draftId = searchParams.get(QueryKeys.DRAFT_ID);
  const contractId = searchParams.get(QueryKeys.CONTRACT_ID);
  const [resetKey, setResetKey] = useState<number>(0);
  const [genStepActive, setGenStepActive] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<CalcResult<VehicleCalcResultData>>();
  const [selectedVehicleVin, setSelectedVehicleVin] = useState<string>();
  const [calcDataSource, setCalcDataSource] = useState<CalcDataSource>("init");
  const [calcData, setCalcData] = useState<VehicleCalc>();
  const [genData, setGenData] = useState<VehicleGenForm>();
  const [clients, setClients] = useState<VehicleFormClients>({
    holder: undefined,
    policyHolder: undefined,
    owner: undefined,
    representative: undefined
  });

  const clearData = () => {
    props.actions.deleteStateVehicleInitialCalcGenData();
    props.actions.deleteStateVehicleCalcResults();
    props.actions.deleteStateVehicleGenResult();
    props.actions.deleteStateVehicleDraft();
  };

  useEffect(() => {
    if (recordId) {
      props.actions.getCalcRecordData({ id: recordId });
    }

    if (draftId) {
      props.actions.getCalcDraft({ id: draftId });
    }

    if (contractId) {
      props.actions.loadContractToCalculator({ id: contractId });
    }

    return () => {
      clearData();
    };
  }, [recordId, draftId, contractId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calcData) {
      return;
    }

    if (props.initialCalcData || props.initialGenData) {
      messageUtils.infoNotification({
        message: t("calc.records.helpers.calcDataInitTitle"),
        description: t("calc.records.helpers.calcDataInit"),
        duration: 10,
        key: "vehicleCalcDataInit"
      });
    }

    if (props.initialCalcData) {
      setCalcData(createVehicleCalcObjectFromCalcData(props.initialCalcData));
      setClients(createVehicleFormClientsObjectFromCalcData(props.initialCalcData));
      setCalcDataSource("calcData");
    } else if (props.initialGenData) {
      setCalcData(createVehicleCalcObjectFromGenData(props.initialGenData));
      const clients = createVehicleFormClientsObjectFromGenData(props.initialGenData);
      setClients(clients);
      setGenData(createVehicleGenFormDataObject(props.initialGenData, clients));
      setSelectedVehicleVin(props.initialGenData.vehicleData.vin);
      setCalcDataSource("genData");
    } else if (props.draft?.draftData) {
      messageUtils.infoNotification({
        message: t("calc.draft.helpers.draftLoaded"),
        description: t("calc.draft.helpers.draftLoadedInfo"),
        duration: 10,
        key: "vehicleDraftLoaded"
      });

      setCalcData(createVehicleCalcObjectFromGenData(props.draft.draftData));
      const clients = createVehicleFormClientsObjectFromGenData(props.draft.draftData);
      setClients(clients);

      if (props.draft.stage === OperationStage.GENERATE) {
        setGenData(createVehicleGenFormDataObject(props.draft.draftData, clients));
        setCalcDataSource("genDraft");
        setSelectedVehicleVin(props.draft?.draftData.vehicleData.vin);
      } else {
        setCalcDataSource("calcDraft");
      }
    }
  }, [props.initialCalcData, props.initialGenData, props.draft?.draftData, calcData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientChange = (type: ClientFormType, client?: Client): VehicleFormClients => {
    let updatedClients: VehicleFormClients;
    switch (type) {
      case ClientFormType.HOLDER:
        updatedClients = { ...clients, holder: client };
        break;
      case ClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case ClientFormType.OWNER:
        updatedClients = { ...clients, owner: client };
        break;
      case ClientFormType.REPRESENTATIVE:
        updatedClients = { ...clients, representative: client };
        break;
      default:
        updatedClients = { ...clients };
        break;
    }
    setClients(updatedClients);
    return updatedClients;
  };

  const handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        setResetKey(resetKey + 1);
        setClients({ holder: undefined, policyHolder: undefined, owner: undefined, representative: undefined });
        setCalcDataSource("init");
        setCalcData(undefined);
        setGenData(undefined);
        setSelectedResult(undefined);

        clearData();
        navigate(CALC_ROUTE_PATHS.vehicle.to, { replace: true });
      }
    });
  };

  const handleCalcDataSourceReset = (): void => {
    setCalcDataSource("init");
  };

  const handleCalculationFormSubmit = (calcData: VehicleCalc): void => {
    setCalcData(calcData);
    props.actions.deleteStateVehicleCalcResults();
    props.actions.calculateVehicle(calcData);
  };

  const handleGenerateContractClick = (
    calcData: VehicleCalc,
    selectedResult: CalcResult<VehicleCalcResultData>
  ): void => {
    setGenStepActive(true);
    setCalcData(calcData);
    setSelectedResult(selectedResult);
  };

  const handleGenerateOfferClick = (type: VehicleOfferType, calcRequest?: VehicleCalc): void => {
    const { mtpl, crash, mtplCrash, gap, pas } = props.calcResults;
    if (calcRequest || calcData) {
      props.actions.generateVehicleOffer({
        type,
        calcRequest: (calcRequest ?? calcData) as VehicleCalc,
        calcResponse: {
          results:
            type === OfferType.MTPL
              ? mtpl.flat()
              : type === OfferType.CRASH
                ? [...crash.flat(), ...gap.flat(), ...pas.flat()]
                : [...mtplCrash.flat(), ...gap.flat(), ...pas.flat()]
        }
      });
    }
  };

  const handleSaveDraftClick = (data: VehicleCalc | VehicleGen, overwriteExisting: boolean): void => {
    const { mtpl, crash, mtplCrash, gap, pas } = props.calcResults;
    const draft: CreateUpdateCalcDraft = {
      draftData: data,
      calcResponse: { results: [...mtpl.flat(), ...crash.flat(), ...mtplCrash.flat(), ...gap.flat(), ...pas.flat()] },
      clientName: clients.holder?.aggregatedName ?? "",
      calcType: data.type,
      stage: genStepActive ? OperationStage.GENERATE : OperationStage.CALCULATE
    };

    if (props.draft && overwriteExisting) {
      props.actions.updateCalcDraft({
        id: props.draft.id,
        object: { optimisticLockVersion: props.draft.optimisticLockVersion, ...draft }
      });
    } else {
      props.actions.createCalcDraft(draft);
    }
  };

  const handleReturnToCalculationClick = (genFormData: VehicleGenForm, contractCreated?: boolean): void => {
    if (contractCreated && props.genResult?.contract && calcData) {
      const { contract } = props.genResult;
      const insurance = contract.insurances[0] as VehicleInsurance;
      const { policyHolderRelation, ownerRelation } = calcData.clientsData;

      setGenStepActive(false);
      setSelectedResult(undefined);
      setCalcDataSource("init");
      setGenData({
        ...genFormData,
        clientsData: {
          ...genFormData.clientsData,
          representativeIdentifier: undefined,
          representativeFunction: undefined
        }
      });
      setClients({
        holder: contract.clients[insurance.vehicleHolderIndex],
        policyHolder:
          policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER
            ? contract.clients[contract.policyHolderIndex]
            : undefined,
        owner:
          ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_BOTH
            ? contract.clients[insurance.vehicleOwnerIndex]
            : undefined,
        representative: undefined
      });
    } else {
      setGenStepActive(false);
      setSelectedResult(undefined);
      setCalcDataSource("calcData");
      setGenData(genFormData);
    }

    props.actions.deleteStateVehicleGenResult();
  };

  return (
    <DisplayWrapper itemLoaded={recordId || draftId || contractId ? !!calcData : true}>
      <ContentWrapper>
        {genStepActive && calcData && selectedResult ? (
          <VehicleGenWrapper
            initialData={genData}
            genResult={props.genResult}
            calcData={calcData}
            clients={clients}
            calcResults={props.calcResults}
            selectedResult={selectedResult}
            draftId={props.draft?.id}
            selectedVehicleVin={selectedVehicleVin}
            onGenerateFormSubmit={props.actions.generateVehicle}
            onGenResultDelete={props.actions.deleteStateVehicleGenResult}
            onGenerateOfferClick={handleGenerateOfferClick}
            onSaveDraftClick={handleSaveDraftClick}
            onSelectedResultChange={setSelectedResult}
            onReturnToCalculationClick={handleReturnToCalculationClick}
            onClientChange={handleClientChange}
            onSelectedVehicleVinSet={setSelectedVehicleVin}
          />
        ) : (
          <VehicleCalcWrapper
            key={resetKey}
            calcData={calcData}
            calcDataSource={calcDataSource}
            calcResults={props.calcResults}
            holder={clients.holder}
            onCalcResultsDelete={props.actions.deleteStateVehicleCalcResults}
            onDownloadCardReader={props.actions.downloadCardReader}
            onCalculationFormSubmit={handleCalculationFormSubmit}
            onGenerateContractClick={handleGenerateContractClick}
            onGenerateOfferClick={handleGenerateOfferClick}
            onSaveDraftClick={handleSaveDraftClick}
            onClientChange={handleClientChange}
            onResetCalculatorClick={handleResetCalculatorClick}
            onCalcDataSourceReset={handleCalcDataSourceReset}
          />
        )}

        <CalcLoadingModal />
      </ContentWrapper>
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectVehicleInitialCalcData(state),
  initialGenData: selectVehicleInitialGenData(state),
  draft: selectVehicleDraft(state),
  calcResults: selectVehicleCalcResults(state),
  genResult: selectVehicleGenResult(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      calculateVehicle: calculateVehicleActions.request,
      generateVehicle: generateVehicleActions.request,
      generateVehicleOffer: generateVehicleOfferActions.request,
      downloadCardReader: downloadCardReaderActions.request,
      createCalcDraft: createCalcDraftActions.request,
      updateCalcDraft: updateCalcDraftActions.request,
      deleteStateVehicleCalcResults: deleteStateVehicleCalcResultsAction,
      deleteStateVehicleGenResult: deleteStateVehicleGenResultAction,
      deleteStateVehicleDraft: deleteStateVehicleDraftAction,
      deleteStateVehicleInitialCalcGenData: deleteStateVehicleInitialCalcGenDataAction,
      getCalcRecordData: getCalcRecordDataActions.request,
      getCalcDraft: getCalcDraftActions.request,
      loadContractToCalculator: loadContractToCalculatorActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(VehicleCalcContainer);
