import { List, Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import HtmlView from "../../../../common/components/views/HtmlView";
import { ModalSizes } from "../../../../common/constants";
import { formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import { toMoment } from "../../../../common/utils/formUtils";
import {
  DashboardNoticeAttachmentsTableView,
  DashboardNoticeIcon
} from "../../../dashboard/components/widgets/notices/components";
import { DashboardNotice } from "../../../dashboard/types";

interface Props {
  open: boolean;
  notices: DashboardNotice[];
  onCancel: () => void;
}

const CalcNoticesModal = ({ open, notices, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("calc.titles.notices")}
      forceRender
      centered
      closable
      maskClosable
      okButtonProps={{ style: { display: "none" } }}
      cancelText={t("common.close")}
      className="calc-notices-modal"
      onCancel={onCancel}
    >
      <List<DashboardNotice>
        className="calc-notices-list"
        itemLayout="vertical"
        dataSource={notices}
        renderItem={item => (
          <List.Item
            key={item.id}
            actions={item.attachments.length ? [<DashboardNoticeAttachmentsTableView notice={item} />] : undefined}
          >
            <List.Item.Meta
              avatar={<DashboardNoticeIcon type={item.type} />}
              title={<Ellipsis>{item.title}</Ellipsis>}
              description={
                <Tooltip title={formatLocaleDateTime(item.createdAt)}>{toMoment(item.createdAt)?.fromNow()}</Tooltip>
              }
            />
            <HtmlView value={item.text} />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default CalcNoticesModal;
