import { Button, Card, Col, Row } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import JobSettingsView from "../components/views/JobSettingsView";
import {
  abortJobActions,
  cancelJobActions,
  deleteStateJobItemsAction,
  filterJobRecordsActions,
  getAllJobsActions,
  scheduleJobActions,
  selectJobs,
  updateJobActions
} from "../ducks";
import { JobSettings } from "../types";

interface StateProps {
  jobs: JobSettings[];
}

interface ActionsMap {
  getAllJobs: typeof getAllJobsActions.request;
  updateJob: typeof updateJobActions.request;
  scheduleJob: typeof scheduleJobActions.request;
  filterJobRecords: typeof filterJobRecordsActions.request;
  cancelJob: typeof cancelJobActions.request;
  abortJob: typeof abortJobActions.request;
  deleteStateJobItems: typeof deleteStateJobItemsAction;
}

const JobsContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  useEffect(() => {
    props.actions.getAllJobs();
    return () => {
      props.actions.deleteStateJobItems();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={<h2 className="margin-bottom-medium">{t("job.titles.page")}</h2>}
        extra={
          <Button
            size="small"
            className="secondary-button"
            icon={<AntIcon type="reload" />}
            onClick={() => props.actions.getAllJobs()}
          >
            {t("job.actions.refresh")}
          </Button>
        }
      >
        <Row justify="center">
          <Col span={24}>
            <JobSettingsView
              jobs={props.jobs}
              onAllJobsGet={props.actions.getAllJobs}
              onUpdate={props.actions.updateJob}
              onSchedule={props.actions.scheduleJob}
              onFilter={props.actions.filterJobRecords}
              onCancel={props.actions.cancelJob}
              onAbort={props.actions.abortJob}
            />
          </Col>
        </Row>
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  jobs: selectJobs(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      getAllJobs: getAllJobsActions.request,
      updateJob: updateJobActions.request,
      scheduleJob: scheduleJobActions.request,
      filterJobRecords: filterJobRecordsActions.request,
      cancelJob: cancelJobActions.request,
      abortJob: abortJobActions.request,
      deleteStateJobItems: deleteStateJobItemsAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(JobsContainer);
