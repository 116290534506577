import groupBy from "lodash/groupBy";
import { DEFAULT_LOCALE } from "../../../app/i18n";
import { toMoment } from "../../../common/utils/formUtils";
import { removeFromArray } from "../../../common/utils/utils";
import { ProductFinancialSector } from "../../product/enums";
import { CommissionsSettingsLevelRule } from "../level/types";
import { SpecialCommissionsSettingsRule } from "../special/types";
import { CommissionsSettingsRule } from "./types";

export const sortCommissionsSettingsRules = <T extends CommissionsSettingsRule | CommissionsSettingsLevelRule>(
  rules: T[]
): T[] => {
  const sectorsMap = groupBy(rules, rule => rule.sector);
  const sectorsSortedKeys = Object.keys(sectorsMap).sort(
    (a, b) => Object.keys(ProductFinancialSector).indexOf(a) - Object.keys(ProductFinancialSector).indexOf(b)
  );

  const sortedRules: Record<any, any>[] = [];

  sectorsSortedKeys.forEach(sector => {
    const productGroupsMap = groupBy(sectorsMap[sector], rule => rule.productGroup?.name);
    const productGroupsSortedKeys = moveUndefinedValueToEnd(
      Object.keys(productGroupsMap).sort((a, b) => a?.localeCompare(b, DEFAULT_LOCALE, { sensitivity: "accent" }))
    );

    productGroupsSortedKeys.forEach(productGroupName => {
      const institutionsMap = groupBy(productGroupsMap[productGroupName], rule => rule.institution?.name);
      const institutionsSortedKeys = moveUndefinedValueToEnd(
        Object.keys(institutionsMap).sort((a, b) => a?.localeCompare(b, DEFAULT_LOCALE, { sensitivity: "accent" }))
      );

      institutionsSortedKeys.forEach(institutionName => {
        const productsMap = groupBy(institutionsMap[institutionName], rule => rule.product?.name);
        const productsSortedKeys = moveUndefinedValueToEnd(
          Object.keys(productsMap).sort((a, b) => a?.localeCompare(b, DEFAULT_LOCALE, { sensitivity: "accent" }))
        );

        productsSortedKeys.forEach(productName => sortedRules.push(...(productsMap[productName] as T[])));
      });
    });
  });

  return sortedRules;
};

const moveUndefinedValueToEnd = (array: string[]): string[] => {
  const outputArray = removeFromArray(array, item => item === "undefined");
  if (array.length !== outputArray.length) {
    outputArray.push("undefined");
  }
  return outputArray;
};

export const sortCommissionsSettingsSpecialRules = (
  specialRules: SpecialCommissionsSettingsRule[]
): SpecialCommissionsSettingsRule[] =>
  [...specialRules].sort((r1, r2) => {
    const codeCmp = r1.code.localeCompare(r2.code, DEFAULT_LOCALE, { sensitivity: "accent" });
    if (codeCmp !== 0) {
      return codeCmp;
    }

    const r1StartDate = toMoment(r1.startDate);
    const r2StartDate = toMoment(r2.startDate);
    if (r1StartDate && r2StartDate) {
      const startDateCmp = r1StartDate.isBefore(r2StartDate, "days") ? 1 : r1StartDate.isAfter(r2StartDate) ? -1 : 0;
      if (startDateCmp !== 0) {
        return startDateCmp;
      }

      return toMoment(r1.createdAt)?.isSameOrBefore(toMoment(r2.createdAt)) ? -1 : 1;
    } else {
      return r1StartDate ? 1 : -1;
    }
  });
