import AntIcon from "./AntIcon";

interface Props {
  status: "success" | "error";
}

const TimelineDotIcon = ({ status }: Props) => (
  <AntIcon type={status === "success" ? "check" : "close-circle"} className="timeline-dot-icon" />
);

export default TimelineDotIcon;
