import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Root from "./app/containers/Root";
import * as serviceWorker from "./serviceWorker";
import "./app/i18n";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import "./app/styles/general.less";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <Root />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Learn more https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
