import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import FinancialMediationForm from "../components/forms/FinancialMediationForm";
import { createFinancialMediationActions } from "../ducks";

interface ActionsMap {
  createFinancialMediation: typeof createFinancialMediationActions.request;
}

const FinancialMediationCreateContainer = ({ actions }: ActionProps<ActionsMap>) => (
  <ContentWrapper>
    <FinancialMediationForm onCreate={actions.createFinancialMediation} />
  </ContentWrapper>
);

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      createFinancialMediation: createFinancialMediationActions.request
    },
    dispatch
  )
});

export default connect<{}, ActionProps<ActionsMap>, {}, RootState>(
  null,
  mapDispatchToProps
)(FinancialMediationCreateContainer);
