import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import classNames from "classnames";
import AntIcon, { type AntIconType } from "../icons/AntIcon";

interface Props extends ButtonProps {
  icon: AntIconType;
  label: string;
  color?: "blue" | "green" | "red" | "orange";
}

const ActionButton = ({ icon, label, color, className, ...props }: Props) => (
  <Button
    {...props}
    size="small"
    type="dashed"
    icon={<AntIcon type={icon} />}
    className={classNames(className, { [`action-button--${color}`]: color })}
  >
    {label}
  </Button>
);

export default ActionButton;
