import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Moment } from "moment";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { PageSizes, TableSizes } from "../../../../../common/constants";
import {
  formatAgentName,
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocalePercentageNumber
} from "../../../../../common/utils/formatUtils";
import { toMoment } from "../../../../../common/utils/formUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import type { UUID } from "../../../../../typings/global";
import { deleteSpecialCommissionsRuleActions } from "../../ducks";
import { SpecialCommissionsSettingsRuleType } from "../../enums";
import { SpecialCommissionsSettingsRule } from "../../types";
import SpecialCommissionsSettingsRuleSuspendedTag from "../SpecialCommissionsSettingsRuleSuspendedTag";

interface Props {
  rules: SpecialCommissionsSettingsRule[];
  agentId: UUID;
  showActions: boolean;
  onUpdateClick: (rule: SpecialCommissionsSettingsRule) => void;
  onDelete: typeof deleteSpecialCommissionsRuleActions.request;
}

const SpecialCommissionsSettingsRuleTableView = ({ rules, agentId, showActions, onUpdateClick, onDelete }: Props) => {
  const columns: ColumnsType<SpecialCommissionsSettingsRule> = [
    {
      key: "code",
      title: t("commissions.special.attrs.code"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.code}</Ellipsis>
    },
    {
      key: "name",
      title: t("commissions.special.attrs.name"),
      width: 300,
      render: (_, record) => record.name
    },
    {
      key: "type",
      title: t("commissions.special.enums.ruleType._label"),
      width: 170,
      render: (_, record) => t("commissions.special.enums.ruleType." + record.type)
    },
    {
      key: "startDate",
      title: t("commissions.special.attrs.startDate"),
      width: 120,
      render: (_, record) =>
        record.type === SpecialCommissionsSettingsRuleType.ONE_TIME
          ? (toMoment(record.startDate) as Moment).format("MMMM YYYY")
          : formatLocaleDate(record.startDate)
    },
    {
      key: "endDate",
      title: t("commissions.special.attrs.endDate"),
      width: 120,
      render: (_, record) =>
        record.type === SpecialCommissionsSettingsRuleType.ONE_TIME
          ? (toMoment(record.endDate) as Moment).format("MMMM YYYY")
          : formatLocaleDate(record.endDate)
    },
    {
      key: "suspended",
      width: 110,
      render: (_, record) => record.suspended && <SpecialCommissionsSettingsRuleSuspendedTag />
    },
    {
      key: "payer",
      title: t("commissions.special.attrs.payer"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAgentName(record.payer)}</Ellipsis>
    },
    {
      key: "amountOrRate",
      title: t("commissions.special.attrs.commissionAmount"),
      width: 200,
      align: "right",
      render: (_, record) =>
        record.type === SpecialCommissionsSettingsRuleType.NETTO_POINTS_RATE ? (
          <>
            {t("commissions.special.helpers.nettoPointsRuleDesc", {
              rate: formatLocalePercentageNumber(record.nettoPointsRate)
            })}
            <br />
            {formatAgentName(record.nettoPointsTargetAgent)}
          </>
        ) : (
          formatLocaleCurrency(record.amount)
        )
    }
  ];

  if (showActions) {
    columns.push({
      key: "actions",
      width: 180,
      align: "right",
      fixed: "right",
      render: (_, record) => (
        <div className="nowrap">
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("commissions.special.helpers.deleteRuleConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => onDelete({ id1: agentId, id2: record.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </div>
      )
    });
  }

  return (
    <Table<SpecialCommissionsSettingsRule>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.HUGE }}
      dataSource={rules}
      pagination={{
        ...paginationTableProps,
        pageSize: PageSizes.MEDIUM,
        total: rules.length
      }}
    />
  );
};

export default SpecialCommissionsSettingsRuleTableView;
