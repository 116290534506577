import { Button, Divider, Popconfirm } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import ContactsView from "../../../../../common/modules/contact/ContactsView";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { formatAddress } from "../../../../../common/utils/formatUtils";
import { ContractFilterPageResult } from "../../../../contract/types";
import { deleteClientActions } from "../../../ducks";
import { ClientType } from "../../../enums";
import { Client, LegalClient, NaturalClient, SelfEmployedClient } from "../../../types";
import { resolveClientAddressLabel } from "../../../utils";
import ClientTypeTagWithName from "../../tags/ClientTypeTagWithName";
import ClientContractsTableView from "./ClientContractsTableView";
import LegalClientDataView from "./LegalClientDataView";
import NaturalClientDataView from "./NaturalClientDataView";
import SelfEmployedClientDataView from "./SelfEmployedClientDataView";

interface Props {
  client: Client;
  contractsPage?: ContractFilterPageResult;
  onUpdateClick?: () => void;
  onDelete?: typeof deleteClientActions.request;
  onContractsTablePageChange?: (pagination: TablePaginationConfig) => void;
}

const ClientDetailView = ({ client, contractsPage, onUpdateClick, onDelete, onContractsTablePageChange }: Props) => {
  let dataView;
  switch (client.type) {
    case ClientType.NATURAL:
      dataView = <NaturalClientDataView client={client as NaturalClient} />;
      break;
    case ClientType.SELF_EMPLOYED:
      dataView = <SelfEmployedClientDataView client={client as SelfEmployedClient} />;
      break;
    case ClientType.LEGAL:
      dataView = (
        <>
          <LegalClientDataView client={client as LegalClient} />
          {(client as LegalClient).representatives?.length && (
            <>
              <Divider orientation="left">{t("client.titles.representatives")}</Divider>
              <table className="data-table-view">
                <tbody>
                  {(client as LegalClient).representatives?.map((r, index) => (
                    <tr key={index}>
                      <td>
                        <ClientTypeTagWithName client={r.representative} showLink target="_blank" />
                      </td>
                      <td>{t("client.enums.representativeFunction." + r.function)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </>
      );
      break;
  }

  return (
    <>
      <Divider orientation="left">{t("client.titles.basicData")}</Divider>

      {dataView}

      <Divider orientation="left">{t("client.titles.addresses")}</Divider>

      <table className="data-table-view">
        <tbody>
          <tr>
            <td>{resolveClientAddressLabel(client.type)}:</td>
            <td>{formatAddress(client.address)}</td>
          </tr>
          <tr>
            <td>{t("client.attrs.correspondenceAddress")}:</td>
            <td>{formatAddress(client.correspondenceAddress)}</td>
          </tr>
        </tbody>
      </table>

      <Divider orientation="left">{t("client.titles.contacts")}</Divider>

      <ContactsView contacts={client.contacts} marketingConsentsAreApplicable />

      {contractsPage && (
        <>
          <Divider orientation="left">{t("client.titles.contracts")}</Divider>
          <ClientContractsTableView contractsPage={contractsPage} onPageChange={onContractsTablePageChange} />
        </>
      )}

      <div className="margin-top-large">
        {onUpdateClick && (
          <ComponentWithPermission permissions={[Permission.CLIENT_UPDATE]}>
            <Button className="margin-right-tiny" type="primary" onClick={onUpdateClick} icon={<AntIcon type="edit" />}>
              {t("common.edit")}
            </Button>
          </ComponentWithPermission>
        )}

        {onDelete && (
          <ComponentWithPermission permissions={[Permission.CLIENT_DELETE]}>
            <Popconfirm
              title={t("client.titles.deleteConfirm", { aggregatedName: client.aggregatedName })}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={() => onDelete({ id: client.id })}
            >
              <Button icon={<AntIcon type="delete" />} danger>
                {t("common.delete")}
              </Button>
            </Popconfirm>
          </ComponentWithPermission>
        )}
      </div>
    </>
  );
};

export default ClientDetailView;
