import { Drawer } from "antd";
import { useState } from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { DrawerSizes } from "../../../../common/constants";
import { contains, isDefinedValue } from "../../../../common/utils/utils";
import { ClientFormType } from "../../enums";
import { Client } from "../../types";
import ClientTypeTagWithDescription from "../tags/ClientTypeTagWithDescription";
import ClientTypeTagWithName from "../tags/ClientTypeTagWithName";
import ClientDetailView from "../views/detail/ClientDetailView";

export interface ClientDataProps {
  client: Client;
  clientFormTypes: ClientFormType[];
  clientPolicyHolderType?: "insurance" | "loan";
}

export type ClientsWithFormType = { type: ClientFormType; client?: Client }[];

export const createClientsData = (clientsWithType: ClientsWithFormType): ClientDataProps[] => {
  const clientsData: ClientDataProps[] = [];

  clientsWithType.forEach(clientWithType => {
    const { client, type } = clientWithType;

    if (!client) {
      return;
    }

    let clientDataIndex = 0;
    const clientData = clientsData.find((clientData, key) => {
      if (clientData.client.id === client.id) {
        clientDataIndex = key;
        return true;
      }
      return false;
    });

    if (clientData) {
      clientsData[clientDataIndex] = {
        ...clientData,
        clientFormTypes: contains(clientData.clientFormTypes, type)
          ? clientData.clientFormTypes
          : [...clientData.clientFormTypes, type],
        clientPolicyHolderType: type === ClientFormType.POLICY_HOLDER ? "insurance" : clientData.clientPolicyHolderType
      };
    } else {
      clientsData.push({
        client: client,
        clientFormTypes: [type],
        clientPolicyHolderType: type === ClientFormType.POLICY_HOLDER ? "insurance" : undefined
      });
    }
  });

  return clientsData;
};

interface Props {
  clientsData?: ClientDataProps[];
  className?: string;
}

export const ClientsListDrawerView = ({ clientsData, className }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [clientIndex, setClientIndex] = useState<number>();
  const clientFromData = clientsData ? clientsData[clientIndex as number]?.client : undefined;

  return (
    <>
      {isDefinedValue(clientIndex) && clientFromData && (
        <Drawer
          title={<ClientTypeTagWithName client={clientFromData} />}
          width={DrawerSizes.MEDIUM}
          open={drawerOpen}
          closable
          onClose={() => setDrawerOpen(false)}
          afterOpenChange={open => {
            if (!open) {
              setClientIndex(undefined);
            }
          }}
        >
          <ClientDetailView client={clientFromData} />
        </Drawer>
      )}

      <table className={"data-table-view clients-list-drawer-view " + (className || "")}>
        <tbody>
          {clientsData?.map((data, index) => {
            let clientLabel = data.clientFormTypes?.length
              ? data.clientFormTypes
                  .map(type =>
                    type === ClientFormType.POLICY_HOLDER && data.clientPolicyHolderType
                      ? t("client.helpers." + data.clientPolicyHolderType + "PolicyHolder")
                      : t("client.enums.formType." + type)
                  )
                  .join(", ")
              : t("client.helpers.client");

            return (
              <tr key={index}>
                <td>{clientLabel}</td>
                <td
                  className="client-name"
                  onClick={() => {
                    setClientIndex(index);
                    setDrawerOpen(true);
                  }}
                >
                  <ClientTypeTagWithDescription client={data.client} />
                  <span className="detail-icon margin-left-tiny">
                    <ActionTextIcon icon="eye" color="blue" disableDelayEffect />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
