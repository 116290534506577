import { Action, Hash, Key, Location, Pathname, Search } from "history";
import moment from "moment";
import { combineReducers } from "redux";
import { ActionType, createAction, createReducer } from "typesafe-actions";
import type { ApiRequest } from "../common/api/ApiRequestAdapter";
import { HttpStatus } from "../common/constants";
import type { RootState } from "../common/types";
import { requests as agentRequests } from "./agent/api";
import authRequests from "./auth/requests";
import { requests as calcRequests } from "./calculator/calcs/api";
import { requests as clientRequests } from "./client/api";
import { requests as commissionsBatchRequests } from "./commissions/batch/api";
import { requests as contractTerminationRequests } from "./contracttermination/api";
import { requests as dashboardRequests } from "./dashboard/api";
import { getEnumerationsActions } from "./enumerations/ducks";
import { AgentBrandingEnumeration } from "./enumerations/types";
import { requests as globalSearchRequests } from "./globalsearch/api";
import { requests as institutionRequests } from "./institution/api";
import { requests as notificationRequests } from "./notifications/api";
import { requests as partnerRequests } from "./partner/api";
import { deleteStatePartnerSsoTempDataAction, setStatePartnerSsoTempDataAction } from "./partner/ducks";
import type { PartnerSsoTempData } from "./partner/types";
import type { AppReducerState, PersistDataReducerState, RequestAction, ValidationErrorResponse } from "./types";
import { requests as vehicleAutocompleteRequests } from "./vehicle/autocomplete/api";
import { requests as vehiclePriceRequests } from "./vehicle/price/api";

/**
 * CONSTANTS
 * runningRequestsCountExclusions - array of identifiers of requests which should not be added to global running requests count
 */
const runningRequestsCountExclusions: Array<ApiRequest> = [
  agentRequests.GET_AGENT,
  agentRequests.SEARCH_AGENT,
  authRequests.REFRESH_TOKEN,
  calcRequests.CALCULATE,
  calcRequests.GENERATE,
  clientRequests.GET_CLIENT,
  clientRequests.SEARCH_CLIENT,
  clientRequests.VALIDATE_CLIENT,
  clientRequests.VALIDATE_CLIENTS,
  commissionsBatchRequests.CALCULATE_COMMISSIONS_BATCH_PAYMENT_TO_BE_PAID,
  commissionsBatchRequests.GET_COMMISSIONS_BATCH,
  contractTerminationRequests.SEARCH_CONTRACT_FOR_TERMINATION,
  dashboardRequests.FILTER_DASHBOARD_NOTICES,
  dashboardRequests.GET_DASHBOARD_AGENT_COMPETENCE,
  dashboardRequests.GET_DASHBOARD_CONTRACT_STATISTICS,
  dashboardRequests.GET_DASHBOARD_CONTRACT_STATUSES,
  dashboardRequests.GET_DASHBOARD_PERSONAL_DATES,
  dashboardRequests.GET_DASHBOARD_STATISTICS,
  dashboardRequests.SEARCH_DASHBOARD_CONTACTS,
  globalSearchRequests.SEARCH_GLOBALLY,
  institutionRequests.GET_INSTITUTION,
  notificationRequests.FILTER_NOTIFICATIONS,
  notificationRequests.MARK_ALL_NOTIFICATIONS_AS_SEEN,
  notificationRequests.TOGGLE_NOTIFICATION_SENT_AND_SEEN_STATUS,
  partnerRequests.GET_PARTNER_CONFIGS_AND_SSO_PROPS,
  partnerRequests.MONLY_AUTHORIZATION_CODE_GRANT,
  vehicleAutocompleteRequests.AUTOCOMPLETE_VEHICLES,
  vehiclePriceRequests.GET_VEHICLE_PRICE
];

/**
 * ACTIONS
 */
export const changeRunningRequestKeyAction = createAction("app/CHANGE_RUNNING_REQUEST_KEY")<void>();
export const startRequestAction = createAction("app/START_REQUEST")<RequestAction>();
export const finishRequestAction = createAction("app/FINISH_REQUEST")<RequestAction>();
export const deleteStateValidationErrorResponseAction = createAction(
  "app/DELETE_STATE_VALIDATION_ERROR_RESPONSE"
)<void>();
export const setNotFoundPageRenderedAction = createAction("app/NOT_FOUND_RENDERED")<boolean>();

const actions = {
  changeRunningRequestKeyAction,
  startRequestAction,
  finishRequestAction,
  deleteStateValidationErrorResponseAction,
  setNotFoundPageRenderedAction
};

export type ModulesAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const appReducerInitialSate: AppReducerState = {
  runningRequestsCount: 0,
  runningRequests: {},
  runningRequestKey: null,
  validationErrorResponse: null,
  notFoundPageRendered: false
};

const runningRequestsCountReducer = createReducer(appReducerInitialSate.runningRequestsCount)
  .handleAction(startRequestAction, (state, { payload }) =>
    payload.requestIdentifier && shouldAddToRunningRequestsCount(payload.requestIdentifier) ? state + 1 : state
  )
  .handleAction(finishRequestAction, (state, { payload }) =>
    payload.requestIdentifier && shouldAddToRunningRequestsCount(payload.requestIdentifier) && state > 0
      ? state - 1
      : state
  );

const runningRequestsReducer = createReducer(appReducerInitialSate.runningRequests)
  .handleAction(startRequestAction, (state, { payload }) => {
    const requestIdentifier = state[payload.requestIdentifier];

    return {
      ...state,
      [payload.requestIdentifier]: requestIdentifier ? requestIdentifier + 1 : 1
    };
  })
  .handleAction(finishRequestAction, (state, { payload }) => {
    const requestIdentifier = state[payload.requestIdentifier];

    if (requestIdentifier) {
      const updatedState = {
        ...state,
        [payload.requestIdentifier]: Math.max(requestIdentifier - 1, 0)
      };

      if (updatedState[payload.requestIdentifier] === 0) {
        delete updatedState[payload.requestIdentifier];
      }

      return updatedState;
    }
    return state;
  });

const runningRequestKeyReducer = createReducer(appReducerInitialSate.runningRequestKey).handleAction(
  changeRunningRequestKeyAction,
  () => moment().valueOf().toString()
);

const validationErrorResponseReducer = createReducer(appReducerInitialSate.validationErrorResponse)
  .handleAction(
    [startRequestAction, deleteStateValidationErrorResponseAction],
    () => appReducerInitialSate.validationErrorResponse
  )
  .handleAction(finishRequestAction, (_, { payload }) =>
    payload.status === HttpStatus.UNPROCESSABLE_ENTITY || payload.status === HttpStatus.NOT_FOUND
      ? {
          id: moment().valueOf(),
          requestIdentifier: payload.requestIdentifier,
          status: payload.status,
          violations: payload.violations ?? []
        }
      : appReducerInitialSate.validationErrorResponse
  );

const notFoundPageRenderedReducer = createReducer(appReducerInitialSate.notFoundPageRendered).handleAction(
  setNotFoundPageRenderedAction,
  (_, { payload }) => payload
);

export const appReducer = combineReducers<AppReducerState>({
  runningRequestsCount: runningRequestsCountReducer,
  runningRequests: runningRequestsReducer,
  runningRequestKey: runningRequestKeyReducer,
  validationErrorResponse: validationErrorResponseReducer,
  notFoundPageRendered: notFoundPageRenderedReducer
});

const persistDataReducerInitialState: PersistDataReducerState = {
  branding: null,
  partnerSsoTempData: null
};

const brandingReducer = createReducer(persistDataReducerInitialState.branding)
  .handleAction(getEnumerationsActions.success, (_, { payload }) => payload.branding)
  .handleAction(getEnumerationsActions.failure, () => persistDataReducerInitialState.branding);

const partnerSsoTempDataReducer = createReducer(persistDataReducerInitialState.partnerSsoTempData)
  .handleAction(setStatePartnerSsoTempDataAction, (_, { payload }) => payload)
  .handleAction(deleteStatePartnerSsoTempDataAction, () => persistDataReducerInitialState.partnerSsoTempData);

export const persistDataReducer = combineReducers<PersistDataReducerState>({
  branding: brandingReducer,
  partnerSsoTempData: partnerSsoTempDataReducer
});

/**
 * SELECTORS
 */
const selectApp = (state: RootState): AppReducerState => state.app;

export const selectHasActiveRequest = (state: RootState): boolean => !!selectApp(state).runningRequestsCount;

export const selectIsRequestInProgress = (state: RootState, request: ApiRequest): boolean => {
  if (request.hasParamsInUrl()) {
    const runningRequests = selectApp(state).runningRequests;
    for (const runningReqIdentifier of Object.keys(runningRequests)) {
      if (request.compareIdentifier(runningReqIdentifier)) {
        return !!runningRequests[runningReqIdentifier];
      }
    }
    return false;
  }

  return !!selectApp(state).runningRequests[request.identifier];
};

export const selectIsAnyRequestInProgress = (state: RootState, requests: ApiRequest[]): boolean => {
  return requests.some(request => selectIsRequestInProgress(state, request));
};

export const selectRunningRequestKey = (state: RootState): string | undefined =>
  selectApp(state).runningRequestKey ?? undefined;

export const selectValidationErrorResponse = (
  state: RootState,
  request: ApiRequest
): ValidationErrorResponse | undefined => {
  const errorResponse = selectApp(state).validationErrorResponse;
  return errorResponse && request.compareIdentifier(errorResponse.requestIdentifier) ? errorResponse : undefined;
};

export const selectAnyValidationErrorResponse = (
  state: RootState,
  requests: ApiRequest[]
): ValidationErrorResponse | undefined => {
  for (const request of requests) {
    const errorResponse = selectValidationErrorResponse(state, request);
    if (errorResponse) {
      return errorResponse;
    }
  }
  return undefined;
};

export const selectIsNotFoundErrorResponse = (state: RootState, request: ApiRequest): boolean => {
  const errorResponse = selectApp(state).validationErrorResponse;
  return !!(
    errorResponse &&
    request.compareIdentifier(errorResponse.requestIdentifier) &&
    errorResponse.status === HttpStatus.NOT_FOUND
  );
};

export const selectNotFoundPageRendered = (state: RootState): boolean => selectApp(state).notFoundPageRendered;

export const selectRouterAction = (state: RootState): Action => state.router.action as Action;
export const selectRouterLocation = (state: RootState): Location => state.router.location as Location;
export const selectRouterLocationPathname = (state: RootState): Pathname => selectRouterLocation(state).pathname;
export const selectRouterLocationSearch = (state: RootState): Search => selectRouterLocation(state).search;
export const selectRouterLocationHash = (state: RootState): Hash => selectRouterLocation(state).hash;
export const selectRouterLocationKey = (state: RootState): Key => selectRouterLocation(state).key;

export const selectRouterLocationSearchParam = (state: RootState, paramKey: string): string | undefined => {
  return new URLSearchParams(selectRouterLocationSearch(state)).get(paramKey) ?? undefined;
};

const selectPersistData = (state: RootState): PersistDataReducerState => state.persistData;

export const selectBrandingPersistData = (state: RootState): AgentBrandingEnumeration | undefined =>
  selectPersistData(state).branding ?? undefined;
export const selectPartnerSsoTempData = (state: RootState): PartnerSsoTempData | undefined =>
  selectPersistData(state).partnerSsoTempData ?? undefined;

/**
 * HELPER FUNCTIONS
 */
const shouldAddToRunningRequestsCount = (requestIdentifier: string): boolean => {
  for (const exclusion of runningRequestsCountExclusions) {
    if (exclusion.compareIdentifier(requestIdentifier)) {
      return false;
    }
  }
  return true;
};
