import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState } from "../../../common/types";
import { initPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import api from "./api";
import {
  CalcBlacklist,
  CalcBlacklistFilterPageRequest,
  CalcBlacklistFilterPageResult,
  CalcBlacklistReducerState,
  CreateUpdateCalcBlacklist
} from "./types";

/**
 * ACTIONS
 */
export const filterCalcBlacklistsActions = createAsyncAction(
  "calc-blacklist/FILTER_REQUEST",
  "calc-blacklist/FILTER_SUCCESS",
  "calc-blacklist/FILTER_FAILURE"
)<CalcBlacklistFilterPageRequest, CalcBlacklistFilterPageResult, void>();

export const createCalcBlacklistActions = createAsyncAction(
  "calc-blacklist/CREATE_REQUEST",
  "calc-blacklist/CREATE_SUCCESS",
  "calc-blacklist/CREATE_FAILURE"
)<CreateUpdateCalcBlacklist, CalcBlacklist, void>();

export const updateCalcBlacklistActions = createAsyncAction(
  "calc-blacklist/UPDATE_REQUEST",
  "calc-blacklist/UPDATE_SUCCESS",
  "calc-blacklist/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCalcBlacklist>, CalcBlacklist, void>();

export const deleteCalcBlacklistActions = createAsyncAction(
  "calc-blacklist/DELETE_REQUEST",
  "calc-blacklist/DELETE_SUCCESS",
  "calc-blacklist/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateCalcBlacklistsPageAction = createAction("calc-blacklist/DELETE_STATE_LIST")<void>();

const actions = {
  filterCalcBlacklistsActions,
  createCalcBlacklistActions,
  updateCalcBlacklistActions,
  deleteCalcBlacklistActions,
  deleteStateCalcBlacklistsPageAction
};

export type CalcBlacklistAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CalcBlacklistReducerState = {
  currentPage: {
    ...initPageResult<CalcBlacklist>(),
    calcType: undefined,
    institutionIds: [],
    agentIds: []
  }
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterCalcBlacklistsActions.success, (_, { payload }) => payload)
  .handleAction(updateCalcBlacklistActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterCalcBlacklistsActions.failure, deleteStateCalcBlacklistsPageAction],
    () => initialState.currentPage
  );

export const calcBlacklistReducer = combineReducers<CalcBlacklistReducerState>({
  currentPage: currentPageReducer
});

/**
 * SELECTORS
 */
const selectCalcBlacklist = (state: RootState): CalcBlacklistReducerState => state.calculator.blacklist;

export const selectCalcBlacklistsCurrentPage = (state: RootState): CalcBlacklistFilterPageResult =>
  selectCalcBlacklist(state).currentPage;

/**
 * SAGAS
 */
function* filterCalcBlacklists({ payload }: ReturnType<typeof filterCalcBlacklistsActions.request>) {
  try {
    const response: AxiosResponse<CalcBlacklistFilterPageResult> = yield call(api.filterCalcBlacklists, payload);
    yield put(filterCalcBlacklistsActions.success(response.data));
  } catch (error) {
    yield put(filterCalcBlacklistsActions.failure());
  }
}

function* createCalcBlacklist({ payload }: ReturnType<typeof createCalcBlacklistActions.request>) {
  try {
    const response: AxiosResponse<CalcBlacklist> = yield call(api.createCalcBlacklist, payload);
    yield put(createCalcBlacklistActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createCalcBlacklistActions.failure());
  }
}

function* updateCalcBlacklist({ payload }: ReturnType<typeof updateCalcBlacklistActions.request>) {
  try {
    const response: AxiosResponse<CalcBlacklist> = yield call(api.updateCalcBlacklist, payload);
    yield put(updateCalcBlacklistActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateCalcBlacklistActions.failure());
  }
}

function* deleteCalcBlacklist({ payload }: ReturnType<typeof deleteCalcBlacklistActions.request>) {
  try {
    yield call(api.deleteCalcBlacklist, payload);
    yield put(deleteCalcBlacklistActions.success());
    messageUtils.itemDeletedNotification();
    yield* refreshCurrentPage(true);
  } catch (error) {
    yield put(deleteCalcBlacklistActions.failure());
  }
}

function* refreshCurrentPage(movePage?: boolean) {
  const currentPage: CalcBlacklistFilterPageResult = yield select(selectCalcBlacklistsCurrentPage);
  yield put(
    filterCalcBlacklistsActions.request({
      pageIndex: movePage
        ? currentPage.pageElementsCount === 1
          ? Math.max(currentPage.pageIndex - 1, 0)
          : currentPage.pageIndex
        : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      calcType: currentPage.calcType,
      institutionIds: currentPage.institutionIds,
      agentIds: currentPage.agentIds
    })
  );
}

export function* calcBlacklistSaga() {
  yield takeLatest(filterCalcBlacklistsActions.request, filterCalcBlacklists);
  yield takeLatest(createCalcBlacklistActions.request, createCalcBlacklist);
  yield takeLatest(updateCalcBlacklistActions.request, updateCalcBlacklist);
  yield takeLatest(deleteCalcBlacklistActions.request, deleteCalcBlacklist);
}
