import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { selectInstitutionsEnums } from "../../../enumerations/ducks";
import { InstitutionWithSettings } from "../../../enumerations/types";
import { InstitutionEnum } from "../../../institution/enums";
import { InstitutionBase } from "../../../institution/types";
import { requests } from "../../api";
import { replacePartnerConfigsActions } from "../../ducks";
import { PartnerSsoType, ssoTypeToInstitutionMap } from "../../enums";
import { CreateUpdatePartnerConfig, PartnerConfig, PartnerConfigsAndSsoProperties } from "../../types";

interface Props {
  configsAndSsoProps?: PartnerConfigsAndSsoProperties;
  open: boolean;
  configs?: PartnerConfig[];
  onFormSubmit: typeof replacePartnerConfigsActions.request;
  onFormCancel: () => void;
}

interface CreateUpdatePartnerConfigsForm {
  configs: CreateUpdatePartnerConfig[];
}

const PartnerConfigForm = ({ configsAndSsoProps, open, configs, ...actions }: Props) => {
  const [form] = Form.useForm<CreateUpdatePartnerConfigsForm>();
  useFormErrorHandler(form, "partnerConfig.attrs", [requests.CREATE_PARTNER_CONFIGS, requests.UPDATE_PARTNER_CONFIGS]);

  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const allowedSsoTypes = useMemo(() => {
    const ssoTypes = [PartnerSsoType.MONLY];

    ssoTypeToInstitutionMap.forEach((institutionEnum, ssoType) => {
      const institution = institutionsEnums.find(i => i.institutionEnum === institutionEnum);
      if ((institution && !institution.settings.deactivated) || configs?.some(c => c.ssoType === ssoType)) {
        ssoTypes.push(ssoType);
      }
    });

    return ssoTypes;
  }, [institutionsEnums, configs]);

  useEffect(() => {
    if (open && configs) {
      form.setFieldsValue({
        configs: configs.map(config => ({ ...config, institutionId: config.institution?.id }))
      });
    }
  }, [open, configs, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_PARTNER_CONFIGS, requests.UPDATE_PARTNER_CONFIGS, requests.DELETE_PARTNER_CONFIGS],
    actions.onFormCancel
  );

  const handleSsoTypeChange = (index: number, ssoType: PartnerSsoType): void => {
    let values: Record<string, any> = [...form.getFieldsValue().configs];
    if (ssoType) {
      switch (ssoType) {
        case PartnerSsoType.MONLY:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.monlySsoProperties.baseUrl,
            institutionId: undefined
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.ALLIANZ:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.allianzSsoProperties.entryUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.ALLIANZ)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.AXA:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.uniqaStudioSsoProperties.entryUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.AXA_NON_LIFE)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.CSOB:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.csobSsoProperties.entryUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.CSOB)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.GENERALI:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.generaliSsoProperties.entryUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.GENERALI)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.METLIFE:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.metLifeSsoProperties.baseUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.MET_LIFE)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.NN_EXPERT_APP:
        case PartnerSsoType.NN_WEB_AGENT_APP:
          values[index] = {
            ...values[index],
            link:
              ssoType === PartnerSsoType.NN_EXPERT_APP
                ? configsAndSsoProps?.nnSsoProperties.expertAppEntryUrl
                : configsAndSsoProps?.nnSsoProperties.webAgentAppEntryUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.NN)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.UNIQA:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.uniqaSsoProperties.entryUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.UNIQA)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
        case PartnerSsoType.YOUPLUS:
          values[index] = {
            ...values[index],
            link: configsAndSsoProps?.youPlusSsoProperties.entryUrl,
            institutionId: institutionsEnums.find(i => i.institutionEnum === InstitutionEnum.YOUPLUS)?.id
          };
          form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
          break;
      }
    } else {
      values[index] = { ...values[index], link: undefined, institutionId: configs?.[0]?.institution?.id };
      form.setFieldsValue({ configs: values as CreateUpdatePartnerConfig[] });
    }
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        actions.onFormSubmit({ prevConfigs: configs, newConfigs: values.configs });
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={configs ? t("partnerConfig.titles.updateConfig") : t("partnerConfig.titles.createConfig")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={actions.onFormCancel}
    >
      <Form form={form} layout="vertical" name="partnerConfigsForm">
        <Form.List name="configs" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={index} gutter={rowGutter} className="margin-top-medium">
                  <Col span={22}>
                    <Row gutter={rowGutter}>
                      <HiddenInput name={[field.name, "id"]} />
                      <HiddenInput name={[field.name, "optimisticLockVersion"]} />

                      <Col span={8}>
                        <Form.Item
                          name={[field.name, "label"]}
                          label={t("partnerConfig.attrs.label")}
                          rules={[validations.notBlank, validations.size(1, 255)]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          name={[field.name, "ssoType"]}
                          label={t("partnerConfig.attrs.ssoType")}
                          rules={[validations.none]}
                        >
                          <Select
                            {...selectStandardProps}
                            allowClear
                            options={allowedSsoTypes.map(ssoType => ({
                              value: ssoType,
                              label: t("partnerSso.enums.ssoType." + ssoType)
                            }))}
                            disabled={configs?.[0]?.ssoType === PartnerSsoType.MONLY}
                            onChange={ssoType => handleSsoTypeChange(index, ssoType)}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, next) =>
                            prev.configs?.[field.name]?.ssoType !== next.configs?.[field.name]?.ssoType
                          }
                        >
                          {({ getFieldValue }) => (
                            <InstitutionSelect
                              formItemProps={{
                                name: [field.name, "institutionId"],
                                label: t("partnerConfig.attrs.institutionId"),
                                rules: [validations.none]
                              }}
                              selectProps={{
                                allowClear: true,
                                disabled:
                                  !!getFieldValue(["configs", field.name, "ssoType"]) || !!configs?.[0]?.institution
                              }}
                              optionsProps={{
                                groupByType: true,
                                selected: configs?.[index]?.institution
                                  ? [configs[index]?.institution as InstitutionBase]
                                  : []
                              }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={rowGutter}>
                      <Col span={24}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, next) =>
                            prev.configs?.[field.name]?.ssoType !== next.configs?.[field.name]?.ssoType
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              name={[field.name, "link"]}
                              label={t("partnerConfig.attrs.link")}
                              rules={[
                                validations.notBlank,
                                validations.size(1, 255),
                                validations.pattern(regexPatterns.webPageRegex)
                              ]}
                            >
                              <Input disabled={!!getFieldValue(["configs", field.name, "ssoType"])} />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <div className="partner-form-delete-icon-container">
                      {fields.length > 1 && <DeleteIcon onClick={() => remove(field.name)} />}
                    </div>
                  </Col>
                </Row>
              ))}
              {configs?.[0]?.ssoType !== PartnerSsoType.MONLY && (
                <ActionButton
                  icon="plus"
                  className="margin-top-small"
                  label={t("common.add")}
                  onClick={() => add({ institutionId: configs?.[0]?.institution?.id })}
                />
              )}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default PartnerConfigForm;
