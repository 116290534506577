import { Button, Modal, Result, Timeline } from "antd";
import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import TimelineDotIcon from "../../../../common/components/icons/TimelineDotIcon";
import { ModalSizes } from "../../../../common/constants";
import { isLocalhostDevMode } from "../../../../common/utils/utils";
import { CONTRACT_ROUTE_PATHS } from "../../../contract/paths";
import { GenStep } from "../enums";
import { GenResponse } from "../types";
import { checkIfStepsAreSuccessful } from "../utils";

interface Props {
  result?: GenResponse;
  showReturnToCalculationButton?: boolean;
  onCancel?: () => void;
  onReturnToCalculationClick?: () => void;
}

const CalcGenResultModal = ({ result, showReturnToCalculationButton, ...props }: Props) => {
  const navigate = useNavigate();

  const contractCreated = result && result.steps && checkIfStepsAreSuccessful(result.steps, GenStep.CREATE_CONTRACT);
  let contractSaved = false;
  let attachmentsProcessed = false;
  let emailSent = false;
  let processFinalized = false;
  let allStepsSuccessful = false;

  if (contractCreated) {
    contractSaved = checkIfStepsAreSuccessful(result.steps, GenStep.SAVE_CLIENTS_AND_CONTRACT);
    attachmentsProcessed = checkIfStepsAreSuccessful(
      result.steps,
      GenStep.DOWNLOAD_ATTACHMENTS,
      GenStep.GENERATE_ATTACHMENTS,
      GenStep.SAVE_ATTACHMENTS
    );
    emailSent = checkIfStepsAreSuccessful(result.steps, GenStep.SEND_EMAIL);
    processFinalized = checkIfStepsAreSuccessful(result.steps, GenStep.FINALIZE_PROCESS);
    allStepsSuccessful = contractSaved && attachmentsProcessed && emailSent && processFinalized;
  }

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      title={t("calc.genResult.title")}
      open={contractCreated}
      maskClosable={false}
      closable={isLocalhostDevMode()}
      cancelButtonProps={undefined}
      footer={
        <>
          {showReturnToCalculationButton && props.onReturnToCalculationClick && (
            <Button type="dashed" icon={<AntIcon type="double-left" />} onClick={props.onReturnToCalculationClick}>
              {t("calc.genResult.returnToCalculation")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<AntIcon type="file-done" />}
            onClick={() =>
              navigate(
                contractSaved
                  ? generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: result?.contract.id })
                  : CONTRACT_ROUTE_PATHS.list.to,
                { replace: true }
              )
            }
          >
            {contractSaved ? t("calc.genResult.continueToContract") : t("calc.genResult.continueToContractsList")}
          </Button>
        </>
      }
      onCancel={props.onCancel}
    >
      <Result
        style={{ padding: "0 32px" }}
        status={allStepsSuccessful ? "success" : "warning"}
        title={allStepsSuccessful ? t("calc.genResult.successDesc") : t("calc.genResult.errorDesc")}
        extra={
          <>
            {!allStepsSuccessful && (
              <div className="margin-bottom-large center-align">
                <div className="highlighted-text-big margin-bottom-small">{t("calc.genResult.errorInfoDesc1")}</div>
                <div className="sub-header-info">{t("calc.genResult.errorInfoDesc2")}</div>
              </div>
            )}

            <div style={{ maxWidth: "350px", margin: "0 auto" }}>
              <Timeline>
                <Timeline.Item dot={<TimelineDotIcon status="success" />} color="green">
                  {t("calc.genResult.steps.createContract")}
                </Timeline.Item>

                <Timeline.Item
                  dot={<TimelineDotIcon status={contractSaved ? "success" : "error"} />}
                  color={contractSaved ? "green" : "red"}
                >
                  {t("calc.genResult.steps.saveContract")}
                </Timeline.Item>

                <Timeline.Item
                  dot={<TimelineDotIcon status={attachmentsProcessed ? "success" : "error"} />}
                  color={attachmentsProcessed ? "green" : "red"}
                >
                  {t("calc.genResult.steps.processAttachments")}
                </Timeline.Item>

                <Timeline.Item
                  dot={<TimelineDotIcon status={emailSent ? "success" : "error"} />}
                  color={emailSent ? "green" : "red"}
                >
                  {t("calc.genResult.steps.sendEmail")}
                </Timeline.Item>

                <Timeline.Item
                  dot={<TimelineDotIcon status={processFinalized ? "success" : "error"} />}
                  color={processFinalized ? "green" : "red"}
                >
                  {t("calc.genResult.steps.finalizeProcess")}
                </Timeline.Item>
              </Timeline>
            </div>
          </>
        }
      />
    </Modal>
  );
};

export default React.memo(CalcGenResultModal);
