import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../../app/i18n";
import { EntityIdObject, EntityObject, RootState, TwoLevelEntityIdObject } from "../../../common/types";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { openBlobFile, removeFromArray, replaceInArray } from "../../../common/utils/utils";
import { setRealtyCalcResultsAction, setRealtyDraftAction } from "../calcs/realty/ducks";
import { RealtyCalcDraft, RealtyCalcResultData } from "../calcs/realty/types";
import { setTravelCalcResultsAction, setTravelDraftAction } from "../calcs/travel/ducks";
import { TravelCalcDraft, TravelCalcResultData } from "../calcs/travel/types";
import { isRealtyCalcDraft, isTravelCalcDraft, isVehicleCalcDraft, sortAndGroupCalcResults } from "../calcs/utils";
import { setVehicleCalcResultsAction, setVehicleDraftAction } from "../calcs/vehicle/ducks";
import { VehicleCalcDraft } from "../calcs/vehicle/types";
import { sortAndGroupVehicleCalcResults } from "../calcs/vehicle/utils";
import { CalcType } from "../enums";
import api from "./api";
import {
  CalcDraft,
  CalcDraftAttachment,
  CalcDraftFilterPageRequest,
  CalcDraftFilterPageResult,
  CalcDraftList,
  CalcDraftReducerState,
  CreateUpdateCalcDraft
} from "./types";

/**
 * ACTIONS
 */
export const filterCalcDraftsActions = createAsyncAction(
  "calc-draft/FILTER_REQUEST",
  "calc-draft/FILTER_SUCCESS",
  "calc-draft/FILTER_FAILURE"
)<CalcDraftFilterPageRequest, CalcDraftFilterPageResult, void>();

export const getCalcDraftActions = createAsyncAction(
  "calc-draft/GET_REQUEST",
  "calc-draft/GET_SUCCESS",
  "calc-draft/GET_FAILURE"
)<EntityIdObject, CalcDraft, void>();

export const createCalcDraftActions = createAsyncAction(
  "calc-draft/CREATE_REQUEST",
  "calc-draft/CREATE_SUCCESS",
  "calc-draft/CREATE_FAILURE"
)<CreateUpdateCalcDraft, CalcDraft, void>();

export const updateCalcDraftActions = createAsyncAction(
  "calc-draft/UPDATE_REQUEST",
  "calc-draft/UPDATE_SUCCESS",
  "calc-draft/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCalcDraft>, CalcDraft, void>();

export const deleteCalcDraftActions = createAsyncAction(
  "calc-draft/DELETE_REQUEST",
  "calc-draft/DELETE_SUCCESS",
  "calc-draft/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateCalcDraftsPageAction = createAction("calc-draft/DELETE_STATE_LIST")<void>();

export const uploadCalcDraftAttachmentsActions = createAsyncAction(
  "calc-draft-attachment/UPLOAD_REQUEST",
  "calc-draft-attachment/UPLOAD_SUCCESS",
  "calc-draft-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, EntityObject<CalcDraftAttachment[]>, void>();

export const downloadCalcDraftAttachmentActions = createAsyncAction(
  "calc-draft-attachment/DOWNLOAD_REQUEST",
  "calc-draft-attachment/DOWNLOAD_SUCCESS",
  "calc-draft-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const deleteCalcDraftAttachmentActions = createAsyncAction(
  "calc-draft-attachment/DELETE_REQUEST",
  "calc-draft-attachment/DELETE_SUCCESS",
  "calc-draft-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, TwoLevelEntityIdObject, void>();

const actions = {
  filterCalcDraftsActions,
  getCalcDraftActions,
  createCalcDraftActions,
  updateCalcDraftActions,
  deleteCalcDraftActions,
  deleteStateCalcDraftsPageAction,
  uploadCalcDraftAttachmentsActions,
  downloadCalcDraftAttachmentActions,
  deleteCalcDraftAttachmentActions
};

export type CalcDraftAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CalcDraftReducerState = {
  currentPage: {
    ...initSearchPageResult<CalcDraftList>(),
    calcTypes: []
  }
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterCalcDraftsActions.success, (_, { payload }) => payload)
  .handleAction(uploadCalcDraftAttachmentsActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      current => ({ ...current, attachments: payload.object })
    )
  }))
  .handleAction(deleteCalcDraftAttachmentActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id1,
      current => ({ ...current, attachments: removeFromArray(current.attachments, item => item.id === payload.id2) })
    )
  }))
  .handleAction([filterCalcDraftsActions.failure, deleteStateCalcDraftsPageAction], () => initialState.currentPage);

export const calcDraftsReducer = combineReducers<CalcDraftReducerState>({ currentPage: currentPageReducer });

/**
 * SELECTORS
 */
const selectCalcDrafts = (state: RootState): CalcDraftReducerState => state.calculator.drafts;

export const selectCalcDraftsCurrentPage = (state: RootState): CalcDraftFilterPageResult =>
  selectCalcDrafts(state).currentPage;

/**
 * HELPERS
 */

function* setDraftAction(data: VehicleCalcDraft | RealtyCalcDraft | TravelCalcDraft) {
  switch (data.calcType) {
    case CalcType.MTPL:
    case CalcType.CRASH:
    case CalcType.MTPL_CRASH:
    case CalcType.GAP:
    case CalcType.PAS:
      yield put(setVehicleDraftAction(data as VehicleCalcDraft));
      break;
    case CalcType.REALTY:
      yield put(setRealtyDraftAction(data as RealtyCalcDraft));
      break;
    case CalcType.TRAVEL:
      yield put(setTravelDraftAction(data as TravelCalcDraft));
      break;
  }
}

/**
 * SAGAS
 */
function* filterCalcDrafts({ payload }: ReturnType<typeof filterCalcDraftsActions.request>) {
  try {
    const response: AxiosResponse<CalcDraftFilterPageResult> = yield call(api.filterCalcDrafts, payload);
    yield put(filterCalcDraftsActions.success(response.data));
  } catch (error) {
    yield put(filterCalcDraftsActions.failure());
  }
}

function* getCalcDraft({ payload }: ReturnType<typeof getCalcDraftActions.request>) {
  try {
    const responseData = ((yield call(api.getCalcDraft, payload)) as AxiosResponse<CalcDraft>).data;
    yield put(getCalcDraftActions.success(responseData));

    if (isVehicleCalcDraft(responseData)) {
      yield put(setVehicleDraftAction(responseData));
      yield put(setVehicleCalcResultsAction(sortAndGroupVehicleCalcResults(responseData.calcResponse)));
    } else if (isRealtyCalcDraft(responseData)) {
      yield put(setRealtyDraftAction(responseData));
      yield put(setRealtyCalcResultsAction(sortAndGroupCalcResults<RealtyCalcResultData>(responseData.calcResponse)));
    } else if (isTravelCalcDraft(responseData)) {
      yield put(setTravelDraftAction(responseData));
      yield put(setTravelCalcResultsAction(sortAndGroupCalcResults<TravelCalcResultData>(responseData.calcResponse)));
    }
  } catch (error) {
    yield put(replace(window.location.pathname));
    yield put(getCalcDraftActions.failure());
  }
}

function* createCalcDraft({ payload }: ReturnType<typeof createCalcDraftActions.request>) {
  try {
    const response: AxiosResponse<CalcDraft> = yield call(api.createCalcDraft, payload);
    yield put(createCalcDraftActions.success(response.data));

    yield setDraftAction(response.data);

    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("calc.draft.helpers.draftSaved")
    });
  } catch (error) {
    yield put(createCalcDraftActions.failure());
  }
}

function* updateCalcDraft({ payload }: ReturnType<typeof updateCalcDraftActions.request>) {
  try {
    const response: AxiosResponse<CalcDraft> = yield call(api.updateCalcDraft, payload);
    yield put(updateCalcDraftActions.success(response.data));

    yield setDraftAction(response.data);

    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("calc.draft.helpers.draftSaved")
    });
  } catch (error) {
    yield put(updateCalcDraftActions.failure());
  }
}

function* deleteCalcDraft({ payload }: ReturnType<typeof deleteCalcDraftActions.request>) {
  try {
    yield call(api.deleteCalcDraft, payload);
    yield put(deleteCalcDraftActions.success());

    const currentPage: CalcDraftFilterPageResult = yield select(selectCalcDraftsCurrentPage);
    yield put(
      filterCalcDraftsActions.request({
        keyword: currentPage.keyword,
        pageIndex: currentPage.pageElementsCount === 1 ? Math.max(currentPage.pageIndex - 1, 0) : currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        calcTypes: currentPage.calcTypes
      })
    );
  } catch (error) {
    yield put(deleteCalcDraftActions.failure());
  }
}

function* uploadCalcDraftAttachments({ payload }: ReturnType<typeof uploadCalcDraftAttachmentsActions.request>) {
  try {
    const response: AxiosResponse<CalcDraftAttachment[]> = yield call(api.uploadCalcDraftAttachments, payload);
    yield put(uploadCalcDraftAttachmentsActions.success({ id: payload.id, object: response.data }));
  } catch (error) {
    yield put(uploadCalcDraftAttachmentsActions.failure());
  }
}

function* downloadCalcDraftAttachment({ payload }: ReturnType<typeof downloadCalcDraftAttachmentActions.request>) {
  try {
    const response: AxiosResponse = yield call(api.downloadCalcDraftAttachment, payload);
    openBlobFile(response);
    yield put(downloadCalcDraftAttachmentActions.success());
  } catch (error) {
    yield put(downloadCalcDraftAttachmentActions.failure());
  }
}

function* deleteCalcDraftAttachment({ payload }: ReturnType<typeof deleteCalcDraftAttachmentActions.request>) {
  try {
    yield call(api.deleteCalcDraftAttachment, payload);
    yield put(deleteCalcDraftAttachmentActions.success(payload));
  } catch (error) {
    yield put(deleteCalcDraftAttachmentActions.failure());
  }
}

export function* calcDraftsSaga() {
  yield takeLatest(filterCalcDraftsActions.request, filterCalcDrafts);
  yield takeLatest(getCalcDraftActions.request, getCalcDraft);
  yield takeLatest(createCalcDraftActions.request, createCalcDraft);
  yield takeLatest(updateCalcDraftActions.request, updateCalcDraft);
  yield takeLatest(deleteCalcDraftActions.request, deleteCalcDraft);
  yield takeLatest(uploadCalcDraftAttachmentsActions.request, uploadCalcDraftAttachments);
  yield takeLatest(downloadCalcDraftAttachmentActions.request, downloadCalcDraftAttachment);
  yield takeLatest(deleteCalcDraftAttachmentActions.request, deleteCalcDraftAttachment);
}
