import { Alert, AutoComplete, Card, Col, Divider, Form, Input, Row, Switch } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { useEffect, useState } from "react";
import t from "../../../../../../../app/i18n";
import ContactsEmailAutoComplete from "../../../../../../../common/components/form/components/ContactsEmailAutoComplete";
import ContactsPhoneNumberAutoComplete from "../../../../../../../common/components/form/components/ContactsPhoneNumberAutoComplete";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../../common/constants";
import MarketingConsentSelect from "../../../../../../../common/modules/contact/MarketingConsentSelect";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  upperCaseStringNormalizeFunction
} from "../../../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../../../common/utils/validationUtils";
import ClientDrawerForm from "../../../../../../client/components/drawers/ClientDrawerForm";
import ClientRepresentativeSearchForm from "../../../../../../client/components/search/ClientRepresentativeSearchForm";
import ClientSearchInput from "../../../../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientFormType, ClientSearchActionType, ClientType } from "../../../../../../client/enums";
import { Client, LegalClient, NaturalClient, SelfEmployedClient } from "../../../../../../client/types";
import { useClientSearch } from "../../../../../../client/utils";
import { InstitutionEnum } from "../../../../../../institution/enums";
import { resolveClientFormTypeIdentifierName } from "../../../../utils";
import { RealtyFormClients, RealtyGenForm, RealtyGenFormClientsData } from "../../../types";

interface Props {
  form: FormInstance<RealtyGenForm>;
  vinculation: boolean;
  selectedInstitutionEnum: InstitutionEnum;
  clients: RealtyFormClients;
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  onClientChange: (type: ClientFormType, client?: NaturalClient | SelfEmployedClient | LegalClient) => void;
  onClientViolationErrorsChange: (type: ClientFormType, violations?: FieldConstraintViolation[]) => void;
}

interface ClientsStagesState {
  readonly insured?: ClientFormStage;
  readonly representative?: ClientFormStage;
  readonly vinculation?: ClientFormStage;
}

const noRepeatedClient =
  (
    policyHolder: NaturalClient | SelfEmployedClient | LegalClient,
    vinculation: boolean,
    checkedIdentifier: ClientFormType
  ): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      if (value) {
        const {
          insuredClientIdentifier,
          policyHolderIsAlsoInsured,
          representativeIdentifier,
          vinculationClientIdentifier
        } = getFieldValue(["clientsData"]) as RealtyGenFormClientsData;

        return value === policyHolder.identifier ||
          (checkedIdentifier !== ClientFormType.INSURED &&
            !policyHolderIsAlsoInsured &&
            value === insuredClientIdentifier) ||
          (checkedIdentifier !== ClientFormType.REPRESENTATIVE &&
            policyHolder.type === ClientType.LEGAL &&
            value === representativeIdentifier) ||
          (checkedIdentifier !== ClientFormType.VINCULATION && vinculation && value === vinculationClientIdentifier)
          ? Promise.reject(t("validation.noRepeatedClient"))
          : Promise.resolve();
      }
      return Promise.resolve();
    }
  });

const RealtyGenClientsDataSection = ({ form, clients, ...props }: Props) => {
  const clientSearch = useClientSearch();

  const [processedClientFormType, setProcessedClientFormType] = useState<ClientFormType>();
  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [clientStages, setClientStages] = useState<ClientsStagesState>({
    insured: clients.insured ? ClientFormStage.EXISTING : undefined,
    representative:
      clients.representative &&
      clients.policyHolder?.type === ClientType.LEGAL &&
      ((clients.policyHolder as LegalClient)?.representatives || []).some(
        r => r.representative.identifier === clients.representative?.identifier
      )
        ? ClientFormStage.EXISTING
        : undefined,
    vinculation: clients.vinculation && props.vinculation ? ClientFormStage.EXISTING : undefined
  });

  useEffect(() => {
    if (clients.insured) {
      props.onClientChange(ClientFormType.INSURED, clients.insured);
    }
    return () => {
      clientSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processedClientFormType && clientSearch.result.keyword === getClientFormIdentifier(processedClientFormType)) {
      if (clientSearch.result.data) {
        setClientFormOpen(true);
        setClientFormStage(processedClientFormType, ClientFormStage.EXISTING);
        if (
          clientSearch.result.data.type === ClientType.NATURAL ||
          clientSearch.result.data.type === ClientType.SELF_EMPLOYED
        ) {
          props.onClientChange(processedClientFormType, {
            ...clientSearch.result.data,
            identityCardNumber: undefined,
            previousIdentityCardNumber: (clientSearch.result.data as NaturalClient).identityCardNumber
          } as NaturalClient);
        } else {
          props.onClientChange(processedClientFormType, clientSearch.result.data as LegalClient);
        }
      } else {
        setClientFormStage(processedClientFormType, ClientFormStage.NEW);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientSearchSubmit = (value: string, type: ClientFormType): void => {
    form
      .validateFields([["clientsData", resolveClientFormTypeIdentifierName(type)]])
      .then(() => clientSearch.onSearch({ keyword: value, clientType: resolveClientTypeByClientFormType(type) }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, type: ClientFormType): void => {
    if (getClientFormStage(type)) {
      setClientFormStage(type);
    }
    if (getClientFromProps(type)) {
      props.onClientChange(type);
    }
    if (props.clientsViolationErrors.has(type)) {
      props.onClientViolationErrorsChange(type);
    }
    handleClientSearchSubmit(value, type);
  };

  const handleClientSearchActionClick = (type: ClientFormType, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setProcessedClientFormType(type);
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        form.setFieldsValue({ clientsData: { [resolveClientFormTypeIdentifierName(type)]: null } });
        setClientFormStage(type);
        props.onClientViolationErrorsChange(type);
        props.onClientChange(type);
        break;
    }
  };

  const handleClientFormSubmit = (
    client: NaturalClient | SelfEmployedClient | LegalClient,
    clientFormType: ClientFormType
  ): void => {
    setClientFormOpen(false);
    setProcessedClientFormType(undefined);
    setClientFormStage(clientFormType, ClientFormStage.SELECTED);
    props.onClientViolationErrorsChange(clientFormType);
    props.onClientChange(clientFormType, client);
  };

  const getClientFormIdentifier = (type?: ClientFormType): string => {
    switch (type) {
      case ClientFormType.INSURED:
        return form.getFieldValue(["clientsData", "insuredClientIdentifier"]);
      case ClientFormType.REPRESENTATIVE:
        return form.getFieldValue(["clientsData", "representativeIdentifier"]);
      case ClientFormType.VINCULATION:
        return form.getFieldValue(["clientsData", "vinculationClientIdentifier"]);
      default:
        return "";
    }
  };

  const getClientFormStage = (type: ClientFormType): ClientFormStage | undefined => {
    switch (type) {
      case ClientFormType.INSURED:
        return clientStages.insured;
      case ClientFormType.REPRESENTATIVE:
        return clientStages.representative;
      case ClientFormType.VINCULATION:
        return clientStages.vinculation;
      default:
        return clientStages.insured;
    }
  };

  const getClientFromProps = (type?: ClientFormType): Client | undefined => {
    switch (type) {
      case ClientFormType.INSURED:
        return clients.insured;
      case ClientFormType.POLICY_HOLDER:
        return clients.policyHolder;
      case ClientFormType.REPRESENTATIVE:
        return clients.representative;
      case ClientFormType.VINCULATION:
        return clients.vinculation;
      default:
        return;
    }
  };

  const setClientFormStage = (type: ClientFormType, stage?: ClientFormStage): void => {
    switch (type) {
      case ClientFormType.INSURED:
        setClientStages({ ...clientStages, insured: stage });
        break;
      case ClientFormType.REPRESENTATIVE:
        setClientStages({ ...clientStages, representative: stage });
        break;
      case ClientFormType.VINCULATION:
        setClientStages({ ...clientStages, vinculation: stage });
        break;
    }
  };

  const resolveClientTypeByClientFormType = (type?: ClientFormType): ClientType => {
    switch (type) {
      case ClientFormType.INSURED:
        return ClientType.NATURAL;
      case ClientFormType.REPRESENTATIVE:
        return ClientType.NATURAL;
      case ClientFormType.VINCULATION:
        return ClientType.LEGAL;
      default:
        return ClientType.NATURAL;
    }
  };

  const clientSearchProps = {
    processedType: processedClientFormType,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    inputColSpan: 5,
    clientNameColSpan: 6,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientFormType,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  };

  const colSpan = 4;

  return (
    <>
      <Card type="inner" className="card-box" title={t("calc.realty.sections.clientsData")}>
        <Divider className="divider-subheader">{t("calc.realty.sections.policyHolder")}</Divider>

        <div className="sub-header-info normal-font-size margin-bottom-small">
          <span>{t("calc.realty.sections.policyHolder")}: </span>
          {clients.policyHolder?.aggregatedName} ({clients.policyHolder?.identifier})
        </div>

        {props.clientsViolationErrors.has(ClientFormType.POLICY_HOLDER) && (
          <Alert
            type="error"
            showIcon
            className="margin-bottom-medium"
            style={{ maxWidth: "600px" }}
            message={t("calc.realty.validations.policyHolderError")}
            description={props.clientsViolationErrors
              .get(ClientFormType.POLICY_HOLDER)
              ?.flatMap(violation => violation.errors)
              .join(" ")}
          />
        )}

        <Row gutter={rowGutter}>
          {(clients.policyHolder?.type === ClientType.NATURAL ||
            clients.policyHolder?.type === ClientType.SELF_EMPLOYED) && (
            <Col span={colSpan}>
              <Form.Item
                name={["clientsData", "policyHolderIdentityCardNumber"]}
                label={t("calc.realty.attrs.clientsData.policyHolderIdentityCardNumber")}
                rules={[validations.notBlank, validations.size(8, 10), validations.pattern(regexPatterns.idCardRegex)]}
                normalize={upperCaseStringNormalizeFunction}
              >
                {(clients.policyHolder as NaturalClient).previousIdentityCardNumber ? (
                  <AutoComplete
                    options={[
                      {
                        value: (clients.policyHolder as NaturalClient).previousIdentityCardNumber,
                        label: (
                          <>
                            <span className="sub-header-info">{t("client.attrs.previousIdentityCardNumber")}</span>
                            <br />
                            {(clients.policyHolder as NaturalClient).previousIdentityCardNumber}
                          </>
                        )
                      }
                    ]}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>
          )}

          <Col span={colSpan}>
            <ContactsEmailAutoComplete
              formItemProps={{
                name: ["clientsData", "policyHolderEmail"],
                label: t("calc.realty.attrs.clientsData.policyHolderEmail"),
                rules: [validations.notBlank, validations.size(1, 254), validations.email]
              }}
              contacts={clients.policyHolder?.contacts}
            />
          </Col>

          <Col span={colSpan}>
            <ContactsPhoneNumberAutoComplete
              formItemProps={{
                name: ["clientsData", "policyHolderPhone"],
                label: t("calc.realty.attrs.clientsData.policyHolderPhone"),
                rules: [validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber],
                normalize: phoneNumberNormalizeFunction
              }}
              contacts={clients.policyHolder?.contacts}
            />
          </Col>

          <Col span={colSpan}>
            <MarketingConsentSelect
              formItemProps={{
                name: ["clientsData", "policyHolderMarketingConsent"],
                label: t("contact.enums.marketingConsent._label"),
                rules: [validations.notNull]
              }}
            />
          </Col>
        </Row>

        <ClientRepresentativeSearchForm
          form={form}
          additionalRules={
            clients.policyHolder
              ? [noRepeatedClient(clients.policyHolder, props.vinculation, ClientFormType.REPRESENTATIVE)]
              : []
          }
          policyHolder={clients.policyHolder}
          searchInputProps={{
            ...clientSearchProps,
            formStage: clientStages.representative,
            formType: ClientFormType.REPRESENTATIVE,
            client: clients.representative
          }}
          colSpan={colSpan}
        />

        <Divider className="divider-subheader">{t("calc.realty.sections.insuredClient")}</Divider>

        <Row gutter={rowGutter}>
          <Col flex="238px" className="bold-text">
            {t("calc.realty.sections.policyHolderIsAlsoInsured")}:
          </Col>
          <Col span={12} style={{ marginTop: "-4px" }}>
            <Form.Item
              name={["clientsData", "policyHolderIsAlsoInsured"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={true}
            >
              <Switch
                checkedChildren={<AntIcon type="check" />}
                unCheckedChildren={<AntIcon type="close" />}
                disabled={clients.policyHolder?.type !== ClientType.NATURAL}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.clientsData.policyHolderIsAlsoInsured !== next.clientsData.policyHolderIsAlsoInsured
          }
        >
          {({ getFieldValue }) =>
            !getFieldValue(["clientsData", "policyHolderIsAlsoInsured"]) && (
              <Row gutter={rowGutter}>
                <ClientSearchInput<ClientFormType>
                  {...clientSearchProps}
                  formItemProps={{
                    name: ["clientsData", "insuredClientIdentifier"],
                    label: t("calc.realty.attrs.clientsData.insuredClientIndexPin"),
                    rules: [
                      validations.notBlank,
                      validations.pin,
                      validations.adultByPin,
                      clients.policyHolder
                        ? noRepeatedClient(clients.policyHolder, props.vinculation, ClientFormType.INSURED)
                        : validations.none
                    ],
                    validateFirst: true
                  }}
                  formStage={clientStages.insured}
                  formType={ClientFormType.INSURED}
                  client={clients.insured}
                />
              </Row>
            )
          }
        </Form.Item>

        {props.vinculation && (
          <>
            <Divider className="divider-subheader">{t("calc.realty.sections.vinculationClient")}</Divider>
            <Row gutter={rowGutter}>
              <ClientSearchInput<ClientFormType>
                {...clientSearchProps}
                formItemProps={{
                  name: ["clientsData", "vinculationClientIdentifier"],
                  label: t("calc.realty.attrs.clientsData.vinculationClientIndex"),
                  rules: [
                    validations.notBlank,
                    validations.crn,
                    clients.policyHolder
                      ? noRepeatedClient(clients.policyHolder, props.vinculation, ClientFormType.VINCULATION)
                      : validations.none
                  ],
                  validateFirst: true
                }}
                formStage={clientStages.vinculation}
                formType={ClientFormType.VINCULATION}
                client={clients.vinculation}
              />

              {(props.selectedInstitutionEnum === InstitutionEnum.PREMIUM ||
                props.selectedInstitutionEnum === InstitutionEnum.UNIQA) && (
                <Col span={colSpan}>
                  <Form.Item
                    name={["generalInsuranceData", "loanNumber"]}
                    label={t("calc.realty.attrs.generalInsuranceData.loanNumber")}
                    rules={[validations.notBlank, validations.size(1, 64)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </>
        )}
      </Card>

      <ClientDrawerForm<ClientFormType>
        open={clientFormOpen}
        client={getClientFromProps(processedClientFormType)}
        initialClientType={resolveClientTypeByClientFormType(processedClientFormType)}
        initialIdentifier={getClientFormIdentifier(processedClientFormType)}
        requireIdCardNumber
        formType={processedClientFormType}
        violationErrors={props.clientsViolationErrors}
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default RealtyGenClientsDataSection;
