import { Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import { Moment } from "moment/moment";
import { useEffect, useState } from "react";
import t from "../../../../../../../app/i18n";
import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { rowGutter } from "../../../../../../../common/constants";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import {
  datePickerClearableProps,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMinDate,
  resolveFormValidationError,
  selectStandardProps,
  toMoment
} from "../../../../../../../common/utils/formUtils";
import { contains, isDefined } from "../../../../../../../common/utils/utils";
import { validations } from "../../../../../../../common/utils/validationUtils";
import ClientDrawerForm from "../../../../../../client/components/drawers/ClientDrawerForm";
import ClientSearchInput from "../../../../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientFormType, ClientSearchActionType, ClientType } from "../../../../../../client/enums";
import { Client, NaturalClient } from "../../../../../../client/types";
import { useClientSearch } from "../../../../../../client/utils";
import { CalcType } from "../../../../../enums";
import {
  HolderAccidentsYearsKey,
  TimeWithoutAccident,
  VehicleOwnerRelation,
  VehiclePolicyHolderRelation
} from "../../../enums";
import { VehicleCalcForm, VehicleCalcFormClientsData } from "../../../types";
import { resolveGapCoverageLimitOptions, YEARS_TO_ACCIDENT_TIMES_MAP } from "../../../utils";

interface Props {
  form: FormInstance<VehicleCalcForm>;
  holder?: Client;
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  onHolderChange: (client?: Client) => void;
  onHolderViolationErrorsDelete: () => void;
}

const VehicleCalcClientsDataSection = ({ form, holder, clientsViolationErrors, ...props }: Props) => {
  const clientSearch = useClientSearch();

  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [holderFormStage, setHolderFormStage] = useState<ClientFormStage | undefined>(
    holder ? ClientFormStage.SELECTED : undefined
  );

  useEffect(() => {
    if (
      clientSearch.result.keyword &&
      clientSearch.result.keyword === form.getFieldValue(["clientsData", "holderIdentifier"])
    ) {
      if (clientSearch.result.data) {
        setClientFormOpen(true);
        setHolderFormStage(ClientFormStage.EXISTING);
        if (
          clientSearch.result.data.type === ClientType.NATURAL ||
          clientSearch.result.data.type === ClientType.SELF_EMPLOYED
        ) {
          props.onHolderChange({
            ...clientSearch.result.data,
            previousIdentityCardNumber: (clientSearch.result.data as NaturalClient).identityCardNumber
          } as NaturalClient);
        } else {
          props.onHolderChange(clientSearch.result.data);
        }
      } else {
        setHolderFormStage(ClientFormStage.NEW);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLeasingChange = (event: CheckboxChangeEvent): void => {
    if (event.target.checked) {
      form.setFieldsValue({
        clientsData: {
          policyHolderRelation: VehiclePolicyHolderRelation.SAME_AS_VEHICLE_HOLDER,
          ownerRelation: VehicleOwnerRelation.DIFFERENT_FROM_BOTH
        }
      });
    } else if (holder && holder.type !== ClientType.NATURAL) {
      form.setFieldsValue({
        clientsData: {
          policyHolderRelation: VehiclePolicyHolderRelation.SAME_AS_VEHICLE_HOLDER,
          ownerRelation: VehicleOwnerRelation.SAME_AS_VEHICLE_HOLDER
        }
      });
    }
  };

  const handleHolderTimeWithoutAccidentChange = (holderTimeWithoutAccident: TimeWithoutAccident): void => {
    const clientsData = form.getFieldValue(["clientsData"]) as VehicleCalcFormClientsData;
    form.setFieldsValue({
      clientsData: {
        holderAccidentsIn2Years: contains(
          YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.TWO) as TimeWithoutAccident[],
          holderTimeWithoutAccident
        )
          ? clientsData.holderAccidentsIn2Years || 1
          : 0,
        holderAccidentsIn3Years: contains(
          YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.THREE) as TimeWithoutAccident[],
          holderTimeWithoutAccident
        )
          ? clientsData.holderAccidentsIn3Years || 1
          : 0,
        holderAccidentsIn5Years: contains(
          YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.FIVE) as TimeWithoutAccident[],
          holderTimeWithoutAccident
        )
          ? clientsData.holderAccidentsIn5Years || 1
          : 0,
        holderAccidentsIn8Years: contains(
          YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.EIGHT) as TimeWithoutAccident[],
          holderTimeWithoutAccident
        )
          ? clientsData.holderAccidentsIn8Years || 1
          : 0,
        holderAccidentsIn10Years: contains(
          YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.TEN) as TimeWithoutAccident[],
          holderTimeWithoutAccident
        )
          ? clientsData.holderAccidentsIn10Years || 1
          : 0,
        holderAccidentsInAllYears: contains(
          YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.ALL) as TimeWithoutAccident[],
          holderTimeWithoutAccident
        )
          ? clientsData.holderAccidentsInAllYears || 1
          : 0
      }
    });
  };

  const handleHolderAccidentsInYearsChange = (accidents: number, years: number): void => {
    const {
      holderTimeWithoutAccident,
      holderAccidentsIn2Years,
      holderAccidentsIn3Years,
      holderAccidentsIn5Years,
      holderAccidentsIn8Years,
      holderAccidentsIn10Years,
      holderAccidentsInAllYears
    } = form.getFieldValue(["clientsData"]) as VehicleCalcFormClientsData;

    form.setFieldsValue({
      clientsData: {
        holderAccidentsIn2Years:
          years < 2 &&
          holderAccidentsIn2Years &&
          accidents > holderAccidentsIn2Years &&
          contains(
            YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.TWO) as TimeWithoutAccident[],
            holderTimeWithoutAccident
          )
            ? accidents
            : holderAccidentsIn2Years,
        holderAccidentsIn3Years:
          years < 3 &&
          holderAccidentsIn3Years &&
          accidents > holderAccidentsIn3Years &&
          contains(
            YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.THREE) as TimeWithoutAccident[],
            holderTimeWithoutAccident
          )
            ? accidents
            : holderAccidentsIn3Years,
        holderAccidentsIn5Years:
          years < 5 &&
          holderAccidentsIn5Years &&
          accidents > holderAccidentsIn5Years &&
          contains(
            YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.FIVE) as TimeWithoutAccident[],
            holderTimeWithoutAccident
          )
            ? accidents
            : holderAccidentsIn5Years,
        holderAccidentsIn8Years:
          years < 8 &&
          holderAccidentsIn8Years &&
          accidents > holderAccidentsIn8Years &&
          contains(
            YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.EIGHT) as TimeWithoutAccident[],
            holderTimeWithoutAccident
          )
            ? accidents
            : holderAccidentsIn8Years,
        holderAccidentsIn10Years:
          years < 10 &&
          holderAccidentsIn10Years &&
          accidents > holderAccidentsIn10Years &&
          contains(
            YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.TEN) as TimeWithoutAccident[],
            holderTimeWithoutAccident
          )
            ? accidents
            : holderAccidentsIn10Years,
        holderAccidentsInAllYears:
          years <= 10 &&
          holderAccidentsInAllYears &&
          accidents > holderAccidentsInAllYears &&
          contains(
            YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.ALL) as TimeWithoutAccident[],
            holderTimeWithoutAccident
          )
            ? accidents
            : holderAccidentsInAllYears
      }
    });
  };

  const handleHolderRegistrationDateChange = (holderRegistrationDate: Moment | null): void => {
    const gapCoverageLimit = form.getFieldValue(["generalData", "gapCoverageLimit"]);
    if (
      gapCoverageLimit &&
      holderRegistrationDate &&
      !resolveGapCoverageLimitOptions(holderRegistrationDate).includes(gapCoverageLimit)
    ) {
      form.setFieldsValue({ generalData: { gapCoverageLimit: undefined } });
    }
  };

  const handleClientSearchActionClick = (_: ClientFormType, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        form.setFieldsValue({ clientsData: { holderIdentifier: undefined } });
        setHolderFormStage(undefined);
        props.onHolderViolationErrorsDelete();
        props.onHolderChange(undefined);
        break;
    }
  };

  const handleClientSearchSubmit = (value: string): void => {
    form
      .validateFields([["clientsData", "holderIdentifier"]])
      .then(() => clientSearch.onSearch({ keyword: value }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string): void => {
    if (holderFormStage) {
      setHolderFormStage(undefined);
    }
    if (holder) {
      props.onHolderChange(undefined);
    }
    if (clientsViolationErrors.has(ClientFormType.HOLDER)) {
      props.onHolderViolationErrorsDelete();
    }
    handleClientSearchSubmit(value);
  };

  const handleClientFormSubmit = (client: Client): void => {
    setClientFormOpen(false);
    setHolderFormStage(ClientFormStage.SELECTED);
    props.onHolderViolationErrorsDelete();
    props.onHolderChange(client);
  };

  const getHolderFormIdentifier = (): string => {
    return form.getFieldValue(["clientsData", "holderIdentifier"]);
  };

  const colSpan = 4;
  const eightColumnColSpan = 3;

  return (
    <>
      <Card type="inner" className="card-box" title={t("calc.vehicle.sections.vehicleHolder")}>
        <Row gutter={rowGutter}>
          <ClientSearchInput<ClientFormType>
            formItemProps={{
              name: ["clientsData", "holderIdentifier"],
              label: t("calc.vehicle.attrs.clientsData.holderIdentifier"),
              rules: [validations.notBlank, validations.pinOrCrn],
              validateFirst: true
            }}
            formStage={holderFormStage}
            formType={ClientFormType.HOLDER}
            processedType={ClientFormType.HOLDER}
            client={holder}
            violationErrors={clientsViolationErrors}
            inProgress={clientSearch.inProgress}
            inputColSpan={colSpan + 1}
            clientNameColSpan={colSpan + 3}
            onActionClick={handleClientSearchActionClick}
            onSearch={handleClientSearchSubmit}
            onChange={handleClientSearchChange}
          />
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.type !== next.type ||
            prev.clientsData.holderIsDisabledPerson !== next.clientsData.holderIsDisabledPerson ||
            prev.vehicleData.firstRegistrationDate !== next.vehicleData.firstRegistrationDate ||
            prev.generalData.effectiveBeginningDate !== next.generalData.effectiveBeginningDate
          }
        >
          {({ getFieldValue }) => {
            const disabledPersonChecked = getFieldValue(["clientsData", "holderIsDisabledPerson"]);
            const firstRegistrationDate = getFieldValue(["vehicleData", "firstRegistrationDate"]) as Moment;
            const effectiveBeginningDate = getFieldValue(["generalData", "effectiveBeginningDate"]) as Moment;

            const registrationDateRules = [];
            if (firstRegistrationDate) {
              registrationDateRules.push(
                validations.notBefore(firstRegistrationDate, t("calc.vehicle.attrs.vehicleData.firstRegistrationDate"))
              );
            }
            if (effectiveBeginningDate) {
              registrationDateRules.push(
                validations.notAfter(effectiveBeginningDate, t("calc.vehicle.attrs.generalData.effectiveBeginningDate"))
              );
            }

            const holderBirthDayYear = toMoment((holder as NaturalClient)?.birthDate)?.year();

            return (
              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item
                    name={["clientsData", "holderDrivingLicenseYear"]}
                    label={t("calc.vehicle.attrs.clientsData.holderDrivingLicenseYear")}
                    rules={[
                      holderBirthDayYear ? validations.minNumber(holderBirthDayYear + 17) : validations.none,
                      validations.maxNumber(moment().year())
                    ]}
                  >
                    <InputNumber disabled={holder?.type !== ClientType.NATURAL} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["clientsData", "holderRegistrationDate"]}
                    label={t("calc.vehicle.attrs.clientsData.holderRegistrationDate")}
                    rules={registrationDateRules.length > 0 ? registrationDateRules : [validations.none]}
                  >
                    <DatePicker
                      {...datePickerClearableProps}
                      disabledDate={current =>
                        (firstRegistrationDate && disableDatePickerOutOfMinDate(current, firstRegistrationDate)) ||
                        (effectiveBeginningDate && disableDatePickerOutOfMaxDate(current, effectiveBeginningDate))
                      }
                      onChange={handleHolderRegistrationDateChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan} style={{ paddingRight: "4px" }}>
                  <Form.Item
                    name={["clientsData", "holderVehicleUsedByYoungRider"]}
                    className="two-line-form-item-without-label"
                    valuePropName="checked"
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox>
                      <LabelWithPopover
                        label={t("calc.vehicle.attrs.clientsData.holderVehicleUsedByYoungRider")}
                        popoverContent={
                          <div style={{ width: "500px" }}>
                            <p>{t("calc.vehicle.helpers.holderVehicleUsedByYoungRiderDesc.question")}</p>
                            <ul>
                              <li>{t("calc.vehicle.helpers.holderVehicleUsedByYoungRiderDesc.bullet1")}</li>
                              <li>{t("calc.vehicle.helpers.holderVehicleUsedByYoungRiderDesc.bullet2")}</li>
                            </ul>
                          </div>
                        }
                      />
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={colSpan}>
                  <Form.Item
                    name={["clientsData", "holderIsDisabledPerson"]}
                    className="form-item-without-label"
                    valuePropName="checked"
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox disabled={holder?.type !== ClientType.NATURAL}>
                      {t("calc.vehicle.attrs.clientsData.holderIsDisabledPerson")}
                    </Checkbox>
                  </Form.Item>
                </Col>
                {disabledPersonChecked && (
                  <Col span={colSpan}>
                    <Form.Item
                      name={["clientsData", "disabledPersonIdCardNumber"]}
                      label={t("calc.vehicle.attrs.clientsData.disabledPersonIdCardNumber")}
                      rules={[validations.numeric, validations.length(6)]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            );
          }}
        </Form.Item>

        <Row gutter={rowGutter}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.clientsData.leasing !== next.clientsData.leasing}>
            {({ getFieldValue }) => {
              const relationSelectsDisabled =
                (holder && holder.type !== ClientType.NATURAL) || getFieldValue(["clientsData", "leasing"]);
              return (
                <>
                  <Col span={colSpan}>
                    <Form.Item
                      name={["clientsData", "policyHolderRelation"]}
                      label={t("calc.vehicle.enums.vehiclePolicyHolderRelation._label")}
                      rules={[validations.notNull]}
                    >
                      <Select
                        {...selectStandardProps}
                        disabled={relationSelectsDisabled}
                        options={Object.keys(VehiclePolicyHolderRelation).map(relation => ({
                          value: relation,
                          label: t("calc.vehicle.enums.vehiclePolicyHolderRelation." + relation)
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name={["clientsData", "ownerRelation"]}
                      label={t("calc.vehicle.enums.vehicleOwnerRelation._label")}
                      rules={[validations.notNull]}
                    >
                      <Select
                        {...selectStandardProps}
                        disabled={relationSelectsDisabled}
                        options={Object.keys(VehicleOwnerRelation).map(relation => ({
                          value: relation,
                          label: t("calc.vehicle.enums.vehicleOwnerRelation." + relation)
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </>
              );
            }}
          </Form.Item>

          <Col span={colSpan}>
            <Form.Item
              name={["clientsData", "leasing"]}
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox onChange={handleLeasingChange}>{t("calc.vehicle.attrs.clientsData.leasing")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={["clientsData", "holderHasNeverBeenMtplInsured"]}
              className="two-line-form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("calc.vehicle.attrs.clientsData.holderHasNeverBeenMtplInsured")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
              {({ getFieldValue }) => (
                <Form.Item
                  name={["clientsData", "holderHasNeverBeenCrashInsured"]}
                  className="two-line-form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox disabled={getFieldValue(["type"]) === CalcType.MTPL}>
                    {t("calc.vehicle.attrs.clientsData.holderHasNeverBeenCrashInsured")}
                  </Checkbox>
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.clientsData.holderTimeWithoutAccident !== next.clientsData.holderTimeWithoutAccident ||
            prev.clientsData.holderAccidentsIn2Years !== next.clientsData.holderAccidentsIn2Years ||
            prev.clientsData.holderAccidentsIn3Years !== next.clientsData.holderAccidentsIn3Years ||
            prev.clientsData.holderAccidentsIn5Years !== next.clientsData.holderAccidentsIn5Years ||
            prev.clientsData.holderAccidentsIn8Years !== next.clientsData.holderAccidentsIn8Years ||
            prev.clientsData.holderAccidentsIn10Years !== next.clientsData.holderAccidentsIn10Years
          }
        >
          {({ getFieldValue }) => {
            const holderTimeWithoutAccident = getFieldValue([
              "clientsData",
              "holderTimeWithoutAccident"
            ]) as TimeWithoutAccident;
            const holderAccidentsIn2Years = getFieldValue(["clientsData", "holderAccidentsIn2Years"]);
            const holderAccidentsIn3Years = getFieldValue(["clientsData", "holderAccidentsIn3Years"]);
            const holderAccidentsIn5Years = getFieldValue(["clientsData", "holderAccidentsIn5Years"]);
            const holderAccidentsIn8Years = getFieldValue(["clientsData", "holderAccidentsIn8Years"]);
            const holderAccidentsIn10Years = getFieldValue(["clientsData", "holderAccidentsIn10Years"]);

            return (
              <>
                <Row gutter={rowGutter}>
                  <Col span={colSpan}>
                    <Form.Item
                      name={["clientsData", "holderTimeWithoutAccident"]}
                      label={<Ellipsis>{t("calc.vehicle.enums.timeWithoutAccident._label")}</Ellipsis>}
                      rules={[validations.notNull]}
                    >
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(TimeWithoutAccident).map(time => ({
                          value: time,
                          label: t("calc.vehicle.enums.timeWithoutAccident." + time)
                        }))}
                        onChange={handleHolderTimeWithoutAccidentChange}
                      />
                    </Form.Item>
                  </Col>

                  {contains(
                    YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.TWO) as TimeWithoutAccident[],
                    holderTimeWithoutAccident
                  ) && (
                    <Col span={eightColumnColSpan}>
                      <Form.Item
                        name={["clientsData", "holderAccidentsIn2Years"]}
                        label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn2Years")}
                        rules={[validations.notNull, validations.minNumber(1)]}
                      >
                        <InputNumber<number>
                          onChange={value => (value ? handleHolderAccidentsInYearsChange(value, 2) : undefined)}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {contains(
                    YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.THREE) as TimeWithoutAccident[],
                    holderTimeWithoutAccident
                  ) && (
                    <Col span={eightColumnColSpan}>
                      <Form.Item
                        name={["clientsData", "holderAccidentsIn3Years"]}
                        label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn3Years")}
                        rules={[
                          validations.notNull,
                          validations.minNumber(
                            holderAccidentsIn2Years > 0 ? holderAccidentsIn2Years : 1,
                            isDefined(holderAccidentsIn2Years) && holderAccidentsIn2Years > 0
                              ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn2Years")
                              : undefined
                          )
                        ]}
                      >
                        <InputNumber<number>
                          onChange={value => (value ? handleHolderAccidentsInYearsChange(value, 3) : undefined)}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {contains(
                    YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.FIVE) as TimeWithoutAccident[],
                    holderTimeWithoutAccident
                  ) && (
                    <Col span={eightColumnColSpan}>
                      <Form.Item
                        name={["clientsData", "holderAccidentsIn5Years"]}
                        label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn5Years")}
                        rules={[
                          validations.notNull,
                          validations.minNumber(
                            holderAccidentsIn3Years > 0 ? holderAccidentsIn3Years : 1,
                            isDefined(holderAccidentsIn3Years) && holderAccidentsIn3Years > 0
                              ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn3Years")
                              : undefined
                          )
                        ]}
                      >
                        <InputNumber<number>
                          onChange={value => (value ? handleHolderAccidentsInYearsChange(value, 5) : undefined)}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {contains(
                    YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.EIGHT) as TimeWithoutAccident[],
                    holderTimeWithoutAccident
                  ) && (
                    <Col span={eightColumnColSpan}>
                      <Form.Item
                        name={["clientsData", "holderAccidentsIn8Years"]}
                        label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn8Years")}
                        rules={[
                          validations.notNull,
                          validations.minNumber(
                            holderAccidentsIn5Years > 0 ? holderAccidentsIn5Years : 1,
                            isDefined(holderAccidentsIn5Years) && holderAccidentsIn5Years > 0
                              ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn5Years")
                              : undefined
                          )
                        ]}
                      >
                        <InputNumber<number>
                          onChange={value => (value ? handleHolderAccidentsInYearsChange(value, 8) : undefined)}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {contains(
                    YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.TEN) as TimeWithoutAccident[],
                    holderTimeWithoutAccident
                  ) && (
                    <Col span={eightColumnColSpan}>
                      <Form.Item
                        name={["clientsData", "holderAccidentsIn10Years"]}
                        label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn10Years")}
                        rules={[
                          validations.notNull,
                          validations.minNumber(
                            holderAccidentsIn8Years > 0 ? holderAccidentsIn8Years : 1,
                            isDefined(holderAccidentsIn8Years) && holderAccidentsIn8Years > 0
                              ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn8Years")
                              : undefined
                          )
                        ]}
                      >
                        <InputNumber<number>
                          onChange={value => (value ? handleHolderAccidentsInYearsChange(value, 10) : undefined)}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {contains(
                    YEARS_TO_ACCIDENT_TIMES_MAP.get(HolderAccidentsYearsKey.ALL) as TimeWithoutAccident[],
                    holderTimeWithoutAccident
                  ) && (
                    <Col span={eightColumnColSpan}>
                      <Form.Item
                        name={["clientsData", "holderAccidentsInAllYears"]}
                        label={t("calc.vehicle.attrs.clientsData.holderAccidentsInAllYears")}
                        rules={[
                          validations.notNull,
                          validations.minNumber(
                            holderAccidentsIn10Years > 0 ? holderAccidentsIn10Years : 1,
                            isDefined(holderAccidentsIn10Years) && holderAccidentsIn10Years > 0
                              ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn10Years")
                              : undefined
                          )
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                  )}
                </Row>

                {holderAccidentsIn3Years >= 3 && (
                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["clientsData", "currentlyInsuredVehicles"]}
                        label={t("calc.vehicle.attrs.clientsData.currentlyInsuredVehicles")}
                        rules={[validations.minNumber(0)]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </>
            );
          }}
        </Form.Item>
      </Card>

      <ClientDrawerForm<ClientFormType>
        open={clientFormOpen}
        client={holder}
        initialIdentifier={getHolderFormIdentifier()}
        formType={ClientFormType.HOLDER}
        violationErrors={clientsViolationErrors}
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default VehicleCalcClientsDataSection;
