import { useBackNavigation } from "../../utils/hooksUtils";
import AntIcon from "../icons/AntIcon";

interface Props {
  children?: React.ReactNode;
}

const BackNavigationArrow = (props: Props) => {
  const onBackClick = useBackNavigation();

  return (
    <div className="back-navigation">
      <div onClick={onBackClick} className="back-navigation__arrow">
        <AntIcon type="arrow-left" />
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default BackNavigationArrow;
