import { Tag, Tooltip } from "antd";
import t from "../../../../app/i18n";
import { PostponementReason } from "../enums";

interface Props {
  reason: PostponementReason;
  manualPostponementReason?: string;
  closable?: boolean;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const PostponementReasonTag = ({ reason, manualPostponementReason, closable, onClose }: Props) => {
  let color: string;

  switch (reason) {
    case PostponementReason.NOT_FOUND:
      color = "magenta";
      break;
    case PostponementReason.NOT_VERIFIED:
      color = "volcano";
      break;
    case PostponementReason.DUPLICATE_CONTRACT:
      color = "purple";
      break;
    case PostponementReason.IMPORT_ERROR:
      color = "red";
      break;
    case PostponementReason.MANUAL:
      color = "cyan";
      break;
    default:
      return null;
  }

  const tag = (
    <Tag color={color} closable={closable} onClose={onClose} style={{ marginRight: 0 }}>
      {t("commissions.batch.enums.postponementReason." + reason)}
    </Tag>
  );

  return reason === PostponementReason.MANUAL && manualPostponementReason ? (
    <Tooltip title={manualPostponementReason}>{tag}</Tooltip>
  ) : (
    tag
  );
};

export default PostponementReasonTag;
