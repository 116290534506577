import { Dropdown, Table } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../common/components/icons/AntIcon";
import FileTypeIcon from "../../../../common/components/icons/FileTypeIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { formatFileSize, formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import { tableStandardProps } from "../../../../common/utils/utils";
import { DocumentNodeTypeKey } from "../../enums";
import { DOCUMENT_NODE_ROUTE_PATHS } from "../../paths";
import { DocumentNodeTree, DocumentNodeTreeViewProps, RootDocumentNodeTree } from "../../types";
import { getCurrentSubTree } from "../../utils";

interface Props {
  hasPermission: boolean;
  currentNode: DocumentNodeTreeViewProps;
  currentNodeTree: DocumentNodeTreeViewProps[];
  currentNodeTypeIndex: DocumentNodeTypeKey;
  documentTree: RootDocumentNodeTree;
  selectedNodes: DocumentNodeTree[];
  onNodesSelect: (nodes: DocumentNodeTree[]) => void;
  onNodeOpen: (tree: DocumentNodeTreeViewProps) => void;
  onFileDownload: (node: DocumentNodeTree) => void;
  onRenameNodeClick: (node: DocumentNodeTree) => void;
  onMoveNodesClick: (nodes: DocumentNodeTree[]) => void;
  onDeleteNodesClick: (nodes: DocumentNodeTree[]) => void;
}

const ACTION = {
  DOWNLOAD: "download",
  RENAME: "rename",
  MOVE: "move",
  DELETE: "delete"
};

const DocumentNodeListView = ({
  hasPermission,
  currentNode,
  currentNodeTree,
  currentNodeTypeIndex,
  documentTree,
  selectedNodes,
  onNodesSelect,
  onNodeOpen,
  onFileDownload,
  onRenameNodeClick,
  onMoveNodesClick,
  onDeleteNodesClick
}: Props) => {
  const [dataSource, setDataSource] = useState<DocumentNodeTree[]>();

  useEffect(() => {
    if (currentNode.parent) {
      const backRow = {
        id: "",
        optimisticLockVersion: 0,
        createdAt: "",
        updatedAt: "",
        name: "...",
        treePath: "",
        type: undefined,
        file: undefined,
        children: []
      };

      setDataSource([backRow, ...currentNode.nodes] as DocumentNodeTree[]);
    } else {
      setDataSource(currentNode.nodes);
    }
  }, [currentNode]);

  const handleOpenClick = (record: DocumentNodeTree): void => {
    onNodesSelect([]);

    if (!record.id) {
      const parentTreePath = currentNodeTree[currentNodeTypeIndex]?.parent?.treePath.split("|");
      parentTreePath?.pop();

      const parentPath = parentTreePath?.join("|");
      const currentSubTree = getCurrentSubTree(documentTree, currentNodeTypeIndex, parentPath);

      onNodeOpen(currentSubTree);
    } else if (!record.file) {
      const currentSubTree = getCurrentSubTree(documentTree, currentNodeTypeIndex, record.treePath);
      onNodeOpen(currentSubTree);
    }
  };

  const handleActionClick = (actionKey: string, record: DocumentNodeTree): void => {
    switch (actionKey) {
      case ACTION.DOWNLOAD:
        onFileDownload(record);
        break;
      case ACTION.RENAME:
        onRenameNodeClick(record);
        break;
      case ACTION.MOVE:
        onMoveNodesClick([record]);
        break;
      case ACTION.DELETE:
        onDeleteNodesClick([record]);
        break;
    }
  };

  const renderRecordName = (record: DocumentNodeTree): React.ReactNode => {
    const content = (
      <>
        {record.id === "" ? (
          <AntIcon type="left-square" />
        ) : record.file ? (
          <FileTypeIcon contentType={record.file.contentType} />
        ) : (
          <AntIcon type="open-folder" className="file-type-icon" />
        )}
        <span className="margin-left-tiny">{record.name}</span>
      </>
    );

    return record.file ? (
      <Link
        to={generatePath(DOCUMENT_NODE_ROUTE_PATHS.attachment.to, { id: record.id })}
        target="_blank"
        className="no-link-color"
      >
        {content}
      </Link>
    ) : (
      <span className={classNames("no-link-color", "documents__item-link")} onClick={() => handleOpenClick(record)}>
        {content}
      </span>
    );
  };

  const columns: ColumnsType<DocumentNodeTree> = [
    {
      key: "name",
      title: t("common.filename"),
      ellipsis: { showTitle: false },
      width: 435,
      render: (_, record) => <Ellipsis tooltip={record.name}>{renderRecordName(record)}</Ellipsis>
    },
    {
      key: "size",
      title: t("common.filesize"),
      width: 150,
      render: (_, record) => (record.file ? formatFileSize(record.file.size) : null)
    },
    {
      key: "updatedAt",
      title: t("common.updatedAt"),
      width: 150,
      render: (_, record) => formatLocaleDateTime(record.updatedAt)
    },
    {
      key: "actions",
      width: 100,
      align: "right",
      render: (_, record) => {
        if (record.id !== "") {
          const items: ItemType[] = [];

          if (record.file) {
            items.push({
              key: ACTION.DOWNLOAD,
              label: <ActionTextIcon icon="download" color="green" text={t("documentNode.menu.download")} />
            });
          }

          if (hasPermission) {
            items.push(
              ...[
                {
                  key: ACTION.RENAME,
                  label: <ActionTextIcon icon="edit" color="blue" text={t("documentNode.menu.rename")} />
                },
                {
                  key: ACTION.MOVE,
                  label: <ActionTextIcon icon="retweet" color="orange" text={t("documentNode.menu.move")} />
                },
                {
                  key: ACTION.DELETE,
                  label: <ActionTextIcon icon="delete" color="red" text={t("documentNode.menu.delete")} />
                }
              ]
            );
          }

          return (
            items.length > 0 && (
              <Dropdown
                menu={{ items, onClick: event => handleActionClick(event.key, record) }}
                trigger={["click"]}
                onOpenChange={() => onNodesSelect([])}
              >
                <ActionTextIcon icon="menu-unfold" color="blue" text={t("common.options")} />
              </Dropdown>
            )
          );
        }

        return null;
      }
    }
  ];

  return (
    <Table<DocumentNodeTree>
      {...tableStandardProps()}
      dataSource={dataSource}
      columns={columns}
      scroll={{ x: TableSizes.MEDIUM, y: TableSizes.SMALL }}
      rowSelection={
        hasPermission
          ? {
              onChange: (_, selectedRows) => {
                onNodesSelect(selectedRows);
              },
              selectedRowKeys: selectedNodes.map(row => row.id),
              getCheckboxProps: record => ({
                disabled: record.id === ""
              })
            }
          : undefined
      }
      expandable={{
        expandRowByClick: false,
        expandIcon: () => undefined
      }}
      pagination={false}
    />
  );
};

export default DocumentNodeListView;
