import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { RootState } from "../../../common/types";
import api from "./api";
import { VehiclePriceReducerState, VehiclePriceRequest } from "./types";

/**
 * ACTIONS
 */
export const getVehiclePriceActions = createAsyncAction(
  "vehicle-price/GET_REQUEST",
  "vehicle-price/GET_SUCCESS",
  "vehicle-price/GET_FAILURE"
)<VehiclePriceRequest, number, void>();

export const deleteStateVehiclePriceResultAction = createAction("vehicle-price/DELETE_STATE_RESULT")<void>();

const actions = {
  getVehiclePriceActions,
  deleteStateVehiclePriceResultAction
};

export type VehiclePriceAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: VehiclePriceReducerState = {
  result: null
};

const resultReducer = createReducer(initialState.result)
  .handleAction(getVehiclePriceActions.success, (_, { payload }) => payload)
  .handleAction([getVehiclePriceActions.failure, deleteStateVehiclePriceResultAction], () => initialState.result);

export const vehiclePriceReducer = combineReducers<VehiclePriceReducerState>({
  result: resultReducer
});

/**
 * SELECTORS
 */
const selectVehiclePrice = (state: RootState): VehiclePriceReducerState => state.vehicle.price;

export const selectVehiclePriceResult = (state: RootState): number | null => selectVehiclePrice(state).result;

/**
 * SAGAS
 */
function* getVehiclePrice({ payload }: ReturnType<typeof getVehiclePriceActions.request>) {
  try {
    const response: AxiosResponse<number> = yield call(api.getVehiclePrice, payload);
    yield put(getVehiclePriceActions.success(response.data));
  } catch (error) {
    yield put(getVehiclePriceActions.failure());
  }
}

export function* vehiclePriceSaga() {
  yield takeLatest(getVehiclePriceActions.request, getVehiclePrice);
}
