import { Col, Divider, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../app/i18n";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { PageResult, RootState } from "../../../../../common/types";
import {
  formatAgentName,
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocaleNettoPoints
} from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../auth/ducks";
import CommissionKindTag from "../../../../commissions/batch/components/CommissionKindTag";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../../commissions/batch/paths";
import { removeBatchNameCustomSuffix } from "../../../../commissions/batch/utils";
import { ContractCommission } from "../../../types";

interface Props {
  commissionsPage: PageResult<ContractCommission>;
  onFilter?: () => void;
  onPageChange: (pageNumber: number) => void;
}

const ContractCommissionTableView = ({ commissionsPage, onFilter, onPageChange }: Props) => {
  const hasCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS)(state)
  );

  useEffect(() => onFilter?.(), []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: ColumnsType<ContractCommission> = [
    {
      key: "batch",
      title: t("commissions.batch.attrs.commission.batch"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        hasCommissionsPermission ? (
          <Ellipsis tooltip={record.batch.name}>
            <Link to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.batch.id })} target="_blank">
              {record.batch.name}
            </Link>
          </Ellipsis>
        ) : (
          removeBatchNameCustomSuffix(record.batch)
        )
    },
    {
      key: "tariffCode",
      title: t("commissions.batch.attrs.commission.tariffCode"),
      width: 90,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.tariffCode}</Ellipsis>
    },
    {
      key: "dates",
      title: t("commissions.batch.attrs.commission.dates"),
      width: 105,
      render: (_, record) => (
        <>
          {formatLocaleDate(record.startDate)}
          <br />
          {formatLocaleDate(record.endDate)}
        </>
      )
    },
    {
      key: "commissionKind",
      title: t("commissions.batch.enums.commissionKind._label"),
      width: 105,
      render: (_, record) => <CommissionKindTag kind={record.commissionKind} />
    },
    {
      key: "commissionBaseAmount",
      title: t("commissions.batch.attrs.commission.commissionBaseAmount"),
      align: "right",
      width: 130,
      render: (_, record) => formatLocaleCurrency(record.commissionBaseAmount)
    }
  ];

  if (hasCommissionsPermission) {
    columns.push({
      key: "commissionAmount",
      title: t("commissions.batch.attrs.commission.commissionAmount"),
      align: "right",
      width: 120,
      render: (_, record) => formatLocaleCurrency(record.commissionAmount)
    });
  }

  columns.push({
    key: "calculations",
    title: t("commissions.batch.helpers.commissionsDivision"),
    align: "right",
    fixed: "right",
    width: hasCommissionsPermission ? 450 : 570,
    render: (_, record) =>
      record.calculatedCommissions.map((cc, index) => (
        <React.Fragment key={cc.id}>
          <Row>
            <Col span={12} className="left-align">
              <Ellipsis>{formatAgentName(cc.gainer)}</Ellipsis>
            </Col>
            <Col span={6} className="right-align">
              {formatLocaleCurrency(cc.commissionAmount)}
            </Col>
            <Col span={6} className="right-align">
              {formatLocaleNettoPoints(cc.nettoPointAmount)}
            </Col>
          </Row>
          {cc.individualNettoPoints && index < record.calculatedCommissions.length - 1 && (
            <Divider style={{ fontSize: "1em", marginTop: "5px", marginBottom: "5px" }} />
          )}
        </React.Fragment>
      ))
  });

  return (
    <Table<ContractCommission>
      {...tableStandardProps()}
      columns={columns}
      dataSource={commissionsPage.pageData}
      scroll={{ x: TableSizes.LARGE }}
      pagination={{
        ...paginationTableProps,
        current: commissionsPage.pageIndex + 1,
        pageSize: commissionsPage.pageSize,
        total: commissionsPage.totalElementsCount,
        onChange: onPageChange
      }}
    />
  );
};

export default ContractCommissionTableView;
