import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { RootState } from "../../common/types";
import { openBlobFile } from "../../common/utils/utils";
import api from "./api";
import {
  ContractTerminationReducerState,
  ContractTerminationSearchRequest,
  ContractTerminationSearchResult,
  GenerateContractTermination
} from "./types";

/**
 * ACTIONS
 */
export const searchContractForTerminationActions = createAsyncAction(
  "contract-termination/SEARCH_REQUEST",
  "contract-termination/SEARCH_SUCCESS",
  "contract-termination/SEARCH_FAILURE"
)<ContractTerminationSearchRequest, ContractTerminationSearchResult, void>();

export const generateContractTerminationActions = createAsyncAction(
  "contract-termination/GENERATE_REQUEST",
  "contract-termination/GENERATE_SUCCESS",
  "contract-termination/GENERATE_FAILURE"
)<GenerateContractTermination, void, void>();

export const deleteStateContractTerminationSearchResultAction = createAction(
  "contract-termination/DELETE_STATE_SEARCH_RESULT"
)<void>();

const actions = {
  searchContractForTerminationActions,
  generateContractTerminationActions,
  deleteStateContractTerminationSearchResultAction
};

export type ContractTerminationAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: ContractTerminationReducerState = {
  searchResult: null
};

const searchResultReducer = createReducer(initialState.searchResult)
  .handleAction(searchContractForTerminationActions.success, (_, { payload }) => payload)
  .handleAction(
    [searchContractForTerminationActions.failure, deleteStateContractTerminationSearchResultAction],
    () => initialState.searchResult
  );

export const contractTerminationReducer = combineReducers<ContractTerminationReducerState>({
  searchResult: searchResultReducer
});

/**
 * SELECTORS
 */
export const selectContractTermination = (state: RootState): ContractTerminationReducerState =>
  state.contractTermination;
export const selectContractTerminationSearchResult = (state: RootState): ContractTerminationSearchResult | undefined =>
  selectContractTermination(state).searchResult ?? undefined;

/**
 * SAGAS
 */
function* searchContractForTermination({ payload }: ReturnType<typeof searchContractForTerminationActions.request>) {
  try {
    const response: AxiosResponse<ContractTerminationSearchResult> = yield call(
      api.searchContractForTermination,
      payload
    );
    yield put(searchContractForTerminationActions.success(response.data));
  } catch (error) {
    yield put(searchContractForTerminationActions.failure());
  }
}

function* generateContractTermination({ payload }: ReturnType<typeof generateContractTerminationActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.generateContractTermination, payload);
    openBlobFile(response, true);
    yield put(generateContractTerminationActions.success());
  } catch (error) {
    yield put(generateContractTerminationActions.failure());
  }
}

export function* contractTerminationSaga() {
  yield takeLatest(searchContractForTerminationActions.request, searchContractForTermination);
  yield takeLatest(generateContractTerminationActions.request, generateContractTermination);
}
