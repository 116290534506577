import { blue, orange, red } from "@ant-design/colors";
import { ExclamationCircleTwoTone, InfoCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { DashboardNoticeType } from "../../../../enums";

interface Props {
  type: DashboardNoticeType;
}

export const DashboardNoticeIcon = ({ type }: Props) => {
  let icon: React.ReactNode;

  switch (type) {
    case DashboardNoticeType.INFO:
      icon = <InfoCircleTwoTone twoToneColor={blue[4]} />;
      break;
    case DashboardNoticeType.WARNING:
      icon = <WarningTwoTone twoToneColor={orange[4]} />;
      break;
    case DashboardNoticeType.ERROR:
      icon = <ExclamationCircleTwoTone twoToneColor={red[4]} />;
      break;
    default:
      return null;
  }

  return <div className="dashboard-notice-icon-box">{icon}</div>;
};
