import { Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectHasPermissions } from "../../auth/ducks";
import ContractTerminationForm from "../../contracttermination/components/forms/ContractTerminationForm";
import {
  generateContractTerminationActions,
  searchContractForTerminationActions
} from "../../contracttermination/ducks";
import { selectRouterLocationSearch } from "../../ducks";
import FinancialMediationFilterView from "../../financialmediation/components/views/FinancialMediationFilterView";
import FinancialMediationTableView from "../../financialmediation/components/views/FinancialMediationTableView";
import {
  deleteStateFinancialMediationsPageAction,
  filterFinancialMediationsActions,
  selectFinancialMediationsCurrentPage
} from "../../financialmediation/ducks";
import {
  FinancialMediationFilterPageRequest,
  FinancialMediationFilterPageResult
} from "../../financialmediation/types";
import { ProductFinancialSector } from "../../product/enums";

interface StateProps {
  financialMediationsPage: FinancialMediationFilterPageResult;
  hasInsuranceReadPermission: boolean;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterFinancialMediations: typeof filterFinancialMediationsActions.request;
  deleteStateFinancialMediationsPage: typeof deleteStateFinancialMediationsPageAction;
  searchContractForTermination: typeof searchContractForTerminationActions.request;
  generateContractTermination: typeof generateContractTerminationActions.request;
}

export const TAB = {
  MEDIATION: "mediation",
  TERMINATION: "termination"
};

export const CONTRACT_NUMBER_QUERY_PARAM = "contractNumber";

const ContractFormsContainer = ({
  financialMediationsPage,
  hasInsuranceReadPermission,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.MEDIATION);

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    const paramTab = urlParams.get("tab");

    const urlTabKey = paramTab ? (Object.values(TAB).includes(paramTab) ? paramTab : TAB.MEDIATION) : undefined;

    switch (urlTabKey) {
      case TAB.MEDIATION:
        actions.filterFinancialMediations({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword") ?? undefined,
          includeAssigned: urlParams.get("includeAssigned") === "true",
          sectors: urlParams.getAll("sectors") as ProductFinancialSector[]
        });
        setTabKey(urlTabKey);
        break;
      case TAB.TERMINATION:
        setTabKey(hasInsuranceReadPermission ? urlTabKey : TAB.MEDIATION);
        break;
      default:
        setTabKey(TAB.MEDIATION);
        break;
    }

    return () => {
      actions.deleteStateFinancialMediationsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (key: string): void => {
    navigate(
      appendSearchParamsToURL({
        tab: key,
        pageIndex: undefined,
        keyword: undefined,
        includeAssigned: undefined,
        sectors: undefined
      }),
      { replace: true }
    );

    setTabKey(key);

    if (key === TAB.MEDIATION) {
      actions.filterFinancialMediations({
        pageIndex: 0,
        pageSize: PageSizes.LARGE
      });
    }
  };

  const handleFinancialMediationFilterSubmit = (filter: FinancialMediationFilterPageRequest): void => {
    const tabParam = new URLSearchParams(urlSearchQuery).get("tab");
    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: undefined,
        keyword: filter.keyword || undefined,
        includeAssigned: filter.includeAssigned || undefined,
        tab: tabParam || undefined
      }),
      { replace: true }
    );

    actions.filterFinancialMediations({
      ...filter,
      pageIndex: 0,
      pageSize: financialMediationsPage.pageSize
    });
  };

  const handleFinancialMediationsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword, includeAssigned, sectors } = financialMediationsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterFinancialMediations({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      includeAssigned,
      sectors
    });
  };

  const getItems = (): TabsProps["items"] => {
    const items: TabsProps["items"] = [];

    items.push({
      key: TAB.MEDIATION,
      label: (
        <span>
          <AntIcon type="profile" />
          {t("financialMediation.titles.listTab")}
        </span>
      ),
      children: (
        <div className="margin-top-small">
          <FinancialMediationFilterView
            currentFilter={financialMediationsPage}
            onFilterSubmit={handleFinancialMediationFilterSubmit}
          />

          <FinancialMediationTableView
            mediationsPage={financialMediationsPage}
            onPageChange={handleFinancialMediationsPageChange}
          />
        </div>
      )
    });

    if (hasInsuranceReadPermission) {
      items.push({
        key: TAB.TERMINATION,
        label: (
          <span>
            <AntIcon type="stop" />
            {t("contractTermination.titles.page")}
          </span>
        ),
        children: (
          <div className="margin-top-small">
            <ContractTerminationForm
              initialContractNumber={new URLSearchParams(urlSearchQuery).get(CONTRACT_NUMBER_QUERY_PARAM) ?? ""}
              onGenerateTermination={actions.generateContractTermination}
            />
          </div>
        )
      });
    }

    return items;
  };

  return (
    <ContentWrapper>
      <Tabs className="tabs-box" activeKey={tabKey} onChange={handleTabKeyChange} items={getItems()} />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  financialMediationsPage: selectFinancialMediationsCurrentPage(state),
  hasInsuranceReadPermission: selectHasPermissions(Permission.INSURANCE_READ)(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterFinancialMediations: filterFinancialMediationsActions.request,
      deleteStateFinancialMediationsPage: deleteStateFinancialMediationsPageAction,
      searchContractForTermination: searchContractForTerminationActions.request,
      generateContractTermination: generateContractTerminationActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ContractFormsContainer);
