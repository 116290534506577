import { Tag } from "antd";
import t from "../../../../app/i18n";
import { CalcType } from "../../enums";

interface Props {
  type: CalcType;
  style?: React.CSSProperties;
}

const CalcTypeTag = ({ type, style }: Props) => {
  let color: string;

  switch (type) {
    case CalcType.MTPL:
      color = "green";
      break;
    case CalcType.CRASH:
      color = "geekblue";
      break;
    case CalcType.MTPL_CRASH:
      color = "cyan";
      break;
    case CalcType.GAP:
      color = "purple";
      break;
    case CalcType.PAS:
      color = "volcano";
      break;
    case CalcType.REALTY:
      color = "red";
      break;
    case CalcType.TRAVEL:
      color = "orange";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} style={style}>
      {t("calc.enums.calcType." + type)}
    </Tag>
  );
};

export default CalcTypeTag;
