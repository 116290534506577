import { Card, Col, Divider, Popconfirm, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import InfoIcon from "../../../../../common/components/icons/InfoIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { rowGutter, TableSizes } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import {
  formatLocaleCurrency,
  formatLocaleCurrencyWithNullAsZero,
  formatLocaleDate
} from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasAnyPermissions } from "../../../../auth/ducks";
import { CONTRACT_ROUTE_PATHS } from "../../../../contract/paths";
import CommissionKindTag from "../../../batch/components/CommissionKindTag";
import PostponementReasonTag from "../../../batch/components/PostponementReasonTag";
import { CommissionType } from "../../../batch/enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../batch/paths";
import { Commission } from "../../../batch/types";
import { resolvePostponedCommissionActions, unresolvePostponedCommissionActions } from "../../ducks";
import { PostponedCommissionsReport } from "../../enums";
import { PostponedCommissionsFilterPageResult } from "../../types";

interface Props {
  page: PostponedCommissionsFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (commission: Commission) => void;
  onResolve: typeof resolvePostponedCommissionActions.request;
  onUnresolve: typeof unresolvePostponedCommissionActions.request;
}

const PostponedCommissionsTableView = (props: Props) => {
  const hasReadContractPermission = useSelector<RootState, boolean>(state =>
    selectHasAnyPermissions(
      Permission.INSURANCE_READ,
      Permission.LOAN_READ,
      Permission.INVESTMENT_READ,
      Permission.DEPOSIT_READ,
      Permission.SECOND_PILLAR_READ,
      Permission.THIRD_PILLAR_READ,
      Permission.GENERIC_READ
    )(state)
  );

  const columns: ColumnsType<Commission> = [
    {
      key: "duplicatedFromBatch",
      width: 35,
      render: (_, record) =>
        record.type === CommissionType.DUPLICATED_COMMISSION ? (
          <InfoIcon
            tooltip={
              <>
                <span>{t("commissions.postponed.helpers.createdInBatch")}: </span>
                <Link
                  to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.duplicateOf?.batch.id })}
                  target="_blank"
                >
                  {record.duplicateOf?.batch.name}
                </Link>
                <br />
                <span>
                  {props.page.report === PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH
                    ? t("commissions.postponed.helpers.includedInBatch")
                    : t("commissions.postponed.helpers.resolvedInBatch")}
                  :{" "}
                </span>
                <Link
                  to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.batch.id })}
                  target="_blank"
                >
                  {record.batch.name}
                </Link>
              </>
            }
          />
        ) : (
          <InfoIcon
            tooltip={
              <>
                <span>{t("commissions.postponed.helpers.createdInBatch")}: </span>
                <Link
                  to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.batch.id })}
                  target="_blank"
                >
                  {record.batch.name}
                </Link>
              </>
            }
          />
        )
    },
    {
      key: "institution",
      title: t("commissions.batch.attrs.commission.institution"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "contract",
      title: t("commissions.batch.attrs.commission.contract"),
      width: 110,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.contract && hasReadContractPermission ? (
          <>
            {record.contractNumber && (
              <>
                <Ellipsis tooltip={record.contractNumber}>
                  <Link to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.contract.id })} target="_blank">
                    {record.contractNumber}
                  </Link>
                </Ellipsis>
                <br />
              </>
            )}
            {record.secondaryContractNumber && (
              <Ellipsis tooltip={record.secondaryContractNumber}>
                <Link to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.contract.id })} target="_blank">
                  {record.secondaryContractNumber}
                </Link>
              </Ellipsis>
            )}
          </>
        ) : (
          <>
            {record.contractNumber && (
              <>
                <Ellipsis>{record.contractNumber}</Ellipsis>
                <br />
              </>
            )}
            {record.secondaryContractNumber && <Ellipsis>{record.secondaryContractNumber}</Ellipsis>}
          </>
        )
    },
    {
      key: "product",
      title: t("commissions.batch.attrs.commission.product"),
      width: 125,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.contract?.product.name}</Ellipsis>
    },
    {
      key: "tariffCode",
      title: t("commissions.batch.attrs.commission.tariffCode"),
      width: 90,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.tariffCode}</Ellipsis>
    },
    {
      key: "clientName",
      title: t("commissions.batch.attrs.commission.clientName"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.clientName}</Ellipsis>
    },
    {
      key: "dates",
      title: t("commissions.batch.attrs.commission.dates"),
      width: 105,
      render: (_, record) => (
        <>
          {formatLocaleDate(record.startDate)}
          <br />
          {formatLocaleDate(record.endDate)}
        </>
      )
    },
    {
      key: "commissionKind",
      title: t("commissions.batch.enums.commissionKind._label"),
      width: 105,
      render: (_, record) => <CommissionKindTag kind={record.commissionKind} />
    },
    {
      key: "commissionBaseAmount",
      title: t("commissions.batch.attrs.commission.commissionBaseAmount"),
      align: "right",
      width: 127,
      render: (_, record) => formatLocaleCurrency(record.commissionBaseAmount)
    },
    {
      key: "commissionAmount",
      title: t("commissions.batch.attrs.commission.commissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrency(record.commissionAmount)
    },
    {
      key: "postponementReason",
      title: t("commissions.postponed.helpers.originalPostponementReason"),
      align: "center",
      width: 165,
      render: (_, record) =>
        record.type === CommissionType.DUPLICATED_COMMISSION && record.duplicateOf ? (
          <PostponementReasonTag
            reason={record.duplicateOf.postponementReason}
            manualPostponementReason={record.duplicateOf.manualPostponementReason}
          />
        ) : (
          <PostponementReasonTag
            reason={record.postponementReason}
            manualPostponementReason={record.manualPostponementReason}
          />
        )
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: props.page.report === PostponedCommissionsReport.UNRESOLVED ? 280 : 210,
      render: (_, record) =>
        props.page.report === PostponedCommissionsReport.UNRESOLVED ? (
          <>
            <ActionTextIcon
              icon="edit"
              color="blue"
              text={t("common.edit")}
              onClick={() => props.onUpdateClick(record)}
            />

            <Divider type="vertical" />

            <Popconfirm
              title={t("commissions.postponed.helpers.resolveConfirm")}
              icon={<AntIcon type="question" />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="primary"
              onConfirm={() =>
                props.onResolve({ id: record.id, object: { optimisticLockVersion: record.optimisticLockVersion } })
              }
            >
              <ActionTextIcon icon="check-circle" color="orange" text={t("commissions.postponed.actions.resolve")} />
            </Popconfirm>
          </>
        ) : (
          <Popconfirm
            title={t("commissions.postponed.helpers.unresolveConfirm")}
            icon={<AntIcon type="question" />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="primary"
            onConfirm={() =>
              props.onUnresolve({ id: record.id, object: { optimisticLockVersion: record.optimisticLockVersion } })
            }
            disabled={!record.manuallyResolvedAfterPostponement}
          >
            <ActionTextIcon
              icon="reload"
              color="blue"
              text={t("commissions.postponed.actions.unresolve")}
              disabled={!record.manuallyResolvedAfterPostponement}
            />
          </Popconfirm>
        )
    }
  ];

  return (
    <Card className="card-box">
      <div className={props.page.totalElementsCount > 0 ? "table-header-margin" : "margin-bottom-small"}>
        <b>{t("commissions.batch.helpers.filteredCommissionsSum")}: </b>
        {props.page.commissionAmountsSum
          ? formatLocaleCurrencyWithNullAsZero(props.page.commissionAmountsSum)
          : undefined}
      </div>
      <Table<Commission>
        {...tableStandardProps()}
        columns={columns}
        dataSource={props.page.pageData}
        scroll={{ x: TableSizes.HUGE }}
        expandable={{
          rowExpandable: record =>
            !!record.errors.length && props.page.report !== PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH,
          expandedRowRender: record => (
            <Row gutter={rowGutter} className="margin-top-small margin-bottom-small">
              <Col flex="70px">
                <b>{t("common.errors")}:</b>
              </Col>
              <Col flex="1 1 0">{record.errors.map(error => error.description).join(", ")}</Col>
            </Row>
          )
        }}
        pagination={{
          ...paginationTableProps,
          current: props.page.pageIndex + 1,
          pageSize: props.page.pageSize,
          total: props.page.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default PostponedCommissionsTableView;
