import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import t from "../../../../../../../app/i18n";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import { rowGutter } from "../../../../../../../common/constants";
import { inputNumberIntegerStandardProps, selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../../common/utils/validationUtils";
import { ConstructionMaterial } from "../../../enums";
import { RealtyCalc } from "../../../types";
import FloorPopoverLabel from "../../FloorPopoverLabel";

interface Props {
  form: FormInstance<RealtyCalc>;
}

const RealtyCalcApartmentSection = ({ form }: Props) => {
  const handleFloorChange = (floor: string | number | null): void => {
    if (floor === 1) {
      const apartmentData = form.getFieldValue("apartmentData");
      form.setFieldsValue({ apartmentData: { ...apartmentData, topFloor: false } });
    }
  };

  const colSpan = 4;

  return (
    <Card type="inner" className="card-box" title={t("calc.realty.sections.insuredObjectAndAreas")}>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "constructionMaterial"]}
            label={t("calc.realty.enums.constructionMaterial._label")}
            rules={[validations.none]}
          >
            <Select
              {...selectStandardProps}
              allowClear
              options={Object.keys(ConstructionMaterial).map(material => ({
                value: material,
                label: t("calc.realty.enums.constructionMaterial." + material)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "apartmentNumber"]}
            label={t("calc.realty.attrs.apartmentData.apartmentNumber")}
            rules={[validations.notBlank, validations.size(1, 64)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "floor"]}
            label={
              <FloorPopoverLabel
                label={t("calc.realty.attrs.apartmentData.floor")}
                popoverTitle={t("calc.realty.helpers.apartmentFloor")}
              />
            }
            rules={[validations.notNull, validations.minNumber(1)]}
          >
            <InputNumber onChange={handleFloorChange} />
          </Form.Item>
        </Col>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.apartmentData?.floor !== next.apartmentData?.floor}>
          {({ getFieldValue }) => (
            <Col span={colSpan}>
              <Form.Item
                name={["apartmentData", "topFloor"]}
                className="two-line-form-item-without-label"
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox disabled={getFieldValue(["apartmentData", "floor"]) === 1}>
                  {t("calc.realty.attrs.apartmentData.topFloor")}
                </Checkbox>
              </Form.Item>
            </Col>
          )}
        </Form.Item>
      </Row>

      <Divider className="divider-subheader">{t("calc.realty.sections.areas")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "floorArea"]}
            label={t("calc.realty.attrs.apartmentData.floorArea")}
            rules={[validations.notNull, validations.minNumber(1)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "balconyArea"]}
            label={t("calc.realty.attrs.apartmentData.balconyArea")}
            rules={[validations.none, validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "terraceArea"]}
            label={t("calc.realty.attrs.apartmentData.terraceArea")}
            rules={[validations.none, validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "cellarArea"]}
            label={t("calc.realty.attrs.apartmentData.cellarArea")}
            rules={[validations.none, validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["apartmentData", "garageArea"]}
            label={t("calc.realty.attrs.apartmentData.garageArea")}
            rules={[validations.none, validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default RealtyCalcApartmentSection;
