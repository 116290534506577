import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityObject, RootState } from "../../../common/types";
import { initPageResult } from "../../../common/utils/apiUtils";
import api from "./api";
import {
  DebtAccountBalance,
  DebtAccountMovement,
  DebtAccountMovementFilterPageRequest,
  DebtAccountMovementFilterPageResult,
  DebtAccountReducerState,
  DebtAccountsReportFilterPageRequest,
  DebtAccountsReportFilterPageResult
} from "./types";

/**
 * ACTIONS
 */
export const getDebtAccountsReportActions = createAsyncAction(
  "debt-accounts-report/GET_REQUEST",
  "debt-accounts-report/GET_SUCCESS",
  "debt-accounts-report/GET_FAILURE"
)<DebtAccountsReportFilterPageRequest, DebtAccountsReportFilterPageResult, void>();

export const deleteStateDebtAccountsReportAction = createAction("debt-accounts-report/DELETE_STATE_REPORT")<void>();

export const filterDebtAccountMovementsActions = createAsyncAction(
  "debt-account-movement/FILTER_REQUEST",
  "debt-account-movement/FILTER_SUCCESS",
  "debt-account-movement/FILTER_FAILURE"
)<EntityObject<DebtAccountMovementFilterPageRequest>, DebtAccountMovementFilterPageResult, void>();

export const deleteStateDebtAccountMovementsPageAction = createAction(
  "debt-account-movement/DELETE_STATE_LIST"
)<void>();

const actions = {
  getDebtAccountsReportActions,
  deleteStateDebtAccountsReportAction,
  filterDebtAccountMovementsActions,
  deleteStateDebtAccountMovementsPageAction
};

export type DebtAccountAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: DebtAccountReducerState = {
  report: {
    ...initPageResult<DebtAccountBalance>(),
    balanceAsOfDate: undefined,
    commissionsSettingsLevelIds: [],
    agentCreatedAtDateMin: undefined,
    includeZeroBalance: false,
    rootAgentId: undefined,
    onlyDirectSubordinates: false,
    includeDeactivated: false,
    includeRepresentatives: false,
    includeNonGainers: false
  },
  movementsPage: {
    ...initPageResult<DebtAccountMovement>(),
    onlyProcessed: true,
    amountsSum: undefined
  }
};

const debtAccountsReportReducer = createReducer(initialState.report)
  .handleAction(getDebtAccountsReportActions.success, (_, { payload }) => payload)
  .handleAction([getDebtAccountsReportActions.failure, deleteStateDebtAccountsReportAction], () => initialState.report);

const movementsPageReducer = createReducer(initialState.movementsPage)
  .handleAction(filterDebtAccountMovementsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterDebtAccountMovementsActions.failure, deleteStateDebtAccountMovementsPageAction],
    () => initialState.movementsPage
  );

export const debtAccountReducer = combineReducers<DebtAccountReducerState>({
  report: debtAccountsReportReducer,
  movementsPage: movementsPageReducer
});

/**
 * SELECTORS
 */
const selectDebtAccount = (state: RootState): DebtAccountReducerState => state.commissions.debtAccount;

export const selectDebtAccountsReport = (state: RootState): DebtAccountsReportFilterPageResult =>
  selectDebtAccount(state).report;
export const selectDebtAccountMovementsPage = (state: RootState): DebtAccountMovementFilterPageResult =>
  selectDebtAccount(state).movementsPage;

/**
 * SAGAS
 */
function* getDebtAccountsReport({ payload }: ReturnType<typeof getDebtAccountsReportActions.request>) {
  try {
    const response: AxiosResponse<DebtAccountsReportFilterPageResult> = yield call(api.getDebtAccountsReport, payload);
    yield put(getDebtAccountsReportActions.success(response.data));
  } catch (error) {
    yield put(getDebtAccountsReportActions.failure());
  }
}

function* filterDebtAccountMovements({ payload }: ReturnType<typeof filterDebtAccountMovementsActions.request>) {
  try {
    const response: AxiosResponse<DebtAccountMovementFilterPageResult> = yield call(
      api.filterDebtAccountMovements,
      payload
    );
    yield put(filterDebtAccountMovementsActions.success(response.data));
  } catch (error) {
    yield put(filterDebtAccountMovementsActions.failure());
  }
}

export function* debtAccountSaga() {
  yield takeLatest(getDebtAccountsReportActions.request, getDebtAccountsReport);
  yield takeLatest(filterDebtAccountMovementsActions.request, filterDebtAccountMovements);
}
