import { Alert, Popover } from "antd";
import t from "../../../../../../app/i18n";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import { HttpStatus } from "../../../../../../common/constants";
import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { formatLocaleCurrency, formatLocaleCurrencyWithNullAsZero } from "../../../../../../common/utils/formatUtils";
import { InstitutionEnum } from "../../../../../institution/enums";
import { CalcResult } from "../../../types";
import { RealtyCalcResultData } from "../../types";

interface Props {
  result: CalcResult<RealtyCalcResultData>;
  selected: boolean;
  onGenerateContractClick: (result: CalcResult<RealtyCalcResultData>) => void;
  onShowErrorsClick?: (result: CalcResult<RealtyCalcResultData>) => void;
}

const RealtyCalcResultItem = ({ result, selected, ...props }: Props) => {
  const resultPricesTable = result.data ? (
    <table className="prices-table-view">
      <tbody>
        <tr>
          <td>{t("calc.realty.results.annualPremium")}:</td>
          <td>{formatLocaleCurrency(result.data.annualPremium)}</td>
        </tr>
        {result.data.realtyInsuranceAmount && (
          <tr>
            <td>{t("calc.realty.results.realtyInsuranceAmount")}:</td>
            <td>{formatLocaleCurrency(result.data.realtyInsuranceAmount)}</td>
          </tr>
        )}
        {result.data.householdInsuranceAmount && (
          <tr>
            <td>{t("calc.realty.results.householdInsuranceAmount")}:</td>
            <td>{formatLocaleCurrency(result.data.householdInsuranceAmount)}</td>
          </tr>
        )}
      </tbody>
    </table>
  ) : null;

  let resultAlert = null;
  if (result.data && result.data.warnings && result.data.warnings.length > 0) {
    resultAlert = (
      <Alert type="warning" showIcon message={<MultiLineText showDivider tokens={result.data.warnings} />} />
    );
  } else if (result.error) {
    if (result.error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      const violations = result.error.violations?.filter(
        violation => !violation.fieldPath.startsWith("clientsData.clients")
      );

      if (result.error.violations?.some(violation => violation.fieldPath.startsWith("clientsData.clients"))) {
        violations?.unshift({
          fieldPath: "clientsData.policyHolder",
          errors: [t("calc.validations.clientViolationError")],
          fieldValue: null
        });
      }

      resultAlert = (
        <Alert
          type="error"
          showIcon
          message={result.error.message}
          description={<FieldViolationsView violations={violations} rootPath="calc.realty.attrs" />}
        />
      );
    } else {
      resultAlert = (
        <Alert
          type="error"
          showIcon
          message={result.error.message}
          description={<MultiLineText tokens={result.error.errors} />}
        />
      );
    }
  }

  let resultAction = undefined;
  if (result.data) {
    resultAction = (
      <ComponentWithPermission permissions={[Permission.REALTY_GENERATE_CONTRACT]}>
        <ActionTextIcon
          icon="check-circle"
          color="green"
          size="large"
          disabled={selected}
          text={t("calc.realty.results.createContract")}
          onClick={() => props.onGenerateContractClick(result)}
        />
      </ComponentWithPermission>
    );
  } else if (props.onShowErrorsClick && result.error && result.error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
    resultAction = (
      <ActionTextIcon
        icon="eye"
        color="red"
        size="large"
        text={t("calc.realty.results.showErrors")}
        onClick={() => (props.onShowErrorsClick ? props.onShowErrorsClick(result) : undefined)}
      />
    );
  }

  const resultContent = (
    <div>
      {result.coverage && <h4>{result.coverage}</h4>}
      {resultPricesTable}
      {resultAlert}
      {resultAction && <div className="margin-top-tiny">{resultAction}</div>}
    </div>
  );

  return result.error ? (
    resultContent
  ) : (
    <Popover
      placement="left"
      title={t("calc.realty.results.additionalInfo")}
      content={
        <div className="result-additional-info">
          <table className="prices-table-view">
            <thead>
              <tr>
                <th />
                <th>{t("calc.realty.results.annualPremium")}</th>
                <th>{t("calc.realty.results.insuranceAmount")}</th>
              </tr>
            </thead>
            <tbody>
              {(result.data.realtyAnnualPremium || result.data.realtyInsuranceAmount) && (
                <tr>
                  <td>
                    <div>{t("calc.realty.sections.realtyInsurance")}</div>
                    <div style={{ fontSize: "12px" }}>
                      <i>{t("calc.realty.sections.realtyInsuranceWithNearbyBuildings")}</i>
                    </div>
                  </td>
                  <td>{formatLocaleCurrency(result.data.realtyAnnualPremium)}</td>
                  <td>{formatLocaleCurrency(result.data.realtyInsuranceAmount)}</td>
                </tr>
              )}
              {(result.data.householdAnnualPremium || result.data.householdInsuranceAmount) && (
                <tr>
                  <td>{t("calc.realty.sections.householdInsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.householdAnnualPremium)}</td>
                  <td>{formatLocaleCurrency(result.data.householdInsuranceAmount)}</td>
                </tr>
              )}
              {result.data.equipmentReinsuranceAnnualPremium && (
                <tr>
                  <td>{t("calc.realty.results.equipmentReinsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.equipmentReinsuranceAnnualPremium)}</td>
                  <td>-</td>
                </tr>
              )}
              {(result.data.realtyLiabilityAnnualPremium || result.data.realtyLiabilityInsuranceAmount) && (
                <tr>
                  <td>{t("calc.realty.results.realtyLiability")}</td>
                  <td>{formatLocaleCurrency(result.data.realtyLiabilityAnnualPremium)}</td>
                  <td>{formatLocaleCurrency(result.data.realtyLiabilityInsuranceAmount)}</td>
                </tr>
              )}
              {(result.data.householdLiabilityAnnualPremium || result.data.householdLiabilityInsuranceAmount) && (
                <tr>
                  <td>{t("calc.realty.results.householdLiability")}</td>
                  <td>{formatLocaleCurrency(result.data.householdLiabilityAnnualPremium)}</td>
                  <td>{formatLocaleCurrency(result.data.householdLiabilityInsuranceAmount)}</td>
                </tr>
              )}
              {(result.data.liabilityAnnualPremium || result.data.liabilityInsuranceAmount) && (
                <tr>
                  <td>{t("calc.realty.results.liability")}</td>
                  <td>{formatLocaleCurrency(result.data.liabilityAnnualPremium)}</td>
                  <td>{formatLocaleCurrency(result.data.liabilityInsuranceAmount)}</td>
                </tr>
              )}
              {(result.data.tenantLiabilityAnnualPremium || result.data.tenantLiabilityInsuranceAmount) && (
                <tr>
                  <td>{t("calc.realty.results.tenantLiability")}</td>
                  <td>{formatLocaleCurrency(result.data.tenantLiabilityAnnualPremium)}</td>
                  <td>{formatLocaleCurrency(result.data.tenantLiabilityInsuranceAmount)}</td>
                </tr>
              )}
              {result.data.civilLiabilityReinsuranceAnnualPremium && (
                <tr>
                  <td>{t("calc.realty.results.civilLiabilityReinsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.civilLiabilityReinsuranceAnnualPremium)}</td>
                  <td>-</td>
                </tr>
              )}
              {(result.data.cyberneticReinsuranceAnnualPremium || result.data.cyberneticReinsuranceInsuranceAmount) && (
                <tr>
                  <td>{t("calc.realty.results.cyberneticReinsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.cyberneticReinsuranceAnnualPremium)}</td>
                  <td>{formatLocaleCurrency(result.data.cyberneticReinsuranceInsuranceAmount)}</td>
                </tr>
              )}
              {result.data.warrantyReinsuranceAnnualPremium && (
                <tr>
                  <td>{t("calc.realty.results.warrantyReinsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.warrantyReinsuranceAnnualPremium)}</td>
                  <td>-</td>
                </tr>
              )}
              {result.data.cyclingReinsuranceAnnualPremium && (
                <tr>
                  <td>{t("calc.realty.results.cyclingReinsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.cyclingReinsuranceAnnualPremium)}</td>
                  <td>-</td>
                </tr>
              )}
              {result.data.loanReinsuranceAnnualPremium && (
                <tr>
                  <td>{t("calc.realty.results.loanReinsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.loanReinsuranceAnnualPremium)}</td>
                  <td>-</td>
                </tr>
              )}
              {result.data.rentReinsuranceAnnualPremium && (
                <tr>
                  <td>{t("calc.realty.results.rentReinsurance")}</td>
                  <td>{formatLocaleCurrency(result.data.rentReinsuranceAnnualPremium)}</td>
                  <td>-</td>
                </tr>
              )}
            </tbody>
          </table>

          <table className="prices-table-view margin-top-small">
            <tbody>
              <tr>
                <td>
                  {result.insuranceInstitution.institutionEnum === InstitutionEnum.PREMIUM
                    ? t("calc.realty.results.complicityAltName")
                    : t("calc.realty.results.complicity")}
                </td>
                <td>{formatLocaleCurrencyWithNullAsZero(result.data.complicity)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    >
      {resultContent}
    </Popover>
  );
};

export default RealtyCalcResultItem;
