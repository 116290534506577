import { Button, Card, Col, Dropdown, Form, PageHeader, Popconfirm, Radio, Row, Space, Tag } from "antd";
import { FormInstance } from "antd/lib/form";
import { useMemo } from "react";
import t from "../../../../../app/i18n";
import HiddenCheckbox from "../../../../../common/components/form/components/HiddenCheckbox";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import BackNavigationArrow from "../../../../../common/components/views/BackNavigationArrow";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { rowGutter } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { useBackNavigation } from "../../../../../common/utils/hooksUtils";
import { hasPermission } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import { ProductFinancialSector } from "../../../../product/enums";
import { PRODUCT_SECTOR_TO_UPDATE_CONTRACT_PERMISSION_MAP } from "../../../../product/utils";
import { deleteFinancialMediationActions } from "../../../ducks";
import { FinancialMediationStatus, FinancialMediationVersion } from "../../../enums";
import { CreateUpdateFinancialMediation, FinancialMediation, FinancialMediationRecommendation } from "../../../types";
import { getAllVersionsForFinancialMediation } from "../../../utils";
import FinancialMediationStatusTag from "../../FinancialMediationStatusTag";
import FinancialMediationVersionTag from "../../FinancialMediationVersionTag";

interface Props {
  form: FormInstance<CreateUpdateFinancialMediation>;
  financialMediation?: FinancialMediation;
  permissions: Permission[];
  onFormSubmit: () => void;
  onGenerateClick: (version: FinancialMediationVersion) => void;
  onChangeVersionClick: () => void;
  onFinishClick: () => void;
  onAssignClick: (status: FinancialMediationStatus) => void;
  onDelete?: typeof deleteFinancialMediationActions.request;
}

const FinancialMediationFormHeaderSection = ({ form, financialMediation, permissions, ...props }: Props) => {
  const onBackClick = useBackNavigation();

  const allowedSectors = useMemo(() => {
    const sectors: ProductFinancialSector[] = [];

    if (hasPermission(permissions, Permission.INSURANCE_CREATE)) {
      sectors.push(ProductFinancialSector.NON_LIFE_INSURANCES, ProductFinancialSector.LIFE_INSURANCES);
    }
    if (hasPermission(permissions, Permission.LOAN_CREATE)) {
      sectors.push(ProductFinancialSector.LOANS_AND_MORTGAGES);
    }
    if (hasPermission(permissions, Permission.DEPOSIT_CREATE)) {
      sectors.push(ProductFinancialSector.DEPOSITS);
    }
    if (hasPermission(permissions, Permission.SECOND_PILLAR_CREATE)) {
      sectors.push(ProductFinancialSector.SENIOR_PENSION_SAVINGS);
    }
    if (hasPermission(permissions, Permission.THIRD_PILLAR_CREATE)) {
      sectors.push(ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS);
    }

    return sectors;
  }, [permissions]);

  const handleFinishClick = (status: FinancialMediationStatus): void => {
    switch (status) {
      case FinancialMediationStatus.ASSIGNED_TO_CONTRACT:
        props.onFinishClick();
        break;
      case FinancialMediationStatus.ASSIGNED_TO_CLIENT:
        props.onAssignClick(status);
        break;
    }
  };

  const handleSectorChange = (sector: ProductFinancialSector): void => {
    const recommendations = [
      ...(form.getFieldValue(["mediationData", "recommendationData", "recommendations"]) || Array(1).fill({}, 0, 1))
    ] as FinancialMediationRecommendation[];

    form.setFieldsValue({
      mediationData: {
        recommendationData: {
          recommendations: recommendations.map(recommendation => ({
            ...recommendation,
            institutionId: undefined,
            productId: undefined
          }))
        },
        clientRequirements: [],
        clientSpecificRequirements:
          sector === ProductFinancialSector.DEPOSITS ? Array(3).fill({ selected: false }, 0, 3) : undefined,
        investmentQuestionnaireData: undefined,
        sectorAttachments: []
      }
    });
  };

  return financialMediation ? (
    <>
      <HiddenInput name="sector" initialValue={financialMediation.sector} />
      <HiddenCheckbox name="validateAllFields" initialValue={false} />

      <PageHeader
        className="page-header-box white-background margin-bottom-small"
        title={t("financialMediation.titles.detail")}
        tags={[
          <Tag key={0} color="purple">
            {t("product.enums.financialSector." + financialMediation.sector)}
          </Tag>,
          <FinancialMediationVersionTag key={1} version={financialMediation.version} />,
          <FinancialMediationStatusTag key={2} status={financialMediation.status} />
        ]}
        extra={
          <Space>
            {hasPermission(
              permissions,
              PRODUCT_SECTOR_TO_UPDATE_CONTRACT_PERMISSION_MAP.get(financialMediation.sector) as Permission
            ) &&
              financialMediation.status === FinancialMediationStatus.UNFINISHED && (
                <Button type="primary" icon={<AntIcon type="save" />} onClick={props.onFormSubmit}>
                  {t("common.save")}
                </Button>
              )}

            {financialMediation.version === FinancialMediationVersion.VERSION_1 ? (
              <Button
                type="primary"
                icon={<AntIcon type="file-text" />}
                disabled={false}
                onClick={() => props.onGenerateClick(FinancialMediationVersion.VERSION_1)}
              >
                {t("common.download")}
              </Button>
            ) : (
              <Dropdown
                disabled={false}
                menu={{
                  items: getAllVersionsForFinancialMediation(financialMediation.version).map(version => ({
                    label: t("financialMediation.enums.version." + version),
                    key: version
                  })),
                  onClick: event =>
                    props.onGenerateClick(
                      FinancialMediationVersion[event.key as keyof typeof FinancialMediationVersion]
                    )
                }}
              >
                <Button type="primary" disabled={false}>
                  <Space>
                    {t("common.download")}
                    <AntIcon type="down" />
                  </Space>
                </Button>
              </Dropdown>
            )}

            {hasPermission(
              permissions,
              PRODUCT_SECTOR_TO_UPDATE_CONTRACT_PERMISSION_MAP.get(financialMediation.sector) as Permission
            ) && (
              <>
                {(financialMediation.status === FinancialMediationStatus.FINISHED ||
                  financialMediation.status === FinancialMediationStatus.ASSIGNED_TO_CONTRACT) && (
                  <Button
                    className="secondary-button"
                    icon={<AntIcon type="delivered-procedure" />}
                    disabled={false}
                    onClick={() => props.onAssignClick(FinancialMediationStatus.ASSIGNED_TO_CONTRACT)}
                  >
                    {t("financialMediation.actions.assignToContract")}
                  </Button>
                )}

                {financialMediation.version !== FinancialMediationVersion.VERSION_5 &&
                  financialMediation.status === FinancialMediationStatus.UNFINISHED && (
                    <Button
                      className="secondary-button"
                      icon={<AntIcon type="node-expand" />}
                      onClick={props.onChangeVersionClick}
                    >
                      {t("financialMediation.actions.changeVersion")}
                    </Button>
                  )}

                {financialMediation.status === FinancialMediationStatus.UNFINISHED && (
                  <Dropdown
                    disabled={false}
                    menu={{
                      items: [
                        {
                          label: t("financialMediation.helpers.contractCreated"),
                          icon: <AntIcon type="file-done" />,
                          key: FinancialMediationStatus.ASSIGNED_TO_CONTRACT
                        },
                        {
                          label: t("financialMediation.helpers.contractNotCreated"),
                          icon: <AntIcon type="user-switch" />,
                          key: FinancialMediationStatus.ASSIGNED_TO_CLIENT
                        }
                      ],
                      onClick: event =>
                        handleFinishClick(FinancialMediationStatus[event.key as keyof typeof FinancialMediationStatus])
                    }}
                  >
                    <Button className="secondary-button" disabled={false}>
                      <Space>
                        {t("financialMediation.actions.finish")}
                        <AntIcon type="down" />
                      </Space>
                    </Button>
                  </Dropdown>
                )}

                <Popconfirm
                  title={t("financialMediation.helpers.deleteConfirm")}
                  icon={<PopconfirmDeleteIcon />}
                  okText={t("common.yes")}
                  okButtonProps={{ disabled: false }}
                  okType="danger"
                  cancelText={t("common.no")}
                  cancelButtonProps={{ disabled: false }}
                  disabled={false}
                  onConfirm={() => (props.onDelete ? props.onDelete({ id: financialMediation.id }) : undefined)}
                >
                  <Button danger icon={<AntIcon type="delete" />} disabled={false} />
                </Popconfirm>
              </>
            )}
          </Space>
        }
        onBack={onBackClick}
      >
        <ItemCreatedUpdatedInfoView item={financialMediation} style={{ marginTop: "-15px" }} />
      </PageHeader>
    </>
  ) : (
    <Card
      className="card-box card-box--inner-extra"
      title={
        <BackNavigationArrow>
          <h2>{t("financialMediation.titles.create")}</h2>
        </BackNavigationArrow>
      }
      extra={
        <Button type="primary" icon={<AntIcon type="save" />} onClick={props.onFormSubmit}>
          {t("common.save")}
        </Button>
      }
    >
      <Row gutter={rowGutter} justify="center">
        <Col span={24} className="center-align">
          <Form.Item name="sector" rules={[validations.notNull]} className="no-bottom-space">
            <Radio.Group
              size="large"
              buttonStyle="solid"
              className="radio-group-icon"
              onChange={e => handleSectorChange(e.target.value)}
            >
              {allowedSectors.map(sector => (
                <Radio.Button value={sector} key={sector} style={{ width: "140px" }}>
                  <span style={{ fontSize: "16px", fontWeight: "bold", lineHeight: "20px" }}>
                    {t("product.enums.financialSector." + sector)}
                  </span>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default FinancialMediationFormHeaderSection;
