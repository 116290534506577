import { Breadcrumb } from "antd";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { DocumentNodeType } from "../../enums";
import { DocumentNodeTree } from "../../types";

interface Props {
  breadcrumbs?: DocumentNodeTree[];
  treeNodeType: DocumentNodeType;
  onClick: (breadcrumb?: DocumentNodeTree) => void;
}

const DocumentNodeBreadcrumbsView = ({ breadcrumbs, treeNodeType, onClick }: Props) => (
  <div className="documents__breadcrumbs">
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => onClick()}>
        <ActionTextIcon icon="home" color="blue" text={t(`documentNode.enums.${treeNodeType}`)} />
      </Breadcrumb.Item>
      {breadcrumbs?.map((breadcrumb, index) =>
        index === breadcrumbs.length - 1 ? (
          <Breadcrumb.Item key={index}>{breadcrumb.name}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index} onClick={() => onClick(breadcrumb)}>
            {breadcrumb.name}
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  </div>
);

export default DocumentNodeBreadcrumbsView;
