import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL } from "../../../common/utils/utils";
import type { UUID } from "../../../typings/global";
import { selectRouterLocationSearch } from "../../ducks";
import { requests } from "../api";
import AgentTreeFilterView from "../components/views/tree/AgentTreeFilterView";
import AgentTreeView from "../components/views/tree/AgentTreeView";
import { deleteStateAgentTreeAction, getAgentTreeActions, selectAgentTree } from "../ducks";
import { AgentTree, AgentTreeFilterRequest } from "../types";

interface StateProps {
  agentTree?: AgentTree;
  urlSearchQuery: string;
}

interface ActionsMap {
  getAgentTree: typeof getAgentTreeActions.request;
  deleteStateAgentTree: typeof deleteStateAgentTreeAction;
}

const AgentTreeContainer = ({ agentTree, urlSearchQuery, actions }: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);

    actions.getAgentTree({
      rootAgentId: urlParams.get("rootAgentId") as UUID,
      includeDeactivated: urlParams.get("includeDeactivated") === "true" ?? undefined,
      includeRepresentatives: urlParams.get("includeRepresentatives") === "true" ?? undefined
    });

    return () => {
      actions.deleteStateAgentTree();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: AgentTreeFilterRequest): void => {
    navigate(
      appendSearchParamsToURL({
        ...filter,
        includeDeactivated: filter.includeDeactivated ?? undefined,
        includeRepresentatives: filter.includeRepresentatives ?? undefined
      }),
      { replace: true }
    );
    actions.getAgentTree(filter);
  };

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!agentTree} notFoundCheckRequest={requests.GET_AGENT_TREE}>
        <AgentTreeFilterView filter={agentTree} onFilterSubmit={handleFilterSubmit} />
        <AgentTreeView agentTree={agentTree} />
      </DisplayWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  agentTree: selectAgentTree(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getAgentTree: getAgentTreeActions.request,
      deleteStateAgentTree: deleteStateAgentTreeAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AgentTreeContainer);
