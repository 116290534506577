import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState, SearchPageRequest, SearchPageResult } from "../../../common/types";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import { getEnumerationsActions } from "../../enumerations/ducks";
import api from "./api";
import { CertificateVehicleColor, CreateUpdateCertificateVehicleColor, VehicleColorReducerState } from "./types";

/**
 * ACTIONS
 */
export const filterVehicleColorsActions = createAsyncAction(
  "vehicle-color/FILTER_REQUEST",
  "vehicle-color/FILTER_SUCCESS",
  "vehicle-color/FILTER_FAILURE"
)<SearchPageRequest, SearchPageResult<CertificateVehicleColor>, void>();

export const createVehicleColorActions = createAsyncAction(
  "vehicle-color/CREATE_REQUEST",
  "vehicle-color/CREATE_SUCCESS",
  "vehicle-color/CREATE_FAILURE"
)<CreateUpdateCertificateVehicleColor, CertificateVehicleColor, void>();

export const updateVehicleColorActions = createAsyncAction(
  "vehicle-color/UPDATE_REQUEST",
  "vehicle-color/UPDATE_SUCCESS",
  "vehicle-color/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCertificateVehicleColor>, CertificateVehicleColor, void>();

export const deleteVehicleColorActions = createAsyncAction(
  "vehicle-color/DELETE_REQUEST",
  "vehicle-color/DELETE_SUCCESS",
  "vehicle-color/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateVehicleColorsPageAction = createAction("vehicle-color/DELETE_STATE_LIST")<void>();

const actions = {
  filterVehicleColorsActions,
  createVehicleColorActions,
  updateVehicleColorActions,
  deleteVehicleColorActions,
  deleteStateVehicleColorsPageAction
};

export type VehicleColorAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: VehicleColorReducerState = {
  currentPage: initSearchPageResult<CertificateVehicleColor>()
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterVehicleColorsActions.success, (_, { payload }) => payload)
  .handleAction(updateVehicleColorActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterVehicleColorsActions.failure, deleteStateVehicleColorsPageAction],
    () => initialState.currentPage
  );

export const vehicleColorReducer = combineReducers<VehicleColorReducerState>({
  currentPage: currentPageReducer
});

/**
 * SELECTORS
 */
const selectVehicleColor = (state: RootState): VehicleColorReducerState => state.vehicle.color;

export const selectVehicleColorsCurrentPage = (state: RootState): SearchPageResult<CertificateVehicleColor> =>
  selectVehicleColor(state).currentPage;

/**
 * SAGAS
 */
function* filterVehicleColors({ payload }: ReturnType<typeof filterVehicleColorsActions.request>) {
  try {
    const response: AxiosResponse<SearchPageResult<CertificateVehicleColor>> = yield call(
      api.filterVehicleColors,
      payload
    );
    yield put(filterVehicleColorsActions.success(response.data));
  } catch (error) {
    yield put(filterVehicleColorsActions.failure());
  }
}

function* createVehicleColor({ payload }: ReturnType<typeof createVehicleColorActions.request>) {
  try {
    const response: AxiosResponse<CertificateVehicleColor> = yield call(api.createVehicleColor, payload);
    yield put(createVehicleColorActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createVehicleColorActions.failure());
  }
}

function* updateVehicleColor({ payload }: ReturnType<typeof updateVehicleColorActions.request>) {
  try {
    const response: AxiosResponse<CertificateVehicleColor> = yield call(api.updateVehicleColor, payload);
    yield put(updateVehicleColorActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateVehicleColorActions.failure());
  }
}

function* deleteVehicleColor({ payload }: ReturnType<typeof deleteVehicleColorActions.request>) {
  try {
    yield call(api.deleteVehicleColor, payload);
    yield put(deleteVehicleColorActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();

    yield* refreshCurrentPage(true);
  } catch (error) {
    yield put(deleteVehicleColorActions.failure());
  }
}

function* refreshCurrentPage(movePage?: boolean) {
  const currentPage: SearchPageResult<CertificateVehicleColor> = yield select(selectVehicleColorsCurrentPage);
  yield put(
    filterVehicleColorsActions.request({
      pageIndex: movePage
        ? currentPage.pageElementsCount === 1
          ? Math.max(currentPage.pageIndex - 1, 0)
          : currentPage.pageIndex
        : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      keyword: currentPage.keyword
    })
  );
}

export function* vehicleColorSaga() {
  yield takeLatest(filterVehicleColorsActions.request, filterVehicleColors);
  yield takeLatest(createVehicleColorActions.request, createVehicleColor);
  yield takeLatest(updateVehicleColorActions.request, updateVehicleColor);
  yield takeLatest(deleteVehicleColorActions.request, deleteVehicleColor);
}
