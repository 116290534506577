import { AutoComplete, Form } from "antd";
import { AutoCompleteProps } from "antd/lib/auto-complete";
import { FormItemProps } from "antd/lib/form";
import { CommercialRegisterDistrictOffices } from "../../../../common/constants";
import { selectFilterFunction } from "../../../../common/utils/formUtils";

interface Props {
  formItemProps: FormItemProps;
  autocompleteProps?: AutoCompleteProps<string>;
}

const CommercialRegisterDistrictOfficeAutoComplete = ({ formItemProps, autocompleteProps }: Props) => (
  <Form.Item {...formItemProps}>
    <AutoComplete
      {...autocompleteProps}
      dropdownMatchSelectWidth={false}
      options={CommercialRegisterDistrictOffices.map(office => ({ value: office, label: office }))}
      filterOption={selectFilterFunction}
    />
  </Form.Item>
);

export default CommercialRegisterDistrictOfficeAutoComplete;
