import { Button, Col, Modal, Popconfirm, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import { ModalSizes, PageSizes, rowGutter, TableSizes } from "../../../../common/constants";
import { initNonEmptyPageResult, initPageResult } from "../../../../common/utils/apiUtils";
import { formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import { isDefinedValue, paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { abortJobActions, cancelJobActions, filterJobRecordsActions } from "../../ducks";
import { JobState } from "../../enums";
import { JobRecord, JobSettings } from "../../types";
import JobStateTag from "../JobStateTag";

interface Props {
  job: JobSettings;
  onFilter: typeof filterJobRecordsActions.request;
  onCancel: typeof cancelJobActions.request;
  onAbort: typeof abortJobActions.request;
}

const JobRecordsTableView = ({ job, ...props }: Props) => {
  const [resultToShow, setResultToShow] = useState<any>();

  const handleShowOtherRecordsClick = (): void => {
    props.onFilter({ id: job.id, object: { pageIndex: 0, pageSize: PageSizes.TINY } });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    props.onFilter({ id: job.id, object: { pageIndex: pageNumber - 1, pageSize: PageSizes.TINY } });
  };

  const columns: ColumnsType<JobRecord> = [
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "state",
      title: t("job.enums.jobState._label"),
      width: 130,
      render: (_, record) => <JobStateTag state={record.state} style={{ marginRight: 0 }} />
    },
    {
      key: "startedAt",
      title: t("job.attrs.record.startedAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.startedAt)
    },
    {
      key: "finishedAt",
      title: t("job.attrs.record.finishedAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.finishedAt)
    },
    {
      key: "manuallyScheduledFor",
      title: t("job.attrs.record.manuallyScheduledFor"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.manuallyScheduledFor)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_, record) => {
        switch (record.state) {
          case JobState.MANUALLY_SCHEDULED:
            return (
              <Popconfirm
                title={t("job.helpers.cancelJobConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => props.onCancel({ id1: job.id, id2: record.id })}
              >
                <ActionTextIcon icon="close" color="orange" text={t("common.cancel")} />
              </Popconfirm>
            );
          case JobState.IN_PROGRESS:
            return (
              <Popconfirm
                title={t("job.helpers.abortJobConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => props.onAbort({ id1: job.id, id2: record.id })}
              >
                <ActionTextIcon icon="close" color="red" text={t("common.cancel")} />
              </Popconfirm>
            );
          default:
            return (
              <ActionTextIcon
                icon="eye"
                color="blue"
                text={t("common.show")}
                disabled={!isDefinedValue(record.result)}
                onClick={() => setResultToShow(record.result)}
              />
            );
        }
      }
    }
  ];

  const recordsPage = job.records
    ? job.records
    : job.mostRecentRecord
      ? initNonEmptyPageResult<JobRecord>([job.mostRecentRecord], PageSizes.TINY)
      : initPageResult<JobRecord>();

  return (
    <>
      <p className="margin-top-small">
        <b>{t("job.sections.mostRecentRecords")}</b>
      </p>

      <Table<JobRecord>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.MEDIUM }}
        dataSource={recordsPage.pageData}
        pagination={{
          ...paginationTableProps,
          hideOnSinglePage: true,
          position: ["bottomRight"],
          current: recordsPage.pageIndex + 1,
          pageSize: recordsPage.pageSize,
          total: recordsPage.totalElementsCount,
          onChange: handleTablePageChange
        }}
      />

      {!job.records ? (
        <Row gutter={rowGutter}>
          <Col span={24} className="center-align">
            <Button size="small" className="secondary-button margin-top-small" onClick={handleShowOtherRecordsClick}>
              {t("job.actions.showOtherRecords")}
            </Button>
          </Col>
        </Row>
      ) : undefined}

      <Modal
        open={isDefinedValue(resultToShow)}
        title={t("job.titles.jobResult", { jobName: job.name })}
        width={ModalSizes.MEDIUM}
        centered
        onCancel={() => setResultToShow(undefined)}
        footer={
          <Button onClick={() => setResultToShow(undefined)} icon={<AntIcon type="close" />}>
            {t("common.close")}
          </Button>
        }
      >
        {resultToShow && (
          <Row gutter={rowGutter} justify="center">
            <Col span={20}>
              <pre className="modal-code-block">{JSON.stringify(resultToShow, undefined, 2)}</pre>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default JobRecordsTableView;
