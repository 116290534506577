import { Button, Col, Modal, Row, Select } from "antd";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { ModalSizes } from "../../../../../common/constants";
import { formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { saveTextFile } from "../../../../../common/utils/utils";
import { CalcRecord } from "../../types";

interface Props {
  open: boolean;
  record?: CalcRecord;
  onCancelClick: () => void;
  onAfterClose: () => void;
}

const CalcRecordStackTracesLogsModal = ({ open, record, onCancelClick, onAfterClose }: Props) => {
  const [selectedLogIndex, setSelectedLogIndex] = useState<number>(0);

  const handleSelectedLogChange = (value: number): void => {
    setSelectedLogIndex(value);
  };

  const handleAfterCloseExecuted = (): void => {
    setSelectedLogIndex(0);
    onAfterClose();
  };

  const handleLogFileSave = (content: string): void => {
    saveTextFile(content, createLogsTitle());
  };

  const handleCopyInfoMessageDisplay = (): void => {
    messageUtils.infoMessage(t("common.clipboardCopied"));
  };

  const createLogsTitle = (): string => {
    return (
      t("calc.records.titles.stackTraceLogs") + ` - ${record?.user.name} / ` + formatLocaleDateTime(record?.createdAt)
    );
  };

  if (record && record.stackTracesLogs && record.stackTracesLogs.length > 0) {
    const stackTraceLog = record.stackTracesLogs[selectedLogIndex]?.stackTrace;

    if (!stackTraceLog) {
      return null;
    }

    return (
      <Modal
        open={open}
        title={createLogsTitle()}
        width={ModalSizes.HUGE}
        centered
        onCancel={onCancelClick}
        afterClose={handleAfterCloseExecuted}
        footer={
          <Button onClick={onCancelClick} icon={<AntIcon type="close" />}>
            {t("common.close")}
          </Button>
        }
      >
        <Row gutter={16} className="margin-bottom-medium">
          <Select
            {...selectStandardProps}
            style={{ width: "200px" }}
            value={selectedLogIndex}
            options={record.stackTracesLogs.map((log, index) => ({
              value: index,
              label: log.institution + (log.coverage ? " - " + log.coverage : "")
            }))}
            onChange={handleSelectedLogChange}
          />
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <pre className="modal-code-block">{stackTraceLog}</pre>

            <div className="center-align margin-top-small">
              <Button
                icon={<AntIcon type="download" />}
                className="secondary-button margin-right-medium"
                onClick={() => handleLogFileSave(stackTraceLog)}
              >
                {t("common.download")}
              </Button>

              <CopyToClipboard text={stackTraceLog} onCopy={handleCopyInfoMessageDisplay}>
                <Button icon={<AntIcon type="copy" />} className="secondary-button">
                  {t("common.copy")}
                </Button>
              </CopyToClipboard>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }

  return null;
};

export default CalcRecordStackTracesLogsModal;
