import { Alert, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  disableDatePickerOutOfMinDate,
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  selectTagsStandardProps,
  toMoment,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import type { UUID } from "../../../../../typings/global";
import { requests as postponedCommissionsRequests } from "../../../postponed/api";
import { updateDuplicatedCommissionActions, updatePostponedCommissionActions } from "../../../postponed/ducks";
import { requests as batchRequests } from "../../api";
import { createCommissionActions, updateCommissionActions } from "../../ducks";
import { CommissionKind, commissionKindTMap } from "../../enums";
import { Commission, CreateManuallyAddedCommission, UpdateCommission } from "../../types";
import CommissionKindTag from "../CommissionKindTag";

interface Props {
  open: boolean;
  formType: "batchCommission" | "duplicatedCommission" | "postponedCommission";
  commission?: Commission;
  batchId?: UUID;
  institutionId?: UUID;
  onCreate?: typeof createCommissionActions.request;
  onUpdate?: typeof updateCommissionActions.request;
  onUpdateDuplicated?: typeof updateDuplicatedCommissionActions.request;
  onUpdatePostponed?: typeof updatePostponedCommissionActions.request;
  onFormCancel: () => void;
}

const CommissionForm = ({
  open,
  formType,
  commission,
  batchId,
  institutionId,
  onCreate,
  onUpdate,
  onUpdateDuplicated,
  onUpdatePostponed,
  onFormCancel
}: Props) => {
  const [form] = Form.useForm<CreateManuallyAddedCommission | UpdateCommission>();
  useFormErrorHandler(form, "commissions.batch.attrs.commission", [
    batchRequests.CREATE_COMMISSION,
    batchRequests.UPDATE_COMMISSION,
    postponedCommissionsRequests.UPDATE_DUPLICATED_COMMISSION,
    postponedCommissionsRequests.UPDATE_POSTPONED_COMMISSION
  ]);

  const colSpan = 8;

  useEffect(() => {
    if (open && commission) {
      form.setFieldsValue({
        ...commission,
        startDate: toMoment(commission.startDate),
        endDate: toMoment(commission.endDate)
      });
      form.validateFields().catch(resolveFormValidationError);
    }
  }, [open, commission, form]);

  const inProgress = useRequestFinishedCallback(
    [
      batchRequests.CREATE_COMMISSION,
      batchRequests.UPDATE_COMMISSION,
      postponedCommissionsRequests.UPDATE_DUPLICATED_COMMISSION,
      postponedCommissionsRequests.UPDATE_POSTPONED_COMMISSION
    ],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        switch (formType) {
          case "batchCommission":
            if (commission) {
              batchId && onUpdate?.({ id1: batchId, id2: commission.id, object: values as UpdateCommission });
            } else {
              batchId &&
                institutionId &&
                onCreate?.({ id: batchId, object: { ...(values as CreateManuallyAddedCommission), institutionId } });
            }
            break;
          case "duplicatedCommission":
            batchId &&
              commission?.id &&
              onUpdateDuplicated?.({ id1: batchId, id2: commission.id, object: values as UpdateCommission });
            break;
          case "postponedCommission":
            commission?.id && onUpdatePostponed?.({ id: commission.id, object: values as UpdateCommission });
            break;
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={
        commission ? t("commissions.batch.titles.updateCommission") : t("commissions.batch.titles.createCommission")
      }
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionUpdateForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("commissions.batch.attrs.commission.contractNumber")}
              dependencies={["secondaryContractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull(
                  "secondaryContractNumber",
                  t("commissions.batch.attrs.commission.secondaryContractNumber")
                )
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="secondaryContractNumber"
              label={t("commissions.batch.attrs.commission.secondaryContractNumber")}
              dependencies={["contractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull("contractNumber", t("commissions.batch.attrs.commission.contractNumber"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="tariffCode"
              label={t("commissions.batch.attrs.commission.tariffCode")}
              rules={[validations.none]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="clientName"
              label={t("commissions.batch.attrs.commission.clientName")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="startDate"
              label={t("commissions.batch.attrs.commission.startDate")}
              rules={[validations.notNull]}
            >
              <DatePicker {...datePickerStandardProps} showToday={false} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.startDate !== next.startDate}>
              {({ getFieldValue }) => {
                const startDate = getFieldValue("startDate");
                return (
                  <Form.Item
                    name="endDate"
                    label={t("commissions.batch.attrs.commission.endDate")}
                    rules={[
                      startDate
                        ? validations.notBefore(startDate, t("commissions.batch.attrs.commission.startDate"))
                        : validations.none
                    ]}
                  >
                    <DatePicker
                      {...datePickerClearableProps}
                      showToday={false}
                      disabledDate={checked => (startDate ? disableDatePickerOutOfMinDate(checked, startDate) : false)}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="commissionBaseAmount"
              label={
                formType === "postponedCommission" ? (
                  <LabelWithTooltip
                    label={t("commissions.batch.attrs.commission.commissionBaseAmount")}
                    tooltip={t("commissions.postponed.helpers.amountsChangeDisabledInfo")}
                  />
                ) : (
                  t("commissions.batch.attrs.commission.commissionBaseAmount")
                )
              }
              rules={[validations.none]}
            >
              <InputNumber
                {...inputNumberDecimalStandardProps}
                addonAfter={<InputAddon type="euro" />}
                disabled={formType === "postponedCommission"}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="commissionAmount"
              label={
                formType === "postponedCommission" ? (
                  <LabelWithTooltip
                    label={t("commissions.batch.attrs.commission.commissionAmount")}
                    tooltip={t("commissions.postponed.helpers.amountsChangeDisabledInfo")}
                  />
                ) : (
                  t("commissions.batch.attrs.commission.commissionAmount")
                )
              }
              rules={[validations.notNull]}
            >
              <InputNumber
                {...inputNumberDecimalStandardProps}
                addonAfter={<InputAddon type="euro" />}
                disabled={formType === "postponedCommission"}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="commissionKind"
              label={t("commissions.batch.enums.commissionKind._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectTagsStandardProps(commissionKindTMap)}
                options={Object.keys(CommissionKind).map(kind => ({
                  value: kind,
                  label: <CommissionKindTag kind={CommissionKind[kind as keyof typeof CommissionKind]} />
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {commission?.calculatedCommissions?.some(calculation => calculation.modified) && (
        <Alert message={t("commissions.batch.helpers.modifiedCommissionUpdate")} type="warning" showIcon />
      )}
    </Modal>
  );
};

export default CommissionForm;
