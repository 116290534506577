import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState } from "../../common/types";
import { initPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import {
  CoverageLimit,
  CoverageLimitFilterPageRequest,
  CoverageLimitFilterPageResult,
  CoverageLimitReducerState,
  CreateUpdateCoverageLimit
} from "./types";

/**
 * ACTIONS
 */
export const filterCoverageLimitsActions = createAsyncAction(
  "coverage-limit/FILTER_REQUEST",
  "coverage-limit/FILTER_SUCCESS",
  "coverage-limit/FILTER_FAILURE"
)<CoverageLimitFilterPageRequest, CoverageLimitFilterPageResult, void>();

export const createCoverageLimitActions = createAsyncAction(
  "coverage-limit/CREATE_REQUEST",
  "coverage-limit/CREATE_SUCCESS",
  "coverage-limit/CREATE_FAILURE"
)<CreateUpdateCoverageLimit, CoverageLimit, void>();

export const updateCoverageLimitActions = createAsyncAction(
  "coverage-limit/UPDATE_REQUEST",
  "coverage-limit/UPDATE_SUCCESS",
  "coverage-limit/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCoverageLimit>, CoverageLimit, void>();

export const deleteCoverageLimitActions = createAsyncAction(
  "coverage-limit/DELETE_REQUEST",
  "coverage-limit/DELETE_SUCCESS",
  "coverage-limit/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateCoverageLimitsPageAction = createAction("coverage-limit/DELETE_STATE_LIST")<void>();

const actions = {
  filterCoverageLimitsActions,
  createCoverageLimitActions,
  updateCoverageLimitActions,
  deleteCoverageLimitActions,
  deleteStateCoverageLimitsPageAction
};

export type CoverageLimitAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CoverageLimitReducerState = {
  currentPage: { ...initPageResult<CoverageLimit>(), institutionIds: [] }
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterCoverageLimitsActions.success, (_, { payload }) => payload)
  .handleAction(updateCoverageLimitActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterCoverageLimitsActions.failure, deleteStateCoverageLimitsPageAction],
    () => initialState.currentPage
  );

export const coverageLimitReducer = combineReducers<CoverageLimitReducerState>({ currentPage: currentPageReducer });

/**
 * SELECTORS
 */
const selectCoverageLimit = (state: RootState): CoverageLimitReducerState => state.coverageLimit;

export const selectCoverageLimitsCurrentPage = (state: RootState): CoverageLimitFilterPageResult =>
  selectCoverageLimit(state).currentPage;

/**
 * SAGAS
 */
function* filterCoverageLimits({ payload }: ReturnType<typeof filterCoverageLimitsActions.request>) {
  try {
    const response: AxiosResponse<CoverageLimitFilterPageResult> = yield call(api.filterCoverageLimits, payload);
    yield put(filterCoverageLimitsActions.success(response.data));
  } catch (error) {
    yield put(filterCoverageLimitsActions.failure());
  }
}

function* createCoverageLimit({ payload }: ReturnType<typeof createCoverageLimitActions.request>) {
  try {
    const response: AxiosResponse<CoverageLimit> = yield call(api.createCoverageLimit, payload);
    yield put(createCoverageLimitActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createCoverageLimitActions.failure());
  }
}

function* updateCoverageLimit({ payload }: ReturnType<typeof updateCoverageLimitActions.request>) {
  try {
    const response: AxiosResponse<CoverageLimit> = yield call(api.updateCoverageLimit, payload);
    yield put(updateCoverageLimitActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateCoverageLimitActions.failure());
  }
}

function* deleteCoverageLimit({ payload }: ReturnType<typeof deleteCoverageLimitActions.request>) {
  try {
    yield call(api.deleteCoverageLimit, payload);
    yield put(deleteCoverageLimitActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(deleteCoverageLimitActions.failure());
  }
}

function* refreshCurrentPage() {
  const currentPage: CoverageLimitFilterPageResult = yield select(selectCoverageLimitsCurrentPage);
  yield put(
    filterCoverageLimitsActions.request({
      pageIndex: currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      institutionIds: currentPage.institutionIds
    })
  );
}

export function* coverageLimitSaga() {
  yield takeLatest(filterCoverageLimitsActions.request, filterCoverageLimits);
  yield takeLatest(createCoverageLimitActions.request, createCoverageLimit);
  yield takeLatest(updateCoverageLimitActions.request, updateCoverageLimit);
  yield takeLatest(deleteCoverageLimitActions.request, deleteCoverageLimit);
}
