import { Tag } from "antd";
import t from "../../../../app/i18n";
import { OperationType } from "../enums";

interface Props {
  type: OperationType;
  style?: React.CSSProperties;
}

const OperationTypeTag = ({ type, style }: Props) => {
  let color: string;

  switch (type) {
    case OperationType.CALCULATE:
      color = "blue";
      break;
    case OperationType.RECALCULATE:
      color = "green";
      break;
    case OperationType.GENERATE:
      color = "magenta";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} style={style}>
      {t("calc.records.enums.operationType." + type)}
    </Tag>
  );
};

export default OperationTypeTag;
