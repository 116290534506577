import { Card, Col, DatePicker, Empty, Row, Segmented, Space, Spin, Tooltip } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { bindActionCreators } from "redux";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { ChartItem, RootState } from "../../../../../common/types";
import { serializeParams } from "../../../../../common/utils/apiUtils";
import { chartColors, useChartActiveShapeRender } from "../../../../../common/utils/chartUtils";
import {
  formatIntegerLocaleCurrencyWithNullAsZero,
  formatLocaleNumberWithNullAsZero
} from "../../../../../common/utils/formatUtils";
import {
  datePickerDefaultRangesUntilToday,
  disableDatePickerFuture,
  getDatePickerFormat,
  momentToIsoDateString,
  toMoment
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { selectHasAnyPermissions, selectUserAccount } from "../../../../auth/ducks";
import { ContractVerificationStatus } from "../../../../contract/enums";
import { CONTRACT_ROUTE_PATHS } from "../../../../contract/paths";
import { selectInstitutionsEnums } from "../../../../enumerations/ducks";
import { InstitutionWithSettings } from "../../../../enumerations/types";
import { InstitutionType } from "../../../../institution/enums";
import { ProductFinancialSector } from "../../../../product/enums";
import { UserAccount } from "../../../../user/types";
import { requests } from "../../../api";
import {
  getDashboardContractStatisticsActions,
  selectDashboardContractStatistics,
  selectDashboardContractStatuses
} from "../../../ducks";
import { DashboardContractChartViewType } from "../../../enums";
import { ContractStatisticsDashboard, ContractStatusesDashboard } from "../../../types";

const sectorColors = {
  [ProductFinancialSector.NON_LIFE_INSURANCES]: chartColors[0],
  [ProductFinancialSector.LIFE_INSURANCES]: chartColors[1],
  [ProductFinancialSector.LOANS_AND_MORTGAGES]: chartColors[2],
  [ProductFinancialSector.CAPITAL_MARKET]: chartColors[3],
  [ProductFinancialSector.DEPOSITS]: chartColors[4],
  [ProductFinancialSector.SENIOR_PENSION_SAVINGS]: chartColors[5],
  [ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS]: chartColors[6],
  [ProductFinancialSector.OTHERS]: chartColors[7]
};

export const DashboardContractsWidget = () => {
  const { t } = useTranslation();
  const { activeShapeIndex, setActiveShapeIndex, renderActiveShape } = useChartActiveShapeRender();

  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const hasInsurancesOrLoanReadPermission = useSelector<RootState, boolean>(state =>
    selectHasAnyPermissions(Permission.INSURANCE_READ, Permission.LOAN_READ)(state)
  );

  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const contractStatuses = useSelector<RootState, ContractStatusesDashboard | undefined>(
    selectDashboardContractStatuses
  );
  const contractStatistics = useSelector<RootState, ContractStatisticsDashboard | undefined>(
    selectDashboardContractStatistics
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ onGetContractStatistics: getDashboardContractStatisticsActions.request }, dispatch),
    [dispatch]
  );

  const statusesFetchInProgress = useRequestFinishedCallback([requests.GET_DASHBOARD_CONTRACT_STATUSES]);
  const statisticsFetchInProgress = useRequestFinishedCallback([requests.GET_DASHBOARD_CONTRACT_STATISTICS]);

  const [chartDates, setChartDates] = useState<[Moment, Moment]>([moment().startOf("month"), moment()]);
  const [chartViewType, setChartViewType] = useState<DashboardContractChartViewType>(
    DashboardContractChartViewType.ALL_AMOUNTS
  );

  const chartData = useMemo((): ChartItem[] => {
    if (contractStatistics) {
      let items: Record<ProductFinancialSector, number>;
      switch (chartViewType) {
        case DashboardContractChartViewType.ALL_AMOUNTS:
          items = contractStatistics.allContractsAmountsSum;
          break;
        case DashboardContractChartViewType.ALL_COUNTS:
          items = contractStatistics.allContractsCounts;
          break;
        case DashboardContractChartViewType.OWN_AMOUNTS:
          items = contractStatistics.ownContractsAmountsSum;
          break;
        case DashboardContractChartViewType.OWN_COUNTS:
          items = contractStatistics.ownContractsCounts;
          break;
      }

      return Object.keys(items)
        .sort((a, b) => Object.keys(ProductFinancialSector).indexOf(a) - Object.keys(ProductFinancialSector).indexOf(b))
        .map<ChartItem>(sector => ({
          key: sector as string,
          label: t(`dashboard.contracts.enums.financialSector.${sector as ProductFinancialSector}`),
          value: items[sector as keyof typeof ProductFinancialSector],
          formattedValue:
            chartViewType === DashboardContractChartViewType.OWN_COUNTS ||
            chartViewType === DashboardContractChartViewType.ALL_COUNTS
              ? formatLocaleNumberWithNullAsZero(items[sector as keyof typeof ProductFinancialSector])
              : formatIntegerLocaleCurrencyWithNullAsZero(items[sector as keyof typeof ProductFinancialSector])
        }));
    }
    return [];
  }, [chartViewType, contractStatistics]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (contractStatistics) {
      setChartDates([toMoment(contractStatistics.minDate) as Moment, toMoment(contractStatistics.maxDate) as Moment]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createInterventionStatusPath = (status: ContractVerificationStatus): string => {
    return (
      CONTRACT_ROUTE_PATHS.list.to +
      "?" +
      serializeParams({
        signerIds: [userAccount?.representingAgent?.id || userAccount?.agent?.id],
        verificationStatuses: [status]
      })
    );
  };

  const createAnniversaryDateStatusPath = (type: InstitutionType): string => {
    return (
      CONTRACT_ROUTE_PATHS.anniversaryDate.to +
      "?" +
      serializeParams({
        gainerIds: [userAccount?.agent?.id],
        institutionIds: institutionsEnums.filter(i => !i.settings.deactivated && i.type === type).map(i => i.id)
      })
    );
  };

  const handleChartDatesChange = (dates: [Moment, Moment]): void => {
    if (dates) {
      setChartDates(dates);
      actions.onGetContractStatistics({
        minDate: momentToIsoDateString(dates[0]),
        maxDate: momentToIsoDateString(dates[1])
      });
    }
  };

  const handleChartViewTypeChange = (type: DashboardContractChartViewType): void => {
    setActiveShapeIndex(0);
    setChartViewType(type);
  };

  return (
    <Card
      className="card-box dashboard-contracts__card"
      size="small"
      loading={statusesFetchInProgress || (!contractStatistics && statisticsFetchInProgress)}
      title={t("dashboard.contracts.card")}
      extra={
        <Spin size="small" spinning={contractStatistics && statisticsFetchInProgress}>
          <DatePicker.RangePicker
            size="small"
            format={getDatePickerFormat()}
            disabledDate={current => disableDatePickerFuture(current)}
            allowClear={false}
            ranges={datePickerDefaultRangesUntilToday}
            placeholder={[t("common.from"), t("common.to")]}
            defaultPickerValue={[moment().subtract(1, "month").startOf("month"), moment()]}
            value={chartDates}
            onChange={dates => handleChartDatesChange(dates as [Moment, Moment])}
          />
        </Spin>
      }
    >
      {contractStatuses && contractStatistics ? (
        <Row>
          <Col span={9}>
            <Row gutter={rowGutter} className="margin-top-medium">
              <Col span={12} className="dashboard-contracts__status">
                <div className="dashboard-contracts__status__border">
                  <Space size="small">
                    <span className="dashboard-contracts__status__label">
                      {t("dashboard.contracts.internalIntervention")}
                    </span>
                    <Tooltip title={t("common.showMore")}>
                      <span>
                        <ActionTextIcon
                          icon="export"
                          path={createInterventionStatusPath(ContractVerificationStatus.INTERNAL_INTERVENTION)}
                          target="_blank"
                        />
                      </span>
                    </Tooltip>
                  </Space>

                  <div>
                    <CountUp
                      className={
                        contractStatuses.internalInterventionContractsCount > 0
                          ? "dashboard-contracts__status__error-value"
                          : "dashboard-contracts__status__success-value"
                      }
                      duration={3}
                      separator={" "}
                      end={contractStatuses.internalInterventionContractsCount}
                    />
                  </div>
                </div>
              </Col>

              <Col span={12} className="dashboard-contracts__status">
                <div className="dashboard-contracts__status__border">
                  <Space size="small">
                    <span className="dashboard-contracts__status__label">
                      {t("dashboard.contracts.externalIntervention")}
                    </span>
                    <Tooltip title={t("common.showMore")}>
                      <span>
                        <ActionTextIcon
                          icon="export"
                          path={createInterventionStatusPath(ContractVerificationStatus.EXTERNAL_INTERVENTION)}
                          target="_blank"
                        />
                      </span>
                    </Tooltip>
                  </Space>

                  <div>
                    <CountUp
                      className={
                        contractStatuses.externalInterventionContractsCount > 0
                          ? "dashboard-contracts__status__error-value"
                          : "dashboard-contracts__status__success-value"
                      }
                      duration={3}
                      separator={" "}
                      end={contractStatuses.externalInterventionContractsCount}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12} className="dashboard-contracts__status">
                <div className="dashboard-contracts__status__border">
                  <Space size="small">
                    <span className="dashboard-contracts__status__label">
                      {t("dashboard.contracts.anniversaryDatesInsurances")}
                    </span>
                    {hasInsurancesOrLoanReadPermission && (
                      <Tooltip title={t("common.showMore")}>
                        <span>
                          <ActionTextIcon
                            icon="export"
                            path={createAnniversaryDateStatusPath(InstitutionType.INSURANCE_COMPANY)}
                            target="_blank"
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Space>

                  <div>
                    <CountUp
                      className={
                        contractStatuses.insuranceContractAnniversaryDatesCount > 0
                          ? "dashboard-contracts__status__info-value"
                          : "dashboard-contracts__status__value"
                      }
                      duration={3}
                      separator={" "}
                      end={contractStatuses.insuranceContractAnniversaryDatesCount}
                    />
                  </div>
                </div>
              </Col>

              <Col span={12} className="dashboard-contracts__status">
                <div className="dashboard-contracts__status__border">
                  <Space size="small">
                    <span className="dashboard-contracts__status__label">
                      {t("dashboard.contracts.anniversaryDatesLoans")}
                    </span>
                    {hasInsurancesOrLoanReadPermission && (
                      <Tooltip title={t("common.showMore")}>
                        <span>
                          <ActionTextIcon
                            icon="export"
                            path={createAnniversaryDateStatusPath(InstitutionType.BANK)}
                            target="_blank"
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Space>

                  <div>
                    <CountUp
                      className={
                        contractStatuses.loanContractAnniversaryDatesCount > 0
                          ? "dashboard-contracts__status__info-value"
                          : "dashboard-contracts__status__value"
                      }
                      duration={3}
                      separator={" "}
                      end={contractStatuses.loanContractAnniversaryDatesCount}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={15}>
            <div className="right-align">
              <Segmented
                size="small"
                block
                value={chartViewType}
                options={Object.values(DashboardContractChartViewType).map(type => ({
                  label: t(`dashboard.contracts.enums.chartViewType.${type}`),
                  value: type
                }))}
                onChange={value =>
                  handleChartViewTypeChange(
                    DashboardContractChartViewType[value as keyof typeof DashboardContractChartViewType]
                  )
                }
              />
            </div>

            {chartData.length > 0 ? (
              <div className="dashboard-contracts__chart-container">
                <ResponsiveContainer width="100%" height={270}>
                  <PieChart>
                    <Pie
                      innerRadius={65}
                      paddingAngle={2}
                      cx="56%"
                      cy="52%"
                      dataKey="value"
                      nameKey="label"
                      data={chartData}
                      activeIndex={activeShapeIndex}
                      activeShape={renderActiveShape}
                      onMouseEnter={(_, index) => setActiveShapeIndex(index)}
                    >
                      {chartData.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={sectorColors[item.key as keyof typeof sectorColors]} />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      iconType="line"
                      iconSize={0}
                      wrapperStyle={{ width: "240px" }}
                      formatter={(value, entry) => (
                        <span style={{ color: entry.color }} className="dashboard-contracts__chart-legend">
                          <span className="dashboard-contracts__chart-legend__value">
                            {(entry.payload as unknown as ChartItem).formattedValue}
                          </span>
                          <AntIcon className="dashboard-contracts__chart-legend__icon" type="aim" />
                          <span>{value}</span>
                        </span>
                      )}
                      onMouseEnter={data =>
                        setActiveShapeIndex(chartData.findIndex(value => value.label === data.value))
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <Empty className="dashboard-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Col>
        </Row>
      ) : (
        <Empty className="dashboard-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};
