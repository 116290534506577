import { AutoComplete, Col, DatePicker, Form, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import ReactQuill from "react-quill";
import t from "../../../../../app/i18n";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import { Country } from "../../../../../common/modules/enums";
import {
  datePickerStandardProps,
  disableDatePickerPresentAndFuture,
  fillBirthDateFromPin,
  quillEditorStandardProps,
  upperCaseStringNormalizeFunction
} from "../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import AcademicDegreeAfterSelect from "../../../../enumerations/components/form/AcademicDegreeAfterSelect";
import AcademicDegreeSelect from "../../../../enumerations/components/form/AcademicDegreeSelect";
import CountrySelect from "../../../../enumerations/components/form/CountrySelect";
import DistrictOfficeAutoComplete from "../../../../enumerations/components/form/DistrictOfficeAutoComplete";
import { CreateUpdateClient } from "../../../types";

interface Props {
  form: FormInstance<CreateUpdateClient>;
  colSpan: number;
  disableCrnInput?: boolean;
  requireIdCardNumber?: boolean;
  previousIdCardNumber?: string;
  searchInProgress?: boolean;
  onCrnSearchOrChange?: (crn: string) => void;
}

const SelfEmployedClientDataFormPart = ({
  form,
  colSpan,
  disableCrnInput,
  requireIdCardNumber,
  previousIdCardNumber,
  searchInProgress,
  onCrnSearchOrChange
}: Props) => (
  <>
    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name="companyRegistrationNumber"
          label={t("client.attrs.companyRegistrationNumber")}
          rules={[validations.notBlank, validations.crn]}
        >
          {onCrnSearchOrChange ? (
            <Input.Search
              disabled={disableCrnInput}
              loading={searchInProgress}
              onSearch={onCrnSearchOrChange}
              onChange={e => onCrnSearchOrChange?.(e.target.value)}
            />
          ) : (
            <Input disabled={disableCrnInput} />
          )}
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item
          name="companyName"
          label={t("client.attrs.companyName")}
          rules={[validations.notBlank, validations.size(1, 255)]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <AcademicDegreeSelect
          formItemProps={{ name: "academicDegree", label: t("client.attrs.academicDegree") }}
          selectProps={{ allowClear: true }}
        />
      </Col>
      <Col span={colSpan}>
        <Form.Item
          name="firstName"
          label={t("client.attrs.firstName")}
          rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item
          name="lastName"
          label={t("client.attrs.lastName")}
          rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <AcademicDegreeAfterSelect
          formItemProps={{ name: "academicDegreeAfter", label: t("client.attrs.academicDegreeAfter") }}
          selectProps={{ allowClear: true }}
        />
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item name="taxId" label={t("client.attrs.taxId")} rules={[validations.length(10), validations.numeric]}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <DistrictOfficeAutoComplete
          formItemProps={{
            name: "districtOffice",
            label: t("client.attrs.districtOffice"),
            rules: [validations.size(1, 255)]
          }}
        />
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name="vatId"
          label={t("client.attrs.vatId")}
          rules={[validations.length(12), validations.pattern(regexPatterns.vatIdRegex)]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item
          name="tradeRegisterNumber"
          label={t("client.attrs.tradeRegisterNumber")}
          rules={[validations.size(1, 255)]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name="personalIdentificationNumber"
          label={
            <LabelWithTooltip
              tooltip={t("helpers.pinFormat")}
              label={t("client.attrs.personalIdentificationNumberLong")}
            />
          }
          rules={[validations.notBlank, validations.pin]}
        >
          <Input onChange={event => fillBirthDateFromPin(event.target.value, form)} />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item
          name="birthDate"
          label={t("client.attrs.birthDate")}
          rules={[validations.notNull, validations.notPresentAndFuture]}
        >
          <DatePicker {...datePickerStandardProps} showToday={false} disabledDate={disableDatePickerPresentAndFuture} />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name="identityCardNumber"
          label={t("client.attrs.identityCardNumber")}
          rules={[
            ...(requireIdCardNumber ? [validations.notBlank] : []),
            validations.size(8, 10),
            validations.pattern(regexPatterns.idCardRegex)
          ]}
          normalize={upperCaseStringNormalizeFunction}
        >
          {previousIdCardNumber ? (
            <AutoComplete
              options={[
                {
                  value: previousIdCardNumber,
                  label: (
                    <>
                      <span className="sub-header-info">{t("client.attrs.previousIdentityCardNumber")}</span>
                      <br />
                      {previousIdCardNumber}
                    </>
                  )
                }
              ]}
            />
          ) : (
            <Input />
          )}
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <CountrySelect
          formItemProps={{
            name: "nationality",
            label: t("client.attrs.nationalityLabel"),
            rules: [validations.notNull],
            initialValue: Country.SVK
          }}
        />
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan * 4}>
        <Form.Item
          name="note"
          label={t("client.attrs.note")}
          rules={[validations.size(1, 8192)]}
          initialValue={undefined}
        >
          <ReactQuill {...quillEditorStandardProps} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default SelfEmployedClientDataFormPart;
