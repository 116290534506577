import { Tag } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  closed: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const DashboardNoticeClosedTag = ({ closed, className, style }: Props) => {
  const { t } = useTranslation();
  return closed ? (
    <Tag color="green" className={className} style={{ width: "74px", textAlign: "center", ...style }}>
      {t("dashboard.notices.attrs.closed")}
    </Tag>
  ) : null;
};
