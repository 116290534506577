import { Button, Collapse, Popconfirm, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import { formatPhoneNumber } from "../../../../../common/utils/formatUtils";
import { ClientUserAccountWithUserAgentView } from "../../../../user/types";
import {
  createUserWithClientUserAccountActions,
  deleteClientUserAccountActions,
  deleteStateClientUserAccountsAction,
  getClientUserAccountsActions,
  resendUserConfirmLinkActions,
  updateClientUserAccountActions
} from "../../../ducks";
import { Client } from "../../../types";
import CreateClientUserAccountForm from "../../forms/accounts/CreateClientUserAccountForm";
import UpdateClientUserAccountForm from "../../forms/accounts/UpdateClientUserAccountForm";

interface Props {
  client: Client;
  accounts: ClientUserAccountWithUserAgentView[];
  onGet: typeof getClientUserAccountsActions.request;
  onCreate: typeof createUserWithClientUserAccountActions.request;
  onResendConfirmLink: typeof resendUserConfirmLinkActions.request;
  onUpdate: typeof updateClientUserAccountActions.request;
  onDelete: typeof deleteClientUserAccountActions.request;
  onDeleteStateClientUserAccounts: typeof deleteStateClientUserAccountsAction;
}

const ClientUserAccountsView = (props: Props) => {
  const [createFormOpen, setCreateFormOpen] = useState<boolean>(false);
  const [accountToUpdate, setAccountToUpdate] = useState<ClientUserAccountWithUserAgentView>();

  useEffect(() => {
    props.onGet({ id: props.client.id });
    return () => {
      props.onDeleteStateClientUserAccounts();
    };
  }, [props.client]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.accounts?.length > 0 && (
        <Collapse accordion>
          {props.accounts?.map(account => (
            <Collapse.Panel
              key={account.id}
              header={`${account.user.name} | ${account.user.email || account.user.emailToConfirm}`}
              extra={
                <>
                  {account.user.locked && <Tag color="red">{t("user.helpers.lockedUser")}</Tag>}
                  {account.disabled && <Tag color="purple">{t("user.helpers.disabledAccount")}</Tag>}
                  {!account.confirmed && <Tag color="orange">{t("user.helpers.unconfirmedAccount")}</Tag>}

                  <span onClick={event => event.stopPropagation()}>
                    <Button
                      className="margin-right-tiny"
                      type="primary"
                      size="small"
                      icon={<AntIcon type="edit" />}
                      onClick={() => setAccountToUpdate(account)}
                    >
                      {t("user.actions.updateAccount")}
                    </Button>
                    <Popconfirm
                      title={t("user.titles.deleteClientAccountConfirm")}
                      icon={<PopconfirmDeleteIcon />}
                      okText={t("common.yes")}
                      cancelText={t("common.no")}
                      okType="danger"
                      onConfirm={() => props.onDelete({ id1: props.client.id, id2: account.id })}
                    >
                      <ActionButton icon="minus" label={t("user.actions.deleteAccount")} />
                    </Popconfirm>
                  </span>
                </>
              }
            >
              <table className="data-table-view margin-bottom-medium margin-top-medium">
                <tbody>
                  <tr>
                    <td>{t("user.attrs.name")}:</td>
                    <td>{account.user.name}</td>
                    <td>
                      {t("user.attrs.wrongLoginAttempts")}: {account.user.wrongLoginAttempts}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("user.attrs.email")}:</td>
                    <td>{account.user.email || account.user.emailToConfirm}</td>
                    <td>
                      <Space>
                        {account.user.locked && <Tag color="red">{t("user.helpers.lockedUser")}</Tag>}

                        {account.disabled && <Tag color="purple">{t("user.helpers.disabledAccount")}</Tag>}

                        {!account.confirmed && (
                          <>
                            <Tag color="orange">{t("user.helpers.unconfirmedAccount")}</Tag>
                            <Popconfirm
                              title={t("user.titles.resendConfirmLinkConfirm")}
                              icon={<PopconfirmDeleteIcon />}
                              okText={t("common.yes")}
                              cancelText={t("common.no")}
                              okType="danger"
                              onConfirm={() => props.onResendConfirmLink({ id1: props.client.id, id2: account.id })}
                            >
                              <Button
                                className="secondary-button"
                                size="small"
                                type="primary"
                                icon={<AntIcon type="mail" />}
                              >
                                {t("user.actions.resendConfirmLink")}
                              </Button>
                            </Popconfirm>
                          </>
                        )}
                      </Space>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("user.attrs.phone")}:</td>
                    <td>{formatPhoneNumber(account.confirmed ? account.user.phone : account.user.phoneToConfirm)}</td>
                  </tr>
                </tbody>
              </table>
            </Collapse.Panel>
          ))}
        </Collapse>
      )}

      <ActionButton
        icon="plus"
        label={t("user.actions.addAccount")}
        className="margin-top-small"
        onClick={() => setCreateFormOpen(true)}
      />

      <CreateClientUserAccountForm
        open={createFormOpen}
        client={props.client}
        onFormSubmit={props.onCreate}
        onFormCancel={() => setCreateFormOpen(false)}
      />

      <UpdateClientUserAccountForm
        client={props.client}
        account={accountToUpdate}
        onFormSubmit={props.onUpdate}
        onFormCancel={() => setAccountToUpdate(undefined)}
      />
    </>
  );
};

export default ClientUserAccountsView;
