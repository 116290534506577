import { Tabs } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState, SearchPageRequest, SearchPageResult } from "../../../common/types";
import { useOnMount } from "../../../common/utils/hooksUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import ProductForm from "../components/forms/ProductForm";
import ProductGroupForm from "../components/forms/ProductGroupForm";
import ProductFilterView from "../components/views/ProductFilterView";
import ProductGroupFilterView from "../components/views/ProductGroupFilterView";
import ProductGroupTableView from "../components/views/ProductGroupTableView";
import ProductTableView from "../components/views/ProductTableView";
import {
  createProductActions,
  createProductGroupActions,
  deleteProductActions,
  deleteProductGroupActions,
  deleteStateProductGroupsPageAction,
  deleteStateProductsPageAction,
  filterProductGroupsActions,
  filterProductsActions,
  selectProductGroupsPage,
  selectProductsPage,
  updateProductActions,
  updateProductGroupActions
} from "../ducks";
import { ProductFinancialSector } from "../enums";
import { Product, ProductFilterPageRequest, ProductFilterPageResult, ProductGroup } from "../types";

interface StateProps {
  urlSearchQuery: string;
  productGroupsPage: SearchPageResult<ProductGroup>;
  productsPage: ProductFilterPageResult;
}

interface ActionsMap {
  filterProductGroups: typeof filterProductGroupsActions.request;
  createProductGroup: typeof createProductGroupActions.request;
  updateProductGroup: typeof updateProductGroupActions.request;
  deleteProductGroup: typeof deleteProductGroupActions.request;
  deleteStateProductGroupsPage: typeof deleteStateProductGroupsPageAction;
  filterProducts: typeof filterProductsActions.request;
  createProduct: typeof createProductActions.request;
  updateProduct: typeof updateProductActions.request;
  deleteProduct: typeof deleteProductActions.request;
  deleteStateProductsPage: typeof deleteStateProductsPageAction;
}

const TAB = {
  PRODUCT_GROUPS: "product-groups",
  PRODUCTS: "products"
};

const ProductsContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.PRODUCTS);

  const [productGroupFormOpen, setProductGroupFormOpen] = useState<boolean>(false);
  const [productGroupToUpdate, setProductGroupToUpdate] = useState<ProductGroup>();

  const [productFormOpen, setProductFormOpen] = useState<boolean>(false);
  const [productToUpdate, setProductToUpdate] = useState<Product>();

  useOnMount(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);

    const paramTab = urlParams.get("tab") ?? tabKey;

    const tab = Object.values(TAB).includes(paramTab) ? paramTab : TAB.PRODUCTS;

    setTabKey(tab);

    switch (tab) {
      case TAB.PRODUCT_GROUPS:
        props.actions.filterProductGroups({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword") ?? undefined
        });
        break;
      case TAB.PRODUCTS:
        props.actions.filterProducts({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword") ?? undefined,
          sectors: urlParams.getAll("sectors") as ProductFinancialSector[],
          groupIds: urlParams.getAll("groupIds")
        });
        break;
    }
    return () => {
      props.actions.deleteStateProductGroupsPage();
      props.actions.deleteStateProductsPage();
    };
  });

  const handleTabKeyChange = (tab: string): void => {
    navigate(
      appendSearchParamsToURL({
        pageIndex: undefined,
        keyword: undefined,
        sectors: undefined,
        groupIds: undefined,
        tab
      }),
      {
        replace: true
      }
    );

    setTabKey(tab);

    switch (tab) {
      case TAB.PRODUCT_GROUPS:
        props.actions.filterProductGroups({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: undefined
        });
        break;
      case TAB.PRODUCTS:
        props.actions.filterProducts({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: undefined,
          sectors: [],
          groupIds: []
        });
        break;
    }
  };

  const handleProductGroupsFilterSubmit = (filter: SearchPageRequest): void => {
    const tabParam = new URLSearchParams(props.urlSearchQuery).get("tab");
    navigate(
      appendSearchParamsToURL({
        pageIndex: undefined,
        keyword: !!filter.keyword ? filter.keyword : undefined,
        tab: !!tabParam ? tabParam : undefined
      }),
      { replace: true }
    );

    props.actions.filterProductGroups({
      pageIndex: 0,
      pageSize: props.productGroupsPage.pageSize,
      keyword: filter.keyword
    });
  };

  const handleProductGroupsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword } = props.productGroupsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterProductGroups({ pageIndex: pageNumber - 1, pageSize, keyword });
  };

  const handleProductGroupCreateClick = (): void => {
    setProductGroupFormOpen(true);
  };

  const handleProductGroupUpdateClick = (group: ProductGroup): void => {
    setProductGroupFormOpen(true);
    setProductGroupToUpdate(group);
  };

  const handleProductGroupFormCancel = (): void => {
    setProductGroupFormOpen(false);
    setProductGroupToUpdate(undefined);
  };

  const handleProductsFilterSubmit = (filter: ProductFilterPageRequest): void => {
    const tabParam = new URLSearchParams(props.urlSearchQuery).get("tab");
    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: undefined,
        keyword: !!filter.keyword ? filter.keyword : undefined,
        tab: !!tabParam ? tabParam : undefined
      }),
      { replace: true }
    );

    props.actions.filterProducts({
      pageIndex: 0,
      pageSize: props.productsPage.pageSize,
      keyword: filter.keyword,
      sectors: filter.sectors,
      groupIds: filter.groupIds
    });
  };

  const handleProductsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword, sectors, groupIds } = props.productsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterProducts({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      sectors,
      groupIds
    });
  };

  const handleProductCreateClick = (): void => {
    setProductFormOpen(true);
  };

  const handleProductUpdateClick = (product: Product): void => {
    setProductFormOpen(true);
    setProductToUpdate(product);
  };

  const handleProductFormCancel = (): void => {
    setProductFormOpen(false);
    setProductToUpdate(undefined);
  };

  return (
    <ContentWrapper>
      <Tabs
        className="tabs-box"
        activeKey={tabKey}
        onChange={handleTabKeyChange}
        items={[
          {
            key: TAB.PRODUCT_GROUPS,
            label: (
              <span>
                <AntIcon type="group" />
                {t("product.titles.productGroups")}
              </span>
            ),
            children: (
              <div className="margin-top-small">
                <ProductGroupFilterView
                  currentFilter={props.productGroupsPage}
                  onFilterSubmit={handleProductGroupsFilterSubmit}
                  onCreateClick={handleProductGroupCreateClick}
                />

                <ProductGroupTableView
                  productGroupsPage={props.productGroupsPage}
                  onPageChange={handleProductGroupsPageChange}
                  onUpdateClick={handleProductGroupUpdateClick}
                  onDelete={props.actions.deleteProductGroup}
                />

                <ProductGroupForm
                  open={productGroupFormOpen}
                  productGroup={productGroupToUpdate}
                  onCreate={props.actions.createProductGroup}
                  onUpdate={props.actions.updateProductGroup}
                  onFormCancel={handleProductGroupFormCancel}
                />
              </div>
            )
          },
          {
            key: TAB.PRODUCTS,
            label: (
              <span>
                <AntIcon type="unordered-list" />
                {t("product.titles.products")}
              </span>
            ),
            children: (
              <div className="margin-top-small">
                <ProductFilterView
                  currentFilter={props.productsPage}
                  onFilterSubmit={handleProductsFilterSubmit}
                  onCreateClick={handleProductCreateClick}
                />

                <ProductTableView
                  productsPage={props.productsPage}
                  onPageChange={handleProductsPageChange}
                  onUpdateClick={handleProductUpdateClick}
                  onDelete={props.actions.deleteProduct}
                />

                <ProductForm
                  open={productFormOpen}
                  product={productToUpdate}
                  onCreate={props.actions.createProduct}
                  onUpdate={props.actions.updateProduct}
                  onFormCancel={handleProductFormCancel}
                />
              </div>
            )
          }
        ]}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  productGroupsPage: selectProductGroupsPage(state),
  productsPage: selectProductsPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterProductGroups: filterProductGroupsActions.request,
      createProductGroup: createProductGroupActions.request,
      updateProductGroup: updateProductGroupActions.request,
      deleteProductGroup: deleteProductGroupActions.request,
      deleteStateProductGroupsPage: deleteStateProductGroupsPageAction,
      filterProducts: filterProductsActions.request,
      createProduct: createProductActions.request,
      updateProduct: updateProductActions.request,
      deleteProduct: deleteProductActions.request,
      deleteStateProductsPage: deleteStateProductsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ProductsContainer);
