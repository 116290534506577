import { gold, green, red } from "@ant-design/colors";
import { Card, Col, Empty, Popover, Progress, Row, Tooltip } from "antd";
import classNames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { rowGutter } from "../../../../../common/constants";
import { RootState } from "../../../../../common/types";
import { formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { toMoment } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { tInterval } from "../../../../../common/utils/translationUtils";
import { AgentCompetenceType } from "../../../../agent/enums";
import { TAB as USER_PROFILE_TAB } from "../../../../user/containers/UserProfileContainer";
import { CURRENT_USER_ROUTE_PATHS } from "../../../../user/paths";
import { requests } from "../../../api";
import { selectDashboardAgentCompetences } from "../../../ducks";
import { AgentCompetenceIntervalDashboard } from "../../../types";

export const DashboardAgentCompetencesWidget = () => {
  const { t } = useTranslation();

  const competenceIntervals = useSelector<RootState, AgentCompetenceIntervalDashboard[]>(
    selectDashboardAgentCompetences
  );

  const inProgress = useRequestFinishedCallback([requests.GET_DASHBOARD_AGENT_COMPETENCE]);

  const today = moment().startOf("day");

  return (
    <Card
      className="card-box dashboard-agent-competences__card"
      size="small"
      loading={inProgress}
      title={t("dashboard.competences.card")}
      extra={
        <Tooltip title={t("common.showMore")}>
          <span>
            <ActionTextIcon
              icon="export"
              path={`${CURRENT_USER_ROUTE_PATHS.profile.to}?tab=${USER_PROFILE_TAB.COMPETENCES}`}
              target="_blank"
            />
          </span>
        </Tooltip>
      }
    >
      {competenceIntervals?.length > 0 ? (
        <Row gutter={rowGutter}>
          <Col span={24} className="dashboard-agent-competences__body">
            {competenceIntervals.map((interval, index) => {
              const validForDays = toMoment(interval.endDate)?.startOf("day").diff(today, "days") ?? 0;
              const totalDays =
                toMoment(interval.endDate)?.startOf("day").diff(toMoment(interval.startDate)?.startOf("day"), "days") ??
                0;

              return (
                <Row gutter={rowGutter} key={index} className="dashboard-agent-competences__body__row">
                  <Col span={3}>
                    <Ellipsis>{t(`agent.competence.enums.competenceType.${interval.competence.type}`)}</Ellipsis>
                  </Col>

                  <Col span={9}>
                    {interval.competence.type === AgentCompetenceType.OTHER ? (
                      <Ellipsis>{interval.competence.label}</Ellipsis>
                    ) : (
                      <Ellipsis>
                        {t(`agent.enums.financialSector.${interval.competence.sector}`)} |{" "}
                        {t(`agent.competence.helpers.competenceLevel.${interval.competence.level}`)}
                      </Ellipsis>
                    )}
                  </Col>

                  <Col span={12}>
                    <Progress
                      percent={100 - Math.round((validForDays * 100) / totalDays)}
                      format={() => (
                        <Popover
                          placement="left"
                          content={
                            <>
                              <span>
                                {t("dashboard.competences.validity")}: {formatLocaleDate(interval.startDate)} -{" "}
                                {formatLocaleDate(interval.endDate)}
                              </span>
                              <br />
                              <span className="sub-header-info">
                                {t("dashboard.competences.remainingValidity")}:{" "}
                                {tInterval(
                                  "dashboard.competences.remainingDays_interval",
                                  validForDays >= 0 ? validForDays : 0
                                )}
                              </span>
                            </>
                          }
                        >
                          <span
                            className={classNames({
                              "dashboard-agent-competences__body__row__highlighted-days": validForDays <= 14
                            })}
                          >
                            {tInterval(
                              "dashboard.competences.remainingDays_interval",
                              validForDays >= 0 ? validForDays : 0
                            )}
                          </span>
                        </Popover>
                      )}
                      strokeColor={validForDays > 90 ? green[5] : validForDays > 14 ? gold[5] : red[4]}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      ) : (
        <Empty className="dashboard-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};
