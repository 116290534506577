import { Button, Card } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import ComponentWithPermission from "../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../../common/types";
import PartnerConfigForm from "../components/forms/PartnerConfigForm";
import PartnerSsoUsernameForm from "../components/forms/PartnerSsoUsernameForm";
import PartnerConfigListView from "../components/views/PartnerConfigListView";
import {
  deleteStatePartnerConfigsAndSsoPropertiesAction,
  getPartnerConfigsAndSsoPropsActions,
  getPartnerSsoActions,
  logoutFromPartnerSsoActions,
  replacePartnerConfigsActions,
  selectPartnerConfigsAndSsoProps,
  selectUsernameRequiredForSsoType,
  setUsernameRequiredForPartnerSsoTypeAction
} from "../ducks";
import { PartnerSsoType } from "../enums";
import { PartnerConfig, PartnerConfigsAndSsoProperties } from "../types";

interface StateProps {
  configsAndSsoProps?: PartnerConfigsAndSsoProperties;
  usernameRequiredForSsoType?: PartnerSsoType;
}

interface ActionsMap {
  getPartnerConfigsAndSsoProps: typeof getPartnerConfigsAndSsoPropsActions.request;
  replacePartnerConfigs: typeof replacePartnerConfigsActions.request;
  deleteStatePartnerConfigsAndSsoProperties: typeof deleteStatePartnerConfigsAndSsoPropertiesAction;
  getPartnerSso: typeof getPartnerSsoActions.request;
  logoutFromPartnerSso: typeof logoutFromPartnerSsoActions.request;
  setUsernameRequiredForPartnerSsoType: typeof setUsernameRequiredForPartnerSsoTypeAction;
}

const PartnerConfigsContainer = ({
  configsAndSsoProps,
  usernameRequiredForSsoType,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const [configFormOpen, setConfigFormOpen] = useState<boolean>(false);
  const [configsToUpdate, setConfigsToUpdate] = useState<PartnerConfig[]>();

  useEffect(() => {
    actions.getPartnerConfigsAndSsoProps();

    return () => {
      actions.deleteStatePartnerConfigsAndSsoProperties();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateClick = (): void => {
    setConfigFormOpen(true);
  };

  const handleLogoutClick = (configs: PartnerConfig[]): void => {
    const ssoTypesToLogout = configs.filter(config => config.ssoType).map(config => config.ssoType);
    if (ssoTypesToLogout.length) {
      actions.logoutFromPartnerSso({ ssoTypes: ssoTypesToLogout });
    }
  };

  const handleUpdateClick = (configs: PartnerConfig[]): void => {
    setConfigFormOpen(true);
    setConfigsToUpdate(configs);
  };

  const handleFormCancel = (): void => {
    setConfigFormOpen(false);
    setConfigsToUpdate(undefined);
  };

  const handlePartnerConfigsDelete = (configs: PartnerConfig[]): void => {
    actions.replacePartnerConfigs({ prevConfigs: configs, newConfigs: undefined });
  };

  const handleSsoUsernameFormSubmit = (ssoType: PartnerSsoType, username: string): void => {
    actions.setUsernameRequiredForPartnerSsoType(undefined);
    actions.getPartnerSso({ ssoType, username });
  };

  const handleSsoUsernameFormCancel = (): void => {
    actions.setUsernameRequiredForPartnerSsoType(undefined);
  };

  return (
    <>
      <ContentWrapper>
        <DisplayWrapper itemLoaded={!!configsAndSsoProps}>
          {configsAndSsoProps && (
            <Card
              className="card-box"
              title={<h2>{t("partnerConfig.titles.list")}</h2>}
              extra={
                <ComponentWithPermission permissions={[Permission.ADMIN_PARTNER_CONFIGS]}>
                  <Button type="primary" icon={<AntIcon type="plus" />} onClick={handleCreateClick}>
                    {t("common.add")}
                  </Button>
                </ComponentWithPermission>
              }
            >
              <PartnerConfigListView
                configList={configsAndSsoProps.configs}
                onPartnerSsoGet={actions.getPartnerSso}
                onLogoutClick={handleLogoutClick}
                onUpdateClick={handleUpdateClick}
                onDeleteClick={handlePartnerConfigsDelete}
              />
            </Card>
          )}
        </DisplayWrapper>

        <PartnerConfigForm
          configsAndSsoProps={configsAndSsoProps}
          open={configFormOpen}
          configs={configsToUpdate}
          onFormSubmit={actions.replacePartnerConfigs}
          onFormCancel={handleFormCancel}
        />
      </ContentWrapper>

      <PartnerSsoUsernameForm
        open={!!usernameRequiredForSsoType}
        ssoType={usernameRequiredForSsoType}
        onFormSubmit={handleSsoUsernameFormSubmit}
        onFormCancel={handleSsoUsernameFormCancel}
      />
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  configsAndSsoProps: selectPartnerConfigsAndSsoProps(state),
  usernameRequiredForSsoType: selectUsernameRequiredForSsoType(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getPartnerConfigsAndSsoProps: getPartnerConfigsAndSsoPropsActions.request,
      replacePartnerConfigs: replacePartnerConfigsActions.request,
      deleteStatePartnerConfigsAndSsoProperties: deleteStatePartnerConfigsAndSsoPropertiesAction,
      getPartnerSso: getPartnerSsoActions.request,
      logoutFromPartnerSso: logoutFromPartnerSsoActions.request,
      setUsernameRequiredForPartnerSsoType: setUsernameRequiredForPartnerSsoTypeAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PartnerConfigsContainer);
