import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import { ExportFileType } from "../../../common/enums";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import type { UUID } from "../../../typings/global";
import { selectRouterLocationSearch } from "../../ducks";
import AgentListFilterView from "../components/views/list/AgentListFilterView";
import AgentListTableView from "../components/views/list/AgentListTableView";
import {
  deleteStateAgentsPageAction,
  downloadAgentsExportActions,
  filterAgentsActions,
  selectAgentsCurrentPage
} from "../ducks";
import { AgentFilterPageRequest, AgentFilterPageResult } from "../types";

interface StateProps {
  agentsCurrentPage: AgentFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterAgents: typeof filterAgentsActions.request;
  downloadAgentsExport: typeof downloadAgentsExportActions.request;
  deleteStateAgentsPage: typeof deleteStateAgentsPageAction;
}

const AgentListContainer = ({ agentsCurrentPage, urlSearchQuery, actions }: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterAgents({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.HUGE,
      keyword: urlParams.get("keyword") ?? undefined,
      commissionsSettingsLevelIds: urlParams.getAll("commissionsSettingsLevelIds") as UUID[],
      agentCreatedAtDateMin: urlParams.get("agentCreatedAtDateMin") ?? undefined,
      rootAgentId: urlParams.get("rootAgentId") as UUID,
      onlyDirectSubordinates: urlParams.get("onlyDirectSubordinates") === "true" ?? undefined,
      includeDeactivated: urlParams.get("includeDeactivated") === "false" ? false : undefined,
      includeRepresentatives: urlParams.get("includeRepresentatives") === "false" ? false : undefined,
      includeNonGainers: urlParams.get("includeNonGainers") === "false" ? false : undefined
    });
    return () => {
      actions.deleteStateAgentsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: AgentFilterPageRequest): void => {
    const urlParams = {
      ...filter,
      keyword: filter.keyword !== "" ? filter.keyword : undefined,
      onlyDirectSubordinates: filter.onlyDirectSubordinates || undefined,
      includeDeactivated: filter.includeDeactivated ? undefined : false,
      includeRepresentatives: filter.includeRepresentatives ? undefined : false,
      includeNonGainers: filter.includeNonGainers ? undefined : false
    } as AgentFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...urlParams, pageIndex: undefined } as Record<string, any>), { replace: true });
    actions.filterAgents({
      ...urlParams,
      pageIndex: 0,
      pageSize: agentsCurrentPage.pageSize
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const {
      pageSize,
      keyword,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers
    } = agentsCurrentPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterAgents({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers
    });
  };

  const handleExportClick = (exportFileType: ExportFileType): void => {
    const {
      keyword,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers
    } = agentsCurrentPage;

    actions.downloadAgentsExport({
      keyword,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers,
      exportFileType
    });
  };

  return (
    <ContentWrapper>
      <AgentListFilterView filter={agentsCurrentPage} onFilterSubmit={handleFilterSubmit} />
      <AgentListTableView
        agentsPage={agentsCurrentPage}
        onPageChange={handleTablePageChange}
        onExportClick={handleExportClick}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  agentsCurrentPage: selectAgentsCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterAgents: filterAgentsActions.request,
      downloadAgentsExport: downloadAgentsExportActions.request,
      deleteStateAgentsPage: deleteStateAgentsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AgentListContainer);
