import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import { FormInstance } from "antd/lib/form";
import { InputNumberProps } from "antd/lib/input-number";
import { SelectProps } from "antd/lib/select";
import { SortOrder } from "antd/lib/table/interface";
import { flatten } from "flat";
import { AsYouType } from "libphonenumber-js";
import at from "lodash/at";
import has from "lodash/has";
import moment, { Moment } from "moment";
import { FieldData, NamePath, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import latinise from "voca/latinise";
import t, { DEFAULT_LOCALE } from "../../app/i18n";
import { deleteStateValidationErrorResponseAction, selectAnyValidationErrorResponse } from "../../modules/ducks";
import { InstitutionWithSettings, ProductGroupWithProducts } from "../../modules/enumerations/types";
import { InstitutionType } from "../../modules/institution/enums";
import { ValidationErrorResponse } from "../../modules/types";
import type { UUID } from "../../typings/global";
import type { ApiRequest } from "../api/ApiRequestAdapter";
import { OrderDirection } from "../enums";
import type { FieldConstraintViolation, RootState } from "../types";
import { formatLicensePlate } from "./formatUtils";
import { tValidationMessage } from "./translationUtils";
import { ALL_WHITE_SPACES_PATTERN, isLocalhostDevMode, isNotEmptyArray, parseBirthDateFromPin } from "./utils";
import { regexPatterns, validationFunctions } from "./validationUtils";

const ISO_8601_DATE_FORMAT = "YYYY-MM-DD";
const ISO_8601_DATE_TIME_FORMAT = moment.defaultFormat;

const NON_DIGIT_CHARACTERS_REGEX = new RegExp("[^\\d]", "g");
const EVERY_FOURTH_CHAR_PATTERN = new RegExp(/(.{4})/, "g");
const EVERY_THIRD_CHAR_PATTERN = new RegExp(/(.{3})/, "g");
const BEGINNING_END_WHITE_SPACES_PATTER = new RegExp(/(^\s+|\s+$)/, "g");

export const useFormErrorHandler = (
  form: FormInstance,
  translationRootPath: string,
  requests: ApiRequest[]
): ValidationErrorResponse | undefined => {
  const dispatch = useDispatch();
  const { onErrorResponseDelete } = useMemo(
    () =>
      bindActionCreators(
        {
          onErrorResponseDelete: deleteStateValidationErrorResponseAction
        },
        dispatch
      ),
    [dispatch]
  );

  const errorResponse = useSelector<RootState, ValidationErrorResponse | undefined>(state =>
    selectAnyValidationErrorResponse(state, requests)
  );

  useEffect(() => {
    if (errorResponse) {
      setErrorsToForm(form, translationRootPath, errorResponse.violations);
      onErrorResponseDelete();
    }
  }, [form, translationRootPath, errorResponse, onErrorResponseDelete]);

  return errorResponse;
};

export const setErrorsToForm = (
  form: FormInstance,
  translationRootPath: string,
  violations?: FieldConstraintViolation[]
): void => {
  if (violations && isNotEmptyArray(violations)) {
    const fieldsValues = form.getFieldsValue();
    const errorFields: FieldData[] = [];

    violations.forEach((violation: FieldConstraintViolation): void => {
      const fieldValue = at(fieldsValues, violation.fieldPath)[0];
      if (
        has(fieldsValues, violation.fieldPath) &&
        (fieldValue instanceof moment ||
          Array.isArray(fieldValue) ||
          typeof fieldValue !== "object" ||
          fieldValue === null ||
          fieldValue === undefined)
      ) {
        errorFields.push({
          name: fieldPathToNamePath(violation.fieldPath),
          errors: violation.errors.map<string>(errorMessage => tValidationMessage(translationRootPath, errorMessage))
        });
      }
    });

    if (isNotEmptyArray(errorFields)) {
      form.setFields(errorFields);
    }
  }
};

export const fieldPathToNamePath = (fieldPath: string): NamePath => {
  return fieldPath
    .replaceAll("[", ".")
    .replaceAll("]", "")
    .split(".")
    .map(token => (regexPatterns.numberRegex.test(token) ? parseInt(token) : token));
};

export const getAllFieldsNames = (form: FormInstance): (string | number)[][] => {
  return Object.keys(
    flatten(
      JSON.parse(JSON.stringify(form.getFieldsValue(), (_, value) => (typeof value === "undefined" ? null : value)))
    )
  ).map(key => key.split(".").map(token => (regexPatterns.numberRegex.test(token) ? parseInt(token) : token)));
};

export const resolveFormValidationError = (errorInfo: ValidateErrorEntity): void => {
  if (isLocalhostDevMode()) {
    console.warn("Form validation error caught.", errorInfo);
  }
};

export const toMoment = (input?: string | Moment): Moment | undefined => {
  if (!input) {
    return;
  }
  const date = moment(input);
  return date.isValid() ? date : undefined;
};

export const toMomentArray = (inputs: string[]): Moment[] | undefined => {
  if (!inputs || inputs.length === 0 || inputs.filter(input => !!input).length === 0) {
    return undefined;
  }

  return inputs.map(input => toMoment(input)).filter((input): input is Moment => !!input);
};

export const momentToIsoDateString = (date: Moment): string => {
  return date.format(ISO_8601_DATE_FORMAT);
};

export const momentToIsoDateTimeString = (date: Moment): string => {
  return date.format(ISO_8601_DATE_TIME_FORMAT);
};

//TODO will be removed when BE java will be upgraded
export const momentToUtcIsoDateTimeString = (date: Moment): string => {
  return moment(date).utc().format(ISO_8601_DATE_TIME_FORMAT);
};

export const getDatePickerFormat = (): string[] => [
  moment.localeData().longDateFormat("L"),
  moment.localeData().longDateFormat("l"),
  "DD. MM. YYYY",
  "D. M. YYYY"
];

export const getDateTimePickerFormat = (): string =>
  moment.localeData().longDateFormat("L") + " " + moment.localeData().longDateFormat("LTS");

export const datePickerStandardProps: PickerDateProps<Moment> = {
  format: getDatePickerFormat(),
  placeholder: "",
  allowClear: false
};

export const datePickerClearableProps: PickerDateProps<Moment> = {
  ...datePickerStandardProps,
  allowClear: true
};

export const datePickerDefaultRanges: Record<string, [Moment, Moment]> = {
  [t("common.today")]: [moment().startOf("day"), moment().endOf("day")],
  [t("common.thisWeek")]: [moment().startOf("week"), moment().endOf("week")],
  [t("common.thisMonth")]: [moment().startOf("month"), moment().endOf("month")],
  [t("common.lastMonth")]: [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month")
  ]
};

export const datePickerDefaultRangesUntilToday: Record<string, [Moment, Moment]> = {
  [t("common.today")]: [moment().startOf("day"), moment().endOf("day")],
  [t("common.thisWeek")]: [moment().startOf("week"), moment().endOf("day")],
  [t("common.thisMonth")]: [moment().startOf("month"), moment().endOf("day")],
  [t("common.lastMonth")]: [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month")
  ]
};

export const dateTimePickerStandardProps: PickerDateProps<Moment> = {
  format: getDateTimePickerFormat(),
  placeholder: "",
  allowClear: false
};

export const dateTimePickerClearableProps: PickerDateProps<Moment> = {
  ...dateTimePickerStandardProps,
  allowClear: true
};

export const disableDatePickerPresentAndFuture = (checked: Moment): boolean => {
  return checked && checked.isSameOrAfter(moment(), "day");
};

export const disableDatePickerFuture = (checked: Moment): boolean => {
  return checked && checked.isAfter(moment(), "day");
};

export const disableDatePickerPresentAndPast = (checked: Moment): boolean => {
  return checked && checked.isSameOrBefore(moment(), "day");
};

export const disableDatePickerPast = (checked: Moment): boolean => {
  return checked && checked.isBefore(moment(), "day");
};

export const disableDatePickerPresent = (checked: Moment): boolean => {
  return checked && checked.isSame(moment(), "day");
};

export const disableDatePickerOutOfInterval = (checked: Moment, min: Moment, max: Moment): boolean => {
  return checked && (checked.isBefore(min, "day") || checked.isAfter(max, "day"));
};

export const disableDatePickerOutOfMinDate = (checked: Moment, min: Moment): boolean => {
  return checked && checked.isBefore(min, "day");
};

export const disableDatePickerOutOfMinDateIncluded = (checked: Moment, min: Moment): boolean => {
  return checked && checked.isSameOrBefore(min, "day");
};

export const disableDatePickerOutOfMaxDate = (checked: Moment, max: Moment): boolean => {
  return checked && checked.isAfter(max, "day");
};

export const disableDatePickerOutOfMaxDateIncluded = (checked: Moment, max: Moment): boolean => {
  return checked && checked.isSameOrAfter(max, "day");
};

export const selectFilterFunction = (input: string, option?: DefaultOptionType): boolean => {
  if (!option) {
    return false;
  }

  return (
    latinise((option.label?.toString() || option.children?.toString())?.toLowerCase()).indexOf(
      latinise(input.toLowerCase())
    ) !== -1
  );
};

export const selectTagsFilterFunction = (
  input: string,
  translationsMap: Map<string, string>,
  option?: DefaultOptionType
): boolean => {
  if (!option) {
    return false;
  }

  return (
    latinise((translationsMap.get(option.value as string) || "").toLowerCase()).indexOf(
      latinise(input.toLowerCase())
    ) !== -1
  );
};

export const selectStandardProps: SelectProps = {
  showSearch: true,
  filterOption: selectFilterFunction,
  dropdownMatchSelectWidth: false
};

export const selectTagsStandardProps = (translationsMap: Map<string, string>): SelectProps => ({
  showSearch: true,
  filterOption: (inputValue, option) => selectTagsFilterFunction(inputValue, translationsMap, option),
  dropdownMatchSelectWidth: false
});

export const treeNodeFilterFunction = (input: string, treeNode: Record<string, any>): boolean => {
  return latinise(treeNode["title"].toLowerCase()).indexOf(latinise(input.toLowerCase())) !== -1;
};

export const mapInstitutionTreeSelectValuesToInstitutionIds = (
  treeSelectValues: string[],
  institutionsEnums: InstitutionWithSettings[]
): UUID[] => {
  const institutionTypes = Object.keys(InstitutionType);
  return treeSelectValues
    ?.map(idOrType =>
      institutionTypes.includes(idOrType)
        ? institutionsEnums
            .filter(institution => institution.type === idOrType && !institution.settings.deactivated)
            .map(institution => institution.id)
        : [idOrType]
    )
    .flat();
};

export const mapProductTreeSelectValuesToProductIds = (
  treeSelectValues: UUID[],
  productGroupsEnums: ProductGroupWithProducts[]
): UUID[] => {
  return treeSelectValues
    ?.map(id => productGroupsEnums.find(group => group.id === id)?.products.map(product => product.id) || [id])
    .flat();
};

// noinspection JSUnusedGlobalSymbols
export const inputNumberIntegerStandardProps: InputNumberProps = {
  formatter: value => {
    if (!value) {
      return "";
    }
    if (value === "-") {
      return "-";
    }
    // @ts-ignore
    return Intl.NumberFormat(DEFAULT_LOCALE).format(value);
  },
  parser: displayValue =>
    displayValue ? (displayValue.startsWith("-") ? "-" : "") + displayValue.replace(NON_DIGIT_CHARACTERS_REGEX, "") : ""
};

// noinspection JSUnusedGlobalSymbols
export const inputNumberDecimalStandardProps: InputNumberProps = {
  style: { width: "100%" },
  formatter: value => {
    if (!value) {
      return "";
    }
    if (value === "-") {
      return "-";
    }
    // @ts-ignore
    return Intl.NumberFormat(DEFAULT_LOCALE).format(value);
  },
  precision: 2,
  decimalSeparator: ","
};

export const threeSpacedStringNormalizeFunction = (input: string): string => {
  return input
    ? input
        .replace(ALL_WHITE_SPACES_PATTERN, "")
        .replace(EVERY_THIRD_CHAR_PATTERN, "$1 ")
        .replace(BEGINNING_END_WHITE_SPACES_PATTER, "")
    : input;
};

export const fourSpacedStringNormalizeFunction = (input: string): string => {
  return input
    ? input
        .replace(ALL_WHITE_SPACES_PATTERN, "")
        .replace(EVERY_FOURTH_CHAR_PATTERN, "$1 ")
        .replace(BEGINNING_END_WHITE_SPACES_PATTER, "")
    : input;
};

export const upperCaseStringNormalizeFunction = (input: string): string => (input ? input.toUpperCase() : input);

export const licensePlateNormalizeFunction = (input: string): string => formatLicensePlate(input);

export const phoneNumberNormalizeFunction = (value: string): string =>
  value ? new AsYouType("SK").input(value) : value;

export const ibanNormalizeFunction = (input: string): string =>
  input ? fourSpacedStringNormalizeFunction(input).toUpperCase() : input;

export const fillBirthDateFromPin = (pin: string, form: FormInstance, birthDateFormKey: string = "birthDate"): void => {
  if (!pin || !validationFunctions.validatePin(pin)) {
    form.setFieldsValue({ [birthDateFormKey]: undefined });
  } else {
    form.setFieldsValue({ [birthDateFormKey]: parseBirthDateFromPin(pin) });
  }
};

export const sortOrderToOrderDirection = (sortOrder: SortOrder): OrderDirection | undefined => {
  switch (sortOrder) {
    case "ascend":
      return OrderDirection.ASC;
    case "descend":
      return OrderDirection.DESC;
    default:
      return undefined;
  }
};

export const orderDirectionToSortOrder = (orderDirection?: OrderDirection): SortOrder | undefined => {
  if (!orderDirection) {
    return undefined;
  }
  switch (orderDirection) {
    case OrderDirection.ASC:
      return "ascend";
    case OrderDirection.DESC:
      return "descend";
    default:
      return undefined;
  }
};

export const quillEditorStandardProps: object = {
  theme: "snow",
  modules: {
    toolbar: [
      [{ size: ["small", false, "large"] }],
      ["bold", "italic", "underline", "strike", { color: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "clean"]
    ]
  },
  formats: [
    "header",
    "size",
    "code",
    "code-block",
    "blockquote",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "list",
    "bullet",
    "indent",
    "link"
  ]
};
