import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import t from "../../../../../../app/i18n";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import { selectStandardProps, selectTagsStandardProps } from "../../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../../../enumerations/components/form/InstitutionSelect";
import {
  CommissionOrigin,
  CommissionProcessingResult,
  PostponementReason,
  postponementReasonTMap
} from "../../../enums";
import { CommissionsBatch, CommissionsFilterPageRequest } from "../../../types";
import PostponementReasonTag from "../../PostponementReasonTag";

interface Props {
  batch?: CommissionsBatch;
  showCalculationFilterOptions?: boolean;
  onFilterSubmit: (filter: CommissionsFilterPageRequest) => void;
}

const BatchCalculatedCommissionFilterView = ({ batch, showCalculationFilterOptions, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<CommissionsFilterPageRequest>();

  const handleProcessingResultChange = (processingResult: CommissionProcessingResult): void => {
    if (processingResult !== CommissionProcessingResult.POSTPONED) {
      form.setFieldsValue({ postponementReasons: [] });
    }
  };

  const colSpan = 4;

  return (
    <Card
      title={t("commissions.batch.filter.title")}
      size="small"
      className="card-filter"
      style={{ maxWidth: batch ? "95%" : "1100px" }}
    >
      <Form form={form} layout="vertical" onFinish={onFilterSubmit} name="calculatedCommissionFilterForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("commissions.batch.helpers.commissionsSearchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="origin"
              label={t("commissions.batch.enums.commissionOrigin._label")}
              rules={[validations.none]}
              initialValue={CommissionOrigin.ALL}
            >
              <Select
                {...selectStandardProps}
                options={[CommissionOrigin.ALL, CommissionOrigin.CURRENT_BATCH, CommissionOrigin.PREVIOUS_BATCHES].map(
                  origin => ({
                    value: origin,
                    label: t("commissions.batch.enums.commissionOrigin." + origin)
                  })
                )}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionIds",
                label: t("common.institutions"),
                rules: [validations.none]
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
              optionsProps={{ groupByType: true }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="processingResult"
              label={t("commissions.batch.enums.commissionProcessingResult._label")}
              rules={[validations.none]}
              initialValue={CommissionProcessingResult.ALL}
            >
              <Select
                {...selectStandardProps}
                options={(showCalculationFilterOptions
                  ? [
                      CommissionProcessingResult.ALL,
                      CommissionProcessingResult.PROCESSED,
                      CommissionProcessingResult.WITH_UNMODIFIED_CALCULATION,
                      CommissionProcessingResult.WITH_MODIFIED_CALCULATION,
                      CommissionProcessingResult.WITH_CALCULATION_ERROR,
                      CommissionProcessingResult.POSTPONED
                    ]
                  : [
                      CommissionProcessingResult.ALL,
                      CommissionProcessingResult.PROCESSED,
                      CommissionProcessingResult.POSTPONED
                    ]
                ).map(processingResult => ({
                  value: processingResult,
                  label: t("commissions.batch.enums.commissionProcessingResult." + processingResult)
                }))}
                onChange={handleProcessingResultChange}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.processingResult !== next.processingResult}>
              {({ getFieldValue }) => (
                <Form.Item
                  name="postponementReasons"
                  label={t("commissions.batch.enums.postponementReason._label")}
                  rules={[validations.none]}
                >
                  <Select
                    {...selectTagsStandardProps(postponementReasonTMap)}
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    disabled={getFieldValue("processingResult") !== CommissionProcessingResult.POSTPONED}
                    tagRender={props => (
                      <PostponementReasonTag
                        reason={PostponementReason[props.value as keyof typeof PostponementReason]}
                        closable={props.closable}
                        onClose={props.onClose}
                      />
                    )}
                    options={Object.keys(PostponementReason).map(reason => ({
                      value: reason,
                      label: (
                        <PostponementReasonTag reason={PostponementReason[reason as keyof typeof PostponementReason]} />
                      )
                    }))}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col flex="110px">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BatchCalculatedCommissionFilterView;
