import { Button } from "antd";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { RootState } from "../../../../../common/types";
import { selectUserAccount } from "../../../../auth/ducks";
import { UserAccount } from "../../../../user/types";
import { changeContractVerificationStatusActions } from "../../../ducks";
import { ContractVerificationStatus } from "../../../enums";
import { Contract } from "../../../types";
import { isAgentOnContract } from "../../../utils";
import ContractChangeVerificationStatusForm from "../../forms/ContractChangeVerificationStatusForm";

interface Props {
  contract: Contract;
  hasChangePermission: boolean;
  onStatusChange: typeof changeContractVerificationStatusActions.request;
}

const ContractVerificationStatusButtonView = ({ contract, hasChangePermission, onStatusChange }: Props) => {
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const [statusFormOpen, setStatusFormOpen] = useState<boolean>(false);

  const agentIsOnContract = useMemo(
    () => userAccount && isAgentOnContract(userAccount, contract),
    [userAccount, contract]
  );

  if (
    contract.verificationStatus === ContractVerificationStatus.PAID ||
    contract.verificationStatus === ContractVerificationStatus.NOT_ACCEPTED
  ) {
    return null;
  } else if (hasChangePermission) {
    return (
      <>
        <Button
          className="secondary-button"
          icon={<AntIcon type="reconciliation" />}
          onClick={() => setStatusFormOpen(true)}
        >
          {t("contract.actions.verificationStatusChange")}
        </Button>

        <ContractChangeVerificationStatusForm
          open={statusFormOpen}
          contract={contract}
          onSubmit={onStatusChange}
          onFormCancel={() => setStatusFormOpen(false)}
        />
      </>
    );
  } else if (
    agentIsOnContract &&
    (contract.verificationStatus === ContractVerificationStatus.TYPED ||
      contract.verificationStatus === ContractVerificationStatus.INTERNAL_INTERVENTION ||
      contract.verificationStatus === ContractVerificationStatus.EXTERNAL_INTERVENTION)
  ) {
    return (
      <>
        <Button
          className="secondary-button"
          icon={<AntIcon type="file-done" />}
          onClick={() => setStatusFormOpen(true)}
        >
          {t("contract.actions.sendToBackoffice")}
        </Button>

        <ContractChangeVerificationStatusForm
          open={statusFormOpen}
          contract={contract}
          viewType="sendToBackoffice"
          onSubmit={onStatusChange}
          onFormCancel={() => setStatusFormOpen(false)}
        />
      </>
    );
  }

  return null;
};

export default ContractVerificationStatusButtonView;
