import { AutoComplete, Form } from "antd";
import { AutoCompleteProps } from "antd/lib/auto-complete";
import { FormItemProps } from "antd/lib/form";
import { Cities } from "../../../../common/constants";
import { selectFilterFunction } from "../../../../common/utils/formUtils";

interface Props {
  formItemProps: FormItemProps;
  autocompleteProps?: AutoCompleteProps<string>;
}

const CityAutoComplete = ({ formItemProps, autocompleteProps }: Props) => (
  <Form.Item {...formItemProps}>
    <AutoComplete
      {...autocompleteProps}
      dropdownMatchSelectWidth={false}
      options={Cities.map(city => ({ value: city, label: city }))}
      filterOption={selectFilterFunction}
    />
  </Form.Item>
);

export default CityAutoComplete;
