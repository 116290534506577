import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState, SearchPageRequest, SearchPageResult } from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import { AffiliatePartner, AffiliatePartnerReducerState, CreateUpdateAffiliatePartner } from "./types";

/**
 * ACTIONS
 */
export const filterAffiliatePartnersActions = createAsyncAction(
  "affiliate-partner/FILTER_REQUEST",
  "affiliate-partner/FILTER_SUCCESS",
  "affiliate-partner/FILTER_FAILURE"
)<SearchPageRequest, SearchPageResult<AffiliatePartner>, void>();

export const createAffiliatePartnerActions = createAsyncAction(
  "affiliate-partner/CREATE_REQUEST",
  "affiliate-partner/CREATE_SUCCESS",
  "affiliate-partner/CREATE_FAILURE"
)<CreateUpdateAffiliatePartner, AffiliatePartner, void>();

export const updateAffiliatePartnerActions = createAsyncAction(
  "affiliate-partner/UPDATE_REQUEST",
  "affiliate-partner/UPDATE_SUCCESS",
  "affiliate-partner/UPDATE_FAILURE"
)<EntityObject<CreateUpdateAffiliatePartner>, AffiliatePartner, void>();

export const deleteAffiliatePartnerActions = createAsyncAction(
  "affiliate-partner/DELETE_REQUEST",
  "affiliate-partner/DELETE_SUCCESS",
  "affiliate-partner/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateAffiliatePartnersPageAction = createAction("affiliate-partner/DELETE_STATE_LIST")<void>();

const actions = {
  filterAffiliatePartnersActions,
  createAffiliatePartnerActions,
  updateAffiliatePartnerActions,
  deleteAffiliatePartnerActions,
  deleteStateAffiliatePartnersPageAction
};

export type AffiliatePartnerAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: AffiliatePartnerReducerState = {
  currentPage: initSearchPageResult<AffiliatePartner>()
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterAffiliatePartnersActions.success, (_, { payload }) => payload)
  .handleAction(updateAffiliatePartnerActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterAffiliatePartnersActions.failure, deleteStateAffiliatePartnersPageAction],
    () => initialState.currentPage
  );

export const affiliatePartnerReducer = combineReducers<AffiliatePartnerReducerState>({
  currentPage: currentPageReducer
});

/**
 * SELECTORS
 */
const selectAffiliatePartner = (state: RootState): AffiliatePartnerReducerState => state.affiliatePartner;

export const selectAffiliatePartnersCurrentPage = (state: RootState): SearchPageResult<AffiliatePartner> =>
  selectAffiliatePartner(state).currentPage;

/**
 * SAGAS
 */
function* filterAffiliatePartners({ payload }: ReturnType<typeof filterAffiliatePartnersActions.request>) {
  try {
    const response: AxiosResponse<SearchPageResult<AffiliatePartner>> = yield call(
      api.filterAffiliatePartners,
      payload
    );
    yield put(filterAffiliatePartnersActions.success(response.data));
  } catch (error) {
    yield put(filterAffiliatePartnersActions.failure());
  }
}

function* createAffiliatePartner({ payload }: ReturnType<typeof createAffiliatePartnerActions.request>) {
  try {
    const response: AxiosResponse<AffiliatePartner> = yield call(api.createAffiliatePartner, payload);
    yield put(createAffiliatePartnerActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createAffiliatePartnerActions.failure());
  }
}

function* updateAffiliatePartner({ payload }: ReturnType<typeof updateAffiliatePartnerActions.request>) {
  try {
    const response: AxiosResponse<AffiliatePartner> = yield call(api.updateAffiliatePartner, payload);
    yield put(updateAffiliatePartnerActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateAffiliatePartnerActions.failure());
  }
}

function* deleteAffiliatePartner({ payload }: ReturnType<typeof deleteAffiliatePartnerActions.request>) {
  try {
    yield call(api.deleteAffiliatePartner, payload);
    yield put(deleteAffiliatePartnerActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();
    yield* refreshCurrentPage(true);
  } catch (error) {
    yield put(deleteAffiliatePartnerActions.failure());
  }
}

function* refreshCurrentPage(movePage?: boolean) {
  const currentPage: SearchPageResult<AffiliatePartner> = yield select(selectAffiliatePartnersCurrentPage);
  yield put(
    filterAffiliatePartnersActions.request({
      pageIndex: movePage
        ? currentPage.pageElementsCount === 1
          ? Math.max(currentPage.pageIndex - 1, 0)
          : currentPage.pageIndex
        : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      keyword: currentPage.keyword
    })
  );
}

export function* affiliatePartnerSaga() {
  yield takeLatest(filterAffiliatePartnersActions.request, filterAffiliatePartners);
  yield takeLatest(createAffiliatePartnerActions.request, createAffiliatePartner);
  yield takeLatest(updateAffiliatePartnerActions.request, updateAffiliatePartner);
  yield takeLatest(deleteAffiliatePartnerActions.request, deleteAffiliatePartner);
}
