import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAsyncAction } from "typesafe-actions";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject, TwoLevelEntityObject } from "../../../common/types";
import messageUtils from "../../../common/utils/messageUtils";
import { changeRunningRequestKeyAction } from "../../ducks";
import api from "./api";
import { CreateUpdateSpecialCommissionsSettingsRule, SpecialCommissionsSettingsRule } from "./types";

/**
 * ACTIONS
 */
export const createSpecialCommissionsRuleActions = createAsyncAction(
  "special-commissions-rule/CREATE_REQUEST",
  "special-commissions-rule/CREATE_SUCCESS",
  "special-commissions-rule/CREATE_FAILURE"
)<EntityObject<CreateUpdateSpecialCommissionsSettingsRule>, SpecialCommissionsSettingsRule, void>();

export const updateSpecialCommissionsRuleActions = createAsyncAction(
  "special-commissions-rule/UPDATE_REQUEST",
  "special-commissions-rule/UPDATE_SUCCESS",
  "special-commissions-rule/UPDATE_FAILURE"
)<TwoLevelEntityObject<CreateUpdateSpecialCommissionsSettingsRule>, SpecialCommissionsSettingsRule, void>();

export const deleteSpecialCommissionsRuleActions = createAsyncAction(
  "special-commissions-rule/DELETE_REQUEST",
  "special-commissions-rule/DELETE_SUCCESS",
  "special-commissions-rule/DELETE_FAILURE"
)<TwoLevelEntityIdObject, EntityIdObject, void>();

const actions = {
  createSpecialCommissionsRuleActions,
  updateSpecialCommissionsRuleActions,
  deleteSpecialCommissionsRuleActions
};

export type SpecialCommissionsSettingsAction = ActionType<typeof actions>;

/**
 * SAGAS
 */
function* createSpecialCommissionsRule({ payload }: ReturnType<typeof createSpecialCommissionsRuleActions.request>) {
  try {
    const response: AxiosResponse<SpecialCommissionsSettingsRule> = yield call(
      api.createSpecialCommissionsRule,
      payload
    );
    yield put(createSpecialCommissionsRuleActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch (error) {
    yield put(createSpecialCommissionsRuleActions.failure());
  }
}

function* updateSpecialCommissionsRule({ payload }: ReturnType<typeof updateSpecialCommissionsRuleActions.request>) {
  try {
    const response: AxiosResponse<SpecialCommissionsSettingsRule> = yield call(
      api.updateSpecialCommissionsRule,
      payload
    );
    yield put(updateSpecialCommissionsRuleActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateSpecialCommissionsRuleActions.failure());
  }
}

function* deleteSpecialCommissionsRule({ payload }: ReturnType<typeof deleteSpecialCommissionsRuleActions.request>) {
  try {
    yield call(api.deleteSpecialCommissionsRule, payload);
    yield put(deleteSpecialCommissionsRuleActions.success({ id: payload.id2 }));
    messageUtils.itemDeletedNotification();
  } catch (error) {
    yield put(deleteSpecialCommissionsRuleActions.failure());
  }
}

export function* specialCommissionsSettingsSaga() {
  yield takeLatest(createSpecialCommissionsRuleActions.request, createSpecialCommissionsRule);
  yield takeLatest(updateSpecialCommissionsRuleActions.request, updateSpecialCommissionsRule);
  yield takeLatest(deleteSpecialCommissionsRuleActions.request, deleteSpecialCommissionsRule);
}
