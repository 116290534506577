import { Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";

interface Props {
  hasPermission: boolean;
  anyNodeSelected: boolean;
  onFilesUpload: (file: RcFile, fileList: RcFile[]) => boolean;
  onMoveNodesClick: () => void;
  onCreateFolderClick: () => void;
  onDeleteNodesClick: () => void;
}

const DocumentNodeToolbarMenuView = ({
  hasPermission,
  anyNodeSelected,
  onFilesUpload,
  onMoveNodesClick,
  onCreateFolderClick,
  onDeleteNodesClick
}: Props) => {
  return (
    <div className="documents__menu">
      <ul className="documents__menu-box">
        {hasPermission ? (
          <>
            <li>
              <ActionTextIcon
                icon="new-folder"
                color="blue"
                text={t("documentNode.menu.createFolder")}
                onClick={onCreateFolderClick}
              />
            </li>

            <li>
              <Upload multiple beforeUpload={onFilesUpload} showUploadList={false}>
                <ActionTextIcon icon="cloud-upload" color="green" text={t("documentNode.menu.upload")} />
              </Upload>
            </li>

            <li>
              <ActionTextIcon
                icon="retweet"
                color="orange"
                text={t("documentNode.menu.move")}
                disabled={!anyNodeSelected}
                onClick={() => onMoveNodesClick()}
              />
            </li>

            <li>
              <ActionTextIcon
                icon="delete"
                color="red"
                text={t("documentNode.menu.delete")}
                disabled={!anyNodeSelected}
                onClick={() => onDeleteNodesClick()}
              />
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default DocumentNodeToolbarMenuView;
