import { Button, Card, Space } from "antd";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import ComponentWithPermission from "../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../common/security/authorization/enums";

interface Props {
  onImportClientsClick: () => void;
  onImportContractsClick: () => void;
}

const ContractUpdateFilterView = ({ onImportClientsClick, onImportContractsClick }: Props) => {
  return (
    <Card
      className="card-box"
      title={<h2>{t("contractUpdate.titles.list")}</h2>}
      extra={
        <Space size="small">
          <ComponentWithPermission permissions={[Permission.CLIENT_IMPORT]}>
            <Button className="secondary-button" icon={<AntIcon type="user-add" />} onClick={onImportClientsClick}>
              {t("client.actions.import")}
            </Button>
          </ComponentWithPermission>

          <ComponentWithPermission permissions={[Permission.CONTRACT_IMPORT]}>
            <Button className="secondary-button" icon={<AntIcon type="plus-circle" />} onClick={onImportContractsClick}>
              {t("contract.actions.import")}
            </Button>
          </ComponentWithPermission>
        </Space>
      }
    />
  );
};

export default ContractUpdateFilterView;
