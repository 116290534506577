import { blue, cyan, gold, green, lime, magenta, purple, red, volcano, yellow } from "@ant-design/colors";
import { ReactElement, useState } from "react";
import { Sector } from "recharts";
import { ChartActiveShapeRenderProps } from "../types";

export const chartColors = [
  blue[4],
  volcano[4],
  green[4],
  purple[4],
  gold[4],
  cyan[4],
  magenta[4],
  lime[7],
  red[5],
  yellow[5]
];

export const useChartActiveShapeRender = (): ChartActiveShapeRenderProps => {
  const [activeShapeIndex, setActiveShapeIndex] = useState<number>(0);

  const renderActiveShape = (props: any): ReactElement<SVGElement> => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill}>
          {payload.label}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill="#999">
          {`${(percent * 100).toFixed(2)}%`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#000000d9">
          {payload.formattedValue}
        </text>
      </g>
    );
  };

  return { activeShapeIndex, setActiveShapeIndex, renderActiveShape };
};
