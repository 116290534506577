import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import t from "../../app/i18n";
import { ContactType, MarketingConsent } from "../../common/modules/enums";
import { FieldConstraintViolation, RootState } from "../../common/types";
import { formatAggregatedName } from "../../common/utils/formatUtils";
import {
  deleteStateValidationErrorResponseAction,
  selectIsRequestInProgress,
  selectValidationErrorResponse
} from "../ducks";
import { ValidationErrorResponse } from "../types";
import { requests } from "./api";
import {
  deleteStateClientSearchResultAction,
  searchClientActions,
  selectClientSearchResult,
  validateClientActions,
  validateClientsActions
} from "./ducks";
import { ClientType } from "./enums";
import {
  Client,
  ClientSearchProps,
  ClientSearchResult,
  ClientsValidationProps,
  ClientValidationProps,
  CreateUpdateClient,
  CreateUpdateContractClient,
  CreateUpdateLegalClient,
  CreateUpdateNaturalClient,
  CreateUpdateSelfEmployedClient,
  LegalClient,
  NaturalClient,
  SelfEmployedClient
} from "./types";

export const useClientSearch = (): ClientSearchProps => {
  const result = useSelector<RootState, ClientSearchResult>(selectClientSearchResult);
  const inProgress = useSelector<RootState, boolean>(state => selectIsRequestInProgress(state, requests.SEARCH_CLIENT));

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onSearch: searchClientActions.request,
          onResultDelete: deleteStateClientSearchResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  return { result, inProgress, onSearch: actions.onSearch, onResultDelete: actions.onResultDelete };
};

export const useClientValidation = (): ClientValidationProps => {
  const errorResponse = useSelector<RootState, ValidationErrorResponse | undefined>(state =>
    selectValidationErrorResponse(state, requests.VALIDATE_CLIENT)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onValidate: validateClientActions.request,
          onErrorResponseDelete: deleteStateValidationErrorResponseAction
        },
        dispatch
      ),
    [dispatch]
  );

  return { errorResponse, onValidate: actions.onValidate, onErrorResponseDelete: actions.onErrorResponseDelete };
};

export const useClientsValidation = (): ClientsValidationProps => {
  const errorResponse = useSelector<RootState, ValidationErrorResponse | undefined>(state =>
    selectValidationErrorResponse(state, requests.VALIDATE_CLIENTS)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onValidate: validateClientsActions.request,
          onErrorResponseDelete: deleteStateValidationErrorResponseAction
        },
        dispatch
      ),
    [dispatch]
  );

  return { errorResponse, onValidate: actions.onValidate, onErrorResponseDelete: actions.onErrorResponseDelete };
};

export const createClientAggregatedName = (client: Client): string => {
  switch (client.type) {
    case ClientType.NATURAL:
      return createClientAggregatedNaturalName(client);
    case ClientType.SELF_EMPLOYED:
      return (client as SelfEmployedClient).companyName;
    case ClientType.LEGAL:
      return (client as LegalClient).companyName;
    default:
      return "";
  }
};

export const createClientAggregatedNaturalName = (client: Client): string => {
  const naturalClient = client as NaturalClient;
  return client.type !== ClientType.LEGAL
    ? formatAggregatedName({
        academicDegree: naturalClient.academicDegree,
        firstName: naturalClient.firstName,
        lastName: naturalClient.lastName,
        academicDegreeAfter: naturalClient.academicDegreeAfter
      })
    : "";
};

export const resolveClientIdentifier = (client: Client): string => {
  switch (client.type) {
    case ClientType.NATURAL:
      return (client as NaturalClient).personalIdentificationNumber;
    case ClientType.SELF_EMPLOYED:
      return (client as SelfEmployedClient).companyRegistrationNumber;
    case ClientType.LEGAL:
      return (client as LegalClient).companyRegistrationNumber;
    default:
      return "";
  }
};

export const resolveClientAddressLabel = (type: ClientType): string => {
  switch (type) {
    case ClientType.NATURAL:
      return t("client.attrs.addressLong");
    case ClientType.SELF_EMPLOYED:
      return t("client.attrs.addressLongFOP");
    case ClientType.LEGAL:
      return t("client.attrs.addressLongPO");
    default:
      return "";
  }
};

/**
 * @deprecated - use getCreateUpdateContractClientFromClient
 */
export const clientToCreateUpdateContractClient = (client: Client): CreateUpdateContractClient => {
  const clientData: Record<string, any> = { ...client };
  delete clientData.id;
  delete clientData.aggregatedName;
  delete clientData.aggregatedNaturalName;
  delete clientData.identifier;
  delete clientData.createdAt;
  delete clientData.updatedAt;
  delete clientData.createdAs;
  delete clientData.historyRecords;

  if (clientData.type === ClientType.LEGAL) {
    (clientData as LegalClient).representatives = [];
  }

  return { id: client.id, client: clientData as CreateUpdateClient };
};

export const createUpdateContractClientToClient = (
  createUpdateClient: CreateUpdateContractClient
): NaturalClient | SelfEmployedClient | LegalClient | undefined => {
  if (createUpdateClient) {
    const client: NaturalClient | SelfEmployedClient | LegalClient = Object.assign({}, createUpdateClient.client, {
      id: createUpdateClient.id
    }) as NaturalClient | SelfEmployedClient | LegalClient;
    client.identifier = resolveClientIdentifier(client);
    client.aggregatedName = createClientAggregatedName(client);

    if (client.type === ClientType.SELF_EMPLOYED) {
      (client as SelfEmployedClient).aggregatedNaturalName = createClientAggregatedNaturalName(client);
    }

    return client;
  }

  return undefined;
};

export const processClientFormValues = (formValues: CreateUpdateClient): CreateUpdateClient => {
  const processedValues = { ...formValues };

  processedValues.contacts =
    processedValues.contacts?.map(contact => ({
      ...contact,
      marketingConsent: contact.type !== ContactType.OTHER ? contact.marketingConsent : MarketingConsent.NOT_GRANTED,
      marketingConsentStartDate: contact.type !== ContactType.OTHER ? contact.marketingConsentStartDate : undefined,
      marketingConsentEndDate: contact.type !== ContactType.OTHER ? contact.marketingConsentEndDate : undefined
    })) || [];

  processedValues.correspondenceAddress = processedValues.correspondenceAddressEnabled
    ? processedValues.correspondenceAddress
    : undefined;
  delete processedValues.correspondenceAddressEnabled;

  if (processedValues.type === ClientType.LEGAL) {
    (processedValues as CreateUpdateLegalClient).representatives = [];
  }

  return processedValues;
};

export const processClientsDataViolations = <T>(
  clientsIndicesMap: Map<T, number>,
  pathPrefix: string,
  violations?: FieldConstraintViolation[]
): Map<T, FieldConstraintViolation[]> => {
  const violationsMap = new Map<T, FieldConstraintViolation[]>();

  if (violations) {
    violations
      .filter(violation => violation.fieldPath.startsWith(pathPrefix))
      .forEach(violation => {
        for (const [type, index] of clientsIndicesMap) {
          if (
            index ===
            parseInt(
              violation.fieldPath.substring(violation.fieldPath.indexOf("[") + 1, violation.fieldPath.indexOf("]"))
            )
          ) {
            const processedViolation = {
              ...violation,
              fieldPath: violation.fieldPath.substring(pathPrefix.length + 11, violation.fieldPath.length)
            };
            violationsMap.set(
              type,
              violationsMap.has(type)
                ? [...(violationsMap.get(type) as FieldConstraintViolation[]), processedViolation]
                : [processedViolation]
            );
            break;
          }
        }
      });
  }

  return violationsMap;
};

export const isNaturalClient = (client: NaturalClient | SelfEmployedClient | LegalClient): client is NaturalClient => {
  return client.type === ClientType.NATURAL;
};

export const isSelfEmployedClient = (
  client: NaturalClient | SelfEmployedClient | LegalClient
): client is SelfEmployedClient => {
  return client.type === ClientType.SELF_EMPLOYED;
};

export const isLegalClient = (client: NaturalClient | SelfEmployedClient | LegalClient): client is LegalClient => {
  return client.type === ClientType.LEGAL;
};

export const getCreateUpdateClientFromClient = (
  client: NaturalClient | SelfEmployedClient | LegalClient
): CreateUpdateNaturalClient | CreateUpdateSelfEmployedClient | CreateUpdateLegalClient => {
  if (isNaturalClient(client)) {
    return { ...client } as CreateUpdateNaturalClient;
  } else if (isSelfEmployedClient(client)) {
    return { ...client } as CreateUpdateSelfEmployedClient;
  } else if (isLegalClient(client)) {
    return {
      ...client,
      representatives: [
        ...(client.representatives?.map(clientRepresentative => ({
          ...clientRepresentative,
          representativeId: clientRepresentative.representative.id
        })) ?? [])
      ]
    } as CreateUpdateLegalClient;
  }

  return client as CreateUpdateNaturalClient | CreateUpdateSelfEmployedClient | CreateUpdateLegalClient;
};

export const getCreateUpdateContractClientFromClient = (
  client: NaturalClient | SelfEmployedClient | LegalClient
): CreateUpdateContractClient => {
  return {
    id: client.id,
    client: { ...getCreateUpdateClientFromClient(client) }
  };
};
