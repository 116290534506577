import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import ComplicityForm from "../components/forms/ComplicityForm";
import ComplicityFilterView from "../components/views/ComplicityFilterView";
import ComplicityTableView from "../components/views/ComplicityTableView";
import {
  createComplicityActions,
  deleteComplicityActions,
  deleteStateComplicitiesPageAction,
  filterComplicitiesActions,
  selectComplicitiesCurrentPage,
  updateComplicityActions
} from "../ducks";
import { Complicity, ComplicityFilterPageRequest, ComplicityFilterPageResult } from "../types";

interface StateProps {
  complicitiesCurrentPage: ComplicityFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterComplicities: typeof filterComplicitiesActions.request;
  createComplicity: typeof createComplicityActions.request;
  updateComplicity: typeof updateComplicityActions.request;
  deleteComplicity: typeof deleteComplicityActions.request;
  deleteStateComplicitiesPage: typeof deleteStateComplicitiesPageAction;
}

const ComplicityListContainer = ({
  complicitiesCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [complicityFormOpen, setComplicityFormOpen] = useState<boolean>(false);
  const [complicityToUpdate, setComplicityToUpdate] = useState<Complicity>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterComplicities({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      institutionIds: urlParams.getAll("institutionIds")
    });
    return () => {
      actions.deleteStateComplicitiesPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: ComplicityFilterPageRequest): void => {
    navigate(
      appendSearchParamsToURL({
        pageIndex: undefined,
        institutionIds: filter.institutionIds?.length ? filter.institutionIds : undefined
      }),
      { replace: true }
    );

    actions.filterComplicities({
      pageIndex: 0,
      pageSize: complicitiesCurrentPage.pageSize,
      institutionIds: filter.institutionIds
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, institutionIds } = complicitiesCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterComplicities({
      pageIndex: pageNumber - 1,
      pageSize,
      institutionIds
    });
  };

  const handleCreateClick = (): void => {
    setComplicityFormOpen(true);
  };

  const handleUpdateClick = (complicity: Complicity): void => {
    setComplicityFormOpen(true);
    setComplicityToUpdate(complicity);
  };

  const handleFormCancel = (): void => {
    setComplicityFormOpen(false);
    setComplicityToUpdate(undefined);
  };

  return (
    <ContentWrapper>
      <ComplicityFilterView
        initialFilter={complicitiesCurrentPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <ComplicityTableView
        complicitiesPage={complicitiesCurrentPage}
        onPageChange={handlePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={actions.deleteComplicity}
      />

      <ComplicityForm
        complicity={complicityToUpdate}
        open={complicityFormOpen}
        onCreate={actions.createComplicity}
        onUpdate={actions.updateComplicity}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  complicitiesCurrentPage: selectComplicitiesCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterComplicities: filterComplicitiesActions.request,
      createComplicity: createComplicityActions.request,
      updateComplicity: updateComplicityActions.request,
      deleteComplicity: deleteComplicityActions.request,
      deleteStateComplicitiesPage: deleteStateComplicitiesPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ComplicityListContainer);
