import { Card, Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment, { Moment } from "moment";
import t, { DEFAULT_LOCALE } from "../../../../app/i18n";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { ExportFileType } from "../../../../common/enums";
import {
  formatAgentIdNumber,
  formatLocaleBruttoPointsWithNullAsZero,
  formatLocaleDate,
  formatLocaleNettoPointsWithNullAsZero
} from "../../../../common/utils/formatUtils";
import { toMoment } from "../../../../common/utils/formUtils";
import { numberOrZero, paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import AgentTypeTagWithName from "../../../agent/components/AgentTypeTagWithName";
import { PointsReportType } from "../enums";
import { NettoAndBruttoPointsBalance, PointsReportFilterResult } from "../types";

interface Props {
  report: PointsReportFilterResult;
  onExportClick: (exportFileType: ExportFileType) => void;
}

interface PointsPeriod {
  label: string;
  startDate: string;
  endDate: string;
}

const today = formatLocaleDate(moment());
const currentMonthStartDate = formatLocaleDate(moment().startOf("month"));
const last3MonthsStartDate = formatLocaleDate(moment().subtract(2, "month").startOf("month"));
const last6MonthsStartDate = formatLocaleDate(moment().subtract(5, "month").startOf("month"));

const PointsReportTableView = ({ report, onExportClick }: Props) => {
  const columns: ColumnsType<NettoAndBruttoPointsBalance> = [
    {
      key: "agentName",
      title: t("common.agent"),
      fixed: "left",
      width: 220,
      ellipsis: { showTitle: false },
      render: (_, record) => <AgentTypeTagWithName agent={record.agent} hideIdNumber ellipsis showLink />
    },
    {
      key: "agentIdNumber",
      title: t("agent.attrs.idNumber"),
      fixed: "left",
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => formatAgentIdNumber(record.agent),
      sorter: (b1, b2) => {
        const b1AgentId = formatAgentIdNumber(b1.agent) ?? "";
        const b2AgentId = formatAgentIdNumber(b2.agent) ?? "";

        return b1AgentId.localeCompare(b2AgentId, DEFAULT_LOCALE, {
          sensitivity: "accent"
        });
      }
    },
    {
      key: "commissionsSettingsLevelCode",
      title: t("agent.helpers.commissionsSettingsLevel"),
      width: 110,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.agent.commissionsSettings.commissionsSettingsLevel?.code}</Ellipsis>
    }
  ];

  const periods: PointsPeriod[] = [];
  if (report.periodStartDate) {
    const maxPeriodEndDate = report.periodEndDate !== undefined ? toMoment(report.periodEndDate) : moment();

    let monthPeriodStartDate = toMoment(report.periodStartDate) as Moment;
    while (monthPeriodStartDate.isBefore(maxPeriodEndDate)) {
      const monthPeriodEndDate = moment(monthPeriodStartDate).endOf("month");
      periods.push({
        label: t("commissions.points.helpers.namedPeriod", { name: monthPeriodStartDate.format("MMMM YYYY") }),
        startDate: formatLocaleDate(monthPeriodStartDate) ?? "",
        endDate:
          formatLocaleDate(monthPeriodEndDate.isBefore(maxPeriodEndDate) ? monthPeriodEndDate : maxPeriodEndDate) ?? ""
      });
      monthPeriodStartDate = monthPeriodStartDate.startOf("month").add(1, "month");
    }
  } else {
    periods.push(
      {
        label: t("commissions.points.helpers.currentMonthPeriod"),
        startDate: currentMonthStartDate ?? "",
        endDate: today ?? ""
      },
      {
        label: t("commissions.points.helpers.last3MonthsPeriod"),
        startDate: last3MonthsStartDate ?? "",
        endDate: today ?? ""
      },
      {
        label: t("commissions.points.helpers.last6MonthsPeriod"),
        startDate: last6MonthsStartDate ?? "",
        endDate: today ?? ""
      }
    );
  }

  if (report.periodStartDate) {
    columns.push({
      key: "selectedPeriod",
      title: (
        <LabelWithTooltip
          label={t("commissions.points.helpers.selectedPeriod")}
          tooltip={t("commissions.points.helpers.productionPeriodDesc", {
            startDate: formatLocaleDate(report.periodStartDate),
            endDate: report.periodEndDate ? formatLocaleDate(report.periodEndDate) : today
          })}
        />
      ),
      children: [
        {
          key: "selectedPeriodIndividualPointAmount",
          title: t("commissions.points.helpers.individualPointAmount"),
          align: "right",
          width: 140,
          render: (_, record) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS ? (
              <>
                {formatLocaleNettoPointsWithNullAsZero(record.totalIndividualNettoPointAmount)}
                <br />
                {formatLocaleBruttoPointsWithNullAsZero(record.totalIndividualBruttoPointAmount)}
              </>
            ) : report.reportType === PointsReportType.NETTO_POINTS ? (
              formatLocaleNettoPointsWithNullAsZero(record.totalIndividualNettoPointAmount)
            ) : (
              formatLocaleBruttoPointsWithNullAsZero(record.totalIndividualBruttoPointAmount)
            ),
          sorter: (b1, b2) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS
              ? b1.totalIndividualNettoPointAmount - b2.totalIndividualNettoPointAmount ||
                b1.totalIndividualBruttoPointAmount - b2.totalIndividualBruttoPointAmount
              : report.reportType === PointsReportType.NETTO_POINTS
                ? b1.totalIndividualNettoPointAmount - b2.totalIndividualNettoPointAmount
                : b1.totalIndividualBruttoPointAmount - b2.totalIndividualBruttoPointAmount
        },
        {
          key: "selectedPeriodGroupPointAmount",
          title: t("commissions.points.helpers.groupPointAmount"),
          align: "right",
          width: 140,
          render: (_, record) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS ? (
              <>
                {formatLocaleNettoPointsWithNullAsZero(record.totalGroupNettoPointAmount)}
                <br />
                {formatLocaleBruttoPointsWithNullAsZero(record.totalGroupBruttoPointAmount)}
              </>
            ) : report.reportType === PointsReportType.NETTO_POINTS ? (
              formatLocaleNettoPointsWithNullAsZero(record.totalGroupNettoPointAmount)
            ) : (
              formatLocaleBruttoPointsWithNullAsZero(record.totalGroupBruttoPointAmount)
            ),
          sorter: (b1, b2) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS
              ? b1.totalGroupNettoPointAmount - b2.totalGroupNettoPointAmount ||
                b1.totalGroupBruttoPointAmount - b2.totalGroupBruttoPointAmount
              : report.reportType === PointsReportType.NETTO_POINTS
                ? b1.totalGroupNettoPointAmount - b2.totalGroupNettoPointAmount
                : b1.totalGroupBruttoPointAmount - b2.totalGroupBruttoPointAmount
        }
      ]
    });
  }

  periods.forEach((period, periodIndex) => {
    columns.push({
      key: `period${periodIndex}`,
      title: (
        <LabelWithTooltip
          label={period.label}
          tooltip={t("commissions.points.helpers.productionPeriodDesc", {
            startDate: period.startDate,
            endDate: period.endDate
          })}
        />
      ),
      children: [
        {
          key: `period${periodIndex}IndividualPointAmount`,
          title: t("commissions.points.helpers.individualPointAmount"),
          align: "right",
          width: 140,
          render: (_, record) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS ? (
              <>
                {formatLocaleNettoPointsWithNullAsZero(record.individualNettoPointAmountsByPeriod?.[periodIndex])}
                <br />
                {formatLocaleBruttoPointsWithNullAsZero(record.individualBruttoPointAmountsByPeriod?.[periodIndex])}
              </>
            ) : report.reportType === PointsReportType.NETTO_POINTS ? (
              formatLocaleNettoPointsWithNullAsZero(record.individualNettoPointAmountsByPeriod?.[periodIndex])
            ) : (
              formatLocaleBruttoPointsWithNullAsZero(record.individualBruttoPointAmountsByPeriod?.[periodIndex])
            ),
          sorter: (b1, b2) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS
              ? numberOrZero(b1.individualNettoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.individualNettoPointAmountsByPeriod?.[periodIndex]) ||
                numberOrZero(b1.individualBruttoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.individualBruttoPointAmountsByPeriod?.[periodIndex])
              : report.reportType === PointsReportType.NETTO_POINTS
                ? numberOrZero(b1.individualNettoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.individualNettoPointAmountsByPeriod?.[periodIndex])
                : numberOrZero(b1.individualBruttoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.individualBruttoPointAmountsByPeriod?.[periodIndex])
        },
        {
          key: `period${periodIndex}GroupPointAmount`,
          title: t("commissions.points.helpers.groupPointAmount"),
          align: "right",
          width: 140,
          render: (_, record) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS ? (
              <>
                {formatLocaleNettoPointsWithNullAsZero(record.groupNettoPointAmountsByPeriod?.[periodIndex])}
                <br />
                {formatLocaleBruttoPointsWithNullAsZero(record.groupBruttoPointAmountsByPeriod?.[periodIndex])}
              </>
            ) : report.reportType === PointsReportType.NETTO_POINTS ? (
              formatLocaleNettoPointsWithNullAsZero(record.groupNettoPointAmountsByPeriod?.[periodIndex])
            ) : (
              formatLocaleBruttoPointsWithNullAsZero(record.groupBruttoPointAmountsByPeriod?.[periodIndex])
            ),
          sorter: (b1, b2) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS
              ? numberOrZero(b1.groupNettoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.groupNettoPointAmountsByPeriod?.[periodIndex]) ||
                numberOrZero(b1.groupBruttoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.groupBruttoPointAmountsByPeriod?.[periodIndex])
              : report.reportType === PointsReportType.NETTO_POINTS
                ? numberOrZero(b1.groupNettoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.groupNettoPointAmountsByPeriod?.[periodIndex])
                : numberOrZero(b1.groupBruttoPointAmountsByPeriod?.[periodIndex]) -
                  numberOrZero(b2.groupBruttoPointAmountsByPeriod?.[periodIndex])
        }
      ]
    });
  });

  if (!report.periodStartDate) {
    columns.push({
      key: "total",
      title: t("commissions.points.helpers.totalPeriod"),
      children: [
        {
          key: "totalIndividualPointAmount",
          title: t("commissions.points.helpers.individualPointAmount"),
          align: "right",
          width: 140,
          render: (_, record) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS ? (
              <>
                {formatLocaleNettoPointsWithNullAsZero(record.totalIndividualNettoPointAmount)}
                <br />
                {formatLocaleBruttoPointsWithNullAsZero(record.totalIndividualBruttoPointAmount)}
              </>
            ) : report.reportType === PointsReportType.NETTO_POINTS ? (
              formatLocaleNettoPointsWithNullAsZero(record.totalIndividualNettoPointAmount)
            ) : (
              formatLocaleBruttoPointsWithNullAsZero(record.totalIndividualBruttoPointAmount)
            ),
          sorter: (b1, b2) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS
              ? b1.totalIndividualNettoPointAmount - b2.totalIndividualNettoPointAmount ||
                b1.totalIndividualBruttoPointAmount - b2.totalIndividualBruttoPointAmount
              : report.reportType === PointsReportType.NETTO_POINTS
                ? b1.totalIndividualNettoPointAmount - b2.totalIndividualNettoPointAmount
                : b1.totalIndividualBruttoPointAmount - b2.totalIndividualBruttoPointAmount
        },
        {
          key: "totalGroupPointAmount",
          title: t("commissions.points.helpers.groupPointAmount"),
          align: "right",
          width: 140,
          render: (_, record) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS ? (
              <>
                {formatLocaleNettoPointsWithNullAsZero(record.totalGroupNettoPointAmount)}
                <br />
                {formatLocaleBruttoPointsWithNullAsZero(record.totalGroupBruttoPointAmount)}
              </>
            ) : report.reportType === PointsReportType.NETTO_POINTS ? (
              formatLocaleNettoPointsWithNullAsZero(record.totalGroupNettoPointAmount)
            ) : (
              formatLocaleBruttoPointsWithNullAsZero(record.totalGroupBruttoPointAmount)
            ),
          sorter: (b1, b2) =>
            report.reportType === PointsReportType.NETTO_AND_BRUTTO_POINTS
              ? b1.totalGroupNettoPointAmount - b2.totalGroupNettoPointAmount ||
                b1.totalGroupBruttoPointAmount - b2.totalGroupBruttoPointAmount
              : report.reportType === PointsReportType.NETTO_POINTS
                ? b1.totalGroupNettoPointAmount - b2.totalGroupNettoPointAmount
                : b1.totalGroupBruttoPointAmount - b2.totalGroupBruttoPointAmount
        }
      ]
    });
  }

  return (
    <Card className="card-box">
      <div className={report.data.length > 0 ? "table-header-export-actions" : "margin-bottom-small"}>
        <ActionTextIcon
          icon="download"
          color="blue"
          text={t("common.exportXlsx")}
          onClick={() => onExportClick(ExportFileType.XLSX)}
        />

        <Divider type="vertical" />

        <ActionTextIcon
          icon="download"
          color="green"
          text={t("common.exportCsv")}
          onClick={() => onExportClick(ExportFileType.CSV)}
        />
      </div>
      <Table<NettoAndBruttoPointsBalance>
        key={`${report.reportType}-${report.periodStartDate}-${report.periodEndDate}`}
        {...tableStandardProps()}
        rowKey={record => record.agent.id}
        columns={columns}
        scroll={{ x: 710 + periods.length * 280 }}
        dataSource={report.data}
        pagination={{
          ...paginationTableProps,
          pageSize: 30,
          total: report.data.length
        }}
        showSorterTooltip={false}
      />
    </Card>
  );
};

export default PointsReportTableView;
