import Big from "big.js";
import moment, { Moment } from "moment";
import { bigToFloat, getClosestPreviousWorkDay, isNumberValue } from "../../common/utils/utils";
import { CalcType } from "../calculator/enums";
import {
  ClientDataProps,
  ClientsWithFormType,
  createClientsData
} from "../client/components/drawers/ClientsListDrawerView";
import { ClientFormType } from "../client/enums";
import { UserAccount } from "../user/types";
import {
  ContractStatus,
  ContractType,
  ContractVerificationStatus,
  InsurancePeriod,
  InsuranceType,
  LoanRateType,
  PaymentFrequency,
  PeriodType
} from "./enums";
import {
  Contract,
  ContractList,
  CreateUpdateContractGainerRecord,
  GenericInsurance,
  Insurance,
  InsuranceContract,
  LifeInsurance,
  RealtyInsurance,
  TravelInsurance,
  VehicleInsurance
} from "./types";

const INSURANCE_CONTRACT_CANCELLING_TERM_DAYS = 42;
const LOAN_CONTRACT_FIXATION_TERM_MONTHS = 3;

export const VEHICLE_INSURANCE_TYPES = [InsuranceType.MTPL, InsuranceType.CRASH, InsuranceType.GAP, InsuranceType.PAS];

type ParseVehicleModelNameData = {
  customBrand?: string;
  brandName: string;
  customModel?: string;
  modelName: string;
};

export const parseVehicleModelName = ({
  customBrand,
  brandName,
  modelName,
  customModel
}: ParseVehicleModelNameData): string => {
  return (customBrand || brandName) + " " + (customModel || modelName);
};

type CalculateContactStatusMethodType = {
  startDate: Moment;
  endDate?: Moment;
  cancellationDate: Moment;
  transferredToOtherBrokerDate?: Moment;
};

export const calculateContractStatus = ({
  startDate,
  endDate,
  cancellationDate,
  transferredToOtherBrokerDate
}: CalculateContactStatusMethodType): ContractStatus | undefined => {
  const today = moment();
  if (transferredToOtherBrokerDate?.isBefore(today, "day")) {
    return ContractStatus.TRANSFERRED_TO_BROKER;
  }
  if (cancellationDate?.isBefore(today, "day")) {
    return ContractStatus.CANCELED;
  }
  if (endDate?.isBefore(today, "day")) {
    return ContractStatus.FINISHED;
  }
  return startDate
    ? startDate.isSameOrBefore(today, "day")
      ? ContractStatus.ACTIVE
      : ContractStatus.UNSTARTED
    : undefined;
};

export const calculatePartialPremium = (
  annualPremium: number,
  paymentFrequency: PaymentFrequency
): number | undefined => {
  if (!isNumberValue(annualPremium) || !paymentFrequency) {
    return undefined;
  }

  switch (paymentFrequency) {
    case PaymentFrequency.ONCE:
    case PaymentFrequency.ANNUALLY:
      return annualPremium;
    case PaymentFrequency.SEMI_ANNUALLY:
      return bigToFloat(new Big(annualPremium).div(2));
    case PaymentFrequency.QUARTERLY:
      return bigToFloat(new Big(annualPremium).div(4));
    case PaymentFrequency.MONTHLY:
      return bigToFloat(new Big(annualPremium).div(12));
    default:
      return undefined;
  }
};

/**
 * Vypocet konca poistneho obdobia (KPO)
 */
export const calculateInsurancePeriodEndDate = (
  cancellationDate: Moment,
  periodType: PeriodType,
  effectiveEndDate: Moment,
  insurancePeriod: InsurancePeriod,
  effectiveBeginningDate: Moment,
  transferredToOtherBrokerDate: Moment,
  status?: ContractStatus
): Moment | undefined => {
  if (cancellationDate) {
    return moment(cancellationDate);
  }

  if (periodType) {
    switch (periodType) {
      case PeriodType.DEFINITE:
        if (effectiveEndDate) {
          return moment(effectiveEndDate);
        }
        break;
      case PeriodType.INDEFINITE:
        if (insurancePeriod && effectiveBeginningDate) {
          switch (insurancePeriod) {
            case InsurancePeriod.CALENDAR_YEAR:
              const d = effectiveBeginningDate.isAfter(moment(), "day") ? moment(effectiveBeginningDate) : moment();
              if (status) {
                if (status === ContractStatus.TRANSFERRED_TO_BROKER) {
                  if (transferredToOtherBrokerDate) {
                    const iped = d.endOf("year");
                    if (transferredToOtherBrokerDate.isSameOrBefore(iped, "day")) {
                      return iped.year(transferredToOtherBrokerDate.year());
                    } else {
                      return iped.year(transferredToOtherBrokerDate.year()).add(1, "year");
                    }
                  }
                } else {
                  return d.endOf("year");
                }
              }
              break;
            case InsurancePeriod.TECHNICAL_YEAR:
              if (effectiveBeginningDate.year() >= moment().year()) {
                return moment(effectiveBeginningDate).add(1, "year").subtract(1, "day");
              } else {
                if (status) {
                  if (status === ContractStatus.TRANSFERRED_TO_BROKER) {
                    if (transferredToOtherBrokerDate) {
                      const z = moment(effectiveBeginningDate)
                        .year(transferredToOtherBrokerDate.year())
                        .subtract(1, "day");
                      if (z.isBefore(transferredToOtherBrokerDate, "day")) {
                        return z.add(1, "year");
                      } else {
                        return z;
                      }
                    }
                  } else {
                    const z = moment(effectiveBeginningDate).year(moment().year()).subtract(1, "day");
                    if (z.isBefore(moment(), "day")) {
                      return z.add(1, "year");
                    } else {
                      return z;
                    }
                  }
                }
              }
              break;
          }
        }
        break;
    }
  }

  return undefined;
};

/**
 * Vypocet posledneho datumu na vypoved zmluvy (PDnVZ)
 */
export const calculateLastContractCancellationDate = (
  periodType: PeriodType,
  cancellationDate: Moment,
  transferredToOtherBrokerDate: Moment,
  effectiveBeginningDate: Moment,
  insurancePeriodEndDate?: Moment
): Moment | undefined => {
  if (periodType) {
    switch (periodType) {
      case PeriodType.DEFINITE:
        return undefined;
      case PeriodType.INDEFINITE:
        if (cancellationDate) {
          return undefined;
        } else if (transferredToOtherBrokerDate) {
          return undefined;
        } else if (effectiveBeginningDate && insurancePeriodEndDate) {
          const d = effectiveBeginningDate.isAfter(moment(), "day") ? moment(effectiveBeginningDate) : moment();
          const k = getClosestPreviousWorkDay(
            moment(insurancePeriodEndDate).subtract(INSURANCE_CONTRACT_CANCELLING_TERM_DAYS, "day")
          );

          if (k.isSameOrAfter(d, "day")) {
            return k;
          } else {
            return getClosestPreviousWorkDay(
              moment(insurancePeriodEndDate).add(1, "year").subtract(INSURANCE_CONTRACT_CANCELLING_TERM_DAYS, "day")
            );
          }
        }
        break;
    }
  }

  return undefined;
};

export const calculateFixationAnniversaryDate = (signDate: Moment, rateType: LoanRateType): Moment | undefined => {
  if (signDate && rateType) {
    switch (rateType) {
      case LoanRateType.FIXED_ONE_YEAR:
        return moment(signDate).add(1, "year");
      case LoanRateType.FIXED_TWO_YEARS:
        return moment(signDate).add(2, "year");
      case LoanRateType.FIXED_THREE_YEARS:
        return moment(signDate).add(3, "year");
      case LoanRateType.FIXED_FOUR_YEARS:
        return moment(signDate).add(4, "year");
      case LoanRateType.FIXED_FIVE_YEARS:
        return moment(signDate).add(5, "year");
      case LoanRateType.FIXED_SIX_YEARS:
        return moment(signDate).add(6, "year");
      case LoanRateType.FIXED_SEVEN_YEARS:
        return moment(signDate).add(7, "year");
      case LoanRateType.FIXED_EIGHT_YEARS:
        return moment(signDate).add(8, "year");
      case LoanRateType.FIXED_NINE_YEARS:
        return moment(signDate).add(9, "year");
      case LoanRateType.FIXED_TEN_YEARS:
        return moment(signDate).add(10, "year");
      case LoanRateType.FIXED_ELEVEN_YEARS:
        return moment(signDate).add(11, "year");
      case LoanRateType.FIXED_TWELVE_YEARS:
        return moment(signDate).add(12, "year");
      case LoanRateType.FIXED_THIRTEEN_YEARS:
        return moment(signDate).add(13, "year");
      case LoanRateType.FIXED_FOURTEEN_YEARS:
        return moment(signDate).add(14, "year");
      case LoanRateType.FIXED_FIFTEEN_YEARS:
        return moment(signDate).add(15, "year");
      case LoanRateType.FIXED_SIXTEEN_YEARS:
        return moment(signDate).add(16, "year");
      case LoanRateType.FIXED_SEVENTEEN_YEARS:
        return moment(signDate).add(17, "year");
      case LoanRateType.FIXED_EIGHTEEN_YEARS:
        return moment(signDate).add(18, "year");
      case LoanRateType.FIXED_NINETEEN_YEARS:
        return moment(signDate).add(19, "year");
      case LoanRateType.FIXED_TWENTY_YEARS:
        return moment(signDate).add(20, "year");
      case LoanRateType.VARIABLE:
        return undefined;
    }
  }
  return undefined;
};

export const calculateContactClientDate = (fixationAnniversaryDate?: Moment): Moment | undefined => {
  return fixationAnniversaryDate
    ? getClosestPreviousWorkDay(moment(fixationAnniversaryDate).subtract(LOAN_CONTRACT_FIXATION_TERM_MONTHS, "month"))
    : undefined;
};

export const calculateGainerRecordRatesSum = (record: CreateUpdateContractGainerRecord): Big => {
  return !!record.gainer2Id
    ? Array.from({ length: 5 }, (_, i) => i + 1)
        .map(
          i =>
            record[
              `gainer${i}Rate` as keyof Pick<
                CreateUpdateContractGainerRecord,
                "gainer1Rate" | "gainer2Rate" | "gainer3Rate" | "gainer4Rate" | "gainer5Rate"
              >
            ]
        )
        .map(rate => new Big(rate || 0))
        .reduce((r1, r2) => r1.plus(r2), new Big(0))
    : new Big(100);
};

export const isAgentOnContract = (account: UserAccount, contract: Contract | ContractList): boolean => {
  const ids =
    contract.gainerRecords
      ?.map(r => [r.gainer1?.id, r.gainer2?.id, r.gainer3?.id, r.gainer4?.id, r.gainer5?.id].filter(id => !!id))
      .flat() ?? [];

  const allAgentsIds = [contract.signer?.id, contract.manager?.id, ...ids];

  return allAgentsIds.includes(account.agent?.id) || allAgentsIds.includes(account.representingAgent?.id);
};

export const isContractVerified = (contract?: Contract): boolean => {
  return (
    contract?.verificationStatus === ContractVerificationStatus.VERIFIED ||
    contract?.verificationStatus === ContractVerificationStatus.PAID
  );
};

export const getClientsDataFromInsuranceContract = (contract: InsuranceContract): ClientDataProps[] | undefined => {
  if (contract.type !== ContractType.INSURANCE_CONTRACT) {
    return undefined;
  }

  const clientsWithFromType: ClientsWithFormType = [
    { type: ClientFormType.POLICY_HOLDER, client: contract.clients[contract.policyHolderIndex] }
  ];

  contract.insurances.forEach(insurance => {
    switch (insurance.type) {
      case InsuranceType.MTPL:
      case InsuranceType.CRASH:
      case InsuranceType.GAP:
      case InsuranceType.PAS:
        clientsWithFromType.push({
          type: ClientFormType.HOLDER,
          client: contract.clients[(insurance as VehicleInsurance).vehicleHolderIndex]
        });
        clientsWithFromType.push({
          type: ClientFormType.OWNER,
          client: contract.clients[(insurance as VehicleInsurance).vehicleOwnerIndex]
        });
        break;
      case InsuranceType.REALTY:
        clientsWithFromType.push({
          type: ClientFormType.INSURED,
          client: contract.clients[(insurance as RealtyInsurance).insuredClientIndex]
        });
        clientsWithFromType.push({
          type: ClientFormType.VINCULATION,
          client: contract.clients[(insurance as RealtyInsurance).vinculationClientIndex]
        });
        break;
      case InsuranceType.LIFE:
        clientsWithFromType.push({
          type: ClientFormType.INSURED,
          client: contract.clients[(insurance as LifeInsurance).insuredClientIndex]
        });
        break;
      case InsuranceType.GENERIC:
        clientsWithFromType.push({
          type: ClientFormType.INSURED,
          client: contract.clients[(insurance as GenericInsurance).insuredClientIndex]
        });
        break;
      default:
        break;
    }
  });

  return createClientsData(clientsWithFromType);
};

export const convertInsuranceTypeToCalcType = (insurance: Insurance): CalcType | undefined => {
  if (isVehicleInsurance(insurance)) {
    if (insurance.type === InsuranceType.MTPL) {
      return CalcType.MTPL;
    } else if (insurance.type === InsuranceType.CRASH) {
      return CalcType.CRASH;
    } else if (insurance.type === InsuranceType.GAP) {
      return CalcType.GAP;
    } else if (insurance.type === InsuranceType.PAS) {
      return CalcType.PAS;
    }
  } else if (isRealtyInsurance(insurance)) {
    return CalcType.REALTY;
  } else if (isTravelInsurance(insurance)) {
    return CalcType.TRAVEL;
  }

  return undefined;
};

export const isTravelInsurance = (
  insurance: TravelInsurance | RealtyInsurance | VehicleInsurance | GenericInsurance | LifeInsurance
): insurance is TravelInsurance => insurance.type === InsuranceType.TRAVEL;

export const isRealtyInsurance = (
  insurance: TravelInsurance | RealtyInsurance | VehicleInsurance | GenericInsurance | LifeInsurance
): insurance is RealtyInsurance => insurance.type === InsuranceType.REALTY;

export const isVehicleInsurance = (
  insurance: TravelInsurance | RealtyInsurance | VehicleInsurance | GenericInsurance | LifeInsurance
): insurance is VehicleInsurance =>
  insurance.type === InsuranceType.MTPL ||
  insurance.type === InsuranceType.CRASH ||
  insurance.type === InsuranceType.PAS ||
  insurance.type === InsuranceType.GAP;

export const isTravelOrRealtyOrVehicleInsurance = (
  insurance: TravelInsurance | RealtyInsurance | VehicleInsurance | GenericInsurance | LifeInsurance
): insurance is TravelInsurance | RealtyInsurance | VehicleInsurance =>
  isTravelInsurance(insurance) || isRealtyInsurance(insurance) || isVehicleInsurance(insurance);

export const isInsuranceContract = (contract: Contract): contract is InsuranceContract =>
  contract.type === ContractType.INSURANCE_CONTRACT;
