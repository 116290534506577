import { Card, Col, Row } from "antd";
import t from "../../../app/i18n";
import appStoreBadge from "../../../assets/images/apple-app-store-badge.svg";
import appStoreQrCode from "../../../assets/images/apple-app-store-qr-code.svg";
import playStoreBadge from "../../../assets/images/google-play-store-badge.png";
import playStoreQrCode from "../../../assets/images/google-play-store-qr-code.svg";
import { rowGutter } from "../../constants";
import ContentWrapper from "../../modules/wrappers/ContentWrapper";

const ClientLandingPage = () => {
  return (
    <ContentWrapper>
      <Card
        className="card-box client-landing-card"
        title={<div className="client-landing-card__title">{t("navigation.clientLandingPage.title")}</div>}
      >
        <Row gutter={rowGutter} justify="center" className="margin-bottom-medium">
          <Col>
            <p className="client-landing-card__app-info">{t("navigation.clientLandingPage.text1")}</p>
          </Col>
        </Row>
        <Row gutter={rowGutter} justify="center" className="margin-bottom-small">
          <Col>
            <p className="client-landing-card__download-info">{t("navigation.clientLandingPage.text2")}</p>
          </Col>
        </Row>
        <Row justify="center" className="margin-bottom-medium">
          <Col span={24} className="client-landing-card__store-badges">
            <Row gutter={rowGutter} justify="center">
              <Col span={12} className="client-landing-card__store-badges__google-play">
                <a href="https://play.google.com/store/apps/details?id=sk.itgo.cliip" target="_blank" rel="noreferrer">
                  <img src={playStoreBadge} alt="google-play-store-badge" />
                </a>
              </Col>
              <Col span={12} className="client-landing-card__store-badges__apple-store">
                <a href="https://apps.apple.com/sk/app/cliip/id1578258553?l=sk" target="_blank" rel="noreferrer">
                  <img src={appStoreBadge} alt="apple-app-store-badge" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={rowGutter} justify="center" className="margin-bottom-small">
          <Col>
            <p className="client-landing-card__qr-info">{t("navigation.clientLandingPage.text3")}</p>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24} className="client-landing-card__store-qr-codes">
            <Row gutter={rowGutter}>
              <Col span={12} className="client-landing-card__store-qr-codes__google-play">
                <img src={playStoreQrCode} alt="google-play-store-qr-code" />
              </Col>
              <Col span={12} className="client-landing-card__store-qr-codes__apple-store">
                <img src={appStoreQrCode} alt="apple-app-store-qr-code" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </ContentWrapper>
  );
};

export default ClientLandingPage;
