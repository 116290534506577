import { Tag } from "antd";
import t from "../../../../../../app/i18n";
import { DashboardNoticeType } from "../../../../enums";

interface Props {
  type: DashboardNoticeType;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const DashboardNoticeTypeTag = ({ type, closable, style, onClose }: Props) => {
  let color: string;

  switch (type) {
    case DashboardNoticeType.INFO:
      color = "blue";
      break;
    case DashboardNoticeType.WARNING:
      color = "orange";
      break;
    case DashboardNoticeType.ERROR:
      color = "red";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} closable={closable} style={{ width: "84px", textAlign: "center", ...style }} onClose={onClose}>
      {t("dashboard.notices.enums.type." + type)}
    </Tag>
  );
};
