import { Button, Card, PageHeader, Popconfirm, Space } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../common/types";
import { formatCommissionsLevelName, formatLocaleNumber } from "../../../../../../common/utils/formatUtils";
import { useBackNavigation } from "../../../../../../common/utils/hooksUtils";
import { tBoolean } from "../../../../../../common/utils/translationUtils";
import { selectHasPermissions } from "../../../../../auth/ducks";
import BailAccountSettingsForm from "../../../../bailaccount/components/forms/BailAccountSettingsForm";
import BailAccountSettingsView from "../../../../bailaccount/components/views/BailAccountSettingsView";
import { CreateUpdateBailAccountSettings } from "../../../../bailaccount/types";
import CommissionsSettingsRuleHelperView from "../../../../settings/components/views/CommissionsSettingsRuleHelperView";
import { CommissionsSettingsLevelType } from "../../../enums";
import {
  CommissionsSettingsLevel,
  CommissionsSettingsLevelActions,
  CommissionsSettingsLevelRule
} from "../../../types";
import CommissionsLevelTypeTag from "../../CommissionsLevelTypeTag";
import CommissionsLevelForm from "../../forms/CommissionsLevelForm";
import CommissionsLevelRuleForm from "../../forms/CommissionsLevelRuleForm";
import CommissionsLevelAttachmentsTableView from "./CommissionsLevelAttachmentsTableView";
import CommissionsLevelRuleTableView from "./CommissionsLevelRuleTableView";

interface Props {
  level: CommissionsSettingsLevel;
  actions: CommissionsSettingsLevelActions;
}

const CommissionsLevelDetailView = ({ level, actions }: Props) => {
  const onBackClick = useBackNavigation();

  const hasTopAgentCommissionsManagePermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.TOP_AGENT_COMMISSIONS_MANAGE)(state)
  );

  const [levelFormOpen, setLevelFormOpen] = useState<boolean>(false);

  const [ruleFormOpen, setRuleFormOpen] = useState<boolean>(false);
  const [ruleToUpdate, setRuleToUpdate] = useState<CommissionsSettingsLevelRule>();

  const [bailAccountSettingsFormOpen, setBailAccountSettingsFormOpen] = useState<boolean>(false);

  const handleLevelUpdateClick = (): void => {
    setLevelFormOpen(true);
  };

  const handleLevelFormCancel = (): void => {
    setLevelFormOpen(false);
  };

  const handleRuleCreateClick = (): void => {
    setRuleFormOpen(true);
  };

  const handleRuleUpdateClick = (rule: CommissionsSettingsLevelRule): void => {
    setRuleFormOpen(true);
    setRuleToUpdate(rule);
  };

  const handleRuleFormCancel = (): void => {
    setRuleFormOpen(false);
    setRuleToUpdate(undefined);
  };

  const handleBailAccountSettingsCreateUpdateClick = (): void => {
    setBailAccountSettingsFormOpen(true);
  };

  const handleBailAccountSettingsDeleteClick = (): void => {
    actions.onDeleteBailAccountSettings({ id: level.id });
  };

  const handleBailAccountSettingsFormCreateSubmit = (settings: CreateUpdateBailAccountSettings): void => {
    actions.onCreateBailAccountSettings({ id: level.id, object: settings });
  };

  const handleBailAccountSettingsFormUpdateSubmit = (settings: CreateUpdateBailAccountSettings): void => {
    actions.onUpdateBailAccountSettings({ id: level.id, object: settings });
  };

  const handleBailAccountSettingsFormCancel = (): void => {
    setBailAccountSettingsFormOpen(false);
  };

  return (
    <>
      <PageHeader
        className="page-header-box white-background margin-bottom-small"
        title={`${t("commissions.level.titles.detail")} ${formatCommissionsLevelName(level)}`}
        tags={<CommissionsLevelTypeTag type={level.type} />}
        onBack={onBackClick}
        extra={
          hasTopAgentCommissionsManagePermission && (
            <Space>
              <Button type="primary" icon={<AntIcon type="edit" />} onClick={handleLevelUpdateClick}>
                {t("common.edit")}
              </Button>

              <Popconfirm
                title={t("commissions.level.helpers.deleteLevelConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => actions.onDelete({ id: level.id })}
              >
                <Button danger icon={<AntIcon type="delete" />}>
                  {t("common.delete")}
                </Button>
              </Popconfirm>
            </Space>
          )
        }
      >
        <ItemCreatedUpdatedInfoView item={level} style={{ marginTop: "-15px" }} />

        <table className="data-table-view margin-top-small">
          <tbody>
            <tr>
              <td>{t("commissions.level.attrs.category")}:</td>
              <td>{level.category}</td>
            </tr>
          </tbody>
        </table>

        {level.type === CommissionsSettingsLevelType.CAREER && (
          <>
            <table className="data-table-view margin-top-medium">
              <tbody>
                <tr>
                  <td>{t("commissions.level.attrs.careerPromotionPoints")}:</td>
                  <td className="right-align">
                    {formatLocaleNumber(level.careerPromotionPoints)}{" "}
                    <LabelWithTooltip tooltip={t("commissions.level.helpers.careerPromotionPointsDesc")} />
                  </td>
                </tr>
                <tr>
                  <td>{t("commissions.level.attrs.careerDeclineAvoidmentPoints")}:</td>
                  <td className="right-align">
                    {formatLocaleNumber(level.careerDeclineAvoidmentPoints)}{" "}
                    <LabelWithTooltip tooltip={t("commissions.level.helpers.careerDeclineAvoidmentPointsDesc")} />
                  </td>
                </tr>
                <tr>
                  <td>{t("commissions.level.attrs.careerDeclineDefinitivePoints")}:</td>
                  <td className="right-align">
                    {formatLocaleNumber(level.careerDeclineDefinitivePoints)}{" "}
                    <LabelWithTooltip tooltip={t("commissions.level.helpers.careerDeclineDefinitivePointsDesc")} />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="data-table-view margin-top-medium">
              <tbody>
                <tr>
                  <td>{t("commissions.level.attrs.includeGroupPointsIntoPromotion")}:</td>
                  <td>
                    {tBoolean(level.includeGroupPointsIntoPromotion)}{" "}
                    <LabelWithTooltip tooltip={t("commissions.level.helpers.includeGroupPointsIntoPromotionDesc")} />
                  </td>
                </tr>
                <tr>
                  <td>{t("commissions.level.attrs.nextLevelIds")}:</td>
                  <td>
                    {level.nextLevels?.length ? (
                      <MultiLineText
                        tokens={level.nextLevels.map(nextLevel => formatCommissionsLevelName(nextLevel))}
                      />
                    ) : (
                      <span className="sub-header-info dashed">{t("common.none")}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t("commissions.level.helpers.formerLevels")}:</td>
                  <td>
                    {level.formerLevels?.length ? (
                      <MultiLineText
                        tokens={level.formerLevels.map(formerLevel => formatCommissionsLevelName(formerLevel))}
                      />
                    ) : (
                      <span className="sub-header-info dashed">{t("common.none")}</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </PageHeader>

      <Card type="inner" className="card-box" title={t("commissions.level.sections.rules")}>
        <CommissionsLevelRuleTableView
          level={level}
          showActions={hasTopAgentCommissionsManagePermission}
          onUpdateClick={handleRuleUpdateClick}
          onDelete={actions.onDeleteRule}
        />

        {hasTopAgentCommissionsManagePermission && (
          <div className={level.rules?.length ? "table-footer-margin" : "margin-top-small"}>
            <Space>
              <Button type="primary" size="small" icon={<AntIcon type="plus" />} onClick={handleRuleCreateClick}>
                {t("commissions.level.actions.addRule")}
              </Button>
              <CommissionsSettingsRuleHelperView />
            </Space>
          </div>
        )}
      </Card>

      <Card type="inner" className="card-box" title={t("commissions.level.sections.bailAccountSettings")}>
        <BailAccountSettingsView
          settings={level.bailAccountSettings}
          showActions={hasTopAgentCommissionsManagePermission}
          noSettingsLabel={t("commissions.level.helpers.bailAccount.noSettings")}
          onCreateUpdateClick={handleBailAccountSettingsCreateUpdateClick}
          onDeleteClick={handleBailAccountSettingsDeleteClick}
        />
      </Card>

      <Card type="inner" className="card-box" title={t("commissions.level.sections.documentsAndAttachments")}>
        <CommissionsLevelAttachmentsTableView
          level={level}
          onUpload={hasTopAgentCommissionsManagePermission ? actions.onUploadAttachments : undefined}
          onDelete={hasTopAgentCommissionsManagePermission ? actions.onDeleteAttachment : undefined}
        />
      </Card>

      <CommissionsLevelForm
        open={levelFormOpen}
        level={level}
        onUpdate={actions.onUpdate}
        onFormCancel={handleLevelFormCancel}
      />

      <CommissionsLevelRuleForm
        open={ruleFormOpen}
        rule={ruleToUpdate}
        levelId={level.id}
        onCreate={actions.onCreateRule}
        onUpdate={actions.onUpdateRule}
        onFormCancel={handleRuleFormCancel}
      />

      <BailAccountSettingsForm
        settings={level.bailAccountSettings}
        open={bailAccountSettingsFormOpen}
        onCreateSubmit={handleBailAccountSettingsFormCreateSubmit}
        onUpdateSubmit={handleBailAccountSettingsFormUpdateSubmit}
        onFormCancel={handleBailAccountSettingsFormCancel}
      />
    </>
  );
};

export default CommissionsLevelDetailView;
