import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState } from "../../../common/types";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import api from "./api";
import {
  CertificateVehicleCategory,
  CertificateVehicleCategoryFilterPageRequest,
  CertificateVehicleCategoryFilterPageResult,
  CreateUpdateCertificateVehicleCategory,
  VehicleCategoryReducerState
} from "./types";

/**
 * ACTIONS
 */
export const filterVehicleCategoriesActions = createAsyncAction(
  "vehicle-category/FILTER_REQUEST",
  "vehicle-category/FILTER_SUCCESS",
  "vehicle-category/FILTER_FAILURE"
)<CertificateVehicleCategoryFilterPageRequest, CertificateVehicleCategoryFilterPageResult, void>();

export const createVehicleCategoryActions = createAsyncAction(
  "vehicle-category/CREATE_REQUEST",
  "vehicle-category/CREATE_SUCCESS",
  "vehicle-category/CREATE_FAILURE"
)<CreateUpdateCertificateVehicleCategory, CertificateVehicleCategory, void>();

export const updateVehicleCategoryActions = createAsyncAction(
  "vehicle-category/UPDATE_REQUEST",
  "vehicle-category/UPDATE_SUCCESS",
  "vehicle-category/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCertificateVehicleCategory>, CertificateVehicleCategory, void>();

export const deleteVehicleCategoryActions = createAsyncAction(
  "vehicle-category/DELETE_REQUEST",
  "vehicle-category/DELETE_SUCCESS",
  "vehicle-category/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateVehicleCategoriesPageAction = createAction("vehicle-category/DELETE_STATE_LIST")<void>();

const actions = {
  filterVehicleCategoriesActions,
  createVehicleCategoryActions,
  updateVehicleCategoryActions,
  deleteVehicleCategoryActions,
  deleteStateVehicleCategoriesPageAction
};

export type VehicleCategoryAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: VehicleCategoryReducerState = {
  currentPage: {
    ...initSearchPageResult<CertificateVehicleCategory>(),
    categories: []
  }
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterVehicleCategoriesActions.success, (_, { payload }) => payload)
  .handleAction(updateVehicleCategoryActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterVehicleCategoriesActions.failure, deleteStateVehicleCategoriesPageAction],
    () => initialState.currentPage
  );

export const vehicleCategoryReducer = combineReducers<VehicleCategoryReducerState>({
  currentPage: currentPageReducer
});

/**
 * SELECTORS
 */
const selectVehicleCategory = (state: RootState): VehicleCategoryReducerState => state.vehicle.category;

export const selectVehicleCategoriesCurrentPage = (state: RootState): CertificateVehicleCategoryFilterPageResult =>
  selectVehicleCategory(state).currentPage;

/**
 * SAGAS
 */
function* filterVehicleCategories({ payload }: ReturnType<typeof filterVehicleCategoriesActions.request>) {
  try {
    const response: AxiosResponse<CertificateVehicleCategoryFilterPageResult> = yield call(
      api.filterVehicleCategories,
      payload
    );
    yield put(filterVehicleCategoriesActions.success(response.data));
  } catch (error) {
    yield put(filterVehicleCategoriesActions.failure());
  }
}

function* createVehicleCategory({ payload }: ReturnType<typeof createVehicleCategoryActions.request>) {
  try {
    const response: AxiosResponse<CertificateVehicleCategory> = yield call(api.createVehicleCategory, payload);
    yield put(createVehicleCategoryActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createVehicleCategoryActions.failure());
  }
}

function* updateVehicleCategory({ payload }: ReturnType<typeof updateVehicleCategoryActions.request>) {
  try {
    const response: AxiosResponse<CertificateVehicleCategory> = yield call(api.updateVehicleCategory, payload);
    yield put(updateVehicleCategoryActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateVehicleCategoryActions.failure());
  }
}

function* deleteVehicleCategory({ payload }: ReturnType<typeof deleteVehicleCategoryActions.request>) {
  try {
    yield call(api.deleteVehicleCategory, payload);
    yield put(deleteVehicleCategoryActions.success());
    messageUtils.itemDeletedNotification();
    yield* refreshCurrentPage(true);
  } catch (error) {
    yield put(deleteVehicleCategoryActions.failure());
  }
}

function* refreshCurrentPage(movePage?: boolean) {
  const currentPage: CertificateVehicleCategoryFilterPageResult = yield select(selectVehicleCategoriesCurrentPage);
  yield put(
    filterVehicleCategoriesActions.request({
      pageIndex: movePage
        ? currentPage.pageElementsCount === 1
          ? Math.max(currentPage.pageIndex - 1, 0)
          : currentPage.pageIndex
        : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      keyword: currentPage.keyword,
      categories: currentPage.categories
    })
  );
}

export function* vehicleCategorySaga() {
  yield takeLatest(filterVehicleCategoriesActions.request, filterVehicleCategories);
  yield takeLatest(createVehicleCategoryActions.request, createVehicleCategory);
  yield takeLatest(updateVehicleCategoryActions.request, updateVehicleCategory);
  yield takeLatest(deleteVehicleCategoryActions.request, deleteVehicleCategory);
}
