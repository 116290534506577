import { Col, Row } from "antd";
import { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { rowGutter } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../../common/types";
import { hasAnyPermission, hasPermission } from "../../../common/utils/utils";
import { selectPermissions, selectSelectedAccount } from "../../auth/ducks";
import { AuthSelectedAccount } from "../../auth/types";
import {
  DashboardAgentCompetencesWidget,
  DashboardContactsWidget,
  DashboardContractsWidget,
  DashboardNoticesWidget,
  DashboardPersonalDatesWidget,
  DashboardStatisticsWidget
} from "../components/widgets";
import { refreshDashboardAction } from "../ducks";

interface StateProps {
  permissions: Permission[];
}

interface ActionsMap {
  refreshDashboard: typeof refreshDashboardAction;
}

const DashboardContainer = ({ permissions, actions }: StateProps & ActionProps<ActionsMap>) => {
  const selectedAccount = useSelector<RootState, AuthSelectedAccount>(selectSelectedAccount);

  const { hasContractReadPermission, hasClientOrAgentReadPermission, hasProfileCompetencePermission } = useMemo(() => {
    return {
      hasContractReadPermission: hasAnyPermission(permissions, [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.INVESTMENT_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ,
        Permission.GENERIC_READ
      ]),
      hasClientOrAgentReadPermission: hasAnyPermission(permissions, [Permission.CLIENT_READ, Permission.AGENT_READ]),
      hasProfileCompetencePermission: hasPermission(permissions, Permission.PROFILE_COMPETENCE)
    };
  }, [permissions]);

  useEffect(() => {
    actions.refreshDashboard("initial");
  }, [selectedAccount.accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper wide key={selectedAccount.accountId}>
      <Row gutter={rowGutter}>
        <Col span={18}>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <DashboardStatisticsWidget />
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            {hasContractReadPermission && (
              <Col span={24}>
                <DashboardContractsWidget />
              </Col>
            )}
          </Row>

          <Row gutter={rowGutter}>
            {hasClientOrAgentReadPermission && (
              <Col span={12}>
                <DashboardPersonalDatesWidget />
              </Col>
            )}

            {hasProfileCompetencePermission && (
              <Col span={12}>
                <DashboardAgentCompetencesWidget />
              </Col>
            )}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={24}>
              <DashboardContactsWidget />
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <DashboardNoticesWidget />
        </Col>
      </Row>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  permissions: selectPermissions(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      refreshDashboard: refreshDashboardAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer);
