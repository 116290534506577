import { Button, Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import t from "../../../../../../app/i18n";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import { resolveFormValidationError, selectTagsStandardProps } from "../../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../../../enumerations/components/form/InstitutionSelect";
import { PostponedCommissionsReport } from "../../../../postponed/enums";
import {
  PostponedCommissionsFilterPageRequest,
  PostponedCommissionsFilterPageResult
} from "../../../../postponed/types";
import { CommissionsBatchStep, PostponementReason, postponementReasonTMap } from "../../../enums";
import { CommissionsBatchBase } from "../../../types";
import PostponementReasonTag from "../../PostponementReasonTag";

interface Props {
  filter: PostponedCommissionsFilterPageResult;
  batch?: CommissionsBatchBase;
  onReportSwitch: (filter: PostponedCommissionsFilterPageRequest) => void;
  onFilterSubmit: (filter: PostponedCommissionsFilterPageRequest) => void;
}

const BatchPostponedCommissionFilterView = ({ filter, batch, onReportSwitch, onFilterSubmit }: Props) => {
  const [currentReport, setCurrentReport] = useState<PostponedCommissionsReport>(
    PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH
  );

  const [form] = Form.useForm<PostponedCommissionsFilterPageRequest>();

  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: filter.keyword,
      report: filter.report,
      batchId: filter.batchId,
      postponementReasons: filter.postponementReasons || [],
      institutionIds: filter.institutionIds || []
    });

    if (filter.report) {
      setCurrentReport(filter.report);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReportSwitch = (report: PostponedCommissionsReport): void => {
    form
      .validateFields()
      .then(values => {
        onReportSwitch({ ...values, report });
        setCurrentReport(report);
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFilterSubmit} name="postponedCommissionFilterForm">
      <Row gutter={rowGutter} justify="center" className="margin-bottom-medium">
        <Col span={24} className="center-align">
          <Radio.Group
            size="large"
            buttonStyle="solid"
            value={currentReport}
            onChange={e =>
              handleReportSwitch(PostponedCommissionsReport[e.target.value as keyof typeof PostponedCommissionsReport])
            }
          >
            <Tooltip
              title={t("commissions.batch.helpers.postponedCommissionsIncludedInBatchTooltip")}
              key={PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH}
            >
              <Radio.Button value={PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH}>
                {t("commissions.postponed.enums.report.UNRESOLVED_INCLUDED_IN_BATCH")}
              </Radio.Button>
            </Tooltip>
            {batch?.step !== CommissionsBatchStep.FINISH && (
              <Tooltip
                title={t("commissions.batch.helpers.postponedCommissionsTooltip")}
                key={PostponedCommissionsReport.UNRESOLVED}
              >
                <Radio.Button value={PostponedCommissionsReport.UNRESOLVED}>
                  {t("commissions.postponed.enums.report.UNRESOLVED")}
                </Radio.Button>
              </Tooltip>
            )}
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={rowGutter} justify="center">
        <Col span={colSpan}>
          <Form.Item
            name="keyword"
            label={t("common.searchKeyword")}
            rules={[
              validations.size(
                validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                validationConstants.SEARCH_KEYWORD_MAX_LENGTH
              )
            ]}
          >
            <Input allowClear placeholder={t("commissions.batch.helpers.commissionsSearchHint")} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="postponementReasons"
            label={t("commissions.batch.enums.postponementReason._label")}
            rules={[validations.none]}
          >
            <Select
              {...selectTagsStandardProps(postponementReasonTMap)}
              allowClear
              mode="multiple"
              maxTagCount="responsive"
              tagRender={props => (
                <PostponementReasonTag
                  reason={PostponementReason[props.value as keyof typeof PostponementReason]}
                  closable={props.closable}
                  onClose={props.onClose}
                />
              )}
              options={Object.keys(PostponementReason).map(reason => ({
                value: reason,
                label: <PostponementReasonTag reason={PostponementReason[reason as keyof typeof PostponementReason]} />
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <InstitutionSelect
            formItemProps={{
              name: "institutionIds",
              label: t("common.institution"),
              rules: [validations.none]
            }}
            selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
            optionsProps={{ groupByType: true }}
          />
        </Col>

        <Col flex="110px" className="no-title-space">
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
              {t("common.filter")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BatchPostponedCommissionFilterView;
