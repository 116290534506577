import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState } from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { openBlobFile } from "../../common/utils/utils";
import { CONTRACT_ROUTE_PATHS } from "../contract/paths";
import { changeRunningRequestKeyAction } from "../ducks";
import api from "./api";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "./paths";
import {
  AssignFinancialMediation,
  ChangeFinancialMediationVersion,
  CreateUpdateFinancialMediation,
  FinancialMediation,
  FinancialMediationFilterPageRequest,
  FinancialMediationFilterPageResult,
  FinancialMediationGenerateRequest,
  FinancialMediationList,
  FinancialMediationReducerState
} from "./types";

/**
 * ACTIONS
 */
export const filterFinancialMediationsActions = createAsyncAction(
  "financial-mediation/FILTER_REQUEST",
  "financial-mediation/FILTER_SUCCESS",
  "financial-mediation/FILTER_FAILURE"
)<FinancialMediationFilterPageRequest, FinancialMediationFilterPageResult, void>();

export const getFinancialMediationActions = createAsyncAction(
  "financial-mediation/GET_REQUEST",
  "financial-mediation/GET_SUCCESS",
  "financial-mediation/GET_FAILURE"
)<EntityIdObject, FinancialMediation, void>();

export const createFinancialMediationActions = createAsyncAction(
  "financial-mediation/CREATE_REQUEST",
  "financial-mediation/CREATE_SUCCESS",
  "financial-mediation/CREATE_FAILURE"
)<CreateUpdateFinancialMediation, FinancialMediation, void>();

export const updateFinancialMediationActions = createAsyncAction(
  "financial-mediation/UPDATE_REQUEST",
  "financial-mediation/UPDATE_SUCCESS",
  "financial-mediation/UPDATE_FAILURE"
)<EntityObject<CreateUpdateFinancialMediation>, FinancialMediation, void>();

export const deleteFinancialMediationActions = createAsyncAction(
  "financial-mediation/DELETE_REQUEST",
  "financial-mediation/DELETE_SUCCESS",
  "financial-mediation/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const generateFinancialMediationActions = createAsyncAction(
  "financial-mediation/GENERATE_REQUEST",
  "financial-mediation/GENERATE_SUCCESS",
  "financial-mediation/GENERATE_FAILURE"
)<EntityObject<FinancialMediationGenerateRequest>, void, void>();

export const changeFinancialMediationVersionActions = createAsyncAction(
  "financial-mediation/CHANGE_VERSION_REQUEST",
  "financial-mediation/CHANGE_VERSION_SUCCESS",
  "financial-mediation/CHANGE_VERSION_FAILURE"
)<EntityObject<ChangeFinancialMediationVersion>, FinancialMediation, void>();

export const assignFinancialMediationActions = createAsyncAction(
  "financial-mediation/ASSIGN_REQUEST",
  "financial-mediation/ASSIGN_SUCCESS",
  "financial-mediation/ASSIGN_FAILURE"
)<EntityObject<AssignFinancialMediation>, FinancialMediation, void>();

export const deleteStateFinancialMediationsPageAction = createAction("financial-mediation/DELETE_STATE_LIST")<void>();
export const deleteStateFinancialMediationDetailAction = createAction(
  "financial-mediation/DELETE_STATE_DETAIL"
)<void>();

const actions = {
  filterFinancialMediationsActions,
  getFinancialMediationActions,
  createFinancialMediationActions,
  updateFinancialMediationActions,
  deleteFinancialMediationActions,
  generateFinancialMediationActions,
  changeFinancialMediationVersionActions,
  assignFinancialMediationActions,
  deleteStateFinancialMediationsPageAction,
  deleteStateFinancialMediationDetailAction
};

export type FinancialMediationAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: FinancialMediationReducerState = {
  currentPage: { ...initSearchPageResult<FinancialMediationList>(), includeAssigned: false, sectors: [] },
  mediationDetail: null
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterFinancialMediationsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterFinancialMediationsActions.failure, deleteStateFinancialMediationsPageAction],
    () => initialState.currentPage
  );

const mediationDetailReducer = createReducer(initialState.mediationDetail)
  .handleAction(
    [
      getFinancialMediationActions.success,
      createFinancialMediationActions.success,
      updateFinancialMediationActions.success,
      changeFinancialMediationVersionActions.success,
      assignFinancialMediationActions.success
    ],
    (_, { payload }) => payload
  )
  .handleAction(
    [
      getFinancialMediationActions.failure,
      deleteFinancialMediationActions.success,
      deleteStateFinancialMediationDetailAction
    ],
    () => initialState.mediationDetail
  );

export const financialMediationReducer = combineReducers<FinancialMediationReducerState>({
  currentPage: currentPageReducer,
  mediationDetail: mediationDetailReducer
});

/**
 * SELECTORS
 */
const selectFinancialMediation = (state: RootState): FinancialMediationReducerState => state.financialMediation;

export const selectFinancialMediationsCurrentPage = (state: RootState): FinancialMediationFilterPageResult =>
  selectFinancialMediation(state).currentPage;
export const selectFinancialMediationDetail = (state: RootState): FinancialMediation | undefined =>
  selectFinancialMediation(state).mediationDetail ?? undefined;

/**
 * SAGAS
 */
function* filterFinancialMediations({ payload }: ReturnType<typeof filterFinancialMediationsActions.request>) {
  try {
    const response: AxiosResponse<FinancialMediationFilterPageResult> = yield call(
      api.filterFinancialMediations,
      payload
    );
    yield put(filterFinancialMediationsActions.success(response.data));
  } catch (error) {
    yield put(filterFinancialMediationsActions.failure());
  }
}

function* getFinancialMediation({ payload }: ReturnType<typeof getFinancialMediationActions.request>) {
  try {
    const response: AxiosResponse<FinancialMediation> = yield call(api.getFinancialMediation, payload);
    yield put(getFinancialMediationActions.success(response.data));
  } catch (error) {
    yield put(getFinancialMediationActions.failure());
  }
}

function* createFinancialMediation({ payload }: ReturnType<typeof createFinancialMediationActions.request>) {
  try {
    const response: AxiosResponse<FinancialMediation> = yield call(api.createFinancialMediation, payload);
    yield put(createFinancialMediationActions.success(response.data));
    yield put(replace(generatePath(FINANCIAL_MEDIATION_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch (error) {
    yield put(createFinancialMediationActions.failure());
  }
}

function* updateFinancialMediation({ payload }: ReturnType<typeof updateFinancialMediationActions.request>) {
  try {
    const response: AxiosResponse<FinancialMediation> = yield call(api.updateFinancialMediation, payload);
    yield put(updateFinancialMediationActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateFinancialMediationActions.failure());
  }
}

function* deleteFinancialMediation({ payload }: ReturnType<typeof deleteFinancialMediationActions.request>) {
  try {
    yield call(api.deleteFinancialMediation, payload);
    yield put(deleteFinancialMediationActions.success());
    messageUtils.itemDeletedNotification();
    yield put(replace(CONTRACT_ROUTE_PATHS.forms.to));
  } catch (error) {
    yield put(deleteFinancialMediationActions.failure());
  }
}

function* generateFinancialMediation({ payload }: ReturnType<typeof generateFinancialMediationActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.generateFinancialMediation, payload);
    openBlobFile(response);
    yield put(generateFinancialMediationActions.success());
  } catch (error) {
    yield put(generateFinancialMediationActions.failure());
  }
}

function* changeFinancialMediationVersion({
  payload
}: ReturnType<typeof changeFinancialMediationVersionActions.request>) {
  try {
    const response: AxiosResponse<FinancialMediation> = yield call(api.changeFinancialMediationVersion, payload);
    yield put(changeFinancialMediationVersionActions.success(response.data));
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(changeFinancialMediationVersionActions.failure());
  }
}

function* assignFinancialMediation({ payload }: ReturnType<typeof assignFinancialMediationActions.request>) {
  try {
    const response: AxiosResponse<FinancialMediation> = yield call(api.assignFinancialMediation, payload);
    yield put(assignFinancialMediationActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(assignFinancialMediationActions.failure());
  }
}

export function* financialMediationSaga() {
  yield takeLatest(filterFinancialMediationsActions.request, filterFinancialMediations);
  yield takeLatest(getFinancialMediationActions.request, getFinancialMediation);
  yield takeLatest(createFinancialMediationActions.request, createFinancialMediation);
  yield takeLatest(updateFinancialMediationActions.request, updateFinancialMediation);
  yield takeLatest(deleteFinancialMediationActions.request, deleteFinancialMediation);
  yield takeLatest(generateFinancialMediationActions.request, generateFinancialMediation);
  yield takeLatest(changeFinancialMediationVersionActions.request, changeFinancialMediationVersion);
  yield takeLatest(assignFinancialMediationActions.request, assignFinancialMediation);
}
