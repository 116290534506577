import { Col, Form, Row, Select, Table, Upload } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import useMeasure from "react-use-measure";
import t from "../../../../../../../app/i18n";
import ActionButton from "../../../../../../../common/components/buttons/ActionButton";
import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";
import ActionTextIcon from "../../../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import FileTypeIconWithFilename from "../../../../../../../common/components/icons/FileTypeIconWithFilename";
import PopconfirmDeleteIcon from "../../../../../../../common/components/icons/PopconfirmDeleteIcon";
import StatusTag from "../../../../../../../common/components/tags/StatusTag";
import TableActionsView from "../../../../../../../common/components/views/TableActionsView";
import { rowGutter, TableSizes } from "../../../../../../../common/constants";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import { RootState, TableActionsViewActionConfig } from "../../../../../../../common/types";
import { formatFileSize, formatLocaleCurrencyWithNullAsZero } from "../../../../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { isDefinedValue, tableStandardProps } from "../../../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../../../auth/ducks";
import { selectCommissionSourceTemplatesEnums } from "../../../../../../enumerations/ducks";
import { CommissionSourceTemplate } from "../../../../../../enumerations/types";
import { CommissionsBatchAttachmentType, CommissionsBatchStep } from "../../../../enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../../paths";
import {
  CommissionsBatch,
  CommissionsBatchAttachmentActions,
  CommissionsBatchInputAttachment,
  CommissionsUnit
} from "../../../../types";

interface Props {
  batch: CommissionsBatch;
  unit: CommissionsUnit;
  actions?: CommissionsBatchAttachmentActions;
}

enum FileUploadType {
  SOURCE = "SOURCE",
  DEFAULT_SOURCE = "DEFAULT_SOURCE",
  ATTACHMENT = "ATTACHMENT"
}

const CommissionsUnitAttachmentsTableView = ({ batch, unit, actions }: Props) => {
  const [measuredTableRef, tableMeasures] = useMeasure();

  const hasManageCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS_MANAGE)(state)
  );

  const sourceTemplates = useSelector<RootState, CommissionSourceTemplate[]>(selectCommissionSourceTemplatesEnums);
  const institutionTemplateColumnsNames = useMemo(
    () => sourceTemplates.find(t => t.institution?.id === unit.institution.id)?.expectedColumns,
    [sourceTemplates, unit.institution.id]
  );
  const defaultTemplateColumnsNames = useMemo(
    () => sourceTemplates.find(t => !t.institution)?.expectedColumns,
    [sourceTemplates]
  );

  const [uploadType, setUploadType] = useState<FileUploadType>(
    institutionTemplateColumnsNames ? FileUploadType.SOURCE : FileUploadType.DEFAULT_SOURCE
  );

  const handleAttachmentsUpload = (
    file: RcFile,
    fileList: RcFile[],
    replacedAttachment?: CommissionsBatchInputAttachment
  ): boolean => {
    if (fileList.length > 0) {
      const formData = new FormData();
      formData.append("file", file);

      if (replacedAttachment) {
        formData.append("source", replacedAttachment.source.toString());
        formData.append("forceDefaultImporter", replacedAttachment.defaultSource.toString());
        actions?.onReplace({ id1: batch.id, id2: replacedAttachment.id, object: formData });
      } else {
        formData.append("institutionId", unit.institution.id);
        formData.append(
          "source",
          (uploadType === FileUploadType.SOURCE || uploadType === FileUploadType.DEFAULT_SOURCE).toString()
        );
        formData.append("forceDefaultImporter", (uploadType === FileUploadType.DEFAULT_SOURCE).toString());
        actions?.onUpload({ id: batch.id, object: formData });
      }

      fileList.splice(0, 1);
    }

    return false;
  };

  const resolveFileUploadTypeLabel = (): React.ReactNode => {
    if (uploadType === FileUploadType.ATTACHMENT) {
      return t("commissions.batch.enums.fileUploadType._label");
    }

    const popoverTitle =
      uploadType === FileUploadType.SOURCE
        ? t("commissions.batch.helpers.sourceAttachmentHint")
        : t("commissions.batch.helpers.defaultSourceAttachmentHint");
    const columnsNames =
      uploadType === FileUploadType.SOURCE ? institutionTemplateColumnsNames : defaultTemplateColumnsNames;

    return (
      <LabelWithPopover
        label={t("commissions.batch.enums.fileUploadType._label")}
        popoverTitle={popoverTitle}
        popoverContent={
          <Row gutter={rowGutter} style={{ maxWidth: "400px" }}>
            {columnsNames?.map((column, index) => (
              <Col key={index} span={12}>
                {column}
              </Col>
            ))}
          </Row>
        }
      />
    );
  };

  const columns: ColumnsType<CommissionsBatchInputAttachment> = [
    {
      key: "filename",
      title: t("common.filename"),
      width: batch.step === CommissionsBatchStep.FINISH || !hasManageCommissionsPermission ? 280 : 190,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <FileTypeIconWithFilename contentType={record.file.contentType} filename={record.file.filename} ellipsis />
      )
    },
    {
      key: "source",
      title: t("commissions.batch.attrs.attachment.source"),
      align: "center",
      width: 110,
      render: (_, record) => (record.source ? <AntIcon type="check" /> : <AntIcon type="close" />)
    },
    {
      key: "importedCommissionAmount",
      title: t("commissions.batch.attrs.attachment.importedCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) =>
        record.source ? formatLocaleCurrencyWithNullAsZero(record.importedCommissionAmount) : null
    },
    {
      key: "postponedCommissionAmount",
      title: t("commissions.batch.attrs.attachment.postponedCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) =>
        record.source ? formatLocaleCurrencyWithNullAsZero(record.postponedCommissionAmount) : null
    },
    {
      key: "size",
      title: t("common.size"),
      align: "right",
      width: 110,
      render: (_, record) => formatFileSize(record.file.size)
    }
  ];

  if (batch.step !== CommissionsBatchStep.FINISH) {
    columns.push({
      key: "statusTags",
      width: 140,
      render: (_, record) => (
        <>
          {record.hasImportErrors && (
            <StatusTag
              status="error"
              tooltip={record.importError?.description || t("commissions.batch.helpers.importedAttachmentError")}
            />
          )}
          {record.importInProgress && <StatusTag status="processing" />}
        </>
      )
    });
  }

  columns.push({
    key: "actions",
    align: "right",
    fixed: "right",
    width:
      batch.step === CommissionsBatchStep.FINISH || !hasManageCommissionsPermission
        ? 180
        : tableMeasures.width < 1180
          ? 130
          : 370,
    render: (_, record) => {
      const tableActions: TableActionsViewActionConfig[] = [];

      if (record.source) {
        tableActions.push({
          color: "green",
          icon: "eye",
          text: t("common.show"),
          disabled: batch.stepChangeInProgress || record.importInProgress,
          path: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.attachmentImports.to, {
            id1: batch.id,
            id2: record.id
          })
        });
      }

      tableActions.push({
        color: "blue",
        icon: "download",
        text: t("common.download"),
        disabled: record.importInProgress,
        path: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.attachment.to, { id1: batch.id, id2: record.id }),
        target: "_blank"
      });

      if (batch.step !== CommissionsBatchStep.FINISH && hasManageCommissionsPermission) {
        if (record.source) {
          tableActions.push({
            renderOverride: (
              <Upload
                showUploadList={false}
                accept={".csv"}
                disabled={batch.stepChangeInProgress || record.importInProgress}
                beforeUpload={(file, fileList) => handleAttachmentsUpload(file, fileList, record)}
              >
                <ActionTextIcon
                  color="orange"
                  icon="sync"
                  text={t("common.replace")}
                  disabled={batch.stepChangeInProgress || record.importInProgress}
                />
              </Upload>
            )
          });
        }

        tableActions.push({
          color: "red",
          icon: "delete",
          text: t("common.delete"),
          disabled: batch.stepChangeInProgress || record.importInProgress,
          onClick: () => actions?.onDelete({ id1: batch.id, id2: record.id }),
          confirmDialog: {
            icon: <PopconfirmDeleteIcon />,
            title: t("commissions.batch.helpers.deleteAttachment"),
            okType: "danger",
            okText: t("common.yes"),
            cancelText: t("common.no")
          }
        });
      }

      return batch.step !== CommissionsBatchStep.FINISH && hasManageCommissionsPermission ? (
        <TableActionsView
          actions={tableActions}
          dropdownMaxWidth={360}
          dropdownAction={{ color: "blue", icon: "down" }}
          dropdownExtractFirstAction
        />
      ) : (
        <TableActionsView actions={tableActions} />
      );
    }
  });

  const attachments = (
    batch.attachments.filter(a => a.type === CommissionsBatchAttachmentType.INPUT) as CommissionsBatchInputAttachment[]
  ).filter(a => a.institution.id === unit.institution.id);

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <b>{t("commissions.batch.helpers.manuallyAddedCommissions")}: </b>

          {isDefinedValue(unit.manuallyAddedCommissionAmount) ? (
            <span>
              {t("common.together")}: {formatLocaleCurrencyWithNullAsZero(unit.manuallyAddedCommissionAmount)} |&nbsp;
              {t("common.postponed")}: {formatLocaleCurrencyWithNullAsZero(unit.manuallyAddedPostponedCommissionAmount)}
            </span>
          ) : (
            <span className="sub-header-info dashed">{t("common.none")}</span>
          )}

          <ActionTextIcon
            path={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.manualImports.to, {
              id1: batch.id,
              id2: unit.institution.id
            })}
            icon="interaction"
            color="blue"
            className="margin-left-small"
            text={batch.step === CommissionsBatchStep.FINISH ? t("common.show") : t("common.manage")}
            disabled={batch.stepChangeInProgress}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter} className="margin-top-medium">
        <Col span={24}>
          <Table<CommissionsBatchInputAttachment>
            {...tableStandardProps(false, true, "inner-table")}
            ref={measuredTableRef}
            columns={columns}
            scroll={{ x: TableSizes.MEDIUM }}
            dataSource={attachments}
            pagination={false}
          />
        </Col>
      </Row>

      {batch.step !== CommissionsBatchStep.FINISH && hasManageCommissionsPermission ? (
        <Form layout="vertical">
          <Row gutter={rowGutter} className="margin-top-medium">
            <Col flex="200px">
              <Form.Item label={resolveFileUploadTypeLabel()}>
                <Select<FileUploadType>
                  {...selectStandardProps}
                  size="small"
                  style={{ width: "200px" }}
                  value={uploadType}
                  options={Object.keys(FileUploadType).map(type => ({
                    value: type,
                    label: t("commissions.batch.enums.fileUploadType." + type),
                    disabled: type === FileUploadType.SOURCE && !institutionTemplateColumnsNames
                  }))}
                  onChange={setUploadType}
                />
              </Form.Item>
            </Col>

            <Col flex="140px">
              <Form.Item className="form-item-without-label">
                <Upload
                  showUploadList={false}
                  accept={
                    uploadType === FileUploadType.SOURCE || uploadType === FileUploadType.DEFAULT_SOURCE
                      ? ".csv"
                      : undefined
                  }
                  disabled={batch.stepChangeInProgress}
                  beforeUpload={handleAttachmentsUpload}
                >
                  <ActionButton
                    icon="plus"
                    label={t("commissions.batch.actions.addAttachment")}
                    disabled={batch.stepChangeInProgress}
                  />
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Row gutter={rowGutter} className="margin-top-medium" />
      )}
    </>
  );
};

export default CommissionsUnitAttachmentsTableView;
