import { Button, Checkbox, Col, Collapse, Divider, Form, Input, Row, Tree } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { Feature, Permission, systemAdminOnlyPermissions } from "../../../../common/security/authorization/enums";
import { AntTreeStrictCheckedProps, RootState } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { containsAll, getAllPermissionPrerequisites } from "../../../../common/utils/utils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import { AgentType } from "../../../agent/enums";
import { AgentBase } from "../../../agent/types";
import { selectIsSystemAdmin } from "../../../auth/ducks";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import { selectTopAgentAllowedFeaturesEnums } from "../../../enumerations/ducks";
import { AgentEnumeration } from "../../../enumerations/types";
import { requests } from "../../api";
import { adminCreateUserWithMultipleAgentUserAccountsActions } from "../../ducks";
import { AdminCreateUpdateAgentUserAccount, AdminCreateUserWithAgentUserAccounts } from "../../types";
import { buildPermissionTreeNodes } from "../../utils";
import DefaultPermissionsFormPart from "./DefaultPermissionsFormPart";

interface Props {
  onFormSubmit: typeof adminCreateUserWithMultipleAgentUserAccountsActions.request;
}

interface UserAccountState {
  permissions: Permission[];
  agent?: AgentEnumeration;
  representingAgent?: AgentBase;
}

const AdminCreateUserForm = ({ onFormSubmit }: Props) => {
  const isCurrentUserSystemAdmin = useSelector<RootState, boolean | undefined>(selectIsSystemAdmin);
  const topAgentAllowedFeatures = useSelector<RootState, Feature[]>(selectTopAgentAllowedFeaturesEnums);

  const [form] = Form.useForm<AdminCreateUserWithAgentUserAccounts>();
  useFormErrorHandler(form, "user.attrs", [requests.ADMIN_CREATE_USER_WITH_AGENT_USER_ACCOUNTS]);

  const [userAccounts, setUserAccounts] = useState<UserAccountState[]>([
    { agent: undefined, representingAgent: undefined, permissions: [] }
  ]);

  const handleAccountAdd = (): void => {
    const accounts = [...(form.getFieldValue("accounts") ?? [])];

    accounts.push({
      disabled: false,
      permissions: [],
      agentId: undefined,
      representingAgentId: undefined
    });
    form.setFieldsValue({ accounts: accounts as AdminCreateUpdateAgentUserAccount[] });

    const changedUserAccounts = [...userAccounts];
    changedUserAccounts.push({
      permissions: [],
      agent: undefined,
      representingAgent: undefined
    });
    setUserAccounts(changedUserAccounts);
  };

  const handleAccountDelete = (index: number) => {
    const accounts = [...form.getFieldValue("accounts")] as AdminCreateUpdateAgentUserAccount[];
    accounts.splice(index, 1);
    form.setFieldsValue({ accounts });

    const changedUserAccounts = [...userAccounts];
    changedUserAccounts.splice(index, 1);
    setUserAccounts(changedUserAccounts);
  };

  const handleAgentChange = (index: number, agent: AgentEnumeration): void => {
    const accounts = [...form.getFieldValue("accounts")] as AdminCreateUpdateAgentUserAccount[];
    accounts[index] = {
      ...accounts[index],
      representingAgentId: undefined
    } as AdminCreateUpdateAgentUserAccount;

    form.setFieldsValue({ accounts });

    const changedUserAccounts = [...userAccounts];
    changedUserAccounts[index] = {
      ...changedUserAccounts[index],
      agent
    } as UserAccountState;
    setUserAccounts(changedUserAccounts);
  };

  const handleRepresentingAgentChange = (index: number, agent: AgentBase): void => {
    const changedUserAccounts = [...userAccounts];
    changedUserAccounts[index] = {
      ...changedUserAccounts[index],
      representingAgent: agent
    } as UserAccountState;
    setUserAccounts(changedUserAccounts);
  };

  const handlePermissionTreeCheck = (index: number, checked: AntTreeStrictCheckedProps): void => {
    const checkedPermissions = checked.checked.map(checked => Permission[checked as keyof typeof Permission]);
    const changedUserAccounts = [...userAccounts];
    changedUserAccounts[index] = {
      ...changedUserAccounts[index],
      permissions: checkedPermissions.filter(permission =>
        containsAll(checkedPermissions, ...getAllPermissionPrerequisites(permission))
      )
    } as UserAccountState;
    setUserAccounts(changedUserAccounts);
  };

  const handleDefaultPermissionsSet = (permissions: Permission[], index: number): void => {
    const changedUserAccounts = [...userAccounts];
    changedUserAccounts[index] = {
      ...changedUserAccounts[index],
      permissions
    } as UserAccountState;

    setUserAccounts(changedUserAccounts);
  };

  const handleSystemAdminChange = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      setUserAccounts(
        [...userAccounts].map(userAccount => ({
          ...userAccount,
          permissions: userAccount.permissions.filter(permission => !systemAdminOnlyPermissions.includes(permission))
        }))
      );
    }
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const accountWithNoPermissions = userAccounts.find(account => account.permissions.length === 0);

        if (accountWithNoPermissions) {
          messageUtils.errorMessage(
            t("user.helpers.noPermissionsForAccount", {
              aggregatedName: accountWithNoPermissions.agent?.aggregatedName
            })
          );
        } else {
          const accounts = values.accounts.map((account, index) => ({
            ...account,
            permissions: userAccounts[index] ? (userAccounts[index] as UserAccountState).permissions : []
          }));

          onFormSubmit({
            ...values,
            accounts
          });
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 6;

  return (
    <Form form={form} layout="vertical" name="userCreateForm" onFinish={handleFormSubmit}>
      <Divider orientation="left" style={{ marginTop: 0 }}>
        {t("user.titles.basicData")}
      </Divider>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="name"
            label={t("user.attrs.name")}
            rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}
          >
            <Input placeholder={t("user.helpers.userNamePlaceholder")} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="email"
            label={t("user.attrs.email")}
            rules={[validations.notBlank, validations.size(1, 254), validations.email]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="phone"
            label={t("user.attrs.phone")}
            rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
            normalize={phoneNumberNormalizeFunction}
          >
            <Input />
          </Form.Item>
        </Col>

        {isCurrentUserSystemAdmin && (
          <Col span={colSpan}>
            <Form.Item
              name="systemAdmin"
              className="form-item-without-label"
              valuePropName="checked"
              initialValue={false}
              rules={[validations.none]}
            >
              <Checkbox onChange={handleSystemAdminChange}>{t("user.attrs.systemAdmin")}</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Divider orientation="left">{t("user.titles.accounts")}</Divider>
      <Collapse accordion className="margin-top-medium" defaultActiveKey="0">
        {[...Array(userAccounts.length)].map((_, index) => (
          <Collapse.Panel
            key={index}
            forceRender
            header={
              userAccounts[index]?.representingAgent
                ? `${formatAgentName(userAccounts[index]?.agent)} | ${formatAgentName(
                    userAccounts[index]?.representingAgent
                  )}`
                : formatAgentName(userAccounts[index]?.agent)
            }
            extra={
              <span onClick={event => event.stopPropagation()}>
                <ActionButton
                  icon="minus"
                  label={t("user.actions.deleteAccount")}
                  disabled={userAccounts.length === 1}
                  onClick={() => handleAccountDelete(index)}
                />
              </span>
            }
          >
            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <AgentSelect
                  formItemProps={{
                    name: ["accounts", index, "agentId"],
                    label: t("user.attrs.agentId"),
                    rules: [validations.notNull]
                  }}
                  optionsProps={{ onChange: agent => handleAgentChange(index, agent) }}
                />
              </Col>

              <Col span={colSpan}>
                <AgentSelect
                  formItemProps={{
                    name: ["accounts", index, "representingAgentId"],
                    label: (
                      <LabelWithTooltip
                        label={t("user.attrs.representingAgentId")}
                        tooltip={t("user.helpers.representingAgentInfo")}
                      />
                    ),
                    rules: [
                      userAccounts[index]?.agent?.type === AgentType.LEGAL ? validations.notNull : validations.none
                    ]
                  }}
                  selectProps={{
                    allowClear: true,
                    disabled: userAccounts[index]?.agent?.type !== AgentType.LEGAL
                  }}
                  optionsProps={{
                    filter:
                      userAccounts[index]?.agent && userAccounts[index]?.agent?.representatives
                        ? agentOption =>
                            (userAccounts[index]?.agent?.representatives as AgentBase[]).some(
                              representative => representative.id === agentOption.id
                            )
                        : undefined,
                    onChange: agent => handleRepresentingAgentChange(index, agent)
                  }}
                />
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name={["accounts", index, "disabled"]}
                  className="form-item-without-label"
                  valuePropName="checked"
                  initialValue={false}
                  rules={[validations.none]}
                >
                  <Checkbox>{t("user.attrs.disabled")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" className="divider-subheader">
              {t("user.titles.permissionsSettings")}
            </Divider>

            <DefaultPermissionsFormPart
              allowedFeatures={topAgentAllowedFeatures}
              onPermissionsSet={permissions => handleDefaultPermissionsSet(permissions, index)}
            />

            <Row gutter={rowGutter}>
              <Col span={24}>
                <Form.Item noStyle shouldUpdate={(prev, next) => prev.systemAdmin !== next.systemAdmin}>
                  {({ getFieldValue }) => (
                    <Form.Item label={t("user.attrs.permissionsLabel")} required>
                      <Tree
                        showLine={{ showLeafIcon: false }}
                        checkStrictly
                        checkable
                        checkedKeys={{ checked: userAccounts[index]?.permissions ?? [], halfChecked: [] }}
                        treeData={buildPermissionTreeNodes(
                          topAgentAllowedFeatures,
                          userAccounts[index]?.permissions,
                          isCurrentUserSystemAdmin,
                          getFieldValue("systemAdmin")
                        )}
                        onCheck={checked => handlePermissionTreeCheck(index, checked as AntTreeStrictCheckedProps)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        ))}
      </Collapse>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <ActionButton
            icon="plus"
            label={t("user.actions.addAccount")}
            className="margin-top-small"
            onClick={handleAccountAdd}
          />
        </Col>
      </Row>

      <Button className="margin-top-medium" type="primary" htmlType="submit" icon={<AntIcon type="save" />}>
        {t("common.save")}
      </Button>
    </Form>
  );
};

export default AdminCreateUserForm;
