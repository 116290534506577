import { Tag } from "antd";
import t from "../../../../app/i18n";
import { CommissionsSettingsLevelType } from "../enums";

interface Props {
  type: CommissionsSettingsLevelType;
  style?: React.CSSProperties;
  closable?: boolean;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const CommissionsLevelTypeTag = ({ type, style, closable, onClose }: Props) => {
  let color: string;

  switch (type) {
    case CommissionsSettingsLevelType.BASIC:
      color = "blue";
      break;
    case CommissionsSettingsLevelType.CAREER:
      color = "magenta";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} style={style} closable={closable} onClose={onClose}>
      {t("commissions.level.enums.type." + type)}
    </Tag>
  );
};

export default CommissionsLevelTypeTag;
