import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  wide?: boolean;
}

const ContentWrapper = ({ children, wide }: Props) => (
  <div
    className={classNames("content-wrapper", {
      "content-wrapper--wide": wide
    })}
  >
    {children}
  </div>
);

export default ContentWrapper;
