import moment from "moment";
import t from "../../../../../app/i18n";
import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";
import HtmlView from "../../../../../common/components/views/HtmlView";
import { formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { NaturalClient } from "../../../types";

interface Props {
  client: NaturalClient;
}

const NaturalClientDataView = ({ client }: Props) => (
  <table className="data-table-view">
    <tbody>
      <tr>
        <td>{t("client.attrs.aggregatedName")}:</td>
        <td colSpan={3}>{client.aggregatedName}</td>
      </tr>
      <tr>
        <td colSpan={4} />
      </tr>
      <tr>
        <td>{t("client.attrs.personalIdentificationNumberLong")}:</td>
        <td>
          <CopyToClipboardView content={client.personalIdentificationNumber} />
        </td>
        <td>{t("client.attrs.birthDate")}:</td>
        <td>
          {`${formatLocaleDate(client.birthDate)} (${t("client.helpers.age")}: ${moment().diff(
            moment(client.birthDate),
            "years"
          )})`}
        </td>
      </tr>
      <tr>
        <td>{t("client.attrs.identityCardNumber")}:</td>
        <td>
          <CopyToClipboardView content={client.identityCardNumber} />
        </td>
        <td>{t("client.attrs.nationalityLabel")}:</td>
        <td>{client.nationality}</td>
      </tr>
      <tr>
        <td colSpan={4} />
      </tr>
      <tr>
        <td colSpan={4}>{t("client.attrs.note")}:</td>
      </tr>
      {client.note && (
        <tr>
          <td colSpan={4}>
            <HtmlView value={client.note} />
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default NaturalClientDataView;
