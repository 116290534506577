import { Modal } from "antd";
import { AxiosResponse } from "axios";
import { Pathname } from "history";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { push } from "redux-first-history";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../app/i18n";
import { Permission, Role } from "../../common/security/authorization/enums";
import { RootState } from "../../common/types";
import { toMoment } from "../../common/utils/formUtils";
import messageUtils from "../../common/utils/messageUtils";
import { hasPermission, isDefinedValue, openUrl } from "../../common/utils/utils";
import type { UUID } from "../../typings/global";
import { deleteProfilePictureActions, updateAgentActions, updateProfilePictureActions } from "../agent/ducks";
import { AgentProfilePicture } from "../agent/types";
import { deleteStateDashboardDataAction } from "../dashboard/ducks";
import { DASHBOARD_ROUTE_PATHS } from "../dashboard/paths";
import { changeRunningRequestKeyAction, selectRouterLocationPathname } from "../ducks";
import { deleteStateEnumerationsAction, getEnumerationsActions } from "../enumerations/ducks";
import {
  deleteStateHeaderNotificationsListAction,
  FILTER_NOTIFICATIONS_DEFAULT_REQUEST,
  filterHeaderNotificationsActions
} from "../notifications/ducks";
import { adminDeleteAgentUserAccountActions, adminUpdateUserActions } from "../user/ducks";
import { CURRENT_USER_ROUTE_PATHS } from "../user/paths";
import { User, UserAccount, UserTotpDevice } from "../user/types";
import api from "./api";
import { AUTH_ROUTE_PATHS } from "./paths";
import {
  AuthData,
  AuthReducerState,
  AuthSelectedAccount,
  LoginBaseRequest,
  LoginRequest,
  VerifyTotpCodeRequest
} from "./types";

/**
 * ACTIONS
 */
export const loginActions = createAsyncAction("auth/LOGIN_REQUEST", "auth/LOGIN_SUCCESS", "auth/LOGIN_FAILURE")<
  LoginRequest,
  AuthData,
  void
>();

export const sendTotpCodeViaSmsActions = createAsyncAction(
  "auth/SEND_TOTP_CODE_REQUEST",
  "auth/SEND_TOTP_CODE_SUCCESS",
  "auth/SEND_TOTP_CODE_FAILURE"
)<LoginBaseRequest, void, void>();

export const verifyTotpCodeActions = createAsyncAction(
  "auth/VERIFY_TOTP_CODE_REQUEST",
  "auth/VERIFY_TOTP_CODE_SUCCESS",
  "auth/VERIFY_TOTP_CODE_FAILURE"
)<VerifyTotpCodeRequest, AuthData, void>();

export const refreshTokenActions = createAsyncAction(
  "auth/REFRESH_TOKEN_REQUEST",
  "auth/REFRESH_TOKEN_SUCCESS",
  "auth/REFRESH_TOKEN_FAILURE"
)<void, AuthData, void>();

export const logoutAction = createAction("auth/LOGOUT")<void>();

export const checkAuthStateAction = createAction("auth/CHECK_AUTH_STATE")<void>();

export const clearAuthDataAction = createAction("auth/CLEAR_AUTH_DATA")<void>();

export const switchSelectedAccountAction = createAction("auth/SWITCH_SELECTED_ACCOUNT")<UUID>();

export const setSelectedAccountAction = createAction("auth/SET_SELECTED_ACCOUNT")<AuthSelectedAccount>();

const actions = {
  loginActions,
  sendTotpCodeViaSmsActions,
  verifyTotpCodeActions,
  refreshTokenActions,
  logoutAction,
  checkAuthStateAction,
  clearAuthDataAction,
  switchSelectedAccountAction,
  setSelectedAccountAction
};

export type AuthAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: AuthReducerState = {
  authData: {
    token: undefined,
    tokenValidUntil: undefined,
    rememberMeToken: undefined,
    rememberMeTokenValidUntil: undefined,
    user: undefined,
    totpDevices: [],
    maskedUserPhone: ""
  },
  selectedAccount: {
    userId: undefined,
    role: undefined,
    accountId: undefined
  }
};

const authDataReducer = createReducer(initialState.authData)
  .handleAction([loginActions.success, verifyTotpCodeActions.success], (_, { payload }) => payload)
  .handleAction([refreshTokenActions.success], (state, { payload }) => ({
    ...payload,
    rememberMeToken: state.rememberMeToken,
    rememberMeTokenValidUntil: state.rememberMeTokenValidUntil
  }))
  .handleAction(
    [
      loginActions.failure,
      verifyTotpCodeActions.failure,
      refreshTokenActions.failure,
      clearAuthDataAction,
      logoutAction
    ],
    state => ({
      ...initialState.authData,
      rememberMeToken: state.rememberMeToken,
      rememberMeTokenValidUntil: state.rememberMeTokenValidUntil,
      totpDevices: state.totpDevices,
      maskedUserPhone: state.maskedUserPhone
    })
  )
  .handleAction([updateProfilePictureActions.success, deleteProfilePictureActions.success], (state, { payload }) => {
    const accountIndex = state.user?.agentUserRole?.userAccounts?.findIndex(a => a.agent?.id === payload.id);
    if (isDefinedValue(accountIndex) && accountIndex !== -1) {
      const userAccounts = cloneDeep(state.user?.agentUserRole?.userAccounts);
      if (!userAccounts || accountIndex === undefined) {
        return state;
      }

      const agent = userAccounts[accountIndex]?.agent;

      if (agent && state.user && state.user.agentUserRole) {
        agent.profilePicture = payload.object as AgentProfilePicture;
        return { ...state, user: { ...state.user, agentUserRole: { ...state.user.agentUserRole, agent } } };
      }
    }
    return state;
  })
  .handleAction(adminUpdateUserActions.success, (state, { payload }) =>
    state.user?.id === payload.id ? { ...state, user: { ...state.user, name: payload.name } } : state
  )
  .handleAction(updateAgentActions.success, (state, { payload }) => {
    let accountIndex = state.user?.agentUserRole?.userAccounts?.findIndex(a => a.agent?.id === payload.id);
    if (isDefinedValue(accountIndex) && accountIndex !== -1) {
      const userAccounts = cloneDeep(state.user?.agentUserRole?.userAccounts);

      if (!userAccounts || accountIndex === undefined) {
        return state;
      }

      const agent = userAccounts[accountIndex]?.agent;

      if (agent && state.user && state.user.agentUserRole) {
        agent.aggregatedName = payload.aggregatedName;
        agent.structureIdNumber = payload.structureIdNumber;

        return { ...state, user: { ...state.user, agentUserRole: { ...state.user.agentUserRole, agent } } };
      }
    }

    accountIndex = state.user?.agentUserRole?.userAccounts?.findIndex(a => a.representingAgent?.id === payload.id);
    if (isDefinedValue(accountIndex) && accountIndex !== -1) {
      const userAccounts = cloneDeep(state.user?.agentUserRole?.userAccounts);

      if (!userAccounts || accountIndex === undefined) {
        return state;
      }

      const representingAgent = userAccounts[accountIndex]?.representingAgent;
      if (representingAgent && state.user && state.user.agentUserRole) {
        representingAgent.aggregatedName = payload.aggregatedName;
        representingAgent.structureIdNumber = payload.structureIdNumber;
        return {
          ...state,
          user: { ...state.user, agentUserRole: { ...state.user.agentUserRole, representingAgent } }
        };
      }
    }

    return state;
  });

const selectedAccountReducer = createReducer(initialState.selectedAccount)
  .handleAction(setSelectedAccountAction, (_, { payload }) => payload)
  .handleAction(adminDeleteAgentUserAccountActions.success, (state, { payload }) =>
    payload.id2 === state.accountId ? initialState.selectedAccount : state
  );

export const authReducer = combineReducers<AuthReducerState>({
  authData: authDataReducer,
  selectedAccount: selectedAccountReducer
});

/**
 * SELECTORS
 */
const selectAuth = (state: RootState): AuthReducerState => state.auth;

export const selectAuthData = (state: RootState): AuthData => selectAuth(state).authData;

export const selectSelectedAccount = (state: RootState): AuthSelectedAccount => selectAuth(state).selectedAccount;

export const selectToken = (state: RootState): string | undefined => selectAuthData(state).token;

export const selectRememberMeToken = (state: RootState): string | undefined => selectAuthData(state).rememberMeToken;

export const selectUser = (state: RootState): User | undefined => selectAuthData(state).user;

export const selectTotpDevices = (state: RootState): UserTotpDevice[] => selectAuthData(state).totpDevices;

export const selectMaskedUserPhone = (state: RootState): string | undefined => selectAuthData(state).maskedUserPhone;

export const selectUserAccount = (state: RootState): UserAccount | undefined => {
  const user = selectUser(state);
  const selectedAccount = selectSelectedAccount(state);
  return (
    (selectedAccount.role === Role.AGENT ? user?.agentUserRole?.userAccounts : user?.clientUserRole?.userAccounts) || []
  ).find(account => account.id === selectedAccount.accountId);
};

export const selectIsSystemAdmin = (state: RootState): boolean | undefined =>
  selectUser(state)?.agentUserRole?.systemAdmin;

export const selectPermissions = (state: RootState): Permission[] => selectUserAccount(state)?.permissions || [];

export const hasUserMultipleAccounts = (state: RootState): boolean => {
  const user = selectUser(state);
  const selectedAccount = selectSelectedAccount(state);
  const hasMultipleAccountsAgent = user?.agentUserRole?.userAccounts?.filter(
    account => account.confirmed && !account.disabled
  );
  const hasMultipleAccountsClient = user?.clientUserRole?.userAccounts?.filter(
    account => account.confirmed && !account.disabled
  );

  return selectedAccount.role === Role.AGENT
    ? !!hasMultipleAccountsAgent && hasMultipleAccountsAgent.length > 1
    : !!hasMultipleAccountsClient && hasMultipleAccountsClient.length > 1;
};

export const selectIsUserAuthenticated = createSelector(selectAuthData, authData => isUserAuthenticated(authData));

export const selectHasPermissions = (...checkedPermissions: Permission[]) =>
  createSelector(selectPermissions, accountPermissions =>
    checkedPermissions.every(checkedPermission => hasPermission(accountPermissions, checkedPermission))
  );

export const selectHasAnyPermissions = (...checkedPermissions: Permission[]) =>
  createSelector(selectPermissions, accountPermissions =>
    checkedPermissions.some(checkedPermission => hasPermission(accountPermissions, checkedPermission))
  );

export const selectContainedPermissions = (...checkedPermissions: Permission[]) =>
  createSelector(selectPermissions, accountPermissions =>
    checkedPermissions.filter(checkedPermission => hasPermission(accountPermissions, checkedPermission))
  );

/**
 * SAGAS
 */
function* login({ payload }: ReturnType<typeof loginActions.request>) {
  try {
    const response: AxiosResponse<AuthData> = yield call(api.login, payload);
    yield put(loginActions.success(response.data));

    if (response.data.token) {
      yield* afterSuccessfulAuthentication(response.data);
    } else {
      yield put(changeRunningRequestKeyAction());
    }
  } catch (error) {
    yield put(loginActions.failure());
  }
}

function* sendTotpCodeViaSms({ payload }: ReturnType<typeof sendTotpCodeViaSmsActions.request>) {
  try {
    yield call(api.sendTotpCodeViaSms, payload);
    yield put(sendTotpCodeViaSmsActions.success());
    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("user.helpers.sendTotpCodeViaSmsSuccess")
    });
    yield put(changeRunningRequestKeyAction());
  } catch (error) {
    yield put(sendTotpCodeViaSmsActions.failure());
  }
}

function* verifyTotpCode({ payload }: ReturnType<typeof verifyTotpCodeActions.request>) {
  try {
    const response: AxiosResponse<AuthData> = yield call(api.verifyTotpCode, payload);
    yield put(verifyTotpCodeActions.success(response.data));

    yield* afterSuccessfulAuthentication(response.data);
  } catch (error) {
    yield put(verifyTotpCodeActions.failure());
  }
}

function* afterSuccessfulAuthentication(data: AuthData) {
  const lastAccount: AuthSelectedAccount = yield select(selectSelectedAccount);

  if (
    lastAccount.userId !== data.user?.id ||
    !(
      (lastAccount.role === Role.AGENT &&
        data.user?.agentUserRole?.userAccounts.some(a => a.id === lastAccount.accountId && !a.disabled)) ||
      (lastAccount.role === Role.CLIENT &&
        data.user?.clientUserRole?.userAccounts.some(a => a.id === lastAccount.accountId && !a.disabled))
    )
  ) {
    // TODO(multi-tenant) doplnit moznost, ze ak ma pouzivatel vytvorenu aj agent aj client rolu, aby si vedel vybrat ako sa chce prihlasit
    const activeAgentAccounts = data.user?.agentUserRole?.userAccounts.filter(
      account => account.confirmed && !account.disabled
    );

    const activeClientAccounts = data.user?.clientUserRole?.userAccounts.filter(
      account => account.confirmed && !account.disabled
    );

    if (activeAgentAccounts && activeAgentAccounts.length) {
      yield put(
        setSelectedAccountAction({
          userId: data.user?.id,
          role: data.user?.agentUserRole?.role,
          accountId: activeAgentAccounts[0]?.id
        })
      );
    } else if (activeClientAccounts && activeClientAccounts.length) {
      yield put(
        setSelectedAccountAction({
          userId: data.user?.id,
          role: data.user?.clientUserRole?.role,
          accountId: activeClientAccounts[0]?.id
        })
      );
    }
  }

  yield put(checkAuthStateAction());

  const selectedAccount: AuthSelectedAccount = yield select(selectSelectedAccount);

  if (selectedAccount.role === Role.AGENT) {
    yield put(getEnumerationsActions.request());
    yield put(filterHeaderNotificationsActions.request(FILTER_NOTIFICATIONS_DEFAULT_REQUEST));

    if (data.totpDevices.length === 0) {
      yield new Promise<void>(resolve =>
        Modal.warning({
          title: t("login.titles.totpDeviceNotFound"),
          content: t("login.helpers.totpDeviceRecommendation"),
          okText: t("login.actions.addTotpDeviceNow"),
          closable: true,
          maskClosable: false,
          onOk: () => {
            resolve();
            openUrl(
              window.location.origin + generatePath(CURRENT_USER_ROUTE_PATHS.profile.to) + "?addTotpDevice=true",
              "_blank"
            );
          },
          onCancel: () => resolve()
        })
      );
    }
  }
}

function* refreshToken() {
  try {
    const response: AxiosResponse<AuthData> = yield call(api.refreshToken);
    yield put(refreshTokenActions.success(response.data));
    yield put(checkAuthStateAction());
  } catch (error) {
    yield put(refreshTokenActions.failure());
  }
}

function* logout() {
  const location: Pathname = yield select(selectRouterLocationPathname);
  if (location !== AUTH_ROUTE_PATHS.login.to) {
    yield put(push(AUTH_ROUTE_PATHS.login.to));
  }
  // we call checkAuthStateAction again and by using takeLatest effect we cancel previously timed refresh token action
  yield put(checkAuthStateAction());
  yield put(deleteStateEnumerationsAction());
  yield put(deleteStateHeaderNotificationsListAction());
  yield put(deleteStateDashboardDataAction());
}

function* checkAuthState() {
  const authData: AuthData = yield select(selectAuthData);

  if (!isUserAuthenticated(authData)) {
    yield put(clearAuthDataAction());
  } else {
    const validityTime = moment(authData.tokenValidUntil).subtract(10, "minute");

    if (moment().isAfter(validityTime)) {
      yield put(refreshTokenActions.request());
    } else {
      yield delay(Math.min(validityTime.valueOf() - moment().valueOf(), MAX_RELOGIN_DELAY_MS));
      yield put(refreshTokenActions.request());
    }
  }
}

function* switchSelectedAccount({ payload }: ReturnType<typeof switchSelectedAccountAction>) {
  const user: User = yield select(selectUser);
  const selectedAccount: AuthSelectedAccount = yield select(selectSelectedAccount);

  yield put(deleteStateDashboardDataAction());

  if (selectedAccount.role === Role.AGENT && user.agentUserRole?.userAccounts.some(a => a.id === payload)) {
    yield put(setSelectedAccountAction({ ...selectedAccount, accountId: payload }));
    yield put(push(DASHBOARD_ROUTE_PATHS.homepage.to));
    yield put(getEnumerationsActions.request());
    yield put(filterHeaderNotificationsActions.request(FILTER_NOTIFICATIONS_DEFAULT_REQUEST));
  } else if (selectedAccount.role === Role.CLIENT && user.clientUserRole?.userAccounts.some(a => a.id === payload)) {
    yield put(setSelectedAccountAction({ ...selectedAccount, accountId: payload }));
    yield put(push(DASHBOARD_ROUTE_PATHS.homepage.to));
  }
}

export function* authSaga() {
  yield takeLatest(loginActions.request, login);
  yield takeLatest(sendTotpCodeViaSmsActions.request, sendTotpCodeViaSms);
  yield takeLatest(verifyTotpCodeActions.request, verifyTotpCode);
  yield takeLatest(refreshTokenActions.request, refreshToken);
  yield takeLatest(logoutAction, logout);
  yield takeLatest(checkAuthStateAction, checkAuthState);
  yield takeLatest(switchSelectedAccountAction, switchSelectedAccount);
}

/**
 * HELPERS
 */
const MAX_RELOGIN_DELAY_MS = 259_200_000; // 3 days

const isUserAuthenticated = (authData: AuthData): boolean | undefined => {
  return authData.user && authData.token && authData.tokenValidUntil
    ? toMoment(authData.tokenValidUntil)?.isAfter(moment())
    : undefined;
};
