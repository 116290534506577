import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect } from "react";
import t from "../../../../../../app/i18n";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import { selectTagsStandardProps } from "../../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../../common/utils/validationUtils";
import { CommissionProcessingResult, PostponementReason, postponementReasonTMap } from "../../../enums";
import { CommissionsFilterPageRequest, CommissionsFilterPageResult } from "../../../types";
import PostponementReasonTag from "../../PostponementReasonTag";

interface Props {
  filter: CommissionsFilterPageResult;
  onFilterSubmit: (filter: CommissionsFilterPageRequest) => void;
}

type CommissionsFilterPageRequestForm = CommissionsFilterPageRequest & { onlyPostponed: boolean };

const BatchCommissionFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<CommissionsFilterPageRequestForm>();

  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: filter.keyword,
      processingResult: filter.processingResult,
      onlyPostponed: filter.processingResult === CommissionProcessingResult.POSTPONED,
      postponementReasons: filter.postponementReasons || []
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnlyPostponedChange = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      form.setFieldsValue({ postponementReasons: [] });
    }
  };

  const handleFilterSubmit = (filter: CommissionsFilterPageRequestForm): void => {
    const processedFilter: Record<string, any> = {
      ...filter,
      processingResult: filter.onlyPostponed ? CommissionProcessingResult.POSTPONED : undefined
    };
    delete processedFilter.onlyPostponed;

    onFilterSubmit(processedFilter as CommissionsFilterPageRequest);
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFilterSubmit} name="commissionFilterForm">
      <Row gutter={rowGutter} justify="center">
        <Col span={colSpan}>
          <Form.Item
            name="keyword"
            label={t("common.searchKeyword")}
            rules={[
              validations.size(
                validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                validationConstants.SEARCH_KEYWORD_MAX_LENGTH
              )
            ]}
          >
            <Input allowClear placeholder={t("commissions.batch.helpers.commissionsSearchHint")} />
          </Form.Item>
        </Col>

        <Col span={colSpan} className="center-align">
          <Form.Item
            name="onlyPostponed"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox onChange={handleOnlyPostponedChange}>{t("commissions.batch.filter.onlyPostponed")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.onlyPostponed !== next.onlyPostponed}>
            {({ getFieldValue }) => (
              <Form.Item
                name="postponementReasons"
                label={t("commissions.batch.enums.postponementReason._label")}
                rules={[validations.none]}
              >
                <Select
                  {...selectTagsStandardProps(postponementReasonTMap)}
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  disabled={!getFieldValue("onlyPostponed")}
                  tagRender={props => (
                    <PostponementReasonTag
                      reason={PostponementReason[props.value as keyof typeof PostponementReason]}
                      closable={props.closable}
                      onClose={props.onClose}
                    />
                  )}
                  options={Object.keys(PostponementReason).map(reason => ({
                    value: reason,
                    label: (
                      <PostponementReasonTag reason={PostponementReason[reason as keyof typeof PostponementReason]} />
                    )
                  }))}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col flex="110px" className="no-title-space">
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
              {t("common.filter")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BatchCommissionFilterView;
