import { MenuDividerType, MenuItemType, SubMenuType } from "antd/lib/menu/hooks/useItems";
import classNames from "classnames";
import { Location, Search } from "history";
import { Link, To } from "react-router-dom";
import { pageRulePermissionsMap, Permission } from "../security/authorization/enums";
import { MenuDividerProps, MenuItemProps, SubMenuItemProps } from "../types";
import { hasAllPermissions, hasAnyPermission } from "./utils";

export const createMenuItem = ({
  path,
  label,
  permissions,
  routerLocation,
  configProps,
  ...itemProps
}: MenuItemProps): MenuItemType | undefined => {
  return checkHasPathAccess(path, permissions)
    ? {
        ...itemProps,
        key: itemProps.key || path,
        className: classNames(itemProps.className, { "menu-item-link": !configProps?.topLevelMenuItem }),
        label: configProps?.externalPath ? (
          <a href={path} target="_blank" rel="noreferrer">
            {label}
          </a>
        ) : (
          <Link to={resolveLocationDescriptor(path, routerLocation, configProps?.search)}>{label}</Link>
        )
      }
    : undefined;
};

export const createSubMenuItem = ({
  path,
  permissions,
  ...subMenuProps
}: SubMenuItemProps): SubMenuType | undefined => {
  return checkHasPathAccess(path, permissions) ? { ...subMenuProps, key: subMenuProps.key || path } : undefined;
};

export const createMenuDivider = ({
  path,
  permissions,
  ...dividerProps
}: MenuDividerProps): MenuDividerType | undefined => {
  return checkHasPathAccess(path, permissions)
    ? {
        ...dividerProps,
        key: dividerProps.key || path,
        type: "divider",
        className: classNames(dividerProps?.className, "menu-divider")
      }
    : undefined;
};

const checkHasPathAccess = (path: string, permissions: Permission[]): boolean => {
  const permissionsObject = pageRulePermissionsMap.get(path);
  return (
    !permissionsObject ||
    (permissionsObject.isAnyPermissionRequired
      ? hasAnyPermission(permissions, permissionsObject.permissions)
      : hasAllPermissions(permissions, permissionsObject.permissions))
  );
};

const resolveLocationDescriptor = (pathname: string, routerLocation: Location, search?: Search): To => ({
  pathname,
  search: search ? search : routerLocation.pathname === pathname ? routerLocation.search : undefined
});
