import { green, red } from "@ant-design/colors";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import InfoIcon from "../../../../../common/components/icons/InfoIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import TableActionsView from "../../../../../common/components/views/TableActionsView";
import { PageSizes, TableSizes } from "../../../../../common/constants";
import { formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import type { UUID } from "../../../../../typings/global";
import {
  createAgentBrokerNumberActions,
  deleteAgentBrokerNumberActions,
  getAgentBrokerNumbersActions,
  updateAgentBrokerNumberActions
} from "../../../ducks";
import { AgentBrokerNumber } from "../../../types";
import AgentBrokerNumberForm from "../../forms/AgentBrokerNumberForm";

interface Props {
  agentId: UUID;
  brokerNumbers: AgentBrokerNumber[];
  showAllColumns?: boolean;
  showActions?: boolean;
  onGet: typeof getAgentBrokerNumbersActions.request;
  onCreate?: typeof createAgentBrokerNumberActions.request;
  onUpdate?: typeof updateAgentBrokerNumberActions.request;
  onDelete?: typeof deleteAgentBrokerNumberActions.request;
}

const AgentBrokerNumberTableView = ({ agentId, brokerNumbers, showAllColumns, showActions, ...props }: Props) => {
  const [brokerNumberFormOpen, setBrokerNumberFormOpen] = useState<boolean>(false);
  const [brokerNumberToUpdate, setBrokerNumberToUpdate] = useState<AgentBrokerNumber>();

  const today = moment();

  useEffect(() => {
    props.onGet({ id: agentId });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBrokerNumberUpdateClick = (brokerNumber: AgentBrokerNumber): void => {
    setBrokerNumberToUpdate(brokerNumber);
    setBrokerNumberFormOpen(true);
  };

  const handleBrokerNumberFormCancel = (): void => {
    setBrokerNumberToUpdate(undefined);
    setBrokerNumberFormOpen(false);
  };

  const columns: ColumnsType<AgentBrokerNumber> = [
    {
      key: "institution",
      title: t("agent.brokerNumber.attrs.institutionId"),
      width: 140,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "value",
      title: t("agent.brokerNumber.attrs.value"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.value}</Ellipsis>
    },
    {
      key: "validFrom",
      title: t("agent.brokerNumber.attrs.validFrom"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.validFrom)
    },
    {
      key: "validTo",
      title: t("agent.brokerNumber.attrs.validTo"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.validTo)
    },
    {
      key: "active",
      title: t("agent.brokerNumber.helpers.active"),
      align: "center",
      width: 80,
      render: (_, record) =>
        (record.validFrom ? today.isSameOrAfter(record.validFrom, "day") : true) &&
        (record.validTo ? today.isSameOrBefore(record.validTo, "day") : true) ? (
          <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
        ) : (
          <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
        )
    }
  ];

  if (showAllColumns) {
    columns.splice(4, 0, {
      key: "commonUsage",
      title: t("agent.brokerNumber.attrs.commonUsage"),
      align: "center",
      width: 80,
      render: (_, record) => <AntIcon type={record.commonUsage ? "check" : "close-circle"} />
    });

    columns.push({
      key: "note",
      align: "center",
      width: 40,
      render: (_, record) =>
        record.note ? <InfoIcon title={t("agent.brokerNumber.attrs.note")} tooltip={record.note} /> : null
    });

    if (showActions) {
      columns.push({
        key: "actions",
        align: "right",
        fixed: "right",
        width: 180,
        render: (_, record) => (
          <TableActionsView
            actions={[
              {
                color: "blue",
                text: t("common.edit"),
                icon: "edit",
                onClick: () => handleBrokerNumberUpdateClick(record)
              },
              {
                color: "red",
                text: t("common.delete"),
                icon: "delete",
                confirmDialog: {
                  icon: <PopconfirmDeleteIcon />,
                  title: t("agent.brokerNumber.helpers.deleteConfirm"),
                  okType: "danger",
                  okText: t("common.yes"),
                  cancelText: t("common.no")
                },
                onClick: () => props.onDelete?.({ id1: agentId, id2: record.id })
              }
            ]}
          />
        )
      });
    }
  }

  return (
    <>
      <Table<AgentBrokerNumber>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.MEDIUM }}
        dataSource={brokerNumbers}
        pagination={{ ...paginationTableProps, pageSize: PageSizes.LARGE, total: brokerNumbers.length }}
      />

      {showActions && (
        <>
          <div className={brokerNumbers.length > 0 ? "table-footer-margin" : "margin-top-small"}>
            <Button
              type="primary"
              size="small"
              icon={<AntIcon type="plus" />}
              onClick={() => setBrokerNumberFormOpen(true)}
            >
              {t("common.add")}
            </Button>
          </div>

          <AgentBrokerNumberForm
            open={brokerNumberFormOpen}
            agentId={agentId}
            brokerNumber={brokerNumberToUpdate}
            onCreate={props.onCreate}
            onUpdate={props.onUpdate}
            onFormCancel={handleBrokerNumberFormCancel}
          />
        </>
      )}
    </>
  );
};

export default AgentBrokerNumberTableView;
