import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import AgentEndingCompetenceFilterView from "../components/views/endingcompetence/AgentEndingCompetenceFilterView";
import AgentEndingCompetenceTableView from "../components/views/endingcompetence/AgentEndingCompetenceTableView";
import {
  deleteStateAgentEndingCompetencesPageAction,
  filterAgentEndingCompetencesActions,
  selectAgentEndingCompetencesPage,
  updateAgentCompetenceActions
} from "../ducks";
import { AgentCompetenceLevel, AgentCompetenceProvider, AgentCompetenceType, FinancialSector } from "../enums";
import { AgentCompetenceFilterPageRequest, AgentCompetenceFilterPageResult } from "../types";

interface StateProps {
  endingCompetencesCurrentPage: AgentCompetenceFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterAgentEndingCompetences: typeof filterAgentEndingCompetencesActions.request;
  updateAgentCompetence: typeof updateAgentCompetenceActions.request;
  deleteStateAgentEndingCompetencesPage: typeof deleteStateAgentEndingCompetencesPageAction;
}

const AgentEndingCompetenceContainer = ({
  endingCompetencesCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterAgentEndingCompetences({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.HUGE,
      types: urlParams.getAll("types") as AgentCompetenceType[],
      sectors: urlParams.getAll("sectors") as FinancialSector[],
      levels: urlParams.getAll("levels") as AgentCompetenceLevel[],
      providers: urlParams.getAll("providers") as AgentCompetenceProvider[],
      agentIds: urlParams.getAll("agentIds")
    });
    return () => {
      actions.deleteStateAgentEndingCompetencesPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: AgentCompetenceFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined }), { replace: true });
    actions.filterAgentEndingCompetences({ ...filter, pageIndex: 0, pageSize: endingCompetencesCurrentPage.pageSize });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, types, sectors, levels, providers, agentIds } = endingCompetencesCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterAgentEndingCompetences({
      pageIndex: pageNumber - 1,
      pageSize,
      types,
      sectors,
      levels,
      providers,
      agentIds
    });
  };

  return (
    <ContentWrapper>
      <AgentEndingCompetenceFilterView filter={endingCompetencesCurrentPage} onFilterSubmit={handleFilterSubmit} />
      <AgentEndingCompetenceTableView
        competencesPage={endingCompetencesCurrentPage}
        onPageChange={handleTablePageChange}
        onUpdate={actions.updateAgentCompetence}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  endingCompetencesCurrentPage: selectAgentEndingCompetencesPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterAgentEndingCompetences: filterAgentEndingCompetencesActions.request,
      updateAgentCompetence: updateAgentCompetenceActions.request,
      deleteStateAgentEndingCompetencesPage: deleteStateAgentEndingCompetencesPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AgentEndingCompetenceContainer);
