import { Pathname } from "history";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectIsAnyRequestInProgress,
  selectRouterLocationPathname,
  selectRunningRequestKey
} from "../../modules/ducks";
import type { ApiRequest } from "../api/ApiRequestAdapter";
import type { EntityIdObject, RootState, ThreeLevelEntityIdObject } from "../types";
import { resolvePageTitle } from "./navigationUtils";
import { regexPatterns } from "./validationUtils";

export const useOnMount = (callback: Parameters<typeof useEffect>[0]): void => {
  useEffect(callback, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useRequestFinishedCallback = (requests: ApiRequest[], callback?: VoidFunction): boolean => {
  const requestInProgress = useSelector<RootState, boolean>(state => selectIsAnyRequestInProgress(state, requests));
  const runningRequestKey = useSelector<RootState, string | undefined>(selectRunningRequestKey);

  const [requestStarted, setRequestStarted] = useState<boolean>(false);

  useEffect(() => {
    if (requestInProgress) {
      setRequestStarted(true);
    }
  }, [requestInProgress]);

  useEffect(() => {
    if (requestStarted) {
      setRequestStarted(false);
      callback?.();
    }
  }, [runningRequestKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return requestInProgress;
};

export const useBackNavigation = (): VoidFunction => {
  const navigate = useNavigate();

  return (): void => {
    if (window.history.length <= 1) {
      navigate("..");
    } else {
      navigate(-1);
    }
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{ innerWidth: number; outerWidth: number; height: number }>({
    innerWidth: window.innerWidth,
    outerWidth: window.outerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        outerWidth: window.outerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const useScrollToTopOnLoad = (): void => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const useDocumentTitle = (): void => {
  const pathname = useSelector<RootState, Pathname>(selectRouterLocationPathname);
  useEffect(() => {
    document.title = resolvePageTitle(pathname);
  }, [pathname]);
};

export const useAreParamsIdsValid = (): boolean => {
  const { id, id1, id2, id3 } = useParams<EntityIdObject & ThreeLevelEntityIdObject>();
  return (
    (!id || regexPatterns.exactUuidRegex.test(id)) &&
    (!id1 || regexPatterns.exactUuidRegex.test(id1)) &&
    (!id2 || regexPatterns.exactUuidRegex.test(id2)) &&
    (!id3 || regexPatterns.exactUuidRegex.test(id3))
  );
};
