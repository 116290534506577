import { Button } from "antd";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import ComponentWithPermission from "../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../common/security/authorization/enums";

interface Props {
  requiredPermission: Permission;
  onFormSubmit: () => void;
  onShowResultsClick: () => void;
  onReturnToCalculationClick: () => void;
}

const CalcGenNavigation = (props: Props) => (
  <div className="bottom-fixed-navigation">
    <Button icon={<AntIcon type="calculator" />} onClick={props.onReturnToCalculationClick}>
      {t("calc.actions.backToCalculation")}
    </Button>

    <ComponentWithPermission permissions={[props.requiredPermission]}>
      <Button
        size="large"
        icon={<AntIcon type="solution" />}
        type="primary"
        className="margin-left-small margin-right-small"
        onClick={props.onFormSubmit}
      >
        {t("calc.actions.generateContract")}
      </Button>
    </ComponentWithPermission>

    <Button className="secondary-button" icon={<AntIcon type="double-left" />} onClick={props.onShowResultsClick}>
      {t("common.showResults")}
    </Button>
  </div>
);

export default CalcGenNavigation;
