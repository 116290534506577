import { Divider } from "antd";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { accessTreePathDelimiter } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentIdNumber } from "../../../../../common/utils/formatUtils";
import { isDefined, stripAccents } from "../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { AgentType } from "../../../enums";
import { AGENT_ROUTE_PATHS } from "../../../paths";
import { AgentWithSubordinates } from "../../../types";
import AgentTypeTag from "../../AgentTypeTag";

interface Props {
  agent: AgentWithSubordinates;
  sanitizedSearchKeyword?: string;
}

const AgentTreeNodeTitleView = ({ agent, sanitizedSearchKeyword }: Props) => {
  const hasAgentCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.AGENT_COMMISSIONS)(state)
  );

  const highlightSearchKeyword = (str?: string): React.ReactNode => {
    if (sanitizedSearchKeyword && str) {
      const sanitize = (str: string): string | undefined => stripAccents(str)?.toLowerCase()?.trim();

      const keywordStartIndex = sanitize(str)?.indexOf(sanitizedSearchKeyword);
      if (isDefined<number>(keywordStartIndex) && keywordStartIndex > -1) {
        const beforeKeyword = str.substring(0, keywordStartIndex);
        const keyword = str.substring(keywordStartIndex, keywordStartIndex + sanitizedSearchKeyword.length);
        const afterKeyword = str.substring(keywordStartIndex + sanitizedSearchKeyword.length);
        return (
          <span>
            {beforeKeyword}
            <span className="search-keyword">{keyword}</span>
            {afterKeyword}
          </span>
        );
      }
    }

    return <span>{str}</span>;
  };

  const agentRepresentatives = agent.representatives?.map(r => r.displayName).join(", ");

  return (
    <span className={classNames("agent-tree-node", { deactivated: agent.deactivated })}>
      <AgentTypeTag type={agent.type} />

      <span
        style={{
          display: "inline-block",
          width: 420 - ((agent.accessTreePath?.split(accessTreePathDelimiter) ?? []).length - 1) * 24
        }}
      >
        <Ellipsis tooltip={agent.aggregatedName + (agentRepresentatives ? " | " + agentRepresentatives : "")}>
          <Link className="no-link-color" to={generatePath(AGENT_ROUTE_PATHS.detail.to, { id: agent.id })}>
            {highlightSearchKeyword(agent.aggregatedName)}
          </Link>
          {agentRepresentatives && (
            <span className="select-option-secondary-row"> | {highlightSearchKeyword(agentRepresentatives)}</span>
          )}
        </Ellipsis>
      </span>

      <span className="id-number">
        <span className="property-label">{t("agent.attrs.idNumber")}:</span>
        <span>{highlightSearchKeyword(formatAgentIdNumber(agent))}</span>
      </span>

      <span className="identifier">
        <span className="property-label">
          {t(
            "agent.attrs." +
              (agent.type === AgentType.NATURAL ? "personalIdentificationNumber" : "companyRegistrationNumber")
          )}
          :
        </span>
        <span>{highlightSearchKeyword(agent.identifier)}</span>
      </span>

      <span className="commissions-settings-level">
        {agent.commissionsSettings.commissionsSettingsLevel && (
          <>
            <span className="property-label">{t("agent.helpers.commissionsSettingsLevel")}:</span>
            <span>{highlightSearchKeyword(agent.commissionsSettings.commissionsSettingsLevel.code)}</span>
          </>
        )}
      </span>

      <span className="tipper">{agent.tipper && t("agent.attrs.tipper")}</span>

      <span className="actions">
        <ActionTextIcon
          color="blue"
          icon="eye"
          text={t("common.show")}
          path={generatePath(AGENT_ROUTE_PATHS.detail.to, { id: agent.id })}
        />

        {hasAgentCommissionsPermission && (
          <>
            <Divider type="vertical" />
            <ActionTextIcon
              color="green"
              icon="euro"
              text={t("common.commissions")}
              path={generatePath(AGENT_ROUTE_PATHS.commissions.to, { id: agent.id })}
            />
          </>
        )}
      </span>
    </span>
  );
};

export default AgentTreeNodeTitleView;
