import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import {
  EntityObject,
  PageRequest,
  PageResult,
  RootState,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { initPageResult, initSearchPageResult } from "../../../common/utils/apiUtils";
import { openBlobFile } from "../../../common/utils/utils";
import { CommissionsBatchOutputAttachment } from "../batch/types";
import {
  SpecialCommissionBase,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../special/types";
import api from "./api";
import {
  CalculatedCommissionAgentProfile,
  CalculatedCommissionsFilterPageRequest,
  CalculatedCommissionsFilterPageResult,
  CommissionsOutputReducerState
} from "./types";

/**
 * ACTIONS
 */
export const filterCommissionsOutputsActions = createAsyncAction(
  "commissions-output/FILTER_REQUEST",
  "commissions-output/FILTER_SUCCESS",
  "commissions-output/FILTER_FAILURE"
)<EntityObject<PageRequest>, PageResult<CommissionsBatchOutputAttachment>, void>();

export const deleteStateCommissionsOutputsPageAction = createAction("commissions-output/DELETE_STATE_LIST")<void>();

export const downloadCommissionsOutputActions = createAsyncAction(
  "commissions-output/DOWNLOAD_REQUEST",
  "commissions-output/DOWNLOAD_SUCCESS",
  "commissions-output/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const filterOutputCalculatedCommissionsActions = createAsyncAction(
  "output-calculated-commissions/FILTER_REQUEST",
  "output-calculated-commissions/FILTER_SUCCESS",
  "output-calculated-commissions/FILTER_FAILURE"
)<TwoLevelEntityObject<CalculatedCommissionsFilterPageRequest>, CalculatedCommissionsFilterPageResult, void>();

export const deleteStateOutputCalculatedCommissionsPageAction = createAction(
  "output-calculated-commissions/DELETE_STATE_LIST"
)<void>();

export const filterOutputSpecialCommissionsActions = createAsyncAction(
  "output-special-commissions/FILTER_REQUEST",
  "output-special-commissions/FILTER_SUCCESS",
  "output-special-commissions/FILTER_FAILUE"
)<
  TwoLevelEntityObject<SpecialCommissionsFilterPageRequest>,
  SpecialCommissionsFilterPageResult<SpecialCommissionBase>,
  void
>();

export const deleteStateOutputSpecialCommissionsPageAction = createAction(
  "output-special-commissions/DELETE_STATE_LIST"
)<void>();

const actions = {
  filterCommissionsOutputsActions,
  deleteStateCommissionsOutputsPageAction,
  downloadCommissionsOutputActions,
  filterOutputCalculatedCommissionsActions,
  deleteStateOutputCalculatedCommissionsPageAction,
  filterOutputSpecialCommissionsActions,
  deleteStateOutputSpecialCommissionsPageAction
};

export type CommissionsOutputAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CommissionsOutputReducerState = {
  outputsPage: initPageResult<CommissionsBatchOutputAttachment>(),
  outputCalculatedCommissionsPage: {
    ...initSearchPageResult<CalculatedCommissionAgentProfile>(),
    institutionIds: [],
    batch: undefined,
    agent: undefined,
    commissionAmountsSum: undefined
  },
  outputSpecialCommissionsPage: {
    ...initPageResult<SpecialCommissionBase>(),
    report: undefined,
    code: undefined,
    batch: undefined,
    agent: undefined,
    commissionAmountsSum: undefined
  }
};

const commissionsOutputsPageReducer = createReducer(initialState.outputsPage)
  .handleAction(filterCommissionsOutputsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterCommissionsOutputsActions.failure, deleteStateCommissionsOutputsPageAction],
    () => initialState.outputsPage
  );

const outputCalculatedCommissionsPageReducer = createReducer(initialState.outputCalculatedCommissionsPage)
  .handleAction(filterOutputCalculatedCommissionsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterOutputCalculatedCommissionsActions.failure, deleteStateOutputCalculatedCommissionsPageAction],
    () => initialState.outputCalculatedCommissionsPage
  );

const outputSpecialCommissionsPageReducer = createReducer(initialState.outputSpecialCommissionsPage)
  .handleAction(filterOutputSpecialCommissionsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterOutputSpecialCommissionsActions.failure, deleteStateOutputSpecialCommissionsPageAction],
    () => initialState.outputSpecialCommissionsPage
  );

export const commissionsOutputsReducer = combineReducers<CommissionsOutputReducerState>({
  outputsPage: commissionsOutputsPageReducer,
  outputCalculatedCommissionsPage: outputCalculatedCommissionsPageReducer,
  outputSpecialCommissionsPage: outputSpecialCommissionsPageReducer
});

/**
 * SELECTORS
 */
const selectCommissionsOutput = (state: RootState): CommissionsOutputReducerState => state.commissions.output;

export const selectCommissionsOutputsPage = (state: RootState): PageResult<CommissionsBatchOutputAttachment> =>
  selectCommissionsOutput(state).outputsPage;
export const selectOutputCalculatedCommissionsPage = (state: RootState): CalculatedCommissionsFilterPageResult =>
  selectCommissionsOutput(state).outputCalculatedCommissionsPage;
export const selectOutputSpecialCommissionsPage = (
  state: RootState
): SpecialCommissionsFilterPageResult<SpecialCommissionBase> =>
  selectCommissionsOutput(state).outputSpecialCommissionsPage;

/**
 * SAGAS
 */
function* filterCommissionsOutputs({ payload }: ReturnType<typeof filterCommissionsOutputsActions.request>) {
  try {
    const response: AxiosResponse<PageResult<CommissionsBatchOutputAttachment>> = yield call(
      api.filterCommissionsOutputs,
      payload
    );
    yield put(filterCommissionsOutputsActions.success(response.data));
  } catch (error) {
    yield put(filterCommissionsOutputsActions.failure());
  }
}

function* downloadCommissionsOutput({ payload }: ReturnType<typeof downloadCommissionsOutputActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCommissionsOutput, payload);
    openBlobFile(response);
    yield put(downloadCommissionsOutputActions.success());
  } catch (error) {
    yield put(downloadCommissionsOutputActions.failure());
  }
}

function* filterOutputCalculatedCommissions({
  payload
}: ReturnType<typeof filterOutputCalculatedCommissionsActions.request>) {
  try {
    const response: AxiosResponse<CalculatedCommissionsFilterPageResult> = yield call(
      api.filterOutputCalculatedCommissions,
      payload
    );
    yield put(filterOutputCalculatedCommissionsActions.success(response.data));
  } catch (error) {
    yield put(filterOutputCalculatedCommissionsActions.failure());
  }
}

function* filterOutputSpecialCommissions({
  payload
}: ReturnType<typeof filterOutputSpecialCommissionsActions.request>) {
  try {
    const response: AxiosResponse<SpecialCommissionsFilterPageResult<SpecialCommissionBase>> = yield call(
      api.filterOutputSpecialCommissions,
      payload
    );
    yield put(filterOutputSpecialCommissionsActions.success(response.data));
  } catch (error) {
    yield put(filterOutputSpecialCommissionsActions.failure());
  }
}

export function* commissionsOutputSaga() {
  yield takeLatest(filterCommissionsOutputsActions.request, filterCommissionsOutputs);
  yield takeLatest(downloadCommissionsOutputActions.request, downloadCommissionsOutput);
  yield takeLatest(filterOutputCalculatedCommissionsActions.request, filterOutputCalculatedCommissions);
  yield takeLatest(filterOutputSpecialCommissionsActions.request, filterOutputSpecialCommissions);
}
